import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { FiltroTabelasAdministrativas } from '@tabelasAdministrativas/models/filtro';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'sgp-pesquisar-tabela-administrativa',
	templateUrl: './pesquisar-tabela-administrativa.component.html',
	providers: [MessageService]
})
export class PesquisarTabelaAdministrativaComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() labelNome? = 'Nome';
	@Input() habilitarFiltroCadastradoUsuario? = false;
	@Input() habilitarFiltroFerramenta = false;
	@Input() pesquisaAoCarregar? = false;
	@Input() limparFormulario? = false;
	@Output() filtrosPesquisa: EventEmitter<FiltroTabelasAdministrativas> = new EventEmitter<FiltroTabelasAdministrativas>();
	@Output() executarLimpar: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('elTextoPesquisa') elTextoPesquisa!: ElementRef;

	textoPesquisa!: string;
	ativo = false;
	ferramenta = false;
	cadastradoUsuario = false;
	resultado: any[] = [];

	ngOnChanges(): void {
		if (this.limparFormulario) {
			this.resetarFormulario();
		}
	}

	ngOnInit(): void {
		this.resetarFormulario();
		if (this.pesquisaAoCarregar) {
			this.pesquisar();
		}
	}

	ngAfterViewInit(): void {
		this.elTextoPesquisa.nativeElement.focus();
	}

	pesquisar() {
		const filtro = new FiltroTabelasAdministrativas();
		filtro.nome = this.textoPesquisa;
		filtro.ativo = this.ativo;
		filtro.ferramenta = this.ferramenta;
		filtro.incluido_usuario = this.cadastradoUsuario;
		this.filtrosPesquisa.emit(filtro);
	}

	limpar() {
		this.resetarFormulario();
		this.elTextoPesquisa.nativeElement.focus();
		this.executarLimpar.emit(true);
	}

	private resetarFormulario() {
		this.textoPesquisa = '';
		this.ativo = false;
		this.ferramenta = false;
		this.cadastradoUsuario = false;
		this.limparFormulario = false;
	}
}
