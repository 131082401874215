import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { FiltroTabelasAdministrativas } from '@tabelasAdministrativas/models/filtro';
import { ValidateHelper } from '@core/helpers/validate.helper';

@Component({
	selector: 'sgp-manter-tabela-administrativa',
	templateUrl: './manter-tabela-administrativa.component.html'
})
export class ManterTabelaAdministrativaComponent implements AfterViewInit {
	@Input() item!: FiltroTabelasAdministrativas;
	@Input() tabela = '';
	@Input() exibirCadastradoPeloUsuario!: boolean;
	@Input() exibirFerramenta = false;
	@Output() itemSalvo: EventEmitter<FiltroTabelasAdministrativas> = new EventEmitter<FiltroTabelasAdministrativas>();
	@ViewChild('elNomeCampo') elNomeCampo!: ElementRef;
	erroNome = false;
	mensagemErroNome = '';
	desabilitar = true;

	constructor(private validate: ValidateHelper) {}

	ngAfterViewInit(): void {
		this.elNomeCampo.nativeElement.focus();
	}

	salvar() {
		this.validarPreenchimentoNome();
		if (!this.erroNome) {
			this.itemSalvo.emit(this.item);
		}
	}

	cancelar() {
		this.item = new FiltroTabelasAdministrativas();
		this.erroNome = false;
	}

	validarPreenchimentoNome() {
		const nomeTrim = this.item.nome?.trim();
		if (this.validate.isEmBranco(nomeTrim)) {
			this.erroNome = true;
			this.mensagemErroNome = 'Campo obrigatório';
		} else if (nomeTrim && nomeTrim.length < 3) {
			this.erroNome = true;
			this.mensagemErroNome = 'O campo deve possuir pelo menos três caracteres';
		} else {
			this.item.nome = nomeTrim;
			this.erroNome = false;
		}
	}
}
