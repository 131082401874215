<div class="br-card">
	<div class="card-content" *ngIf="dados; else skeleton">
		<div class="titulo d-flex justify-content-between">
			<div>{{ titulo }}</div>
			<div>
				<button
					pButton
					class="br-button circle"
					title="Anterior"
					(click)="voltar()"
					data-cy="botao-navegacao-grafico-voltar"
					icon="fas fa-chevron-left"
					[disabled]="pagina === 1"
				></button>

				<button
					pButton
					class="br-button circle"
					title="Próximo"
					(click)="avancar()"
					data-cy="botao-navegacao-grafico-avancar"
					icon="fas fa-chevron-right"
					[disabled]="!(totalResultado/ITENS_POR_PAGINA > pagina)"
				></button>
			</div>
		</div>
		<div class="grafico_bar_horizontal">
			<p-chart type="bar" [data]="data" [options]="options" height="250"></p-chart>
		</div>
	</div>
	<ng-template #skeleton>
		<p-skeleton width="50rem" height="23rem"></p-skeleton>
	</ng-template>
</div>
