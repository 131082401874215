import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AlertMessageService } from '@sigepe/sigepe-template';
import { SegurancaService } from '@sigepe/ngx-sigepe-seguranca';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { filter } from 'rxjs/operators';
import pkg from '../../package.json';

@Component({
	selector: 'sgp-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	showNotifications = true;
	autenticado = false;
	public version = pkg.version;

	constructor(
		private activatedRoute: ActivatedRoute,
		private alertMessageService: AlertMessageService,
		private router: Router,
		private segurancaService: SegurancaService,
		private titleService: Title
	) {}

	ngOnInit() {
		this.configurarTituloPagina();
		this.limparMensagens();
		this.titleService.setTitle('');
	}

	isDevelopment() {
		return !environment.production && this.autenticado;
	}

	logout() {
		this.segurancaService.logout();
	}

	private configurarTituloPagina() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			const rt = this.getChild(this.activatedRoute);
			rt.data.subscribe((data: { title: string }) => {
				this.titleService.setTitle(data.title + ' - Oportunidades v.' + this.version);
			});
		});
	}

	private getChild(activatedRoute: ActivatedRoute): any {
		if (activatedRoute.firstChild) {
			return this.getChild(activatedRoute.firstChild);
		} else {
			return activatedRoute;
		}
	}

	private limparMensagens() {
		this.alertMessageService.dismissAll();
	}
}
