import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { ChartModule } from 'primeng/chart';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
	standalone: true,
	selector: 'sgp-card-grafico-line',
	templateUrl: './card-grafico-line.component.html',
	imports: [CommonModule, ChartModule, SkeletonModule]
})
export class CardGraficoLineComponent implements AfterViewInit, OnChanges {
	@Input() titulo!: string;
	@Input() dados?: any[];
	@Input() exibirLegenda = true;
	@Input() height?: number;
	colors = ['#2C608A55', '#AACDEC55'];
	backgrounds = ['#2C608A55', '#AACDEC55'];
	data: any;
	options: any;
	legendas?: string[];
	valores?: number[];
	grupos?: any[];

	constructor() {
		this.data = undefined;
		this.dados = [];
		this.options = undefined;
		this.grupos = [];
	}

	ngOnChanges(): void {
		this.obterDados();
		this.obterOpcoes(this.exibirLegenda);
	}

	ngAfterViewInit() {
		this.obterDados();
		this.obterOpcoes(this.exibirLegenda);
	}

	private obterDados() {
		this.data = {
			labels: [],
			datasets: []
		};

		if (this.dados) {
			for (const dado of this.dados) {
				this.grupos!.push(dado);
				this.data.labels = dado.legenda;

				for (const grupo of this.grupos!) {
					this.data.datasets[grupo.id - 1] = {
						label: grupo.grupo,
						data: grupo.dados,
						fill: true,
						borderColor: this.colors[grupo.id - 1],
						backgroundColor: this.backgrounds[grupo.id - 1],
						tension: 0.4,
						pointStyle: 'rectRounded'
					};
				}
			}
		}
	}

	private obterOpcoes(exibirLegenda: boolean) {
		this.options = {
			interaction: {
				mode: 'index',
				intersect: false
			},
			elements: {
				points: {
					pointStyle: 'star'
				}
			},
			plugins: {
				legend: {
					display: exibirLegenda
				}
			}
		};
	}
}
