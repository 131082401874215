import { Component, Input } from '@angular/core';

@Component({
	selector: 'sgp-item-label-descricao',
	templateUrl: './item-label-descricao.component.html'
})
export class ItemLabelDescricaoComponent {
	@Input() id!: string;
	@Input() label!: string;
	@Input() conteudo?: string;
	@Input() obrigatorioPublicacao = false; //Se for verdadeiro, exibe uma mensagem avisando que o campo é orbigatório para publicação
	@Input() renderizarHTML = false;
}
