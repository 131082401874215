<div class="br-wizard">
	<div class="wizard-progress">
		<button class="wizard-progress-btn" type="button" title="Dados básicos" step="1" disabled>
			<span class="info mt-4">Dados básicos</span>
		</button>
		<button class="wizard-progress-btn" type="button" title="Cadastrar oportunidades" step="2" disabled>
			<span class="info mt-4">Cadastrar oportunidades</span>
		</button>
		<button class="wizard-progress-btn" type="button" title="Cadastrar etapas" step="3" disabled>
			<span class="info mt-4">Cadastrar etapas</span>
		</button>
		<button class="wizard-progress-btn" type="button" title="Incluir anexos" step="4" disabled>
			<span class="info mt-4">Incluir anexos</span>
		</button>
		<button class="wizard-progress-btn" type="button" title="Revisar dados" step="5" disabled>
			<span class="info mt-4">Revisar dados</span>
		</button>
	</div>
	<div class="wizard-form">
		<div class="wizard-panel" active>
			<div class="content">
				<div class="row">
					<div class="col mt-4 ml-1">
						<p-skeleton width="14rem" height="2.3rem" styleClass="mb-5"></p-skeleton>

						<p-skeleton width="10rem" height="1rem" styleClass="mb-1"></p-skeleton>
						<p-skeleton width="23rem" height="2.3rem" styleClass="mb-3"></p-skeleton>

						<p-skeleton width="7rem" height="1rem" styleClass="mb-1"></p-skeleton>
						<p-skeleton width="48rem" height="2.3rem" styleClass="mb-3"></p-skeleton>

						<p-skeleton width="5rem" height="1rem" styleClass="mb-1"></p-skeleton>
						<p-skeleton width="48rem" height="2.3rem" styleClass="mb-3"></p-skeleton>

						<p-skeleton width="15rem" height="1rem" styleClass="mb-1"></p-skeleton>
						<p-skeleton width="61rem" height="8rem" styleClass="mb-1"></p-skeleton>
						<p-skeleton width="18rem" height="1rem" styleClass="mb-3"></p-skeleton>
					</div>
				</div>
			</div>
			<div class="wizard-panel-btn pr-5 text-right">
				<p-skeleton width="10rem" height="3rem" borderRadius="100em" styleClass="float-right"></p-skeleton>
			</div>
		</div>
	</div>
</div>
