import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { Anexo } from '@core/models/anexo';
import { CadastrarEditalApiService } from './../services/cadastrar-edital-api.service';
import { Edital } from '@core/models/edital';
import { EditalService } from '@app/cadastrarEdital/services/edital.service';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-listar-anexos',
	templateUrl: './listar-anexos.component.html',
	providers: [MessageService, ConfirmationService]
})
export class ListarAnexosComponent implements OnInit, OnDestroy {
	@Input() edital!: Edital;
	anexos!: Anexo[];
	anexo!: Anexo;
	operacao = 'listar';
	subscriptions: Subscription[] = [];
	loading = false;

	constructor(
		private cadastrarEditalApi: CadastrarEditalApiService,
		private confirmationService: ConfirmationService,
		private editalService: EditalService,
		private messageService: MessageService,
		private oportunidadesService: OportunidadesApiService,
		private ui: UiService
	) {}

	ngOnInit() {
		this.carregarAnexos(this.edital.id);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 *  Cadastrar novo anexo
	 */
	novoAnexo(): void {
		this.operacao = 'cadastrar';
		this.messageService.clear();
		this.anexo = {};
	}

	/**
	 * Cancela o cadastramento/alteração de anexo, voltando a exibir a lista de anexos.
	 * @param event operacao='listar'
	 */
	cancelar(event: string): void {
		this.operacao = event;
		this.atualizarAnexo();
	}

	async download(idAnexo: number) {
		await this.editalService.downloadAnexo(idAnexo);
	}

	/**
	 * Excluir anexo
	 * @param anexo anexo a ser excluído
	 */
	excluirAnexo(idAnexo: number): void {
		this.messageService.clear();
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir este anexo?',
			accept: async () => {
				this.loading = true;
				this.subscriptions.push(
					this.cadastrarEditalApi.excluirAnexo(idAnexo).subscribe(
						async res => {
							if (res) {
								this.loading = false;
								this.atualizarAnexo();
								this.messageService.add(this.ui.criarMensagem('success', '', 'Anexo excluído com sucesso.'));
							}
						},
						err => {
							this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
						}
					)
				);
			}
		});
	}

	/**
	 * Testa se a operação é de listar oportunidades
	 * @returns true | false
	 */
	isListar(): boolean {
		return this.operacao === 'listar';
	}

	/**
	 * Testa se a operação é de cadastrar ou alterar
	 * @returns true | false
	 */
	isManter(): boolean {
		return this.operacao === 'cadastrar' || this.operacao === 'alterar';
	}

	private async atualizarAnexo() {
		this.operacao = 'listar';
		this.anexo = {};
		this.carregarAnexos(this.edital.id);
	}

	private carregarAnexos(idEdital: number) {
		this.loading = true;
		this.subscriptions.push(
			this.oportunidadesService.consultarAnexosEdital(idEdital).subscribe(
				res => {
					if (res) {
						this.anexos = res;
						this.loading = false;
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}
}
