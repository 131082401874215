export class ResultadoPesquisarDashboardProfissiogafico {
	totalQuestionariosEnviados?: string;
	totalLaudosPriorizados?: string;
	situcaoLiberacaoQuestionario?: any;
	situacaoLaudos: any;
	situacaoCienciaLaudos: any;
	situacaoAtribuicaoLaudos: any;
}

export interface TotaisDashboardPsicografico {
	codigoOrgao: number[];
	dados: string[];
	legenda: string[];
	situacao: string[];
}
