export class AlterarConclusaoLaudo {
	id?: number;
	analiseCurricular?: string;
	resultadoQuestionario?: string;
	sugestaoAlocacao?: string;
	comentariosAnaliseEixo?: ComentariosAnaliseEixo[];
}

export class ComentariosAnaliseEixo {
	id?: number;
	texto?: string;
	eixoAvaliacao?: EixoAvaliacao;
	naoPreenchido: boolean = false;
}

export class EixoAvaliacao {
	id?: number;
	nome?: string;
}
