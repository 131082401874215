/**
 * Candidato
 */
export class Candidato {
	id?: number;
	idOportunidade?: number;
	nomeCandidato?: string;
	cpfCandidato?: number;
	emailCandidato?: string;
	dataHoraInscricao?: string;
	curriculoJson?: string;
	localidadeResidencia?: string;
	nomeVinculo?: string;
	marcado?: boolean;
	favorito?: boolean;
	quantidadeAnexos?: number;
	
}
