import { Edital } from './edital';
import { Subject } from 'rxjs';
/**
 * Id Componente: usado para criar componente
 */
import { Type } from '@angular/core';

export class IdComponent {
	constructor(
		public component: Type<any>,
		public data: any,
		public edital: Edital,
		public salvando?: Subject<boolean>,
		public idEdital?: number,
		public situacaoEdital?: any
	) {}
}
