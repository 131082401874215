import { TabelasAdministrativas } from './tabelasAdministrativas';
/**
 * Área de atuação
 */
export class AreaAtuacao extends TabelasAdministrativas {
	incluido_usuario?: boolean;

	constructor(id?: number, nome?: string, ativo?: boolean, incluido_usuario?: boolean) {
		super(id, nome, ativo);
		this.incluido_usuario = incluido_usuario ? incluido_usuario : false;
	}
}
