import { Message } from 'primeng/api';

export class FeedbackModal {
	exibirModal = false;
	mensagemFeedback?: Message[] = [];
	reload = false;

	constructor(exibirModal: boolean, mensagemFeedback?: Message[], reload = false) {
		this.exibirModal = exibirModal;
		this.mensagemFeedback = mensagemFeedback;
		this.reload = reload;
	}
}
