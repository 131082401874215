import { Edital } from '../models/edital';
import { IAreaAtuacao } from '../interfaces/areaAtuacao.interface';
import { IAreaConhecimento } from '../interfaces/areaConhecimento.interface';
import { ICapacitacao } from '../interfaces/capacitacao.interface';
import { ICertificacao } from '../interfaces/certificacao.interface';
import { ICompetencia } from '../interfaces/competencia.interface';
import { IEdital } from '../interfaces/edital.interface';
import { IEtapa } from '../interfaces/etapas.interface';
import { IExperiencia } from '../interfaces/experiencia.interface';
import { IFormacao } from '../interfaces/formacao.interface';
import { IIdioma } from '../interfaces/idioma.interface';
import { IOportunidade } from '../interfaces/oportunidade.interface';
import { Injectable } from '@angular/core';
import { Oportunidade } from '../models/oportunidade';
import { TipoDocumento } from '@app/core/models/tipo-documento';
import { ValidateHelper } from './validate.helper';
import { VinculoInterface } from '../interfaces/vinculo.interface';

/**
 * Helper com métodos de conversão de edital entre .
 */
@Injectable({
	providedIn: 'root'
})
export class ConverterEditalInterfaceHelper {
	constructor(private validate: ValidateHelper) {}
	/**
	 * Converte o modelo de dados para a interface json do edital necessária para inclusão
	 * @param edital edital do modelo
	 * @returns interface do edital
	 */
	converter(edital: Edital): IEdital {
		//Converte os dados básicos do edital
		const iEdital: IEdital = {
			id: this.validate.validaCampoComRetorno(edital.id, null),
			codigoOrgao: this.validate.validaCampoComRetorno(edital.codigoOrgao, 0),
			emailContato: this.validate.validaCampoComRetorno(edital.emailContato, ''),
			exigenciaLegal: this.validate.validaCampoComRetorno(edital.exigenciaLegal, ''),
			preambulo: this.validate.validaCampoComRetorno(edital.preambulo, ''),
			sobreUnidade: this.validate.validaCampoComRetorno(edital.sobreUnidade, ''),
			sobreVagasOportunidades: this.validate.validaCampoComRetorno(edital.sobreVagasOportunidades, '')
			//situacao: edital.situacao
		};

		//Converte as etapas do edital
		if (edital.etapas !== undefined) {
			const iEtapas: IEtapa[] = this.converterEtapasParaInterface(edital);
			iEdital.etapas = iEtapas;
		}

		//Converter Oportunidades
		if (edital.oportunidades !== undefined) {
			const iOportunidades: IOportunidade[] = this.converterOportunidadesParaInterface(edital);
			iEdital.oportunidades = iOportunidades;
		}
		return iEdital;
	}

	private converterEtapasParaInterface(edital: Edital) {
		const iEtapas: IEtapa[] = [];
		edital.etapas!.forEach(etapa => {
			const iEtapa: any = {
				id: this.validate.validaCampoComRetorno(etapa.id, null),
				dataFimEtapa: this.validate.validaCampoComRetorno(etapa.dataFimEtapa, null, etapa.dataFimEtapa),
				dataInicioEtapa: this.validate.validaCampoComRetorno(etapa.dataInicioEtapa, null, etapa.dataInicioEtapa),
				idTipoEtapaEdital: this.validate.validaCampoComRetorno(etapa.tipoEtapaEdital, 0, etapa.tipoEtapaEdital!.id),
				descricaoEtapa: this.validate.validaCampoComRetorno(etapa.descricaoEtapa, ''),
				orientacoesEtapa: this.validate.validaCampoComRetorno(etapa.orientacoesEtapa, ''),
				informacaoCandidatura: this.validate.validaCampoComRetorno(etapa.informacaoCandidatura, ''),
				permiteEnvioDocumentos: this.validate.validaCampoComRetorno(etapa.permiteEnvioDocumentos, false),
				tiposDocumento: this.converterTipoDocumento(etapa.tiposDocumento)
			};
			iEtapas.push(iEtapa);
		});
		return iEtapas;
	}

	private converterTipoDocumento(tipoDocumento?: TipoDocumento[]) {
		if (tipoDocumento === undefined) {
			return [];
		}
		return tipoDocumento;
	}

	public criarIOportunidadeAPartirDeUmaOportunidade(oportunidade: Oportunidade) {
		const iOportunidade: IOportunidade = {
			id: this.validate.validaCampoComRetorno(oportunidade.id, null),
			nomeOportunidade: this.validate.validaCampoComRetorno(oportunidade.nomeOportunidade, ''),
			descricaoResumida: this.validate.validaCampoComRetorno(oportunidade.descricaoResumida, ''),
			atividades: this.validate.validaCampoComRetorno(oportunidade.atividades, ''),
			tipoMovimentacao: this.validate.validaCampoComRetorno(oportunidade.tipoMovimentacao?.codigo, ''),
			programaGestao: this.validate.validaCampoComRetorno(oportunidade.programaGestao?.codigo, ''),
			tipoJornada: this.validate.validaCampoComRetorno(oportunidade.tipoJornada?.codigo, ''),
			idIncentivo: this.validate.validaCampoComRetorno(oportunidade.idIncentivo, null),
			codigoLocalidade: this.validate.validaCampoComRetorno(oportunidade.codigoLocalidade, null),
			codigoUorg: this.validate.validaCampoComRetorno(oportunidade.codigoUorg, null),
			numeroVagas: this.validate.validaCampoComRetorno(oportunidade.numeroVagas, 0),
			//Requisitos
			codigoMunicipioResidencia: this.validate.validaCampoComRetorno(oportunidade.codigoMunicipioResidencia, null),
			codigoOrgaoExercicio: this.validate.validaCampoComRetorno(oportunidade.codigoOrgaoExercicio, null),
			codigoOrgaoLotacao: this.validate.validaCampoComRetorno(oportunidade.codigoOrgaoLotacao, null),
			outrosRequisitos: this.validate.validaCampoComRetorno(oportunidade.outrosRequisitos, '')
		};
		//Converter Áreas de Atuação da Oportunidade
		if (oportunidade.areasAtuacao !== undefined) {
			const iAreasAtuacao: IAreaAtuacao[] = this.converterAreasAtuacaoParaInterface(oportunidade);
			iOportunidade.areasAtuacao = iAreasAtuacao;
		}
		//Converter Áreas de Conhecimento da Oportunidade
		if (oportunidade.areasConhecimento !== undefined) {
			const iAreasConhecimento: IAreaConhecimento[] = this.converterAreasConhecimentoParaInterface(oportunidade);
			iOportunidade.areasConhecimento = iAreasConhecimento;
		}
		//Converter Competências da Oportunidade
		if (oportunidade.competencias !== undefined) {
			const iCompetencias: ICompetencia[] = this.converterCompetenciasParaInterface(oportunidade);
			iOportunidade.competencias = iCompetencias;
		}
		//Converter Idiomas da Oportunidade
		if (oportunidade.idiomas !== undefined) {
			const iIdiomas: IIdioma[] = this.converterIdiomasParaInterface(oportunidade);
			iOportunidade.idiomas = iIdiomas;
		}
		//Converter Vínculos da Oportunidade
		if (oportunidade.vinculos !== undefined) {
			const iVinculos: VinculoInterface[] = this.converterVinculosParaInterface(oportunidade);
			iOportunidade.vinculos = iVinculos;
		}
		//Converter Capacitações da Oportunidade
		if (oportunidade.capacitacoes !== undefined) {
			const iCapacitacoes: ICapacitacao[] = this.converterCapacitacoesParaInterface(oportunidade);
			iOportunidade.capacitacoes = iCapacitacoes;
		}
		//Converter Certificações da Oportunidade
		if (oportunidade.certificacoes !== undefined) {
			const iCertificacoes: ICertificacao[] = this.converterCertificacoesParaInterface(oportunidade);
			iOportunidade.certificacoes = iCertificacoes;
		}
		//Converter Experiências da Oportunidade
		if (oportunidade.experiencias !== undefined) {
			const iExperiencias: IExperiencia[] = this.converterExperienciasParaInterface(oportunidade);
			iOportunidade.experiencias = iExperiencias;
		}
		//Converter Formações da Oportunidade
		if (oportunidade.formacoes !== undefined) {
			const iFormacoes: IFormacao[] = this.converterFormacoesParaInterface(oportunidade);
			iOportunidade.formacoes = iFormacoes;
		}

		return iOportunidade;
	}

	private converterOportunidadesParaInterface(edital: Edital) {
		const iOportunidades: IOportunidade[] = [];
		edital.oportunidades!.forEach(oportunidade => {
			iOportunidades.push(this.criarIOportunidadeAPartirDeUmaOportunidade(oportunidade))
		});
		return iOportunidades;
	}

	private converterAreasAtuacaoParaInterface(oportunidade: Oportunidade) {
		const iAreasAtuacao: IAreaAtuacao[] = [];
		oportunidade.areasAtuacao!.forEach(area => {
			const iArea: IAreaAtuacao = {
				id: this.validate.validaCampoComRetorno(area.id, null),
				idAreaAtuacao: this.validate.validaCampoComRetorno(area.idAreaAtuacao, 0),
				obrigatorio: this.validate.validaCampoComRetorno(area.obrigatorio, false)
			};
			iAreasAtuacao.push(iArea);
		});
		return iAreasAtuacao;
	}

	private converterAreasConhecimentoParaInterface(oportunidade: Oportunidade) {
		const iAreasConhecimento: IAreaConhecimento[] = [];
		oportunidade.areasConhecimento!.forEach(area => {
			const iArea: IAreaConhecimento = {
				id: this.validate.validaCampoComRetorno(area.id, null),
				idAreaConhecimento: this.validate.validaCampoComRetorno(area.idAreaConhecimento, 0),
				obrigatorio: this.validate.validaCampoComRetorno(area.obrigatorio, false)
			};
			iAreasConhecimento.push(iArea);
		});
		return iAreasConhecimento;
	}

	private converterCompetenciasParaInterface(oportunidade: Oportunidade) {
		const iCompetencias: ICompetencia[] = [];
		oportunidade.competencias!.forEach(competencia => {
			const iCompetencia: ICompetencia = {
				id: this.validate.validaCampoComRetorno(competencia.id, null),
				idCompetencia: this.validate.validaCampoComRetorno(competencia.idCompetencia, 0),
				obrigatorio: this.validate.validaCampoComRetorno(competencia.obrigatorio, false)
			};
			iCompetencias.push(iCompetencia);
		});
		return iCompetencias;
	}

	private converterIdiomasParaInterface(oportunidade: Oportunidade) {
		const iIdiomas: IIdioma[] = [];
		oportunidade.idiomas!.forEach(idioma => {
			const iIdioma: IIdioma = {
				id: this.validate.validaCampoComRetorno(idioma.id, null),
				idIdioma: this.validate.validaCampoComRetorno(idioma.idIdioma, 0),
				obrigatorio: this.validate.validaCampoComRetorno(idioma.obrigatorio, false),
				nivelCompreensao: this.validate.validaCampoComRetorno(
					typeof idioma.nivelCompreensao == 'object' ? idioma.nivelCompreensao.codigo : idioma.nivelCompreensao,
					''
				),
				nivelEscrita: this.validate.validaCampoComRetorno(
					typeof idioma.nivelEscrita == 'object' ? idioma.nivelEscrita.codigo : idioma.nivelEscrita,
					''
				),
				nivelFala: this.validate.validaCampoComRetorno(
					typeof idioma.nivelFala == 'object' ? idioma.nivelFala.codigo : idioma.nivelFala,
					''
				),
				nivelLeitura: this.validate.validaCampoComRetorno(
					typeof idioma.nivelLeitura == 'object' ? idioma.nivelLeitura.codigo : idioma.nivelLeitura,
					''
				)
			};
			iIdiomas.push(iIdioma);
		});
		return iIdiomas;
	}

	private converterVinculosParaInterface(oportunidade: Oportunidade) {
		const iVinculos: VinculoInterface[] = [];
		oportunidade.vinculos!.forEach(vinculo => {
			const iVinculo: VinculoInterface = {
				id: this.validate.validaCampoComRetorno(vinculo.id, null),
				idVinculo: this.validate.validaCampoComRetorno(vinculo.idVinculo, 0),
				excetoEstagioProbatorio: this.validate.validaCampoComRetorno(vinculo.excetoEstagioProbatorio, false),
				obrigatorio: this.validate.validaCampoComRetorno(vinculo.obrigatorio, false)
			};
			iVinculos.push(iVinculo);
		});
		return iVinculos;
	}

	private converterCapacitacoesParaInterface(oportunidade: Oportunidade) {
		const iCapacitacoes: ICapacitacao[] = [];
		oportunidade.capacitacoes!.forEach(capacitacao => {
			const iCapacitacao: ICapacitacao = {
				id: this.validate.validaCampoComRetorno(capacitacao.id, null),
				nomeCapacitacao: this.validate.validaCampoComRetorno(capacitacao.nomeCapacitacao, ''),
				obrigatorio: this.validate.validaCampoComRetorno(capacitacao.obrigatorio, false)
			};
			iCapacitacoes.push(iCapacitacao);
		});
		return iCapacitacoes;
	}

	private converterCertificacoesParaInterface(oportunidade: Oportunidade) {
		const iCertificacoes: ICertificacao[] = [];
		oportunidade.certificacoes!.forEach(certificacao => {
			const iCertificacao: ICertificacao = {
				id: this.validate.validaCampoComRetorno(certificacao.id, null),
				nomeCertificacao: this.validate.validaCampoComRetorno(certificacao.nomeCertificacao, ''),
				obrigatorio: this.validate.validaCampoComRetorno(certificacao.obrigatorio, false)
			};
			iCertificacoes.push(iCertificacao);
		});
		return iCertificacoes;
	}

	private converterExperienciasParaInterface(oportunidade: Oportunidade) {
		const iExperiencias: IExperiencia[] = [];
		oportunidade.experiencias!.forEach(experiencia => {
			const iExperiencia: IExperiencia = {
				id: this.validate.validaCampoComRetorno(experiencia.id, null),
				nomeExperiencia: this.validate.validaCampoComRetorno(experiencia.nomeExperiencia, ''),
				obrigatorio: this.validate.validaCampoComRetorno(experiencia.obrigatorio, false),
				qtdMinimaMesesExperiencia: this.validate.validaCampoComRetorno(experiencia.qtdMinimaMesesExperiencia, null),
				servicoPublico: this.validate.validaCampoComRetorno(experiencia.servicoPublico, false)
			};
			iExperiencias.push(iExperiencia);
		});
		return iExperiencias;
	}

	private converterFormacoesParaInterface(oportunidade: Oportunidade) {
		const iFormacoes: IFormacao[] = [];
		oportunidade.formacoes!.forEach(formacao => {
			const iFormacao: IFormacao = {
				id: this.validate.validaCampoComRetorno(formacao.id, null),
				nomeFormacao: this.validate.validaCampoComRetorno(formacao.nomeFormacao, ''),
				obrigatorio: this.validate.validaCampoComRetorno(formacao.obrigatorio, false),
				idNivelCurso: this.validate.validaCampoComRetorno(formacao.idNivelCurso, null)
			};
			iFormacoes.push(iFormacao);
		});
		return iFormacoes;
	}
}
