import { Component, Input, OnDestroy } from '@angular/core';

import { ConsultasApiService } from '../services/consultas-api.service';
import { IMatchOportunidade } from '@core/interfaces/matchOportunidade.interface';
import { MatchService } from '@core/services/match.service';
import { MessageService } from 'primeng/api';
import { Oportunidade } from '@core/models/oportunidade';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-estatisticas-oportunidade',
	templateUrl: './estatisticas-oportunidade.component.html',
	providers: [MessageService]
})
export class EstatisticasOportunidadeComponent implements OnDestroy {
	@Input() oportunidade!: Oportunidade;
	@Input() idOportunidade!: any;
	@Input() possuiRequisito!: boolean;

	listaMatchOportunidades: IMatchOportunidade[] = [];
	subscriptions: Subscription[] = [];

	exibirModal = false;

	constructor(
		private consultasOportunidadesApi: ConsultasApiService,
		private matchService: MatchService,
		private messageService: MessageService,
		private ui: UiService
	) {
		this.messageService.clear();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	exibirModalEstatistica(): void {
		if (this.possuiRequisito) {
			this.obterResultadoMatch();
			this.exibirModal = true;
		}
	}

	private obterResultadoMatch() {
		this.listaMatchOportunidades = [];
		const parametros = this.matchService.montarParametrosMatch(this.oportunidade);
		this.subscriptions.push(
			this.consultasOportunidadesApi.matchPorRequisitosService(parametros).subscribe(
				res => {
					if (res) {
						this.listaMatchOportunidades = res;
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}
}
