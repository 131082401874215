/**
 * Área de atuação da oportunidade
 */
export class AreaAtuacaoOportunidade {
	id?: number;
	idOportunidade?: number;
	idAreaAtuacao?: number;
	nome?: string;
	obrigatorio?: boolean;

	constructor(id?: number, idAreaAtuacao?: number, nome?: string, obrigatorio?: boolean) {
		this.id = id;
		this.idAreaAtuacao = idAreaAtuacao;
		this.nome = nome;
		this.obrigatorio = obrigatorio;
	}
}
