import { AreaAtuacao } from './area-atuacao';
import { Idioma } from './idioma';
import { NivelCurso } from './nivel-curso';
import { TipoVinculo } from './tipo-vinculo';

export class RequisitosEdital {
	tipoVinculo?: TipoVinculo;
	tipoVinculoObrigatorio = false;
	excetoEstagioProbatorio = false;
	localidade?: any;
	outroRequisito?: string;
	areaConhecimento?: any;
	conhecimentoObrigatorio = false;
	softskills?: any[] = [];
	softskillObrigatorio = false;
	orgaoLotacao?: any;
	orgaoExercicio?: any;
	tipoIdioma?: Idioma;
	nivelCompreensao = 0;
	nivelEscrita = 0;
	nivelFala = 0;
	nivelLeitura = 0;
	idiomaObrigatorio = false;
	nomeCertificacao?: string;
	certificacaoObrigatoria = false;
	nivelFormacao?: NivelCurso;
	nomeFormacao?: string;
	formacaoObrigatoria = false;
	noServicoPublico = true;
	nomeExperiencia?: string;
	qtdMinimaMesesExperiencia?: number;
	experienciaObrigatoria = false;
	areaAtuacao?: AreaAtuacao;
	areaAtuacaoObrigatorio = false;
	nomeCapacitacao?: string;
	capacitacaoObrigatoria = false;
}
