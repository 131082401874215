import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
	standalone: true,
	selector: 'sgp-mini-card',
	templateUrl: './mini-card.component.html',
	styleUrls: ['./mini-card.component.scss'],
	imports: [CommonModule, TooltipModule]
})
export class MiniCardComponent {
	@Input() cor!: string;
	@Input() icone!: string;
	@Input() quantidade?: string;
	@Input() descricao!: string;
	@Input() ajuda = false;
	@Input() textoAjuda?: string;
}
