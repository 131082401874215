<div class="br-message info" role="alert" *ngIf="exibirMensagemAlerta">
	<div class="icon"><i class="fas fa-info-circle fa-lg" aria-hidden="true"></i></div>
	<div class="content">
		<span class="message-title">Informe:</span>
		<p class="text-justify">{{mensagemAlerta?.mensagem}}</p>
		<div class="mt-2" *ngIf="mensagemAlerta?.link">
			Para mais informações, <a [href]="mensagemAlerta?.link | incluirHttp " target="_blank" rel="noopener">acesse o endereço</a>.
		</div>
	</div>
	<div class="close">
		<button class="br-button circle small" type="button" aria-label="Fechar" (click)="esconderMensagemAlerta()">
			<i class="fas fa-times" aria-hidden="true"></i>
		</button>
	</div>
</div>
