<div class="loading medium" *ngIf="loading"></div>

<div class="br-table" *ngIf="!loading">
	<div class="table-header">
		<div class="top-bar my-3">
			<div class="table-title" data-cy="titulo-tabela"></div>
			<button
				class="primary mt-3 mt-sm-0 ml-sm-3"
				(click)="downloadTodosAnexos()"
				type="button"
				pButton
				data-cy="botao-adicionar-anexo"
				disabled="true"
				*ngIf="desabilitarDownloadTodosAnexos"
			>
				Download de todos os anexos
			</button>
			<button
				class="primary mt-3 mt-sm-0 ml-sm-3"
				(click)="downloadTodosAnexos()"
				type="button"
				pButton
				data-cy="botao-adicionar-anexo"
				*ngIf="desabilitarDownloadTodosAnexos===false"
			>
				Download de todos os anexos
			</button>
		</div>
	</div>
	<p-table
		[value]="listaAnexos"
		dataKey="id"
		[rowHover]="true"
		[responsive]="true"
		[paginator]="true"
		[rows]="5"
		data-cy="tabela-anexos-candidato"
	>
		<ng-template pTemplate="header">
			<tr>
				<th id="cabecalho-descricao" data-cy="cabecalho-descricao">Descrição</th>
				<th id="cabecalho-tipo" data-cy="cabecalho-tipo">Tipo</th>
				<th id="cabecalho-etapa" data-cy="cabecalho-etapa">Etapa</th>
				<th id="cabecalho-download" data-cy="cabecalho-download" class="text-center">Download</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-anexo>
			<tr>
				<td headers="cabecalho-descricao" data-cy="descricao-anexo">{{anexo.descricaoAnexo}}</td>
				<td headers="cabecalho-tipo" data-cy="tipo-tipo">{{ anexo.nomeTipoDocumentoEtapa }}</td>
				<td headers="cabecalho-etapa" data-cy="tipo-etapa">{{ anexo.descricaoEtapa }}</td>
				<td headers="cabecalho-download" data-cy="tipo-download" class="text-center">
					<button
						pButton
						class="br-button circle"
						title="Download"
						(click)="downloadAnexo(anexo)"
						data-cy="botao-download-anexo"
						icon="fas fa-download"
						*ngIf="anexo.ativo === true"
					></button>
					<button
						pButton
						class="br-button circle"
						title="Download"
						(click)="downloadAnexo(anexo)"
						data-cy="botao-download-anexo"
						icon="fas fa-download"
						disabled="true"
						*ngIf="anexo.ativo === false"
					></button>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="5" data-cy="mensagem-nenhum-anexo">Nenhum anexo incluído.</td>
			</tr>
		</ng-template>
	</p-table>
</div>
