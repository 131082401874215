<div class="row mt-4">
	<div class="col-12 mb-3">
		<p-skeleton width="15rem" styleClass="mb-3"></p-skeleton>
	</div>
	<div class="col-sm-12 col-md-12 py-4" *ngFor="let i of quantidade">
		<div class="row">
			<div class="col-sm-10 col-md-10 pt-1">
				<p-skeleton width="35rem" height="2rem"></p-skeleton>
			</div>
			<div class="col-sm-2 col-md-2 text-right">
				<p-skeleton width="10rem" height="3rem" borderRadius="16px"></p-skeleton>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p-skeleton width="15rem" styleClass="p-mb-2"></p-skeleton>
				<p-skeleton width="15rem" styleClass="p-mb-2"></p-skeleton>
				<ul>
					<li><p-skeleton width="35rem" styleClass="p-mb-2"></p-skeleton></li>
				</ul>
			</div>
		</div>
	</div>
</div>
