//create pipe
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'incluirHttp'
})
export class IncluirHttpPipe implements PipeTransform {
	transform(url?: string): any {
		if (url) {
			if (!url.startsWith('http')) {
				return `http://${url}`;
			}
			return url;
		}
		return undefined;
	}
}
