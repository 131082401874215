import { Pipe, PipeTransform } from '@angular/core';

import { ValidateHelper } from '../helpers/validate.helper';

/**
 * imprime um dash ('-') no caso de o valor não ser informado.
 */
@Pipe({ name: 'imprimirDash' })
export class ImprimirDashPipe implements PipeTransform {
	constructor(private validate: ValidateHelper) {}

	transform(value: string | undefined): string {
		if (this.validate.isEmBranco(value)) {
			return '-';
		}
		return value!.toString();
	}
}
