import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DataEtapaPipe } from '@core/pipes/dataEtapa.pipe';
import { Edital } from '@core/models/edital';
import { Oportunidade } from '@core/models/oportunidade';

@Component({
	selector: 'sgp-visualizar-dados-edital',
	templateUrl: './visualizar-dados-edital.component.html'
})
export class VisualizarDadosEditalComponent implements OnInit {
	@Input() edital!: Edital;
	@Input() template!: string;
	@Output() oportunidadeSelecionada: EventEmitter<Oportunidade> = new EventEmitter<Oportunidade>();
	timeline: any[] = [];

	//Cores das etapas (Seguindo o padrão DSGov)
	presente = '#1351B4'; //–color-primary-default
	futuro = '#CCCCCC'; //Secondary 04
	passado = '#168821'; //–color-success

	constructor(private dataEtapaPipe: DataEtapaPipe) {}

	ngOnInit() {
		this.criarEtapas();
	}

	visualizarOportunidade(oportunidade: Oportunidade) {
		this.oportunidadeSelecionada.emit(oportunidade);
	}

	private criarEtapas() {
		if (this.edital.etapas !== undefined) {
			this.edital.etapas.forEach(etapa => {
				const corEtapa = this.verificarFaseEtapa(etapa.dataInicioEtapa, etapa.dataFimEtapa);
				this.timeline.push({
					status: etapa.tipoEtapaEdital!.descricao,
					date: this.dataEtapaPipe.transform(etapa),
					color: corEtapa
				});
			});
		}
	}

	private verificarFaseEtapa(dataInicio: string, dataFim: string): string {
		if (dataInicio && dataFim) {
			const hoje = moment().startOf('day');
			const dataInicial = this.getHoraZeroDoDia(dataInicio);
			const dataFinal = this.getHoraZeroDoDia(dataFim);

			if (dataInicial.isAfter(hoje)) {
				return this.futuro;
			}
			if (dataFinal.isBefore(hoje)) {
				return this.passado;
			}
			return this.presente;
		}
		return this.futuro;
	}

	private getHoraZeroDoDia(data: string) {
		const dia = +data.substring(0, 2);
		const mes = +data.substring(3, 5);
		const ano = +data.substring(6);
		return moment(new Date(ano, mes - 1, dia)).startOf('day');
	}
}
