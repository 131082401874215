export class FiltroPesquisarQuestionarioProfissiografico {
	idConcurso?: number;
	codigoOrgao?: number;
	codigoCargo?: number;
	situacao?: string;
	dataInicioLiberacao?: string;
	dataFimLiberacao?: string;
	page = 1;
	size = 10;
	first = 0;

	calcularFirst() {
		this.first = (this.page - 1) * this.size;
	}

	calcularPage(first: number, size: number) {
		this.page = first / size + 1;
	}
}
