import { Message, MessageService } from 'primeng/api';

import { Component } from '@angular/core';

@Component({
	selector: 'sgp-pesquisar-questionario-profissiografico',
	templateUrl: './pesquisar-questionario.component.html',
	providers: [MessageService]
})
export class PesquisarQuestionarioProfissiograficoComponent {
	messages: Message[] = [];
}
