import { Component, OnInit } from '@angular/core';

import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { Router } from '@angular/router';
import { UsuarioService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-home',
	templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
	perfisProfissiografico = [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PESSOAS, PerfisEnum.GESTOR_PRODUTO];
	perfisOportunidades = [
		PerfisEnum.GESTOR,
		PerfisEnum.AUTORIZADOR,
		PerfisEnum.GESTOR_GERAL,
		PerfisEnum.GESTOR_REFORMA,
		PerfisEnum.AUTORIZADOR_REFORMA,
		PerfisEnum.GESTOR_DADOS
	];
	perfisUsuario?: string[];

	constructor(private usuarioService: UsuarioService, private router: Router) {}

	ngOnInit() {
		this.perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (this.perfisUsuario) {
			this.redirecionarParaDashboard();
		}
	}

	private redirecionarParaDashboard() {
		if (this.verificarSePossuiPerfil(this.perfisOportunidades)) {
			this.router.navigate(['/dashboard']);
		}
		else if (this.verificarSePossuiPerfil(this.perfisProfissiografico)) {
			this.router.navigate(['/dashboard-profissiografico']);
		} else  {
			this.router.navigate(['/forbidden']);
		}
	}

	private verificarSePossuiPerfil(perfisPossiveis: string[]) {
		return perfisPossiveis.some(perfil => this.perfisUsuario?.includes(perfil));
	}
}
