import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ConverterInterfaceEditalHelper } from '@app/core/helpers/converter-interface-edital.helper';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from '@core/services/usuarios.service';

@Component({
	selector: 'sgp-visualizar-oportunidade',
	templateUrl: './visualizar-oportunidade.component.html'
})
export class VisualizarOportunidadeComponent implements OnInit, OnChanges, OnDestroy {
	@Input() oportunidadeSelecionada!: Oportunidade;
	@Input() situacaoEdital!: string;
	@Input() orgaoEdital!: string;
	@Input() numeroEdital?: string;
	@Input() editalArquivado = false;
	oportunidade!: Oportunidade;
	subscriptions: Subscription[] = [];
	existemRequisitos = false;
	usuarioMesmoOrgao = false;
	abaAtiva = 0;

	constructor(
		private usuariosService: UsuariosService,
		private oportunidadesService: OportunidadesApiService,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper
	) {}

	ngOnInit() {
		this.carregarDados();
	}

	/**
	 * Usado para recarregar os dados da oportunidade quando o componente pai altera a oportunidade selecionada
	 */
	ngOnChanges() {
		this.carregarDados();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	setAbaAtiva(event: any) {
		this.abaAtiva = event.index;
	}

	private carregarDados() {
		this.oportunidade = {};
		this.abaAtiva = 0;
		this.subscriptions.push(
			this.oportunidadesService.detalharOportunidade(this.oportunidadeSelecionada.id!).subscribe(
				res => {
					if (res) {
						this.oportunidade = this.converterInterfaceEdital.converterUmRetornoParaOportunidades(res);
					}
				},
				err => {
					// eslint-disable-next-line security-node/detect-crlf
					console.log(err);
				}
			)
		);

		this.oportunidade.descricaoResumida = this.oportunidade.descricaoResumida?.replace(/^(javascript\\:)/, '');
		this.oportunidade.atividades = this.oportunidade.atividades?.replace(/^(javascript\\:)/, '');
		this.usuarioMesmoOrgao = this.usuariosService.isMesmoOrgaoUsuario(this.orgaoEdital);
		this.existemRequisitos = this.verificarExistenciaRequisitos();
	}

	private verificarExistenciaRequisitos() {
		if (
			this.oportunidade.formacoes?.length === 0 &&
			this.oportunidade.codigoMunicipioResidencia == null &&
			this.oportunidade.nomeOrgaoLotacao == null &&
			this.oportunidade.nomeOrgaoExercicio == null &&
			this.oportunidade.experiencias?.length === 0 &&
			this.oportunidade.areasAtuacao?.length === 0 &&
			this.oportunidade.areasConhecimento?.length === 0 &&
			this.oportunidade.capacitacoes?.length === 0 &&
			this.oportunidade.certificacoes?.length === 0 &&
			this.oportunidade.competencias?.length === 0 &&
			this.oportunidade.vinculos?.length === 0 &&
			this.oportunidade.idiomas?.length === 0 &&
			this.oportunidade.outrosRequisitos == null
		) {
			return false;
		}
		return true;
	}
}
