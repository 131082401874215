import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AreaAtuacao } from '@app/core/models/area-atuacao';
import { TalentosApiService } from '@app/core/services/talentos-api.service';

@Component({
	selector: 'sgp-requisitos-area-atuacao',
	templateUrl: './requisitos-area-atuacao.component.html'
})
export class RequisitosAreaAtuacaoComponent implements OnChanges {
	@Input() exibir!: boolean;
	@Input() erroMensagem = false;
	@Input() textoErroMensagem = 'Campo obrigatório';
	@Input() obrigatoriedade? = true;
	@Output() obterArea: EventEmitter<AreaAtuacao> = new EventEmitter<AreaAtuacao>();
	@Output() obterAreaObrigatoria: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() obterErroCampoObrigatorio: EventEmitter<boolean> = new EventEmitter<boolean>();

	atuacao = '';
	erroTamanhoBusca = false;
	areaAtuacaoSelecionada?: AreaAtuacao;
	atuacaoObrigatorio = false;

	metadado = { total: -1 };
	paginaInicial = 1;
	areasAtuacao: any[] = [];
	loading = false;
	itensPorPagina = 10;

	constructor(private talentosService: TalentosApiService) {}

	ngOnChanges(): void {
		if (!this.exibir) {
			this.atuacao = '';
			this.areaAtuacaoSelecionada = undefined;
			this.atuacaoObrigatorio = false;
			this.areasAtuacao = [];
			this.metadado = { total: -1 };
		}
	}

	filterAreaAtuacao(): void {
		//const pagina = Math.trunc(this.paginaInicial / this.itensPorPagina) + 1;

		// const filtered: any[] = [];
		if (this.atuacao.valueOf().length >= 3) {
			this.erroTamanhoBusca = false;
			this.loading = true;
			this.talentosService.consultarAreasAtuacaoPorTexto(this.atuacao).subscribe(res => {
				if (res) {
					this.areasAtuacao = res;
					this.loading = false;
				}
			});
		} else {
			this.erroTamanhoBusca = true;
			this.metadado = { total: -1 };
		}
	}

	paginarAreaAtuacao(event: any) {
		this.paginaInicial = event.first;
		this.filterAreaAtuacao();
	}

	pesquisaInicial() {
		this.paginaInicial = 1;
		this.filterAreaAtuacao();
		this.obterErroCampoObrigatorio.emit(false);
	}

	atualizarValorArea() {
		this.obterArea.emit(this.areaAtuacaoSelecionada);
	}

	atualizaAreaObrigatoria() {
		if (this.obrigatoriedade) {
			this.obterAreaObrigatoria.emit(this.atuacaoObrigatorio);
		}
	}
}
