import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Message } from 'primeng/api';
import { Notificacao } from './../models/notificacao';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';
import { VisualizarEditalApiService } from '../services/visualizar-edital-api.service';

@Component({
	selector: 'sgp-detalhar-mensagens-candidato',
	templateUrl: './detalhar-mensagens-candidato.component.html',
	styleUrls: ['./detalhar-mensagens-candidato.component.scss']
})
export class DetalharMensagensCandidatoComponent implements OnInit, OnChanges {
	@Input() idCandidato!: number;
	@Input() atualizarNotificacoes = false;
	@Output() mensagemFeedback = new EventEmitter<Message[]>();
	@Output() retornoAtualizarNotificacoes = new EventEmitter<boolean>();

	listaNotificacoes: Notificacao[] = [];
	loading = false;
	subscriptions: Subscription[] = [];

	constructor(private ui: UiService, private visualizarEditalApi: VisualizarEditalApiService) {}

	ngOnInit(): void {
		this.exibirNotificacoes();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.atualizarNotificacoes.currentValue) {
			this.exibirNotificacoes();
		}
	}
	private exibirNotificacoes() {
		this.loading = true;
		this.subscriptions.push(
			this.visualizarEditalApi.consultarNotificacoesCandidato(this.idCandidato).subscribe(
				res => {
					this.loading = false;
					this.listaNotificacoes = res;
					this.atualizarNotificacoes = false;
					this.retornoAtualizarNotificacoes.emit(this.atualizarNotificacoes);
				},
				err => {
					this.loading = false;
					this.mensagemFeedback.emit(this.ui.criarListaMensagem('error', '', err.message));
					this.listaNotificacoes = [];
				}
			)
		);
	}
}
