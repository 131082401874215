<div id="main-content">
	<h2 data-cy="titulo-pagina">{{nomeFuncionalidade}}</h2>
	<div>
		<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>

		<sgp-skeleton-cadastrar-edital *ngIf="loading && !bloqueadoAlteracao"></sgp-skeleton-cadastrar-edital>

		<div *ngIf="!loading && !usuarioAutorizado">
			<div class="br-message danger" role="alert">
				<div class="icon"><i class="fas fa-times-circle fa-lg" aria-hidden="true"></i></div>
				<div class="content">
					<strong
						>Você não possui permissão para alterar ou retificar o Edital nº {{ edital.numeroEdital }} do Órgão {{
						edital.nomeOrgao }}.</strong
					>
				</div>
			</div>
		</div>

		<div *ngIf="!loading && usuarioAutorizado && bloqueadoAlteracao">
			<div class="br-message info" role="alert">
				<div class="icon"><i class="fas fa-info-circle fa-lg" aria-hidden="true"></i></div>
				<div class="content">
					<strong>Este edital está na situação {{ edital.situacao.descricao }} e não pode ser alterado.</strong>
					<p><a [routerLink]="['/edital', edital.id]">Você pode visualizá-lo aqui</a>.</p>
				</div>
			</div>
		</div>

		<sgp-wizard
			[steps]="steps"
			[items]="items"
			[contents]="content"
			[edital]="edital"
			[loading]="salvando"
			*ngIf="!loading && usuarioAutorizado && !bloqueadoAlteracao"
			(salvarEdital)="salvarEdital()"
		></sgp-wizard>
	</div>
</div>
