import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { CardBarHorizontalComponent } from './card-bar-horizontal/card-bar-horizontal.component';
import { CardGraficoDoughnutComponent } from '@app/shared/card-grafico-doughnut/card-grafico-doughnut.component';
import { ChartModule } from 'primeng/chart';
import { CommonModule } from '@angular/common';
import { DashboardProfissiograficoComponent } from './dashboard-profissiografico/dashboard-profissiografico.component';
import { DropdownModule } from 'primeng/dropdown';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { FiltroDashboardProfissiograficoComponent } from './filtro-dashboard-profissiografico/filtro-dashboard-profissiografico.component';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MiniCardComponent } from '@app/shared/mini-card/mini-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { environment } from '@environments/environment';

@NgModule({
	declarations: [DashboardProfissiograficoComponent, FiltroDashboardProfissiograficoComponent, CardBarHorizontalComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ButtonModule,
		MiniCardComponent,
		CardGraficoDoughnutComponent,
		DropdownModule,
		MessagesModule,
		ChartModule,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG),
		DsGovDirectivesModule
	],
	providers: [MessageService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DashboardProfissiograficoModule {}
