import { TabelasAdministrativas } from '@core/models/tabelasAdministrativas';
/**
 * Área de conhecimento
 */
export class AreaConhecimentoItem extends TabelasAdministrativas {
	ferramenta?: boolean;
	incluido_usuario?: boolean;

	constructor(id?: number, nome?: string, ativo?: boolean, ferramenta?: boolean, incluido_usuario?: boolean) {
		super(id, nome, ativo);
		this.ferramenta = ferramenta;
		this.incluido_usuario = incluido_usuario;
	}
}
