import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ManterTabelaAdministrativaComponent } from '@tabelasAdministrativas/manter-tabela-administrativa/manter-tabela-administrativa.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { PesquisarTabelaAdministrativaComponent } from '@tabelasAdministrativas/pesquisar-tabela-administrativa/pesquisar-tabela-administrativa.component';
import { SharedModule } from '@app/shared/shared.module';
import { TabelaAreaAtuacaoComponent } from './tabela-area-atuacao/tabela-area-atuacao.component';
import { TabelaAreaConhecimentoComponent } from './tabela-area-conhecimento/tabela-area-conhecimento.component';
import { TabelaIdiomaComponent } from '@tabelasAdministrativas/tabela-idioma/tabela-idioma.component';
import { TabelaIncentivoComponent } from './tabela-incentivo/tabela-incentivo.component';
import { TabelaInstituicaoComponent } from '@tabelasAdministrativas/tabela-instituicao/tabela-instituicao.component';
import { TabelaSoftskillComponent } from '@tabelasAdministrativas/tabela-softskill/tabela-softskill.component';
import { TabelaTipoDocumentoComponent } from './tabela-tipo-documento/tabela-tipo-documento.component';
import { TabelaTipoMovimentacaoComponent } from './tabela-tipo-movimentacao/tabela-tipo-movimentacao.component';
import { TabelaVinculoComponent } from '@tabelasAdministrativas/tabela-vinculo/tabela-vinculo.component';
import { TableModule } from 'primeng/table';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';

@NgModule({
	declarations: [
		ManterTabelaAdministrativaComponent,
		PesquisarTabelaAdministrativaComponent,
		TabelaIdiomaComponent,
		TabelaSoftskillComponent,
		TabelaInstituicaoComponent,
		TabelaVinculoComponent,
		TabelaAreaConhecimentoComponent,
		TabelaAreaAtuacaoComponent,
		TabelaTipoDocumentoComponent,
		TabelaIncentivoComponent,
		TabelaTipoMovimentacaoComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		AppRoutingModule,
		FormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		ButtonModule,
		ConfirmDialogModule,
		DialogModule,
		InputMaskModule,
		InputTextModule,
		MessagesModule,
		MessageModule,
		TableModule,
		DsGovDirectivesModule
	]
})
export class TabelasAdministrativasModule {}
