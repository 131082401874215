//create pipe
import { Pipe, PipeTransform } from '@angular/core';

import { Oportunidade } from '@core/models/oportunidade';

@Pipe({
	name: 'requisitosObrigatorios'
})
export class RequisitosObrigatoriosPipe implements PipeTransform {
	transform(oportunidade: Oportunidade): boolean {
		if (
			this.listaEstaVazia(oportunidade.areasAtuacao) &&
			this.listaEstaVazia(oportunidade.areasConhecimento) &&
			this.listaEstaVazia(oportunidade.capacitacoes) &&
			this.listaEstaVazia(oportunidade.certificacoes) &&
			this.listaEstaVazia(oportunidade.competencias) &&
			this.listaEstaVazia(oportunidade.experiencias) &&
			this.listaEstaVazia(oportunidade.idiomas) &&
			this.listaEstaVazia(oportunidade.formacoes) &&
			this.listaEstaVazia(oportunidade.vinculos) &&
			oportunidade.codigoOrgaoLotacao == null &&
			oportunidade.codigoOrgaoExercicio == null
		) {
			return true;
		}
		return false;
	}

	private listaEstaVazia(lista: any): boolean {
		return lista && lista.length === 0;
	}
}
