import * as moment from 'moment';

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, Message, PrimeNGConfig } from 'primeng/api';

import { FerramentasApiService } from '../services/ferramentas-api.service';
import { MensagemAlerta } from '@app/core/models/MensagemAlerta';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@app/core/services/ui.service';

@Component({
	selector: 'sgp-mensagem-alerta',
	templateUrl: './mensagem-alerta.component.html',
	providers: [ConfirmationService]
})
export class MensagemAlertaComponent implements OnInit, OnDestroy {
	form!: FormGroup;
	mensagem: Message[] = [];
	subscriptions: Subscription[] = [];
	mensagemAlerta?: MensagemAlerta;

	erroMensagem = false;
	textoErroMensagem = 'Campo obrigatório';
	erroLink = false;
	erroPeriodo = false;

	constructor(
		private config: PrimeNGConfig,
		private confirmationService: ConfirmationService,
		private ferramentasApi: FerramentasApiService,
		private oportunidadesApi: OportunidadesApiService,
		private translateService: TranslateService,
		private ui: UiService
	) {
		this.criarFormulario();
	}

	ngOnInit(): void {
		this.obterMensagemAlerta();
		this.setCalendarioParaPortugues();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	prepararSalvarMensagem() {
		if (this.form.valid) {
			this.erroMensagem = false;
			this.erroLink = false;
			this.erroPeriodo = false;
			this.tratarDadosFormulario();
			this.salvarMensagem();
		} else {
			this.exibirMensagensValidacao();
		}
	}

	limpar() {
		this.form.reset();
		this.erroMensagem = false;
		this.erroLink = false;
		this.erroPeriodo = false;
		this.mensagem = [];
		this.mensagemAlerta = undefined;
	}

	prepararExcluir() {
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir esta mensagem?',
			accept: async () => {
				this.excluir();
			}
		});
	}

	private obterMensagemAlerta() {
		this.subscriptions.push(
			this.oportunidadesApi.obterMensagemAlerta().subscribe(mensagemAlerta => {
				if (mensagemAlerta && mensagemAlerta.mensagem) {
					this.mensagemAlerta = mensagemAlerta;
					this.preencherFormularioComMensagem(mensagemAlerta);
				}
			})
		);
	}

	private preencherFormularioComMensagem(mensagemAlerta: MensagemAlerta) {
		this.form.controls['mensagem'].setValue(mensagemAlerta.mensagem);
		this.form.controls['link'].setValue(mensagemAlerta.link);
		this.form.controls['periodo'].setValue([this.converterData(mensagemAlerta.dataInicio), this.converterData(mensagemAlerta.dataFim)]);
	}

	private converterData(data: any) {
		const dataStr = data.substr(3, 2) + '/' + data.substr(0, 2) + '/' + data.substr(6, 4);
		return new Date(dataStr);
	}

	private setCalendarioParaPortugues() {
		this.translateService.setDefaultLang('pt');
		this.translateService.use('pt');
		this.subscriptions.push(this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res)));
	}

	private criarFormulario() {
		this.form = new FormGroup({
			mensagem: new FormControl('', [Validators.required, Validators.minLength(3)]),
			link: new FormControl('', [this.validarUrl]),
			periodo: new FormControl('', [Validators.required])
		});
	}

	private validarUrl(control: AbstractControl): { [key: string]: any } | null {
		const urlGoverno = 'gov.br';
		if (control.value) {
			return control.value.includes(urlGoverno) ? null : { urlInvalida: true };
		}
		return null;
	}

	private tratarDadosFormulario() {
		this.mensagemAlerta = new MensagemAlerta();
		this.mensagemAlerta.mensagem = this.form.controls['mensagem'].value;
		this.mensagemAlerta.link = this.form.controls['link'].value;
		this.mensagemAlerta.dataInicio = moment(this.form.controls['periodo'].value[0]).format('DD/MM/YYYY');
		this.mensagemAlerta.dataFim = moment(this.form.controls['periodo'].value[1]).format('DD/MM/YYYY');
	}

	private salvarMensagem() {
		this.mensagem = [];
		if (this.mensagemAlerta) {
			this.subscriptions.push(
				this.ferramentasApi.cadastrarMensagemAlerta(this.mensagemAlerta).subscribe(
					res => {
						this.mensagem = this.ui.criarListaMensagem('success', '', res.message);
					},
					err => {
						this.mensagem = this.ui.criarListaMensagem('error', '', err.error.message);
					}
				)
			);
		}
	}

	private exibirMensagensValidacao() {
		if (this.form.controls['mensagem'].errors?.minlength) {
			this.textoErroMensagem = 'A mensagem deve possuir no mínimo 3 caracteres';
		} else {
			this.textoErroMensagem = 'Campo obrigatório';
		}
		this.erroMensagem = this.form.controls['mensagem'].invalid;
		this.erroLink = this.form.controls['link'].invalid;
		this.erroPeriodo = this.form.controls['periodo'].invalid;
	}

	private excluir() {
		this.subscriptions.push(
			this.ferramentasApi.excluirMensagemAlerta().subscribe(
				() => {
					this.limpar();
					this.mensagem = this.ui.criarListaMensagem('success', '', 'Mensagem excluída com sucesso');
				},
				err => {
					if (err.status === 200) {
						this.limpar();
						this.mensagem = this.ui.criarListaMensagem('success', '', 'Mensagem excluída com sucesso');
					} else {
						this.mensagem = this.ui.criarListaMensagem('error', '', 'Erro ao excluir a mensagem');
					}
				}
			)
		);
	}
}
