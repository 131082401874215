import 'moment/locale/pt-br';

import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

import { DateHelper } from '../helpers/date.helper';

/**
 * Imprime uma data humanizada no formato 'x dias atrás', 'x meses atrás'...
 */
@Pipe({ name: 'dateAgo' })
export class DateAgoPipe implements PipeTransform {
	constructor(private dateHelper: DateHelper) {}

	transform(data: any): any {
		if (data) {
			moment.locale('pt-BR');
			const hoje = new Date();
			const dataConvertida = moment(data, 'DD/MM/YYYY HH:mm:ss').toString();
			const dataInformada = moment(new Date(dataConvertida));
			const duracao = moment.duration(dataInformada.diff(hoje));
			return duracao.humanize(true);
		}
		return '';
	}
}
