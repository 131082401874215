<p-messages [closable]="false"></p-messages>

<div id="listar-anexos" *ngIf="isListar()">
	<div class="br-table">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">Anexos</div>
				<button class="primary mt-3 mt-sm-0 ml-sm-3" (click)="novoAnexo()" type="button" pButton data-cy="botao-adicionar-anexo">
					Novo anexo
				</button>
			</div>
		</div>
		<p-table [value]="anexos" dataKey="id" [rowHover]="true" [responsive]="true" [paginator]="true" [rows]="10" data-cy="tabela-anexos">
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-descricao" data-cy="cabecalho-descricao">Descrição</th>
					<th id="cabecalho-tipo" data-cy="cabecalho-tipo">Tipo</th>
					<th id="cabecalho-acoes" data-cy="cabecalho-acoes" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-anexo>
				<tr>
					<td headers="cabecalho-descricao" data-cy="descricao-anexo">{{anexo.descricaoAnexo}}</td>
					<td headers="cabecalho-tipo" data-cy="tipo-anexo">{{ anexo.tipoAnexo.descricao }}</td>
					<td headers="cabecalho-acoes">
						<button
							pButton
							class="br-button circle"
							title="Download"
							(click)="download(anexo.idAnexo)"
							data-cy="botao-download-anexo"
							icon="fas fa-download"
							[disabled]="loading"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir"
							(click)="excluirAnexo(anexo.idAnexo)"
							data-cy="botao-excluir-anexo"
							icon="fas fa-trash-alt"
							[disabled]="loading"
						></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5" data-cy="mensagem-nenhum-anexo">Nenhum anexo incluído.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<div id="cadastrar-anexos" *ngIf="isManter()">
	<sgp-cadastrar-anexos
		[idEdital]="edital.id"
		[anexo]="anexo"
		[operacao]="operacao"
		[anexosCadastradas]="edital.anexos!"
		(obterOperacao)="cancelar($event)"
	></sgp-cadastrar-anexos>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
