<div class="br-card">
	<div class="card-content" *ngIf="dados; else skeleton">
		<div *ngIf="totalResultado > 0 && dados?.items?.dados.length > 0; else semDados">
			<div class="titulo d-flex justify-content-between">
				<div>{{ titulo }}</div>
				<div>
					<button
						pButton
						class="br-button circle"
						title="Anterior"
						(click)="voltar()"
						data-cy="botao-navegacao-grafico-voltar"
						icon="fas fa-chevron-left"
						[disabled]="pagina === 1"
					></button>

					<button
						pButton
						class="br-button circle"
						title="Próximo"
						(click)="avancar()"
						data-cy="botao-navegacao-grafico-avancar"
						icon="fas fa-chevron-right"
						[disabled]="!(totalResultado/ITENS_POR_PAGINA > pagina)"
					></button>
				</div>
			</div>
			<div class="grafico_bar_vertical">
				<p-chart type="bar" [data]="data" [options]="options" height="250"></p-chart>
			</div>
		</div>
	</div>
	<ng-template #skeleton>
		<p-skeleton width="50rem" height="23rem"></p-skeleton>
	</ng-template>
	<ng-template #semDados>
		<div class="titulo d-flex justify-content-between">{{ titulo }}</div>
		<div class="text-center">
			<img
				src="assets/images/pie-chart.png"
				height="140"
				style="margin-top: 50px; margin-bottom: 50px"
				alt="Analítica ícones criados por Xinh Studio - Flaticon"
			/>
			<p class="text-up-02 text-gray-cool-40 text-weight-semi-bold">Sem dados para exibir</p>
		</div>
	</ng-template>
</div>
