/**
 * Edital
 */

import { Anexo } from './anexo';
import { Etapa } from './etapa';
import { HistoricoEdital } from './historico-edital';
import { Oportunidade } from './oportunidade';

export class Edital {
	id?: any;
	numeroEdital?: string;
	codigoOrgao?: number;
	nomeOrgao?: string;
	orgao?: any;
	exigenciaLegal?: string;
	preambulo?: string;
	sobreUnidade?: string;
	sobreVagasOportunidades?: string;
	dataCadastro?: string;
	dataPublicacao?: string;
	dataHoraAtualizacao?: string;
	emailContato?: string;
	oportunidades?: Oportunidade[];
	etapas?: Etapa[];
	anexos?: Anexo[];
	historico?: HistoricoEdital[];
	situacao?: any;
	editalArquivado?: false;

	constructor(id?: any) {
		if (id) {
			this.id = id;
		}
	}
}
