<p-messages [closable]="false"></p-messages>

<div id="listar-oportunidades" *ngIf="isListar()">
	<div class="br-table">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">Oportunidades adicionadas</div>
				<button
					class="primary mt-3 mt-sm-0 ml-sm-3"
					(click)="novaOportunidade()"
					type="button"
					pButton
					data-cy="botao-adicionar-oportunidade"
				>
					Nova oportunidade
				</button>
			</div>
		</div>
		<p-table
			*ngIf="!loading"
			[value]="this.edital.oportunidades!"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			[paginator]="true"
			[rows]="10"
			sortField="nomeOportunidade"
			data-cy="tabela-oportunidades"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-oportunidade" data-cy="cabecalho-oportunidade">Nome da oportunidade</th>
					<th id="cabecalho-uorg" data-cy="cabecalho-uorg">Unidade</th>
					<th id="cabecalho-localidade" data-cy="cabecalho-localidade" style="width: 20%">Localidade</th>
					<th id="cabecalho-vagas" data-cy="cabecalho-vagas" style="width: 10%">Vagas</th>
					<th id="cabecalho-acoes" data-cy="cabecalho-acoes" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-oportunidade>
				<tr>
					<td headers="cabecalho-oportunidade" data-cy="nome-oportunidade">{{oportunidade.nomeOportunidade}}</td>
					<td headers="cabecalho-uorg" data-cy="nome-uorg">{{oportunidade.nomeUorg}}</td>
					<td headers="cabecalho-localidade" data-cy="nome-localidade">{{oportunidade.nomeLocalidade | imprimirDash}}</td>
					<td headers="cabecalho-vagas" data-cy="numero-vagas" class="text-center">{{oportunidade.numeroVagas}}</td>
					<td headers="cabecalho-acoes">
						<button
							pButton
							class="br-button circle"
							title="Alterar"
							(click)="alterarOportunidade(oportunidade)"
							data-cy="botao-alterar-oportunidade"
							icon="fas fa-edit"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir"
							(click)="excluirOportunidade(oportunidade)"
							data-cy="botao-excluir-oportunidade"
							icon="fas fa-trash-alt"
						></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5" data-cy="mensagem-nenhuma-oportunidade">
						Nenhuma oportunidade cadastrada. O edital precisa de no mínimo uma oportunidade para ser publicado.
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<div id="cadastrar-oportunidades" *ngIf="isManter()">
	<sgp-cadastrar-oportunidades
		[oportunidade]="oportunidade"
		[codigoOrgao]="codigoOrgaoString()"
		[operacao]="operacao"
		[idEdital]="this.edital.id"
		(obterOportunidade)="updateOportunidade($event)"
		(obterOperacao)="cancelar($event)"
		(salvaComSucesso)="salvaComSucesso()"
	>
	</sgp-cadastrar-oportunidades>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
