import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, catchError, tap } from 'rxjs';

import { ConcursoProfissiografico } from '@app/core/models/perfil-profissiografico/concurso-profissiografico';
import { FiltroDashboardProfissiografico } from '@app/core/models/perfil-profissiografico/filtro-dashboard-profissiografico';
import { MessageService } from 'primeng/api';
import { OrgaoProfissiografico } from '@app/core/models/perfil-profissiografico/orgao-profissiografico';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { PerfilProfissiograficoDashboardApiService } from '@app/core/services/perfil-profissiografico-dashboard-api.service';
import { ResultadoPesquisarDashboardProfissiogafico } from '@app/core/models/perfil-profissiografico/resultado-pesquisar-dashboard-profissiogafico';

@Injectable({
	providedIn: 'any'
})
export class DashboardProfissiograficoService implements OnDestroy {
	renderizarDados = false;
	filtro = new FiltroDashboardProfissiografico();
	resultado = new ResultadoPesquisarDashboardProfissiogafico();

	concursos$?: Observable<ConcursoProfissiografico[]>;
	orgaos$?: Observable<OrgaoProfissiografico[]>;

	subscriptions: Subscription[] = [];
	perfilAnterior: string[] = [];
	/* Observação: basear no exemplo do pesquisar-laudo.service.ts */

	constructor(
		private perfilProfissiograficoApi: PerfilProfissiograficoApiService,
		private messageService: MessageService,
		private perfilProfissiograficoDashboardApiservice: PerfilProfissiograficoDashboardApiService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	listarConcursos(): void {
		this.concursos$ = this.perfilProfissiograficoApi.getConcursos('AND', 1).pipe(
			catchError(error => {
				this.handleError(error, 'listar concursos');
				throw error;
			})
		);
	}

	listarOrgaosConcurso(idConcurso: number): void {
		this.orgaos$ = this.perfilProfissiograficoApi.getOrgaosConcurso(idConcurso).pipe(
			tap(orgaos => {
				const primeiraOrgao = new OrgaoProfissiografico();
				primeiraOrgao.codigoOrgao = 0;
				primeiraOrgao.nomeOrgao = 'TODOS';
				orgaos.unshift(primeiraOrgao);
			}),
			catchError(error => {
				this.handleError(error, 'listar órgãos');
				throw error;
			})
		);
	}

	private handleError(error: any, descricao: string) {
		this.messageService.add({
			severity: 'error',
			summary: `Erro ao ${descricao}. `,
			detail: ' Tente novamente mais tarde.'
		});
	}

	public limpar() {
		this.filtro = new FiltroDashboardProfissiografico();
		this.concursos$ = undefined;
		this.orgaos$ = undefined;
		this.renderizarDados = false;
	}

	public pesquisar() {
		if (this.filtro.idConcurso) {
			this.getSituacaoLiberacaoQuestionario();
			this.getTotais();
			this.getAtribuicaoLaudos();
			this.getCienciaLaudos();
			this.getSituacaoLaudos();
			this.renderizarDados = true;
		}
	}

	getTotais() {
		this.subscriptions.push(
			this.perfilProfissiograficoDashboardApiservice
				.getTotais(this.filtro.idConcurso, this.filtro.codigoOrgao)
				.subscribe(async result => {
					this.resultado.totalLaudosPriorizados = result.qtdLaudosPriorizadosNaoEmitidos;
					this.resultado.totalQuestionariosEnviados = result.qtdQuestionariosEnviados;
				})
		);
	}

	getAtribuicaoLaudos() {
		this.subscriptions.push(
			this.perfilProfissiograficoDashboardApiservice
				.getTotalAtribiucaoLaudos(this.filtro.idConcurso, this.filtro.codigoOrgao)
				.subscribe(async result => {
					const legendas: any[] = [];
					const dados: any[] = [];
					result.quantidadePorSituacao.forEach((element: any) => {
						legendas.push(element.descricao);
						dados.push(element.valor);
					});
					const soma = dados.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
					if (soma > 0)
						this.resultado.situacaoAtribuicaoLaudos = [{ legenda: legendas, dados: dados, total: result.total }];
					else
						this.resultado.situacaoAtribuicaoLaudos = []
				})
		);
	}

	getCienciaLaudos() {
		this.subscriptions.push(
			this.perfilProfissiograficoDashboardApiservice
				.getTotalCienciaLaudos(this.filtro.idConcurso, this.filtro.codigoOrgao)
				.subscribe(async result => {
					const legendas: any[] = [];
					const dados: any[] = [];
					result.quantidadePorSituacao.forEach((element: any) => {
						legendas.push(element.descricao);
						dados.push(element.valor);
					});
					const soma = dados.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
					if (soma > 0)
						this.resultado.situacaoCienciaLaudos = [{ legenda: legendas, dados: dados, total: result.total }];
					else
						this.resultado.situacaoCienciaLaudos = []
				})
		);
	}

	getSituacaoLaudos() {
		this.subscriptions.push(
			this.perfilProfissiograficoDashboardApiservice
				.getTotalSituacaoLaudos(this.filtro.idConcurso, this.filtro.codigoOrgao)
				.subscribe(async result => {
					if (result) {
						const legendas: any[] = [];
						const dados: any[] = [];
						result.quantidadePorSituacao.forEach((element: any) => {
							legendas.push(element.descricao);
							dados.push(element.valor);
						});
						const soma = dados.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
						if (soma > 0)
							this.resultado.situacaoLaudos = [{ legenda: legendas, dados: dados, total: result.total }];
						else
							this.resultado.situacaoLaudos = []
					}
				})
		);
	}

	getSituacaoLiberacaoQuestionario(pagina?: number) {
		const itensPorPagina = 5;
		this.subscriptions.push(
			this.perfilProfissiograficoDashboardApiservice
				.getSituacaoLiberacaoQuestionario(this.filtro.idConcurso, this.filtro.codigoOrgao, pagina, itensPorPagina)
				.subscribe(async result => {
					const soma = result.items.dados.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
					if (soma == 0) {
						result.items.dados = []
					}
					this.resultado.situcaoLiberacaoQuestionario = result;
				})
		);
	}
}
