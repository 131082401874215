import {
	AreaAtuacaoMatch,
	AreaConhecimentoMatch,
	AreaInteresseMatch,
	CapacitacaoMatch,
	CertificacaoMatch,
	CompetenciaMatch,
	ExperienciaMatch,
	FormacaoMatch,
	IdiomaMatch,
	OrgaoMatch,
	ParametrosMatch,
	SeloMatch,
	VinculoMatch
} from '@core/models/parametros-match';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AreaAtuacao } from '@core/models/area-atuacao';
import { AreaInteresse } from '@app/core/models/area-interesse';
import { Idioma } from '@core/models/idioma';
import { NivelCurso } from '@core/models/nivel-curso';
import { OportunidadeCompetencia } from '@core/models/oportunidade-competencia';
import { OportunidadesApiService } from './../../core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { TipoVinculo } from '@core/models/tipo-vinculo';

@Component({
	selector: 'sgp-item-filtro-pesquisa',
	templateUrl: './item-filtro-pesquisa.component.html',
	styles: ['.p-dropdown-items-wrapper { height: 148px !important; max-height: 148px !important;}']
})
export class ItemFiltroPesquisaComponent implements OnInit, OnDestroy {
	@Input() nomeFiltro!: string;
	@Input() requisito!:
		| 'vinculo'
		| 'softskill'
		| 'idioma'
		| 'experiencia'
		| 'capacitacao'
		| 'certificacao'
		| 'areaAtuacao'
		| 'formacao'
		| 'conhecimentos'
		| 'orgao'
		| 'nome'
		| 'cidade'
		| 'uf'
		| 'uorg'
		| 'selo'
		| 'areaInteresse'
		| 'cargo';
	@Input() parametrosFiltro!: ParametrosMatch;
	@Output() parametrosAtualizados: EventEmitter<ParametrosMatch> = new EventEmitter<ParametrosMatch>();
	@Output() ngModelUpdated: EventEmitter<any> = new EventEmitter<any>();

	adicionarRequisito!: () => any;
	exibirModal = false;
	subscriptions: Subscription[] = [];
	filteredOrgaos!: any[];
	orgaos: any[] = [];

	listaTiposVinculos: TipoVinculo[] = [];
	vinculoSelecionado?: TipoVinculo;
	erroVinculo = false;

	listaSoftskills: OportunidadeCompetencia[] = [];
	softskillSelecionado?: OportunidadeCompetencia;
	erroSoftskills = false;

	listaOrgaos: any[] = [];
	orgaoSelecionado?: any;
	erroOrgao = false;

	listaIdiomas: Idioma[] = [];
	idiomaSelecionado?: Idioma;
	filteredIdioma: any[] = [];
	erroIdioma = false;

	nomeCargoSelecionado?: string;
	erroNomeCargo = false;

	nomeCidadeSelecionada?: string;
	erroNomeCidade = false;

	nomeSelecionado?: string;
	erroNome = false;

	nomeUorgSelecionado?: string;
	erroNomeUorg = false;

	nomeExperienciaSelecionado?: string;
	erroNomeExperiencia = false;

	nomeCapacitacaoSelecionada?: string;
	erroNomeCapacitacao = false;

	nomeCertificacaoSelecionada?: string;
	erroNomeCertificacao = false;

	nomeUFSelecionada?: string;
	erroNomeUF = false;

	nomeSeloSelecionado?: string;
	nomeInstituicaoSeloSelecionado?: string;
	erroNomeSelo = false;

	private _listaAreaAtuacao: AreaAtuacao[] = [];
	public get listaAreaAtuacao(): AreaAtuacao[] {
		return this._listaAreaAtuacao;
	}
	public set listaAreaAtuacao(value: AreaAtuacao[]) {
		this._listaAreaAtuacao = value;
	}
	areaAtuacaoSelecionada?: AreaAtuacao;
	erroAreaAtuacao = false;

	private _listaAreaInteresse: AreaAtuacao[] = [];
	public get listaAreaInteresse(): AreaInteresse[] {
		return this._listaAreaInteresse;
	}
	public set listaAreaInteresse(value: AreaInteresse[]) {
		this._listaAreaInteresse = value;
	}
	areaInteresseSelecionada?: AreaInteresse;
	erroAreaInteresse = false;

	listaNiveisFormacao: NivelCurso[] = [];
	nivelFormacaoSelecionado?: NivelCurso;
	erroNivelFormacao = false;
	nomeFormacaoSelecionada?: string;

	areaConhecimento: any;
	erroConhecimento = false;

	constructor(private talentosService: TalentosApiService, private oportunidadeService: OportunidadesApiService) {}

	ngOnInit() {
		this.atribuirMetodoAdicionarRequisito();
		this.getTiposVinculos();
		this.getSoftskills();
		this.getIdiomas();
		this.getAreasAtuacao();
		this.getAreasInteresse();
		this.getNiveisFormacao();
		this.getOrgaos();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	filtrarOrgaos(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.orgaos) {
			for (const orgao of this.orgaos) {
				if (orgao.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(orgao);
				}
			}
		}

		this.filteredOrgaos = filtered;
	}

	/** Atualiza o valor com a opção seleciona */
	atualizarNgModel(event: any) {
		if (event?.value) {
			this.ngModelUpdated.emit(event.value);
		} else {
			this.ngModelUpdated.emit(event);
		}
	}

	abrirModal() {
		this.vinculoSelecionado = undefined;
		this.erroVinculo = false;
		this.softskillSelecionado = undefined;
		this.erroSoftskills = false;
		this.idiomaSelecionado = undefined;
		this.erroIdioma = false;
		this.nomeExperienciaSelecionado = undefined;
		this.erroNomeExperiencia = false;
		this.nomeCapacitacaoSelecionada = undefined;
		this.erroNomeCapacitacao = false;
		this.nomeCertificacaoSelecionada = undefined;
		this.erroNomeCertificacao = false;
		this.areaAtuacaoSelecionada = undefined;
		this.erroAreaAtuacao = false;
		this.nivelFormacaoSelecionado = undefined;
		this.erroNivelFormacao = false;
		this.nomeFormacaoSelecionada = undefined;
		this.areaConhecimento = undefined;
		this.erroConhecimento = false;
		this.nomeCargoSelecionado = undefined;
		this.erroNomeCargo = false;
		this.nomeSelecionado = undefined;
		this.erroNome = false;
		this.nomeCidadeSelecionada = undefined;
		this.erroNomeCidade = false;
		this.nomeUFSelecionada = undefined;
		this.erroNomeUF = false;
		this.orgaoSelecionado = undefined;
		this.erroOrgao = false;
		this.areaInteresseSelecionada = undefined;
		this.erroAreaInteresse = false;
		this.nomeUorgSelecionado = undefined;
		this.erroNomeUorg = false;
		this.nomeSeloSelecionado = undefined;
		this.nomeInstituicaoSeloSelecionado = undefined;
		this.erroNomeSelo = false;
		this.exibirModal = true;
	}

	atualizarExibirModal(event: boolean) {
		this.exibirModal = event;
	}

	//Filtra o combo de tipo de idioma
	filterIdioma(event: any): void {
		const filtered: any[] = [];
		const query = event.query;
		if (this.listaIdiomas) {
			for (const idioma of this.listaIdiomas) {
				if (idioma.ativo && idioma.nome!.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(idioma);
				}
			}
		}
		this.filteredIdioma = filtered;
	}

	updateAreaConhecimento(event: any) {
		this.areaConhecimento = event;
	}

	updateAreaInteresse(event: any) {
		this.areaInteresseSelecionada = event;
	}

	updateAreaAtuacao(event: any) {
		this.areaAtuacaoSelecionada = event;
	}

	atualizarErroAreaObrigatoria(event: any) {
		this.erroConhecimento = event;
	}

	private atribuirMetodoAdicionarRequisito() {
		switch (this.requisito) {
			case 'vinculo':
				this.adicionarRequisito = () => {
					this.adicionarVinculo();
				};
				break;

			case 'softskill':
				this.adicionarRequisito = () => {
					this.adicionarSoftskill();
				};
				break;

			case 'idioma':
				this.adicionarRequisito = () => {
					this.adicionarIdioma();
				};
				break;

			case 'experiencia':
				this.adicionarRequisito = () => {
					this.adicionarExperiencia();
				};
				break;

			case 'capacitacao':
				this.adicionarRequisito = () => {
					this.adicionarCapacitacao();
				};
				break;

			case 'certificacao':
				this.adicionarRequisito = () => {
					this.adicionarCertificacao();
				};
				break;

			case 'areaAtuacao':
				this.adicionarRequisito = () => {
					this.adicionarAreaAtuacao();
				};
				break;

			case 'areaInteresse':
				this.adicionarRequisito = () => {
					this.adicionarAreaInteresse();
				};
				break;

			case 'formacao':
				this.adicionarRequisito = () => {
					this.adicionarFormacao();
				};
				break;

			case 'conhecimentos':
				this.adicionarRequisito = () => {
					this.adicionarConhecimento();
				};
				break;

			case 'orgao':
				this.adicionarRequisito = () => {
					this.adicionarOrgao();
				};
				break;
			case 'cidade':
				this.adicionarRequisito = () => {
					this.adicionarCidade();
				};
				break;
			case 'uf':
				this.adicionarRequisito = () => {
					this.adicionarUf();
				};
				break;
			case 'uorg':
				this.adicionarRequisito = () => {
					this.adicionarUorg();
				};
				break;
			case 'nome':
				this.adicionarRequisito = () => {
					this.adicionarNome();
				};
				break;
			case 'selo':
				this.adicionarRequisito = () => {
					this.adicionarSelo();
				};
				break;
			case 'cargo':
				this.adicionarRequisito = () => {
					this.adicionarCargo();
				};
				break;

			default:
				break;
		}
	}

	private adicionarUf() {
		if (this.nomeUFSelecionada && this.nomeUFSelecionada.length == 2) {
			this.parametrosFiltro.ufOrgao = this.nomeUFSelecionada.toUpperCase();
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroNomeUF = false;
			this.exibirModal = false;
		} else {
			this.erroNomeUF = true;
		}
	}
	private adicionarUorg() {
		if (this.nomeUorgSelecionado && this.nomeUorgSelecionado != '') {
			this.parametrosFiltro.nomeUorg = this.nomeUorgSelecionado.toUpperCase();
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroNomeUorg = false;
			this.exibirModal = false;
		} else {
			this.erroNomeUorg = true;
		}
	}
	private adicionarCidade() {
		if (this.nomeCidadeSelecionada && this.nomeCidadeSelecionada != '') {
			this.parametrosFiltro.nomeMunicipio = this.nomeCidadeSelecionada.toUpperCase();
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroNomeCidade = false;
			this.exibirModal = false;
		} else {
			this.erroNomeCidade = true;
		}
	}
	private adicionarCargo() {
		if (this.nomeCargoSelecionado && this.nomeCargoSelecionado != '') {
			this.parametrosFiltro.cargo = this.nomeCargoSelecionado.toUpperCase();
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroNomeCargo = false;
			this.exibirModal = false;
		} else {
			this.erroNomeCargo = true;
		}
	}
	private adicionarNome() {
		if (this.nomeSelecionado && this.nomeSelecionado != '') {
			const nome = this.nomeSelecionado.toUpperCase();
			this.parametrosFiltro.nome = nome;
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroNome = false;
			this.exibirModal = false;
		} else {
			this.erroNome = true;
		}
	}
	private adicionarSelo() {
		if (this.nomeSeloSelecionado && this.nomeSeloSelecionado != '') {
			const nome = new SeloMatch(this.nomeSeloSelecionado, this.nomeInstituicaoSeloSelecionado);
			if (this.nomeInstituicaoSeloSelecionado != null) {
				const existe2 = this.parametrosFiltro.selos.filter(
					x =>
						x.nomeSelo.toUpperCase() == this.nomeSeloSelecionado?.toUpperCase() &&
						x.nomeInstituicao?.toUpperCase() == this.nomeInstituicaoSeloSelecionado?.toUpperCase()
				);
				if (existe2.length == 0) {
					this.parametrosFiltro.selos.push(nome);
					this.parametrosAtualizados.emit(this.parametrosFiltro);
					this.erroNomeSelo = false;
				}
			} else {
				const existe1 = this.parametrosFiltro.selos.filter(
					x => x.nomeSelo.toUpperCase() == this.nomeSeloSelecionado?.toUpperCase()
				);
				if (existe1.length == 0) {
					this.parametrosFiltro.selos.push(nome);
					this.parametrosAtualizados.emit(this.parametrosFiltro);
					this.erroNomeSelo = false;
				}
			}
			this.exibirModal = false;
		} else {
			if (this.nomeInstituicaoSeloSelecionado && this.nomeInstituicaoSeloSelecionado != '') {
				const nome = new SeloMatch('', this.nomeInstituicaoSeloSelecionado);
				const existe2 = this.parametrosFiltro.selos.filter(
					x => x.nomeInstituicao?.toUpperCase() == this.nomeInstituicaoSeloSelecionado?.toUpperCase()
				);
				if (existe2.length == 0) {
					this.parametrosFiltro.selos.push(nome);
					this.parametrosAtualizados.emit(this.parametrosFiltro);
					this.erroNomeSelo = false;
				}
				this.exibirModal = false;
			} else {
				this.erroNomeSelo = true;
			}
		}
	}
	private adicionarOrgao() {
		if (this.orgaoSelecionado && this.orgaoSelecionado.code) {
			const orgao = new OrgaoMatch(this.orgaoSelecionado.code, this.orgaoSelecionado.name);
			this.parametrosFiltro.orgao = orgao;
			this.parametrosFiltro.codigoOrgao = orgao.codigoOrgao;
			this.parametrosAtualizados.emit(this.parametrosFiltro);
			this.erroOrgao = false;
			this.exibirModal = false;
		} else {
			this.erroOrgao = true;
		}
	}

	private adicionarAreaInteresse() {
		if (this.areaInteresseSelecionada && this.areaInteresseSelecionada.id) {
			const areaInteresse = new AreaInteresseMatch(this.areaInteresseSelecionada.id, this.areaInteresseSelecionada.nome);
			const existe = this.parametrosFiltro.areasInteresse.filter(x => x.idAreaInteresse == this.areaInteresseSelecionada?.id);
			if (existe.length == 0) {
				this.parametrosFiltro.areasInteresse.push(areaInteresse);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroAreaInteresse = false;
			}
			this.exibirModal = false;
		} else {
			this.erroAreaInteresse = true;
		}
	}

	private adicionarVinculo() {
		if (this.vinculoSelecionado && this.vinculoSelecionado.id) {
			const vinculo = new VinculoMatch(this.vinculoSelecionado.id, this.vinculoSelecionado.nome);
			const existe = this.parametrosFiltro.vinculos.filter(x => x.id == this.vinculoSelecionado?.id);
			if (existe.length == 0) {
				this.parametrosFiltro.vinculos.push(vinculo);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroVinculo = false;
			}
			this.exibirModal = false;
		} else {
			this.erroVinculo = true;
		}
	}

	private adicionarSoftskill() {
		if (this.softskillSelecionado && this.softskillSelecionado.id) {
			const softskill = new CompetenciaMatch(this.softskillSelecionado.id, this.softskillSelecionado.nome);
			const existe = this.parametrosFiltro.competencias.filter(x => x.idCompetencia == this.softskillSelecionado?.id);
			if (existe.length == 0) {
				this.parametrosFiltro.competencias.push(softskill);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroSoftskills = false;
			}
			this.exibirModal = false;
		} else {
			this.erroSoftskills = true;
		}
	}

	private adicionarIdioma() {
		if (this.idiomaSelecionado && this.idiomaSelecionado.id) {
			const idioma = new IdiomaMatch(this.idiomaSelecionado.id, this.idiomaSelecionado.nome);
			const existe = this.parametrosFiltro.idiomas.filter(x => x.idIdioma == this.idiomaSelecionado?.id);
			if (existe.length == 0) {
				this.parametrosFiltro.idiomas.push(idioma);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroIdioma = false;
			}
			this.exibirModal = false;
		} else {
			this.erroIdioma = true;
		}
	}

	private adicionarExperiencia() {
		if (this.nomeExperienciaSelecionado && this.nomeExperienciaSelecionado != '') {
			const experiencia = new ExperienciaMatch(this.nomeExperienciaSelecionado);
			const existe = this.parametrosFiltro.experiencias.filter(
				x => x.nomeExperiencia.toUpperCase() == this.nomeExperienciaSelecionado?.toUpperCase()
			);
			if (existe.length == 0) {
				this.parametrosFiltro.experiencias.push(experiencia);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroNomeExperiencia = false;
			}
			this.exibirModal = false;
		} else {
			this.erroNomeExperiencia = true;
		}
	}

	private adicionarCapacitacao() {
		if (this.nomeCapacitacaoSelecionada && this.nomeCapacitacaoSelecionada != '') {
			const capacitacao = new CapacitacaoMatch(this.nomeCapacitacaoSelecionada);
			const existe = this.parametrosFiltro.capacitacoes.filter(
				x => x.nomeCapacitacao.toUpperCase() == this.nomeCapacitacaoSelecionada?.toUpperCase()
			);
			if (existe.length == 0) {
				this.parametrosFiltro.capacitacoes.push(capacitacao);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroNomeCapacitacao = false;
			}
			this.exibirModal = false;
		} else {
			this.erroNomeCapacitacao = true;
		}
	}

	private adicionarCertificacao() {
		if (this.nomeCertificacaoSelecionada && this.nomeCertificacaoSelecionada != '') {
			const certificacao = new CertificacaoMatch(this.nomeCertificacaoSelecionada);
			const existe = this.parametrosFiltro.certificacoes.filter(
				x => x.nomeCertificacao.toUpperCase() == this.nomeCertificacaoSelecionada?.toUpperCase()
			);
			if (existe.length == 0) {
				this.parametrosFiltro.certificacoes.push(certificacao);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroNomeCertificacao = false;
			}
			this.exibirModal = false;
		} else {
			this.erroNomeCertificacao = true;
		}
	}

	private adicionarAreaAtuacao() {
		if (this.areaAtuacaoSelecionada && this.areaAtuacaoSelecionada.id) {
			const areaAtuacao = new AreaAtuacaoMatch(this.areaAtuacaoSelecionada.id, this.areaAtuacaoSelecionada.nome);
			const existe = this.parametrosFiltro.areasAtuacao.filter(x => x.idAreaAtuacao == this.areaAtuacaoSelecionada?.id);
			if (existe.length == 0) {
				this.parametrosFiltro.areasAtuacao.push(areaAtuacao);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroAreaAtuacao = false;
			}
			this.exibirModal = false;
		} else {
			this.erroAreaAtuacao = true;
		}
	}

	private adicionarFormacao() {
		if (this.nivelFormacaoSelecionado != undefined && this.nivelFormacaoSelecionado.id) {
			const formacao = new FormacaoMatch(
				this.nivelFormacaoSelecionado.id,
				this.nomeFormacaoSelecionada,
				this.nivelFormacaoSelecionado.nome
			);
			const existe = this.parametrosFiltro.formacoes.filter(
				x =>
					x.idNivelCurso === this.nivelFormacaoSelecionado?.id &&
					x.nomeFormacao?.toUpperCase() === this.nomeFormacaoSelecionada?.toUpperCase()
			);
			if (existe.length == 0) {
				this.parametrosFiltro.formacoes.push(formacao);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroNivelFormacao = false;
			}
			this.exibirModal = false;
		} else {
			this.erroNivelFormacao = true;
		}
	}

	private adicionarConhecimento() {
		if (this.areaConhecimento) {
			const area = new AreaConhecimentoMatch(this.areaConhecimento.id, this.areaConhecimento.nome);
			const existe = this.parametrosFiltro.areasConhecimento.filter(x => x.idAreaConhecimento == this.areaConhecimento.id);
			if (existe.length == 0) {
				this.parametrosFiltro.areasConhecimento.push(area);
				this.parametrosAtualizados.emit(this.parametrosFiltro);
				this.erroConhecimento = false;
			}
			this.exibirModal = false;
		} else {
			this.erroConhecimento = true;
		}
	}

	private getTiposVinculos() {
		this.subscriptions.push(
			this.talentosService.tipoVinculo().subscribe(dados => {
				if (dados) {
					this.listaTiposVinculos = dados;
				}
			})
		);
	}

	private getSoftskills() {
		this.subscriptions.push(
			this.talentosService.competencias().subscribe(dados => {
				if (dados) {
					this.listaSoftskills = dados.items;
				}
			})
		);
	}

	private getIdiomas() {
		this.subscriptions.push(
			this.talentosService.idiomas().subscribe(dados => {
				if (dados) {
					this.listaIdiomas = dados;
				}
			})
		);
	}

	private getOrgaos() {
		this.orgaos = [];
		this.subscriptions.push(
			this.oportunidadeService.orgaos().subscribe((dados: any[]) => {
				if (dados !== null) {
					dados.forEach(dado => {
						this.orgaos.push({ name: this.descricaoOrgao(dado.sigla, dado.nome, dado.situacao), code: dado.codigoSiape });
					});
					this.orgaos.sort((a, b) => a.name.localeCompare(b.name));
				}
			})
		);
	}

	private descricaoOrgao(sigla: string, nome: string, situacao: string): string {
		if (situacao === 'PENDENTE_EXTINCAO_SIAPE') return sigla + ' - ' + nome + ' (Pendente de extinção)';
		else if (situacao === 'DESATIVADO_EXTINTO') return sigla + ' - ' + nome + ' (Extinto)';
		else return sigla + ' - ' + nome;
	}

	atualizarOrgao(event: any) {
		this.orgaoSelecionado = event;
	}

	private getNiveisFormacao() {
		this.subscriptions.push(
			this.talentosService.niveisCurso('FOR').subscribe(dados => {
				if (dados) {
					this.listaNiveisFormacao = dados;
				}
			})
		);
	}

	private getAreasAtuacao() {
		this.subscriptions.push(
			this.talentosService.areasAtuacao().subscribe(dados => {
				if (dados) {
					this.listaAreaAtuacao = dados;
				}
			})
		);
	}
	private getAreasInteresse() {
		this.subscriptions.push(
			this.talentosService.areasAtuacao().subscribe(dados => {
				if (dados) {
					this.listaAreaInteresse = dados;
				}
			})
		);
	}
}
