export class TipoMovimentacao {
	codigo?: string;
	descricao?: string;
	ativo?: boolean;
	orgaos?: any[];
	orgaosTipoMovimentacao?: OrgaoTipoMovimentacao[];
}

class OrgaoTipoMovimentacao {
	codigoSiape?: number;
	nome?: string;
}
