import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AreaInteresse } from '@app/core/models/area-interesse';
import { TalentosApiService } from '@app/core/services/talentos-api.service';

@Component({
	selector: 'sgp-requisitos-area-interesse',
	templateUrl: './requisitos-area-interesse.component.html'
})
export class RequisitosAreaInteresseComponent implements OnChanges {
	@Input() exibir!: boolean;
	@Input() erroMensagem = false;
	@Input() textoErroMensagem = 'Campo obrigatório';
	@Input() obrigatoriedade? = true;
	@Output() obterArea: EventEmitter<AreaInteresse> = new EventEmitter<AreaInteresse>();
	@Output() obterAreaObrigatoria: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() obterErroCampoObrigatorio: EventEmitter<boolean> = new EventEmitter<boolean>();

	interesse = '';
	erroTamanhoBusca = false;
	areaInteresseSelecionada?: AreaInteresse;
	interesseObrigatorio = false;

	metadado = { total: -1 };
	paginaInicial = 1;
	areasInteresse: any[] = [];
	loading = false;
	itensPorPagina = 10;

	constructor(private talentosService: TalentosApiService) {}

	ngOnChanges(): void {
		if (!this.exibir) {
			this.interesse = '';
			this.areaInteresseSelecionada = undefined;
			this.interesseObrigatorio = false;
			this.areasInteresse = [];
			this.metadado = { total: -1 };
		}
	}

	filterAreaInteresse(): void {
		if (this.interesse.valueOf().length >= 3) {
			this.erroTamanhoBusca = false;
			this.loading = true;
			this.talentosService.consultarAreasAtuacaoPorTexto(this.interesse).subscribe(res => {
				if (res) {
					this.areasInteresse = res;
					this.loading = false;
				}
			});
		} else {
			this.erroTamanhoBusca = true;
			this.metadado = { total: -1 };
		}
	}

	paginarAreaInteresse(event: any) {
		this.paginaInicial = event.first;
		this.filterAreaInteresse();
	}

	pesquisaInicial() {
		this.paginaInicial = 1;
		this.filterAreaInteresse();
		this.obterErroCampoObrigatorio.emit(false);
	}

	atualizarValorArea() {
		this.obterArea.emit(this.areaInteresseSelecionada);
	}

	atualizaAreaObrigatoria() {
		if (this.obrigatoriedade) {
			this.obterAreaObrigatoria.emit(this.interesseObrigatorio);
		}
	}
}
