import { LogLevel, SegurancaConfiguration } from '@sigepe/ngx-sigepe-seguranca';

export class ConfigEnvironment {
	host_oportunidades: string;
	host_talentos: string;
	security_hosts: string[];
	host_login_unico?: string;
	host_jsks?: string;
	host_api_sigepe?: string;
	client_id?: string;
	cookieDomain?: string;

	constructor(
		host_oportunidades: string,
		host_talentos: string,
		security_hosts: string[],
		host_login_unico?: string,
		host_jsks?: string,
		host_api_sigepe?: string,
		client_id?: string,
		cookieDomain?: string
	) {
		this.host_oportunidades = host_oportunidades;
		this.host_talentos = host_talentos;
		this.security_hosts = security_hosts;
		this.host_login_unico = host_login_unico;
		this.host_jsks = host_jsks;
		this.host_api_sigepe = host_api_sigepe;
		this.client_id = client_id;
		this.cookieDomain = cookieDomain;
	}

	getEnvironment() {
		return {
			//OPORTUNIDADES (Usar no oportunidades.service)
			HOST_OPORTUNIDADE: `${this.host_oportunidades}`,
			CANDIDATO_OPORTUNIDADE: `${this.host_oportunidades}/candidato-oportunidade`,
			CESTA_CURRICULO: `${this.host_oportunidades}/cesta-curriculo`,
			EDITAL: `${this.host_oportunidades}/editais`,
			DASHBOARD: `${this.host_oportunidades}/dashboard`,
			ANEXOS: `${this.host_oportunidades}/anexos`,
			ETAPAS: `${this.host_oportunidades}/editais/etapas`,
			INCENTIVO: `${this.host_oportunidades}/incentivos`,
			LOCALIDADE: `${this.host_oportunidades}/localidade?busca=`,
			MATCH: `${this.host_oportunidades}/match`,
			MENSAGEM_ALERTA: `${this.host_oportunidades}/mensagem-alerta`,
			ORGAO: `${this.host_oportunidades}/orgaos`,
			PERFIL_PROFISSIOGRAFICO: `${this.host_oportunidades}/perfil-profissiografico`,
			PERFIL_PROFISSIOGRAFICO_DASHBOARD: `${this.host_oportunidades}/perfil-profissiografico-dashboard`,
			PROGRAMA_GESTAO: `${this.host_oportunidades}/programas-gestao`,
			SERVICOS_TERCEIROS: `${this.host_oportunidades}/servicos-terceiros`,
			SITUACAO_EDITAL: `${this.host_oportunidades}/situacoes-edital`,
			TIPO_ETAPA: `${this.host_oportunidades}/tipos-etapa`,
			TIPO_JORNADA: `${this.host_oportunidades}/tipos-jornada`,
			TIPO_MOVIMENTACAO: `${this.host_oportunidades}/tipos-movimentacao`,
			UNIDADE_ORGANIZACIONAL: `${this.host_oportunidades}/unidades-organizacionais`,
			OPORTUNIDADE: `${this.host_oportunidades}/oportunidades`,
			UNIDADE_AUTORIZADORA: `${this.host_oportunidades}/unidades-organizacionais/superiores`,
			//TALENTOS (Usar no talentos.service)
			AREA_ATUACAO: `${this.host_talentos}/areas-atuacao`,
			AREA_CONHECIMENTO: `${this.host_talentos}/areas-conhecimento`,
			COMPETENCIA: `${this.host_talentos}/competencias`,
			CURRICULOS: `${this.host_talentos}/curriculos`,
			IDIOMA: `${this.host_talentos}/idiomas/`,
			NIVEL_CURSO: `${this.host_talentos}/niveis-curso`,
			VINCULO: `${this.host_talentos}/vinculos`,
			//Autoriza
			SEGURANCA_CONFIG: this.getConfiguracoesAutoriza()
		};
	}

	getConfiguracoesAutoriza(): SegurancaConfiguration {
		const seguranca_config: SegurancaConfiguration = {
			loginUnicoHost: this.host_login_unico,
			jwksHost: this.host_jsks,
			apiSigepeHost: this.host_api_sigepe,
			redirectUrl: `${window.location.protocol}//${window.location.host}/autorizacao`,
			postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
			cookieDomain: this.cookieDomain,
			clientId: this.client_id,
			scope: 'openid+email+phone+profile+govbr_confiabilidades', // 'openid profile ' + your scopes
			sistemaOrigem: 'OPORTUNIDADES',
			responseType: 'code',
			silentRenew: false,
			renewTimeBeforeTokenExpiresInSeconds: 10,
			logLevel: LogLevel.Error,
			secureRoutes: this.security_hosts
		};
		return seguranca_config;
	}
}
