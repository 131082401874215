import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { NomeTag, RetornoComboTags } from './../../core/models/retorno-combo-tags';

import { FeedbackModal } from '@app/core/models/feedback-modal';
import { ParametrosPaginacao } from '../models/parametros-paginacao';
import { RetornoCurriculoTags } from '../models/retorno-curriculo-tags';
import { Subscription } from 'rxjs';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-pesquisar-cesta-curriculo',
	templateUrl: './pesquisar-cesta-curriculo.component.html',
	providers: [MessageService]
})
export class PesquisarCestaCurriculoComponent implements OnInit, OnDestroy {
	tags: string[] = [];
	tagsSelecionadasString: string[] = [];
	retorno?: RetornoCurriculoTags;
	subscriptions: Subscription[] = [];
	primeiroItemPesquisa = 1;
	itemsPorPagina = 10;
	pagina = 1;
	totalResultado = 0;
	messages: Message[] = [];

	constructor(private talentosApi: TalentosApiService, private ui: UiService, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.resetarVariaveis();
		this.carregarComboTags();
		this.pesquisaInicial();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	pesquisar(limparMensagens = true) {
		if (limparMensagens) {
			this.messages = [];
		}
		console.log('tags filtradas', this.tagsSelecionadasString);
		const tags = this.converterObjetoParametrizavel(this.tagsSelecionadasString!);
		console.log('temp', tags);
		this.subscriptions.push(
			this.talentosApi.pesquisarCesta(this.pagina, this.itemsPorPagina, tags).subscribe(
				res => {
					console.log('resultado', res);
					this.retorno = res;
					this.totalResultado = res.metadata.qtdTotal;
					this.cdr.detectChanges();
				},
				err => {
					this.messages.push(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	//TODO: Chamar o paginar no p-table
	paginar(event: any) {
		this.primeiroItemPesquisa = event.first;
		this.pesquisar();
	}

	rePesquisar(event: ParametrosPaginacao) {
		this.pagina = event.pagina!;
		this.itemsPorPagina = event.itemsPorPagina!;
		this.pesquisar(false);
	}

	limpar() {
		this.resetarVariaveis();
		this.carregarComboTags();
		this.pesquisaInicial();
	}

	atualizarResultado(event: FeedbackModal) {
		console.log('atualizarResultado', event);
		if (event.reload) {
			this.resetarVariaveis();
			this.carregarComboTags();
			this.pesquisaInicial();
		}
		if (event.mensagemFeedback) {
			this.messages = event.mensagemFeedback;
			console.log('messages', this.messages);
		}
	}

	private converterObjetoParametrizavel(itens: string[]) {
		const temp: NomeTag[] = [];
		itens.forEach(it => {
			temp.push(new NomeTag(it));
		});
		const retorno = new RetornoComboTags(temp);
		return retorno;
	}

	private carregarComboTags() {
		this.subscriptions.push(
			this.talentosApi.carregarTagsComboCesta().subscribe(
				res => {
					this.preencherCombo(res);
				},
				err => {
					//TODO: tratar erro
					console.log(err);
				}
			)
		);
	}

	private pesquisaInicial() {
		this.primeiroItemPesquisa = 1;
		this.pesquisar();
	}

	private resetarVariaveis() {
		this.tags = [];
		this.retorno = undefined;
		this.primeiroItemPesquisa = 1;
		this.pagina = 1;
		this.messages = [];
		this.tagsSelecionadasString = [];
	}

	private preencherCombo(retorno: RetornoComboTags) {
		retorno.tags!.forEach(item => {
			if (item.nomeTag !== undefined) {
				this.tags.push(item.nomeTag);
			}
		});
	}
}
