<div class="br-input">
	<label for="id">{{label}}</label>
	<p-autoComplete
		[id]="id"
		[(ngModel)]="ngModel"
		[suggestions]="filteredLocalidade"
		(completeMethod)="filterLocalidades($event)"
		field="name"
		dropdownAriaLabel="name"
		[minLength]="1"
		[dropdown]="true"
		[attr.required]="required"
		[attr.appendTo]="appendTo"
		[forceSelection]="true"
		[autoZIndex]="true"
		placeholder="Comece a escrever o nome da cidade para exibir as sugestões"
		[showEmptyMessage]="true"
		emptyMessage="Nenhuma opção encontrada"
		(onSelect)="atualizarNgModel($event)"
		(onClear)="atualizarNgModel($event)"
	>
	</p-autoComplete>
</div>
