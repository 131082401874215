<h4 data-cy="titulo-oportunidade">Oportunidade: {{oportunidade.nomeOportunidade}}</h4>
<div class="row">
	<div class="col">
		<p-tabView [activeIndex]="abaAtiva" (onChange)="setAbaAtiva($event)">
			<p-tabPanel header="Informações da oportunidade">
				<h6 class="font-primary-default" data-cy="titulo-dados-basicos">Dados básicos</h6>
				<p-panel styleClass="mb-3" data-cy="informacoes-oportunidade">
					<sgp-item-label-descricao
						id="unidade-oportunidade"
						label="Unidade"
						[conteudo]="oportunidade.nomeUorg"
					></sgp-item-label-descricao>
					<sgp-item-label-descricao
						id="localidade"
						label="Localidade"
						[conteudo]="oportunidade.nomeLocalidade"
					></sgp-item-label-descricao>
					<div class="row mb-2">
						<div class="col-sm-2">
							<label>Descrição resumida:</label>
						</div>
						<div class="col-sm-10">
							<div
								*ngIf="oportunidade.descricaoResumida; else descricaoVazia"
								[innerHtml]="oportunidade.descricaoResumida"
							></div>
							<ng-template #descricaoVazia>-</ng-template>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-sm-2">
							<label>Atividades a serem executadas:</label>
						</div>
						<div class="col-sm-10">
							<div *ngIf="oportunidade.atividades; else atividadesVazia" [innerHtml]="oportunidade.atividades"></div>
							<ng-template #atividadesVazia>-</ng-template>
						</div>
					</div>
					<sgp-item-label-descricao
						id="movimentacao"
						label="Tipo de movimentação"
						[conteudo]="oportunidade.tipoMovimentacao?.descricao"
						*ngIf="oportunidade.tipoMovimentacao"
					></sgp-item-label-descricao>
					<sgp-item-label-descricao
						id="programa-gestao"
						label="Programa de gestão"
						[conteudo]="oportunidade.programaGestao?.descricao"
					></sgp-item-label-descricao>
					<sgp-item-label-descricao
						id="jornada"
						label="Jornada semanal"
						[conteudo]="oportunidade.tipoJornada?.descricao"
					></sgp-item-label-descricao>
					<sgp-item-label-descricao
						id="gratificacao"
						label="Incentivo/gratificação"
						[conteudo]="oportunidade.incentivo?.descricao?.toUpperCase()"
					></sgp-item-label-descricao>
					<sgp-item-label-descricao
						id="vagas"
						label="Quantidade de vagas"
						[conteudo]="oportunidade.numeroVagas?.toString()"
					></sgp-item-label-descricao>
				</p-panel>

				<!-- Lista de requisitos da oportunidade -->
				<h6 class="font-primary-default" data-cy="titulo-dados-basicos">Requisitos da oportunidade</h6>
				<p-panel styleClass="mb-3" data-cy="requisitos-oportunidade">
					<div *ngIf="!existemRequisitos">
						<p>Nenhum requisito cadastrado</p>
					</div>
					<div *ngIf="existemRequisitos">
						<div class="row mb-2" *ngIf="oportunidade.formacoes && oportunidade.formacoes.length > 0">
							<div class="col-sm-2">
								<label>Formação Acadêmica:</label>
							</div>
							<div class="col-sm-10">
								<div class="text-capitalize" *ngFor="let formacao of oportunidade.formacoes">
									{{formacao.nomeNivelCurso?.toLowerCase()}} - {{formacao.nomeFormacao?.toLowerCase()}}
									<span *ngIf="formacao.obrigatorio">(Obrigatório)</span>
								</div>
							</div>
						</div>

						<sgp-item-label-descricao
							id="residencia"
							label="Residência"
							[conteudo]="oportunidade.nomeMunicipioResidencia"
							*ngIf="oportunidade.codigoMunicipioResidencia"
						></sgp-item-label-descricao>

						<div class="row mb-2" *ngIf="oportunidade.nomeOrgaoLotacao">
							<div class="col-sm-2">
								<label>Órgão de lotação:</label>
							</div>
							<div class="col-sm-10">
								<div>{{ oportunidade.nomeOrgaoLotacao }} (Obrigatório)</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.nomeOrgaoExercicio">
							<div class="col-sm-2">
								<label>Órgão de exercício:</label>
							</div>
							<div class="col-sm-10">
								<div>{{ oportunidade.nomeOrgaoExercicio }} (Obrigatório)</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.experiencias && oportunidade.experiencias.length > 0">
							<div class="col-sm-2">
								<label>Experiências Profissionais:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let experiencia of oportunidade.experiencias">
									<div>
										Experiência: {{ experiencia.nomeExperiencia }}
										<span *ngIf="experiencia.servicoPublico"> - No serviço público</span>
										<span *ngIf="experiencia.obrigatorio"> - Obrigatório</span>
									</div>
									<div>Meses de experiência: {{experiencia.qtdMinimaMesesExperiencia}}</div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.areasAtuacao && oportunidade.areasAtuacao.length > 0">
							<div class="col-sm-2">
								<label>Áreas de Atuação:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let area of oportunidade.areasAtuacao">
									<div>{{area.nome?.toUpperCase()}} <span *ngIf="area.obrigatorio">(Obrigatório)</span></div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.areasConhecimento && oportunidade.areasConhecimento.length > 0">
							<div class="col-sm-2">
								<label>Conhecimentos técnicos:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let area of oportunidade.areasConhecimento">
									<div>{{area.nome?.toUpperCase()}} <span *ngIf="area.obrigatorio">(Obrigatório)</span></div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.capacitacoes && oportunidade.capacitacoes.length > 0">
							<div class="col-sm-2">
								<label>Capacitações:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let capacitacao of oportunidade.capacitacoes">
									<div>{{capacitacao.nomeCapacitacao}} <span *ngIf="capacitacao.obrigatorio">(Obrigatório)</span></div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.certificacoes && oportunidade.certificacoes.length > 0">
							<div class="col-sm-2">
								<label>Certificações:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let certificacao of oportunidade.certificacoes">
									<div>{{certificacao.nomeCertificacao}} <span *ngIf="certificacao.obrigatorio">(Obrigatório)</span></div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.competencias && oportunidade.competencias.length > 0">
							<div class="col-sm-2">
								<label>Softskills:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let competencia of oportunidade.competencias">
									<div>
										{{competencia.nome?.toUpperCase()}} <span *ngIf="competencia.obrigatorio">(Obrigatório)</span>
									</div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.vinculos && oportunidade.vinculos.length > 0">
							<div class="col-sm-2">
								<label>Vínculos:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let vinculo of oportunidade.vinculos">
									<div>
										{{vinculo.nome?.toUpperCase()}}
										<span *ngIf="vinculo.obrigatorio">(Obrigatório)</span>
										<span *ngIf="vinculo.excetoEstagioProbatorio">: Exceto em estágio probatório</span>
									</div>
								</div>
							</div>
						</div>

						<div class="row mb-2" *ngIf="oportunidade.idiomas && oportunidade.idiomas.length > 0">
							<div class="col-sm-2">
								<label>Idiomas:</label>
							</div>
							<div class="col-sm-10">
								<div *ngFor="let idioma of oportunidade.idiomas">
									<div>{{idioma.nome?.toUpperCase()}} <span *ngIf="idioma.obrigatorio"> (Obrigatório)</span></div>
									<div>- Nível de compreensão: {{idioma.nivelCompreensao | idioma }}</div>
									<div>- Nível de escrita: {{idioma.nivelEscrita | idioma}}</div>
									<div>- Nível de leitura: {{idioma.nivelLeitura | idioma}}</div>
									<div>
										<span *ngIf="idioma?.idIdioma === 39; else naoLibras">- Nível de expressão</span>
										<ng-template #naoLibras>- Nível de fala</ng-template>
										{{idioma.nivelFala | idioma}}
									</div>
								</div>
							</div>
						</div>
					</div>

					<sgp-item-label-descricao
						id="outros"
						label="Outros Requisitos"
						[conteudo]="oportunidade.outrosRequisitos"
						*ngIf="oportunidade.outrosRequisitos"
					></sgp-item-label-descricao>
				</p-panel>
			</p-tabPanel>

			<p-tabPanel header="Candidatos inscritos">
				<sgp-visualizar-candidatos
					[idOportunidade]="oportunidade.id!"
					[nomeOportunidade]="oportunidade.nomeOportunidade!"
					[usuarioMesmoOrgao]="usuarioMesmoOrgao"
					[codigoOrgao]="orgaoEdital"
					[situacaoEdital]="situacaoEdital"
					[numeroEdital]="numeroEdital"
					[editalArquivado]="editalArquivado"
					*ngIf="this.abaAtiva === 1"
				></sgp-visualizar-candidatos
			></p-tabPanel>
		</p-tabView>
	</div>
</div>
