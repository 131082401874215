import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule } from 'primeng/accordion';
import { AlterarLaudoComponent } from './alterar-laudo/alterar-laudo.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CoreModule } from '@app/core/core.module';
import { DialogModule } from 'primeng/dialog';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { SharedModule } from './../shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TableModule } from 'primeng/table';

@NgModule({
	declarations: [AlterarLaudoComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ButtonModule,
		InputTextModule,
		MessagesModule,
		TableModule,
		SharedModule,
		SigepeSegurancaModule,
		CoreModule,
		DsGovDirectivesModule,
		FormsModule,
		AccordionModule,
		DialogModule,
		ConfirmDialogModule
	],
	providers: [MessageService, ConfirmationService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AlterarLaudoModule {}
