import { RouterModule, Routes } from '@angular/router';

import { AlterarLaudoComponent } from './alterarLaudo/alterar-laudo/alterar-laudo.component';
import { AutorizacaoComponent } from './auth/autorizacao/autorizacao.component';
import { CadastrarEditalComponent } from '@cadastrarEdital/cadastrar-edital/cadastrar-edital.component';
import { DashboardComponent } from '@dashboard/dashboard/dashboard.component';
import { DashboardProfissiograficoComponent } from './dashboard-profissiografico/dashboard-profissiografico/dashboard-profissiografico.component';
import { DetalharLaudoComponent } from './detalharLaudo/detalhar-laudo/detalhar-laudo.component';
import { DetalharQuestionarioComponent } from './detalhar-questionario-profissiografico/detalhar-questionario.component';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { HomeComponent } from './home/home/home.component';
import { MensagemAlertaComponent } from './ferramentas/mensagem-alerta/mensagem-alerta.component';
import { NgModule } from '@angular/core';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PerfisEnum } from '@core/enums/perfis.enum';
import { PesquisarCestaCurriculoComponent } from './cestaCurriculo/pesquisar-cesta-curriculo/pesquisar-cesta-curriculo.component';
import { PesquisarCurriculoComponent } from '@pesquisarCurriculo/pesquisar-curriculo/pesquisar-curriculo.component';
import { PesquisarEditalComponent } from '@pesquisarEdital/pesquisar-edital/pesquisarEdital.component';
import { PesquisarLaudoComponent } from './pesquisarLaudo/pesquisar-laudo/pesquisar-laudo.component';
import { PesquisarQuestionarioProfissiograficoComponent } from './pesquisarQuestionarioProfissiografico/pesquisar-questionario/pesquisar-questionario.component';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { SolicitarBaseCurriculosComponent } from './gestaoDados/solicitar-base-curriculos/solicitar-base-curriculos.component';
import { TabelaAreaAtuacaoComponent } from '@tabelasAdministrativas/tabela-area-atuacao/tabela-area-atuacao.component';
import { TabelaAreaConhecimentoComponent } from '@tabelasAdministrativas/tabela-area-conhecimento/tabela-area-conhecimento.component';
import { TabelaIdiomaComponent } from '@tabelasAdministrativas/tabela-idioma/tabela-idioma.component';
import { TabelaIncentivoComponent } from './tabelasAdministrativas/tabela-incentivo/tabela-incentivo.component';
import { TabelaInstituicaoComponent } from '@tabelasAdministrativas/tabela-instituicao/tabela-instituicao.component';
import { TabelaSoftskillComponent } from '@tabelasAdministrativas/tabela-softskill/tabela-softskill.component';
import { TabelaTipoDocumentoComponent } from './tabelasAdministrativas/tabela-tipo-documento/tabela-tipo-documento.component';
import { TabelaTipoMovimentacaoComponent } from './tabelasAdministrativas/tabela-tipo-movimentacao/tabela-tipo-movimentacao.component';
import { TabelaVinculoComponent } from '@tabelasAdministrativas/tabela-vinculo/tabela-vinculo.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { VisualizarEditalComponent } from './visualizarEdital/visualizar-edital/visualizar-edital.component';
import { environment } from '@environments/environment';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'home',
		component: HomeComponent,
		data: {
			title: 'Home',
			breadcrumb: 'Home',
			showInMenu: false,
			transactions: ['OPO_DASHBOARD', 'OPO_DASHBOARD_PROFISSIOGRAFICO']
		},
		canLoad: environment.TRANSACTION,
		canActivate: environment.TRANSACTION
	},

	{
		path: 'dashboard',
		component: DashboardComponent,
		data: {
			title: 'Dashboard',
			breadcrumb: 'Dashboard',
			showInMenu: true,
			transactions: 'OPO_DASHBOARD',
			roles: [
				PerfisEnum.GESTOR,
				PerfisEnum.AUTORIZADOR,
				PerfisEnum.GESTOR_GERAL,
				PerfisEnum.GESTOR_REFORMA,
				PerfisEnum.AUTORIZADOR_REFORMA
			]
		},
		canLoad: environment.GUARDS,
		canActivate: environment.GUARDS
	},
	{
		path: 'dashboard-profissiografico',
		component: DashboardProfissiograficoComponent,
		data: {
			title: 'Dashboard profissiográfico',
			breadcrumb: 'Dashboard profissiográfico',
			showInMenu: true,
			transactions: 'OPO_DASHBOARD_PROFISSIOGRAFICO',
			roles: [PerfisEnum.GESTOR_PESSOAS, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.PESQUISADOR]
		},
		canLoad: environment.GUARDS,
		canActivate: environment.GUARDS
	},
	{
		path: 'cadastrarEdital',
		component: CadastrarEditalComponent,
		data: {
			title: 'Cadastrar edital',
			breadcrumb: 'Cadastrar edital',
			showInMenu: true,
			transactions: 'OPO_SOLICITAR_PUBLICACAO_EDITAL',
			roles: [PerfisEnum.GESTOR, PerfisEnum.AUTORIZADOR]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'edital/:id',
		component: VisualizarEditalComponent,
		data: {
			title: 'Visualizar edital',
			breadcrumb: 'Visualizar edital',
			roles: [
				PerfisEnum.GESTOR,
				PerfisEnum.AUTORIZADOR,
				PerfisEnum.GESTOR_GERAL,
				PerfisEnum.GESTOR_REFORMA,
				PerfisEnum.AUTORIZADOR_REFORMA
			]
		},
		canActivate: environment.GUARDS
	},
	{
		path: 'pesquisarEdital',
		component: PesquisarEditalComponent,
		data: {
			title: 'Pesquisar edital',
			breadcrumb: 'Pesquisar edital',
			showInMenu: true,
			transactions: 'OPO_PESQUISAR_EDITAL',
			roles: [
				PerfisEnum.GESTOR,
				PerfisEnum.AUTORIZADOR,
				PerfisEnum.GESTOR_GERAL,
				PerfisEnum.GESTOR_REFORMA,
				PerfisEnum.AUTORIZADOR_REFORMA
			]
		},
		canLoad: environment.GUARDS,
		canActivate: environment.GUARDS,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'pesquisarCurriculo',
		component: PesquisarCurriculoComponent,
		data: {
			title: 'Pesquisar currículo',
			breadcrumb: 'Pesquisar currículo',
			showInMenu: true,
			transactions: 'OPO_PESQUISAR_CURRICULO',
			roles: [PerfisEnum.GESTOR, PerfisEnum.GESTOR_GERAL, PerfisEnum.GESTOR_REFORMA]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'cestaCurriculo',
		component: PesquisarCestaCurriculoComponent,
		data: {
			title: 'Cesta de Currículo',
			breadcrumb: 'Cesta de Currículo',
			showInMenu: true,
			transactions: 'OPO_CESTA_CURRICULO',
			roles: [PerfisEnum.GESTOR, PerfisEnum.GESTOR_GERAL, PerfisEnum.GESTOR_REFORMA]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfilProfissiografico/pesquisarLaudo',
		component: PesquisarLaudoComponent,
		data: {
			title: 'Pesquisar perfis e relatórios',
			breadcrumb: 'Pesquisar perfis e relatórios',
			showInMenu: true,
			transactions: 'OPO_MANTER_LAUDO',
			roles: [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfilProfissiografico/laudo/:id/:idCargo',
		component: DetalharLaudoComponent,
		data: {
			title: 'Detalhar relatório',
			breadcrumb: 'Detalhar relatório',
			showInMenu: false,
			transactions: 'OPO_MANTER_LAUDO',
			roles: [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfilProfissiografico/alterar-laudo/:id',
		component: AlterarLaudoComponent,
		data: {
			title: 'Alterar Relatório',
			breadcrumb: 'Alterar Relatório',
			showInMenu: false,
			transactions: 'OPO_MANTER_LAUDO',
			roles: [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfilProfissiografico/pesquisarQuestionarioProfissiografico',
		component: PesquisarQuestionarioProfissiograficoComponent,
		data: {
			title: 'Pesquisar questionário profissiográfico',
			breadcrumb: 'Pesquisar questionário profissiográfico',
			showInMenu: true,
			transactions: 'OPO_MANTER_QUESTIONARIO',
			roles: [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'perfilProfissiografico/questionario/:idCargo',
		component: DetalharQuestionarioComponent,
		data: {
			title: 'Detalhar questionário profissiográfico',
			breadcrumb: 'Detalhar questionário',
			showInMenu: false,
			transactions: 'OPO_MANTER_LAUDO',
			roles: [PerfisEnum.PESQUISADOR, PerfisEnum.GESTOR_PRODUTO, PerfisEnum.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'solicitarBaseCurriculos',
		component: SolicitarBaseCurriculosComponent,
		data: {
			title: 'Solicitar Base de Curriculos',
			breadcrumb: 'Solicitar Base de Curriculos',
			showInMenu: true,
			transactions: 'OPO_EXPORTAR_BASE_CURRICULO',
			roles: [PerfisEnum.GESTOR_DADOS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION,
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'configuracoes',
		data: {
			title: 'Tabelas administrativas',
			breadcrumb: 'Tabelas administrativas',
			showInMenu: true,
			transactions: 'OPO_TABELA_ADMINISTRATIVA',
			roles: [PerfisEnum.GESTOR_GERAL]
		},
		children: [
			{
				path: 'idiomas',
				component: TabelaIdiomaComponent,
				data: {
					title: 'Tabela de idiomas',
					breadcrumb: 'Tabela de idiomas',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'softskills',
				component: TabelaSoftskillComponent,
				data: {
					title: 'Tabela de softskills',
					breadcrumb: 'Tabela de softskills',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'instituicoes',
				component: TabelaInstituicaoComponent,
				data: {
					title: 'Tabela de instituições acadêmicas',
					breadcrumb: 'Tabela de instituições acadêmicas',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'vinculos',
				component: TabelaVinculoComponent,
				data: {
					title: 'Tabela de tipos de vínculos',
					breadcrumb: 'Tabela de tipos de vínculos',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'tipos-documento',
				component: TabelaTipoDocumentoComponent,
				data: {
					title: 'Tabela de tipos de documentos',
					breadcrumb: 'Tabela de tipos de documentos',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'incentivos',
				component: TabelaIncentivoComponent,
				data: {
					title: 'Tabela de incentivos',
					breadcrumb: 'Tabela de incentivos',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'area-conhecimento',
				component: TabelaAreaConhecimentoComponent,
				data: {
					title: 'Tabela de áreas de conhecimento',
					breadcrumb: 'Tabela de áreas de conhecimento',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'area-atuacao',
				component: TabelaAreaAtuacaoComponent,
				data: {
					title: 'Tabela de áreas de atuação',
					breadcrumb: 'Tabela de áreas de atuação',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'tipo-movimentacao',
				component: TabelaTipoMovimentacaoComponent,
				data: {
					title: 'Tabela de tipos de movimentação',
					breadcrumb: 'Tabela de tipos de movimentação',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			}
		],
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'ferramentas',
		data: {
			title: 'Ferramentas',
			breadcrumb: 'Ferramentas',
			showInMenu: true,
			transactions: 'OPO_TABELA_ADMINISTRATIVA',
			roles: [PerfisEnum.GESTOR_GERAL]
		},
		children: [
			{
				path: 'mensagem-alerta',
				component: MensagemAlertaComponent,
				data: {
					title: 'Mensagem de alerta',
					breadcrumb: 'Mensagem de alerta',
					showInMenu: true,
					transactions: 'OPO_TABELA_ADMINISTRATIVA',
					roles: [PerfisEnum.GESTOR_GERAL]
				},
				canLoad: environment.GUARDS,
				canActivate: environment.GUARDS,
				runGuardsAndResolvers: 'always'
			}
		]
	},
	{
		path: 'alterarEdital/:id',
		component: CadastrarEditalComponent,
		data: {
			title: 'Alterar edital',
			breadcrumb: 'Alterar edital',
			transactions: 'OPO_ALTERAR_EDITAL',
			roles: [PerfisEnum.GESTOR, PerfisEnum.AUTORIZADOR]
		},
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'retificarEdital/:id',
		component: CadastrarEditalComponent,
		data: {
			title: 'Retificar edital',
			breadcrumb: 'Retificar edital',
			transactions: ['OPO_RETIFICAR_EDITAL', 'OPO_SOLICITAR_PUBLICACAO_RETIFICACAO'],
			roles: [PerfisEnum.GESTOR, PerfisEnum.AUTORIZADOR, PerfisEnum.GESTOR_REFORMA, PerfisEnum.AUTORIZADOR_REFORMA]
		},
		canLoad: environment.GUARDS,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'unauthorized',
		data: { title: 'Acesso não autorizado', breadcrumb: 'Acesso não autorizado' },
		component: UnauthorizedComponent
	},
	{ path: 'autorizacao', component: AutorizacaoComponent },
	{ path: 'forbidden', data: { title: 'Acesso não autorizado', breadcrumb: 'Acesso não autorizado' }, component: ForbiddenComponent },
	{
		path: '**',
		data: { title: 'Página não encontrada', breadcrumb: 'Página não encontrada' },
		pathMatch: 'full',
		component: PagenotfoundComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false }),
		SigepeMainLayoutModule,
		SigepeSegurancaModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
