import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

import { Candidato } from '@app/core/models/candidato';
import { DetalheCandidato } from '../models/detalhe-candidato';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';
import { VisualizarEditalApiService } from '../services/visualizar-edital-api.service';

@Component({
	selector: 'sgp-detalhar-candidato',
	templateUrl: './detalhar-candidato.component.html',
	providers: [MessageService]
})
export class DetalharCandidatoComponent implements OnChanges, OnDestroy {
	@Input() idCandidato!: number;
	@Input() idOportunidade!: number;
	@Input() editalComSituacaoFinalistica!: boolean;
	@Input() nomeOportunidade!: string;
	@Input() numeroEdital?: string;
	@Input() nomeOrgao?: string;
	@Input() codigoOrgao!: string;
	@Output() voltar: EventEmitter<void> = new EventEmitter<void>();

	candidato?: DetalheCandidato;
	loading = false;
	mensagens: Message[] = [];

	exibirModalNotificacao = false;
	candidatoSelecionado: Candidato[] = [];

	subscriptions: Subscription[] = [];
	atualizarNotificacoes = false;

	constructor(private ui: UiService, private visualizarEditalApi: VisualizarEditalApiService) {}

	ngOnChanges(): void {
		this.obterDetalhesCandidato();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	voltarParaLista() {
		this.voltar.emit();
	}

	marcarDesmarcarFavorito(idCandidato: number) {
		if (!this.editalComSituacaoFinalistica) {
			this.subscriptions.push(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				this.visualizarEditalApi.marcarCandidatoFavorito(idCandidato).subscribe(res => {
					console.log('favorito', res);
					this.candidato!.favorito = !this.candidato!.favorito;
				})
			);
		}
	}

	marcarDesmarcarAnalisado(idCandidato: number) {
		if (!this.editalComSituacaoFinalistica) {
			this.subscriptions.push(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				this.visualizarEditalApi.marcarCandidatoAnalisado(idCandidato).subscribe(res => {
					console.log('marcado', res);
					this.candidato!.marcado = !this.candidato!.marcado;
				})
			);
		}
	}

	enviarNotificacao() {
		this.montarCandidatoSelecionado();
		this.exibirModalNotificacao = true;
	}

	fecharModalNotificacao(event: boolean) {
		this.exibirModalNotificacao = event;
	}

	obterMensagem(event: Message[]) {
		this.mensagens = event;
		//atualizar lista de mensagens
		if (this.mensagens.length > 0) {
			this.atualizarNotificacoes = true;
		}
	}

	baixarCurriculo() {
		this.subscriptions.push(
			this.visualizarEditalApi
				.visualizarCurriculo(this.idOportunidade, this.idCandidato)
				.subscribe(response => this.ui.downloadFile(response, 'application/pdf'))
		);
	}

	private obterDetalhesCandidato() {
		this.loading = true;
		this.subscriptions.push(
			this.visualizarEditalApi.getCandidato(this.idCandidato).subscribe(
				candidato => {
					console.log('candidato', JSON.stringify(candidato, undefined, 2));
					this.candidato = candidato;
					this.loading = false;
				},
				err => {
					console.error('erro ao buscar candidato', err);
					this.loading = false;
					this.mensagens = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private montarCandidatoSelecionado() {
		const candidatoSelecionado = new Candidato();
		candidatoSelecionado.id = this.candidato!.id;
		candidatoSelecionado.nomeCandidato = this.candidato!.nomeCandidato;

		this.candidatoSelecionado.push(candidatoSelecionado);
	}
}
