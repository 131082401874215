/**
 * Oportunidade competência.
 */
export class OportunidadeCompetencia {
	id?: number;
	idOportunidade?: number;
	idCompetencia?: number;
	nome?: string;
	obrigatorio?: boolean;

	constructor(id?: number, idCompetencia?: number, nome?: string, obrigatorio?: boolean) {
		this.id = id;
		this.idCompetencia = idCompetencia;
		this.nome = nome;
		this.obrigatorio = obrigatorio;
	}
}
