<h3 data-cy="titulo-pagina">Solicitar Base de Currículos</h3>

<p-messages [closable]="false"></p-messages>

<div class="br-message warning" role="alert" *ngIf="flagSituacaoSolicitada && !vemDoIncluir">
	<div class="icon"><i class="fas fa-exclamation-triangle fa-lg" aria-hidden="true"></i></div>
	<div class="content">
		<span class="message-title">Atenção.</span
		><span class="message-body"> Aguarde, o arquivo será disponibilizado em até 1 hora a partir da hora da solicitação.</span>
	</div>
	<div class="close">
		<button class="br-button circle small" type="button" aria-label="Fechar"><i class="fas fa-times" aria-hidden="true"></i></button>
	</div>
</div>

<div class="text-right">
	<button
		id="btnManual"
		data-cy="botao-manual"
		class="secondary mt-3 mt-sm-0 ml-sm-3"
		(click)="exibirModalManual = true"
		type="submit"
		pButton
		pTooltip="Exibe o manual contendo o Formato e Descrição dos campos do arquivo JSON"
	>
		Manual Arquivo
	</button>
	<button
		id="btnCadastrar"
		*ngIf="flagSituacaoDisponivelOuSolicitada"
		data-cy="botao-cadastrar"
		class="primary mt-3 mt-sm-0 ml-sm-3"
		(click)="incluir()"
		type="submit"
		pButton
		disabled="true"
		pTooltip="Opção somente será habilitada na ausência de arquivos vigentes disponíveis para download"
	>
		Solicitar
	</button>
	<button
		id="btnCadastrar"
		*ngIf="!flagSituacaoDisponivelOuSolicitada"
		data-cy="botao-cadastrar"
		class="primary mt-3 mt-sm-0 ml-sm-3"
		(click)="incluir()"
		type="submit"
		pButton
		pTooltip="Opção para gerar arquivo resultante de extração de dados de currículos de servidores em exercício no órgão"
	>
		Solicitar
	</button>
</div>

<div id="resultado" *ngIf="resultado && totalResultado! > 0">
	<div class="br-table small">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">
					<span *ngIf="totalResultado === 1">Foi encontrado 1 resultado</span>
					<span *ngIf="totalResultado! > 1">Foram encontrados {{ totalResultado }} resultados</span>
				</div>
			</div>
		</div>
		<p-table
			[value]="resultado"
			[lazy]="true"
			(onPage)="paginar($event)"
			(onLazyLoad)="getItensPorPagina($event)"
			[loading]="loadingResultado"
			[first]="primeiroItemResultado"
			[totalRecords]="totalResultado!"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			sortField="nome"
			data-cy="resultado-pesquisa"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-acoes" style="width: 30%">Data da Solicitação</th>
					<th id="cabecalho-acoes" style="width: 20%">CPF do Responsável</th>
					<th id="cabecalho-acoes" style="width: 20%">Data de Expiração</th>
					<th id="cabecalho-acoes" style="width: 20%">Situação</th>
					<th id="cabecalho-acoes" style="width: 10%">Operação</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-columns="resultado" let-rowData>
				<tr>
					<td>{{rowData.dataHoraSolicitacao}}</td>
					<td>{{rowData.cpfSolicitante | sgpMascaraCpf}}</td>
					<td>{{rowData.situacao === 'Disponível' ? rowData.dataExpiracao : ''}}</td>
					<td>{{rowData.situacao}}</td>
					<td headers="cabecalho-acoes">
						<button
							*ngIf="rowData.situacao === 'Expirada' || rowData.situacao === 'Solicitada'"
							pButton
							class="br-button circle"
							title="Download"
							(click)="download(rowData.id!)"
							data-cy="botao-download.id"
							icon="fas fa-file-download"
							disabled="true"
						></button>
						<button
							*ngIf="rowData.situacao !== 'Expirada' && rowData.situacao !== 'Solicitada'"
							pButton
							class="br-button circle"
							title="Download"
							(click)="download(rowData.id!)"
							data-cy="botao-download.id"
							icon="fas fa-file-download"
						></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<sgp-nenhum-resultado
	[exibir]="totalResultado === 0"
	mensagem="Não há arquivo disponível com a extração de dados de currículos de servidores em exercício no seu órgão. Clique em Solicitar."
></sgp-nenhum-resultado>

<p-dialog
	header="Manual Base de Currículos"
	[(visible)]="exibirModalManual"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	[baseZIndex]="10000"
	[resizable]="false"
	styleClass="modal-40vw"
	[contentStyle]="{overflow: 'scroll'}"
	(onHide)="exibirModalManual = false"
	data-cy="modal-manual"
>
	<p>
		A funcionalidade “Solicitar base de currículos” foi desenvolvida em atendimento ao § 2º do art. 9º da Instrução Normativa SGP/MGI nº
		31, de 9 de novembro de 2023.
	</p>
	<p>
		A base obtida deve ser tratada e analisada por uma pessoa servidora pública federal com conhecimentos em manipulação de arquivos em
		forma json.
	</p>
	<p>
		Conforme a Instrução Normativa SGP/MGI nº 31, de 9 de novembro de 2023, é vedada a coleta, a utilização, a divulgação ou a
		transferência indevida das informações acessadas por esta funcionalidade, sob pena de responsabilização administrativa, penal e
		civil, na forma da lei.
	</p>
	<p>
		Os campos do currículo estão detalhados no Portal do Servidor > Acesso à Informação > Perguntas Frequentes > SOUGOV.BR > Currículo e
		Oportunidades. Consulte a página em caso de dúvidas quanto as informações obtidas na base de dados.
	</p>
	<br />
	<div class="row">
		<div class="col-md-9 col-sm-12">
			<p>Estrutura dos dados da Base de Currículos</p>
		</div>
	</div>
	<br />
	<br />
	<div>
		<p><strong>areasConhecimento</strong></p>
		<ul>
			<li>id: numero</li>
			<li>nome: texto</li>
			<li>ferramenta: booleano</li>
			<li>incluido_usuario: booleano</li>
		</ul>
		<p><strong>ativo: booleano</strong></p>
		<p><strong>capacitacoes</strong></p>
		<ul>
			<li>anoFim: numero</li>
			<li>anoInicio: numero</li>
			<li>cargaHoraria: numero</li>
			<li>codigoInstituicao: numero</li>
			<li>credencial: numero</li>
			<li>descricao: texto</li>
			<li>id: numero</li>
			<li>
				instituicaoAcademica
				<ul>
					<li>ativo: booleano</li>
					<li>id: numero</li>
					<li>incluido_usuario: booleano</li>
					<li>nome: texto</li>
				</ul>
			</li>
			<li>mesFim: numero</li>
			<li>mesInicio: numero</li>
			<li>
				nivelCurso
				<ul>
					<li>ativo: booleano</li>
					<li>capacitacao: texto</li>
					<li>id: numero</li>
					<li>nome: texto</li>
					<li>obrigatorio: booleano</li>
				</ul>
			</li>
			<li>nome: texto</li>
			<li>nomeInstituicao: texto</li>
			<li>ocultaCapacitacao: booleano (true/false)</li>
			<li>situacao: texto</li>
		</ul>

		<p><strong>certificacoes</strong></p>

		<ul>
			<li>instituicao_academica: texto</li>
			<li>nome_certificacao: texto</li>
			<li>url_credencial: texto</li>
			<li>data_certificado: texto</li>
			<li>data_validade: texto</li>
		</ul>

		<p><strong>competencias</strong></p>
		<ul>
			<li>id: numero</li>
			<li>nome: texto</li>
			<li>ativo: booleano</li>
		</ul>

		<p><strong>dataAtualizacao: texto formato YYYY-MM-DDTHH:mm:ss</strong></p>

		<p><strong>dataCadastro: texto formato YYYY-MM-DDTHH:mm:ss</strong></p>

		<p><strong>experienciasProfissionais</strong></p>
		<ul>
			<li>id: numero</li>
			<li>
				vinculo
				<ul>
					<li>id: numero</li>
					<li>nome: texto</li>
				</ul>
			</li>
			<li>
				areas_atuacao
				<ul>
					<li>id: numero</li>
					<li>nome: texto</li>
				</ul>
			</li>
			<li>nome_instituicao: texto</li>
			<li>codigo_orgao: number</li>
			<li>nome_cargo: texto</li>
			<li>nome_funcao: texto</li>
			<li>mes_inicio: number</li>
			<li>mes_fim: number</li>
			<li>ano_fim: number</li>
			<li>descricao_atividade: texto</li>
			<li>servidor_publico: texto</li>
			<li>oculta_experiencia: booleano</li>
			<li>codigo_cargo: number</li>
			<li>codigo_atividade: number</li>
			<li>sigla_funcao: texto</li>
			<li>sigla_regime_juridico: texto</li>
			<li>nome_regime_juridico: texto</li>
			<li>codigo_atividade: number</li>
		</ul>

		<p><strong>formacoesAcademicas</strong></p>
		<ul>
			<li>id: numero</li>
			<li>
				nivel
				<ul>
					<li>id: numero</li>
					<li>nome: texto</li>
					<li>ativo: booleano</li>
				</ul>
			</li>
			<li>
				curso
				<ul>
					<li>ativo: booleano</li>
					<li>id: numero</li>
					<li>nome: texto</li>
				</ul>
			</li>
			<li>
				instituicao_academica
				<ul>
					<li>ativo: booleano</li>
					<li>id: numero</li>
					<li>incluido_usuario: booleano</li>
					<li>nome: texto</li>
				</ul>
			</li>
			<li>ano_inicio: numero</li>
			<li>ano_fim: numero</li>
			<li>situacao: texto</li>
			<li>oculta_formacao: booleano</li>
		</ul>

		<p><strong>idCurriculo: number</strong></p>

		<p><strong>idiomas</strong></p>
		<ul>
			<li>id: numero</li>
			<li>nome: texto</li>
			<li>nivel_leitura: numero</li>
			<li>nivel_fala: numero</li>
			<li>nivel_escrita: numero</li>
			<li>nivel_compreensao: numero</li>
		</ul>

		<p><strong>numeroCpf: numero</strong></p>

		<p><strong>resumoCurriculo</strong></p>

		<ul>
			<li>id: numero</li>
			<li>texto: texto</li>
		</ul>

		<p><strong>selos</strong></p>
		<ul>
			<li>id: numero</li>
			<li>nome_selo: texto</li>
			<li>nome_instituicao_emissora: texto</li>
			<li>url_selo: texto</li>
			<li>data_selo: texto</li>
			<li>localizacao_imagem_selo: texto</li>
			<li>data_validade: texto</li>
		</ul>

		<p><strong>vinculosSiapecad</strong></p>

		<ul>
			<li>id: numero</li>
			<li>matricula: texto</li>
			<li>programa_gestao: texto</li>
			<li>oculta_vinculo: booleano</li>
			<li>
				vinculo
				<ul>
					<li>id: numero</li>
					<li>nome: texto</li>
				</ul>
			</li>
		</ul>

		<p><strong>nome: texto</strong></p>

		<p><strong>lotacao: texto</strong></p>

		<p><strong>cargoPublico: texto</strong></p>

		<p><strong>orgaoExercicio: texto</strong></p>

		<p><strong>codOrgao: numero</strong></p>

		<p><strong>linkPublico:texto</strong></p>

		<p><strong>ufOrgao: texto</strong></p>

		<p><strong>codMunicipio: numero</strong></p>

		<p><strong>nomeMunicipio: texto</strong></p>

		<p><strong>vinculosUorg</strong></p>

		<ul>
			<li>ativo: booleano</li>
			<li>codigoUorg: texto</li>
			<li>nomeUorg: texto</li>
			<li>siglaUorg: texto</li>
		</ul>

		<p><strong>areasInteresse</strong></p>

		<ul>
			<li>id: numero</li>
			<li>nome: texto</li>
		</ul>

		<p><strong>nr_cpf: texto</strong></p>

		<p><strong>cargo_publico: texto</strong></p>

		<p><strong>orgao_exercicio: texto</strong></p>

		<p><strong>id_curriculo: numero</strong></p>
	</div>
	<div class="br-divider mt-5 mb-5"></div>
</p-dialog>
