import { AutorizacaoComponent } from './autorizacao/autorizacao.component';
import { CommonModule } from '@angular/common';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NgModule } from '@angular/core';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
	imports: [CommonModule],
	declarations: [UnauthorizedComponent, AutorizacaoComponent, ForbiddenComponent]
})
export class AuthModule {}
