import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { MensagemAlertaComponent } from './mensagem-alerta/mensagem-alerta.component';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';

@NgModule({
	declarations: [MensagemAlertaComponent],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		ConfirmDialogModule,
		CalendarModule,
		MessagesModule,
		DsGovDirectivesModule
	]
})
export class FerramentasModule {}
