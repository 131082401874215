import { Component } from '@angular/core';
import { DetalharLaudoService } from './../services/detalhar-laudo.service';
import { Router } from '@angular/router';

@Component({
	selector: 'sgp-detalhar-cargo',
	templateUrl: './detalhar-cargo.component.html'
})
export class DetalharCargoComponent {
	constructor(protected detalharLaudoService: DetalharLaudoService, private router: Router) {}

	existeDataLiberacao(dataLiberacao: string[]): boolean {
		return dataLiberacao.length > 0 && dataLiberacao[0] !== null;
	}

	detalharQuestionario(idCargo: number): void {
		this.router.navigate([`/perfilProfissiografico/questionario/${idCargo}`]);
	}
}
