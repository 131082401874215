export class TagCestaCurriculo {
	id?: number;
	idCestaCurriculo?: number;
	nomeTag?: string;
	constructor(id?: number, idCestaCurriculo?: number, nomeTag?: string) {
		this.id = id;
		this.idCestaCurriculo = idCestaCurriculo;
		this.nomeTag = nomeTag;
	}
}
