import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
	selector: 'sgp-card-bar-horizontal',
	templateUrl: './card-bar-horizontal.component.html',
	styleUrls: ['./card-bar-horizontal.component.scss']
})
export class CardBarHorizontalComponent implements AfterViewInit, OnChanges {
	ITENS_POR_PAGINA = 5;

	@Input() dados: any;
	@Input() titulo = '';
	@Input() pagina!: number;
	@Output() anterior: EventEmitter<number> = new EventEmitter<number>();
	@Output() proximo: EventEmitter<number> = new EventEmitter<number>();
	totalResultado = 0;
	stepSize = 1;

	options: any;
	data: any;

	ngAfterViewInit() {
		this.obterDados();
	}

	ngOnChanges() {
		this.obterDados();
	}

	obterDados() {
		this.data = undefined;
		const documentStyle = getComputedStyle(document.documentElement);
		const labels = new Array<any>();
		this.dados?.items?.legenda.forEach((label: string) => {
			label = label.toLowerCase();
			const palavras = label.split(' ');
			for (let i = 0; i < palavras.length; i++) {
				if (palavras[i].length > 3) {
					palavras[i] = palavras[i][0].toUpperCase() + palavras[i].substring(1);
				}
			}
			label = palavras.join(' ');
			const linha = [];
			if (label.length > 30) {
				const index = label.indexOf(' ', 30);
				if (index >= 30) {
					linha.push(label.substring(0, index));
				}
				linha.push(label.substring(index, label.length));
				labels.push(linha);
			} else {
				labels.push(label);
			}
		});
		const data1: any = [];
		const data2: any = [];

		for (let i = 0; i < this.dados?.items?.dados.length; i += 2) {
			data1.push(this.dados?.items?.dados[i]);
			data2.push(this.dados?.items?.dados[i + 1]);
		}

		this.totalResultado = this.dados?.metadata?.total;
		this.data = {
			labels: labels,
			datasets: [
				{
					label: this.dados?.items?.situacao[0],
					data: data1,
					backgroundColor: documentStyle.getPropertyValue('--blue-500'),
					borderColor: documentStyle.getPropertyValue('--blue-500')
				},
				{
					label: this.dados?.items?.situacao[1],
					data: data2,
					backgroundColor: documentStyle.getPropertyValue('--blue-500'),
					borderColor: documentStyle.getPropertyValue('--blue-500')
				}
			]
		};

		const maxValueData1 = Math.max.apply(null, data1);
		const maxValueData2 = Math.max.apply(null, data2);
		this.stepSize = (maxValueData1 > 9 || maxValueData2 > 9) ? 2 : 1;

		this.obterOpcoes();
	}

	obterOpcoes() {
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		this.options = {
			indexAxis: 'y',
			maintainAspectRatio: false,
			aspectRatio: 0.8,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: textColorSecondary,
						stepSize: this.stepSize
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				},
				y: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				}
			}
		};
	}

	voltar() {
		this.anterior.emit(this.pagina - 1);
	}

	avancar() {
		this.proximo.emit(this.pagina + 1);
	}
}
