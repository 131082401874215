<h4 data-cy="titulo-anexos">Anexos</h4>

<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>

<div id="listar-anexos" *ngIf="!loading">
	<div class="br-table">
		<p-table
			[value]="anexos"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			data-cy="tabela-anexos"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="nome-anexo" data-cy="descricao-anexos">Descrição</th>
					<th id="tipo-anexo" data-cy="tipo-anexos">Tipo de anexo</th>
					<th id="download-anexo" data-cy="download-anexos" style="width: 10%">Download</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item>
				<tr>
					<td headers="nome-anexo">{{item.descricaoAnexo}}</td>
					<td headers="tipo-anexo">{{item.tipoAnexo?.descricao}}</td>
					<td headers="download-anexo">
						<button
							pButton
							class="br-button circle"
							title="Download"
							(click)="download(item.idAnexo)"
							data-cy="botao-download-anexo"
							icon="fas fa-file-pdf"
							*ngIf="item.ativo===true"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Download"
							(click)="download(item.idAnexo)"
							data-cy="botao-download-anexo"
							icon="fas fa-file-pdf"
							*ngIf="item.ativo===false"
							[attr.disabled]="item.ativo"
						></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5">Nenhum arquivo anexado.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
