import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Edital } from '@core/models/edital';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { VisualizarEditalApiService } from '../services/visualizar-edital-api.service';

@Component({
	selector: 'sgp-visualizar-historico-edital',
	templateUrl: './visualizar-historico-edital.component.html'
})
export class VisualizarHistoricoEditalComponent implements OnDestroy {
	@Input() edital!: Edital;
	@Output() gerandoPDF: EventEmitter<boolean> = new EventEmitter<boolean>();
	textoModal = '';
	showModal = false;
	headerModal = '';
	subscriptions: Subscription[] = [];

	constructor(private ui: UiService, private visualizarEditalApi: VisualizarEditalApiService) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get situacoesEnum(): typeof SituacoesEnum {
		return SituacoesEnum;
	}

	exibirModal(texto: string, tipo: string) {
		this.textoModal = texto.replace(/^(javascript\\:)/, '');
		if (tipo === 'justificativa') {
			this.headerModal = 'Justificativa';
		} else {
			this.headerModal = 'Texto da retificação';
		}

		this.showModal = true;
	}

	async downloadEdital(idHistorico: number) {
		this.gerandoPDF.emit(true);
		this.subscriptions.push(
			this.visualizarEditalApi.downloadEditalPublicado(idHistorico).subscribe(response => {
				this.ui.downloadFile(response, 'application/pdf');
				this.gerandoPDF.emit(false);
			})
		);
	}
}
