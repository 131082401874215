import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { LoadingDialogService, UsuarioService } from '@sigepe/sigepe-template';
import { Message, MessageService } from 'primeng/api';

import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { Router } from '@angular/router';
import { UiService } from '@core/services/ui.service';

/**
 * Padrão de cores do dashboard:
 * azul escuro: 2C608A (--blue-60)
 * azul médio: 4F97D1 (--blue-40)
 * azul claro: AACDEC (--blue-20)
 * amarelo escuro: C2850C (--gold-vivid-40)
 * amarelo medio: FFBE2E (--gold-vivid-20)
 * amerelo claro: FFE396 (--gold-vivid-10)
 * verde escuro: 4C6424 (--green-60)
 * verde medio: 7D9B4E (--green-40)
 * verde claro: B8D293 (--green-20)
 */

@Component({
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [MessageService]
})
export class DashboardComponent implements AfterViewInit, OnInit, DoCheck {
	mensagem?: Message;
	nomeUsuario?: string;
	eAutorizador = false;
	dadosUsuario?: any;
	perfilAnterior: string[] = [];

	constructor(
		private cdRef: ChangeDetectorRef,
		private messageService: MessageService,
		private router: Router,
		private ui: UiService,
		private usuarioTemplate: UsuarioService,
		private loadingService: LoadingDialogService
	) {
		this.getMessages();
	}

	/** Para conseguir pegar a mudança de atribuição */
	ngDoCheck() {
		const novoPerfil = this.usuarioTemplate.getUsuario().perfis;
		if (this.perfilAnterior && novoPerfil) {
			this.eAutorizador =
				novoPerfil.includes(this.perfisEnum.AUTORIZADOR) || novoPerfil.includes(this.perfisEnum.AUTORIZADOR_REFORMA);
			if (this.perfilAnterior !== novoPerfil && this.usuarioTemplate.getUsuario().codigoUorgSiape != null) {
				this.messageService.clear();
			}
		} else {
			this.router.navigate(['/forbidden']);
		}
	}

	async ngOnInit() {
		this.loadingService.hideDialog();
		this.dadosUsuario = this.usuarioTemplate.getUsuario();
		this.perfilAnterior = this.dadosUsuario.perfis;
		this.nomeUsuario = this.usuarioTemplate.getToken().name.split(' ')[0];
		this.eAutorizador =
			this.dadosUsuario.perfis.includes(this.perfisEnum.AUTORIZADOR) ||
			this.dadosUsuario.perfis.includes(this.perfisEnum.AUTORIZADOR_REFORMA);
	}

	async ngAfterViewInit() {
		this.atualizarMensagens();
	}

	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}

	obterMensagem(event: string) {
		const mensagem = this.ui.criarMensagem('error', '', event);
		this.messageService.add(mensagem);
		this.cdRef.detectChanges();
	}

	private atualizarMensagens() {
		if (this.mensagem !== undefined) {
			this.messageService.add(this.mensagem);
		}
		this.cdRef.detectChanges();
	}

	/**
	 * Obtém a mensagem passada por parâmetro
	 */
	private getMessages() {
		const navigation = this.router.getCurrentNavigation();
		if (navigation!.extras.state !== undefined) {
			const state = navigation!.extras.state as { message: string };
			this.mensagem = this.ui.criarMensagem('success', '', state.message);
		}
	}
}
