<div #mensagemErro>
	<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>
</div>

<div *ngIf="etapa$">
	<div class="row mt-2">
		<div class="col-sm-3">
			<div class="br-input" [ngClass]="{'danger': erroTipoEtapa}">
				<label for="nome-etapa" #tipoEtapa>Tipo de etapa</label>
				<p-autoComplete
					id="tipo-etapa"
					[(ngModel)]="etapa$.tipoEtapaEdital"
					[showEmptyMessage]="true"
					[suggestions]="filteredTipoEtapas"
					(completeMethod)="filterTipoEtapa($event)"
					field="descricao"
					[minLength]="3"
					[size]="200"
					[dropdown]="true"
					[forceSelection]="true"
					emptyMessage="Nenhuma opção encontrada"
					data-cy="campo-tipo-etapa"
				></p-autoComplete>
				<sgp-message-feedback
					[show]="erroTipoEtapa"
					mensagem="Uma etapa precisa ter no mínimo um tipo definido"
					data-cy="erro-tipo-etapa"
				></sgp-message-feedback>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-6">
			<div class="br-input">
				<label for="descricao-etapa" #labelDescricaoEtapa>Descrição (Opcional)</label>
				<input
					pInputText
					type="text"
					id="descricao-etapa"
					[(ngModel)]="etapa$.descricaoEtapa"
					placeholder="Escreva a descrição da etapa"
					data-cy="campo-descricao-etapa"
					maxlength="300"
				/>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-7">
			<div class="br-input">
				<label for="dataInicial-etapa">Data inicial (Obrigatório para publicação do edital) </label>
				<div style="width: 40%">
					<p-calendar
						#dataInicialEtapa
						id="dataInicial-etapa"
						[(ngModel)]="etapa$.dataInicioEtapa"
						[showIcon]="true"
						inputId="icon"
						icon="fas fa-calendar-alt"
						[monthNavigator]="true"
						[yearNavigator]="true"
						yearRange="2020:2040"
						dateFormat="dd/mm/yy"
						data-cy="campo-data-inicial"
						[disabled]="inibeDataInicio"
					></p-calendar>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col-sm-7">
			<div class="br-input" [ngClass]="{'danger': erroDataFinal}">
				<label for="dataFinal-etapa">Data final (Obrigatório para publicação do edital) </label>
				<div style="width: 40%">
					<p-calendar
						#dataFinalEtapa
						id="dataFinal-etapa"
						[(ngModel)]="etapa$.dataFimEtapa"
						[showIcon]="true"
						inputId="icon"
						icon="fas fa-calendar-alt"
						[monthNavigator]="true"
						[yearNavigator]="true"
						yearRange="2020:2040"
						dateFormat="dd/mm/yy"
						styleClass="dataFinal-etapa-erro"
						data-cy="campo-data-final"
					></p-calendar>
				</div>
				<sgp-message-feedback
					[show]="erroDataFinal"
					mensagem="Data final menor que data inicial"
					data-cy="erro-data-final"
				></sgp-message-feedback>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-10">
			<!-- checkbox -->
			<div class="br-switch right">
				<input
					id="checkbox-permite-documento"
					type="checkbox"
					[(ngModel)]="etapa$.permiteEnvioDocumentos"
					data-cy="campo-permite-documento"
					(change)="limparTiposDocumento()"
				/>
				<label for="checkbox-permite-documento">Permitir que candidatos enviem documentos</label>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-6">
			<!-- combo -->
			<label for="campo-tipo-documento">Tipos de documentos permitidos</label>
			<p-multiSelect
				id="campo-tipo-documento"
				[options]="listaTiposDocumento"
				[(ngModel)]="etapa$.tiposDocumento"
				optionLabel="nome"
				[filter]="false"
				data-cy="campo-tipo-documento"
				[showToggleAll]="false"
				[showHeader]="false"
				[panelStyle]="{overflowY: 'hidden'}"
				[disabled]="!etapa$.permiteEnvioDocumentos"
			></p-multiSelect>
			<sgp-message-feedback
				[show]="erroTipoDocumento"
				mensagem="É necessário informar no mínimo um tipo de documento quando permite envio de documentos pelo candidato."
				data-cy="erro-tipo-documento"
			></sgp-message-feedback>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-10">
			<div class="br-textarea">
				<label for="orientacoes-etapa"
					>Orientações sobre a etapa (Opcional)
					<i
						class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
						title="Dicas de preenchimento"
						(click)="exibirDica = true"
					></i>
				</label>
				<p-editor
					id="orientacoes-etapa"
					[(ngModel)]="etapa$.orientacoesEtapa"
					[style]="{'height':'150px'}"
					data-cy="campo-orientacoes-etapa"
				>
					<ng-template pTemplate="header">
						<span class="ql-formats">
							<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
							<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
							<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
							<button
								type="button"
								class="ql-list"
								value="ordered"
								aria-label="Lista numerada"
								title="Lista numerada"
							></button>
							<button
								type="button"
								class="ql-list"
								value="bullet"
								aria-label="Lista com itens"
								title="Lista com itens"
							></button>
							<span class="ql-format-separator"></span>
							<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
						</span>
					</ng-template>
				</p-editor>
			</div>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col-sm-10">
			<div class="br-textarea">
				<label for="informacao-candidatura"
					>Informações sobre candidatura (Opcional)
					<i
						class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
						title="Dicas de preenchimento"
						(click)="exibirDica = true"
					></i>
				</label>
				<p-editor
					id="informacao-candidatura"
					[(ngModel)]="etapa$.informacaoCandidatura"
					[style]="{'height':'150px'}"
					data-cy="campo-informacao-candidatura"
				>
					<ng-template pTemplate="header">
						<span class="ql-formats">
							<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
							<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
							<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
							<button
								type="button"
								class="ql-list"
								value="ordered"
								aria-label="Lista numerada"
								title="Lista numerada"
							></button>
							<button
								type="button"
								class="ql-list"
								value="bullet"
								aria-label="Lista com itens"
								title="Lista com itens"
							></button>
							<span class="ql-format-separator"></span>
							<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
						</span>
					</ng-template>
				</p-editor>
			</div>
		</div>
	</div>

	<div class="row mt-4 mb-4">
		<div class="col-sm-12 text-right">
			<div class="br-input">
				<button class="secondary mt-3 mt-sm-0 ml-sm-3" (click)="cancelar()" type="button" pButton data-cy="botao-cancelar-etapa">
					Cancelar
				</button>
				<span *ngIf="idEtapa; else salvarNovo">
					<button
						class="primary mt-3 mt-sm-0 ml-sm-3"
						(click)="atualizarEtapa()"
						type="button"
						pButton
						data-cy="botao-atualizar-etapa"
					>
						Salvar
					</button>
				</span>
				<ng-template #salvarNovo>
					<button
						class="primary mt-3 mt-sm-0 ml-sm-3"
						(click)="incluirEtapa()"
						type="button"
						pButton
						data-cy="botao-incluir-etapa"
					>
						Salvar
					</button>
				</ng-template>
			</div>
		</div>
	</div>
</div>

<p-dialog
	header="Dicas de preenchimento"
	[(visible)]="exibirDica"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	[baseZIndex]="10000"
	[resizable]="false"
	styleClass="modal-40vw"
	[contentStyle]="{overflow: 'hidden'}"
	(onHide)="exibirDica = false"
	data-cy="modal-dica-pesquisa"
>
	<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>

	<ng-template pTemplate="footer">
		<p-button (click)="exibirDica = false" label="Fechar" styleClass="br-button secondary" data-cy="botao-fechar-dica"></p-button>
	</ng-template>
</p-dialog>
