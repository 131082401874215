<h4 data-cy="titulo-historico">Histórico do edital</h4>

<div id="listar-historico">
	<div class="br-table" *ngIf="edital.historico">
		<p-table
			[value]="edital.historico"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			data-cy="tabela-historico"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="data-historico" data-cy="data-historico">Data</th>
					<th id="usuario-historico" data-cy="usuario-historico">Usuário</th>
					<th id="situacao-historico" data-cy="situacao-historico">Situação</th>
					<th id="unidade-historico" data-cy="unidade-historico">Unidade autorizadora</th>
					<th id="informacoes-historico" data-cy="informacoes-historico">Informações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item>
				<tr>
					<td headers="data-historico">{{item.dataHoraHistorico}}</td>
					<td headers="usuario-historico">{{item.cpfOperador}}</td>
					<td headers="situacao-historico">{{item.situacao.descricao}}</td>
					<td headers="unidade-historico">{{item.nomeUnidadeAutorizadora}}</td>
					<td headers="informacoes-historico">
						<p-button
							styleClass="circle"
							icon="fas fa-comment"
							title="Justificativa de rejeição"
							id="{{'justificativa-' + item.id}}"
							*ngIf="item.justificativa !== null"
							(click)="exibirModal(item.justificativa, 'justificativa')"
						></p-button>
						<p-button
							styleClass="circle"
							icon="fas fa-file-alt"
							title="Texto da retificação"
							id="{{'retificacao-' + item.id}}"
							*ngIf="item.textoRetificacao !== null"
							(click)="exibirModal(item.textoRetificacao, 'retificacao')"
						></p-button>
						<p-button
							styleClass="circle"
							icon="fas fa-file-pdf"
							title="Edital publicado"
							*ngIf="item.situacao | podeSerSituacao: situacoesEnum.PUB:situacoesEnum.PUR:situacoesEnum.PRE "
							(click)="downloadEdital(item.id)"
						></p-button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5">Nenhuma informação encontrada.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-dialog
	[header]="headerModal"
	[(visible)]="showModal"
	[breakpoints]="{'960px': '75vw'}"
	styleClass="modal-50vw"
	[baseZIndex]="10000"
	[draggable]="false"
	[resizable]="false"
	[modal]="true"
	data-cy="modal"
>
	<div [innerHtml]="textoModal"></div>
	<ng-template pTemplate="footer">
		<p-button (click)="showModal=false" label="Fechar" styleClass="primary" data-cy="fechar-modal"></p-button>
	</ng-template>
</p-dialog>
