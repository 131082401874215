<p-messages [enableService]="true" [closable]="false"></p-messages>

<div class="pr-2 pl-2">
	<div class="row" *ngIf="item.id">
		<div class="col-sm-12"><label>id: </label> {{ item.id }}</div>
	</div>
	<div class="row mt-2">
		<div class="col-sm-12 br-input" [ngClass]="{'danger': erroNome}">
			<label for="nomeCampo">Nome:</label>
			<input
				#elNomeCampo
				id="nomeCampo"
				name="nomeCampo"
				pInputText
				type="text"
				data-cy="campo-texto"
				[(ngModel)]="item.nome"
				maxlength="100"
				minlength="3"
			/>
			<sgp-message-feedback [show]="erroNome" [mensagem]="mensagemErroNome" data-cy="erro-nome-campo"></sgp-message-feedback>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-sm-12 br-checkbox d-inline">
			<input id="manter-ativo" name="manter-ativo" [(ngModel)]="item.ativo" type="checkbox" aria-label="Está ativo" />
			<label for="manter-ativo">Está ativo</label>
		</div>
	</div>

	<div class="row mt-2" *ngIf="tabela === 'idioma'">
		<div class="col-sm-12 br-checkbox d-inline">
			<input id="manter-libras" name="manter-libras" [(ngModel)]="item.libras" type="checkbox" aria-label="É libra" />
			<label for="manter-libras">É libra</label>
		</div>
	</div>

	<div class="row mt-2" *ngIf="exibirFerramenta">
		<div class="col-sm-12 br-checkbox d-inline">
			<input
				id="manter-ferramenta"
				name="manter-ferramenta"
				[(ngModel)]="item.ferramenta"
				type="checkbox"
				aria-label="É ferramenta"
			/>
			<label for="manter-ferramenta">É ferramenta</label>
		</div>
	</div>

	<div class="row mt-2" *ngIf="item.id && exibirCadastradoPeloUsuario">
		<div class="col-sm-12 br-checkbox d-inline">
			<input
				id="manter-cadastradoUsuario"
				name="manter-cadastradoUsuario"
				[(ngModel)]="item.incluido_usuario"
				type="checkbox"
				aria-label="Está ativo"
				[disabled]="desabilitar"
			/>
			<label for="manter-cadastradoUsuario">Cadastrado pelo usuario</label>
		</div>
	</div>

	<div class="row mt-4 mb-4">
		<div class="col-sm-12 text-right">
			<button id="btnSalvar" data-cy="botao-salvar" class="primary" (click)="salvar()" type="submit" pButton>
				Salvar {{tabela}}
			</button>
		</div>
	</div>
</div>
