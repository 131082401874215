import { AcoesEnum } from '../enums/acoes.enum';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
/**
 * Service com métodos úteis de interface
 */
@Injectable({
	providedIn: 'root'
})
export class AcoesService {
	VISUALIZAR_EDITAL = 'visualizar_edital';
	PESQUISAR_EDITAL = 'pesquisar_edital';

	private situacaoEdital = new BehaviorSubject(undefined);
	sharedSituacaoEdital = this.situacaoEdital.asObservable();

	private historicoEdital = new BehaviorSubject(undefined);
	sharedHistoricoEdital = this.historicoEdital.asObservable();

	/**
	 * Salva o valor da situacao do edital para uso em outro componente
	 * @param situacao
	 */
	nextSituacaoEdital(situacao: any) {
		this.situacaoEdital.next(situacao);
	}

	/**
	 * Salva o valor do historico do edital para uso em outro componente
	 * @param historico
	 */
	nextHistoricoEdital(historico: any) {
		this.historicoEdital.next(historico);
	}

	/**
	 * Monta os parametros a serem passados para o serviço de ação de acordo com o tipo de ação solicitada.
	 * @param codigoAcao código da ação
	 * @returns parametros
	 */
	public montarParametrosAcao(
		tipoAcao: AcoesEnum,
		codigoUnidade?: string,
		textoRetificacao?: string,
		textoJustificativa?: string,
		textoResultado?: string
	): any {
		if (this.isAcaoSolicitacaoPublicacaoRetificacao(tipoAcao)) {
			return {
				codigoAcao: tipoAcao,
				codigoUnidadeAutorizadora: codigoUnidade,
				textoRetificacao: textoRetificacao
			};
		} else if (this.isAcaoRejeicao(tipoAcao) || this.isAcaoCancelarEdital(tipoAcao) || this.isAcaoSuspenderEdital(tipoAcao)) {
			return {
				codigoAcao: tipoAcao,
				codigoUnidadeAutorizadora: codigoUnidade,
				textoJustificativa: textoJustificativa
			};
		} else if (this.isAcaoSolicitacaoPublicacaoEdital(tipoAcao)) {
			return {
				codigoAcao: tipoAcao,
				codigoUnidadeAutorizadora: codigoUnidade
			};
		} else if (this.isAcaoPublicarResultado(tipoAcao)) {
			return {
				codigoAcao: tipoAcao,
				textoResultado: textoResultado
			};
		} else {
			return { codigoAcao: tipoAcao };
		}
	}

	mensagemSucessoAcao(tipoAcao: number): string {
		switch (tipoAcao) {
			case AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_EDITAL:
				return 'A publicação do edital foi autorizada.';
			case AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO:
				return 'A retificação do edital foi autorizada.';
			case AcoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL:
				return 'A publicação do edital foi rejeitada.';
			case AcoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO:
				return 'A retificação do edital foi rejeitada.';
			case AcoesEnum.OPO_SOLICITAR_PUBLICACAO_EDITAL:
				return 'A publicação do edital foi solicitada.';
			case AcoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO:
				return 'A retificação do edital foi solicitada.';
			case AcoesEnum.OPO_PUBLICAR_RESULTADO:
				return 'O resultado do edital foi publicado.';
			case AcoesEnum.OPO_CANCELAR_EDITAL:
				return 'O edital está cancelado.';
			case AcoesEnum.OPO_SUSPENDER_EDITAL:
				return 'O edital está suspenso.';
			case AcoesEnum.OPO_ENCERRAR_EDITAL:
				return 'O edital está encerrado.';
			default:
				return '';
		}
	}

	/**
	 * Caso a ação seja do tipo Rejeitar ou Autorizar, ela não será exibida na página de pesquisar edital, só no visualizar.
	 * @param tipoAcao código da ação
	 * @param pagina nome da página
	 * @returns true|false
	 */
	exibirAcao(tipoAcao: number, pagina: string): boolean {
		if (pagina === this.PESQUISAR_EDITAL) {
			if (this.isAcaoRejeicao(tipoAcao) || this.isAcaoAutorizacao(tipoAcao) || this.isAcaoDesfazerRetificacaoEdital(tipoAcao)) {
				return false;
			}
		}

		if (this.isAcaoSolicitacaoPublicacaoRetificacao(tipoAcao)) {
			return false;
		}
		return true;
	}

	isAcaoSolicitacaoPublicacaoRetificacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO;
	}

	isAcaoRejeicao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL || tipoAcao === AcoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO;
	}

	isAcaoAutorizacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_EDITAL || tipoAcao === AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO;
	}

	isAcaoAutorizarPublicacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_EDITAL;
	}

	isAcaoAutorizarRetificacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO;
	}

	isAcaoRejeitarPublicacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL;
	}

	isAcaoRejeitarRetificacao(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO;
	}

	isAcaoSolicitacaoPublicacaoEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_SOLICITAR_PUBLICACAO_EDITAL;
	}

	isAcaoAlterarEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_ALTERAR_EDITAL;
	}

	isAcaoExcluirEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_EXCLUIR_EDITAL;
	}

	isAcaoRetificarEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_RETIFICAR_EDITAL;
	}

	isAcaoDesfazerRetificacaoEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_DESFAZER_RETIFICACAO_EDITAL;
	}

	isAcaoCancelarEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_CANCELAR_EDITAL;
	}

	isAcaoEncerrarEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_ENCERRAR_EDITAL;
	}

	isAcaoSuspenderEdital(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_SUSPENDER_EDITAL;
	}

	isAcaoPublicarResultado(tipoAcao: AcoesEnum) {
		return tipoAcao === AcoesEnum.OPO_PUBLICAR_RESULTADO;
	}
}
