import { Pipe, PipeTransform } from '@angular/core';

/**
 * Imprime o número do edital no formato dddd/YYYY
 */
@Pipe({ name: 'numeroEdital' })
export class NumeroEditalPipe implements PipeTransform {
	transform(numero: string): string {
		if (numero) return 'nº ' + numero;
		else return 'sem número';
	}
}
