<div class="row p-2">
	<div class="col-10">
		<div class="py-2 text-weight-semi-bold">{{ nomeFiltro }}</div>
		<ng-content select="[filtros-incluidos]"></ng-content>
	</div>
	<div class="col-2 text-right">
		<p-button styleClass="circle" icon="fas fa-plus" (click)="abrirModal()"></p-button>
	</div>
</div>
<span class="br-divider"></span>

<sgp-modal-adicionar-filtro
	[exibirModal]="exibirModal"
	(exibirModalAtualizado)="atualizarExibirModal($event)"
	[nomeRequisito]="nomeFiltro"
	*ngIf="exibirModal"
>
	<div formulario>
		<container-element [ngSwitch]="requisito">
			<!-- quem_pode -->
			<div class="row" *ngSwitchCase="'vinculo'">
				<div class="col-12" [ngClass]="{'danger': erroVinculo}">
					<p-dropdown
						id="filtro-vinculo"
						[options]="listaTiposVinculos"
						[(ngModel)]="vinculoSelecionado"
						placeholder="Selecione um tipo de vínculo"
						optionLabel="nome"
						[showClear]="true"
						[panelStyle]="{height: '200px', overflowY: 'hidden',position:'fixed'}"
						[style]="{'z-index': '999999',position:'fixed'}"
						data-cy="campo-tipo-vinculo"
					></p-dropdown>
					<sgp-message-feedback
						[show]="erroVinculo"
						mensagem="Campo obrigatório"
						data-cy="erro-tipo-vinculo"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- softskill -->
			<div class="row" *ngSwitchCase="'softskill'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroSoftskills}">
					<p-dropdown
						id="filtro-softskill"
						[options]="listaSoftskills"
						[(ngModel)]="softskillSelecionado"
						placeholder="Selecione uma softskill"
						optionLabel="nome"
						[showClear]="true"
						[panelStyle]="{height: '200px', overflowY: 'hidden',position:'fixed'}"
						[style]="{'z-index': '999999',position:'fixed'}"
						data-cy="campo-softskill"
					></p-dropdown>
					<sgp-message-feedback
						[show]="erroSoftskills"
						mensagem="Campo obrigatório"
						data-cy="erro-softskill"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Idioma -->
			<div class="row" *ngSwitchCase="'idioma'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroIdioma}">
					<p-autoComplete
						id="idioma-requisito"
						[(ngModel)]="idiomaSelecionado"
						[suggestions]="filteredIdioma"
						(completeMethod)="filterIdioma($event)"
						field="nome"
						scrollHeight="150px"
						[size]="100"
						[dropdown]="true"
						[multiple]="false"
						placeholder="Comece a escrever o idioma para exibir as sugestões"
						[style]="{width: '90%', 'z-index': '999999',position:'fixed'}"
						[forceSelection]="true"
						[showEmptyMessage]="true"
						emptyMessage="Nenhuma opção encontrada"
						data-cy="campo-idioma"
					></p-autoComplete>
					<sgp-message-feedback [show]="erroIdioma" mensagem="Campo obrigatório" data-cy="erro-idioma"></sgp-message-feedback>
				</div>
			</div>

			<!-- Experiência -->
			<div class="row" *ngSwitchCase="'experiencia'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeExperiencia}">
					<input
						pInputText
						type="text"
						id="nome-experiencia-requisito"
						[(ngModel)]="nomeExperienciaSelecionado"
						data-cy="campo-nome-experiencia"
						placeholder="Escreva o nome da experiencia desejada"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeExperiencia"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-experiencia"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Capacitação-->
			<div class="row" *ngSwitchCase="'capacitacao'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeCapacitacao}">
					<input
						pInputText
						type="text"
						id="nome-capacitacao-requisito"
						[(ngModel)]="nomeCapacitacaoSelecionada"
						data-cy="campo-nome-capacitacao"
						placeholder="Escreva o nome da capacitação desejada"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeCapacitacao"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-capacitacao"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Cargo-->
			<div class="row" *ngSwitchCase="'cargo'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeCargo}">
					<input
						pInputText
						type="text"
						id="nome-cargo-requisito"
						[(ngModel)]="nomeCargoSelecionado"
						data-cy="campo-nome-cargo"
						placeholder="Escreva o nome do cargo desejado"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeCargo"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-cargo"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Cidade-->
			<div class="row" *ngSwitchCase="'cidade'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeCidade}">
					<input
						pInputText
						type="text"
						id="nome-cidade-requisito"
						[(ngModel)]="nomeCidadeSelecionada"
						data-cy="campo-nome-cidade"
						placeholder="Escreva o nome da cidade desejada"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeCidade"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-cidade"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Nome-->
			<div class="row" *ngSwitchCase="'nome'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNome}">
					<input
						pInputText
						type="text"
						id="nome-nome-requisito"
						[(ngModel)]="nomeSelecionado"
						data-cy="campo-nome-nome"
						placeholder="Escreva o nome desejado"
						maxlength="100"
					/>
					<sgp-message-feedback [show]="erroNome" mensagem="Campo obrigatório" data-cy="erro-nome"></sgp-message-feedback>
				</div>
			</div>

			<!-- Medalhas Digitais-->
			<div class="row" *ngSwitchCase="'selo'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeSelo}">
					<label for="nome-selo-requisito" class="d-block">Nome da Medalha Digital (Opcional)</label>
					<input
						pInputText
						type="text"
						id="nome-selo-requisito"
						[(ngModel)]="nomeSeloSelecionado"
						data-cy="nome-campo-selo"
						placeholder="Escreva o nome desejado"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeSelo"
						mensagem="Informe pelo menos um campo"
						data-cy="erro-nome-selo"
					></sgp-message-feedback>
				</div>
			</div>
			<div class="row" *ngSwitchCase="'selo'">
				<div class="col-12 mt-3">
					<div class="br-input">
						<label for="nome-formacao-requisito">Instituição da Medalha Digital (Opcional)</label>
						<input
							pInputText
							type="text"
							id="instituicao-selo-requisito"
							[(ngModel)]="nomeInstituicaoSeloSelecionado"
							maxlength="100"
							data-cy="campo-nome-instituicao-selo"
							placeholder="Escreva o nome desejado"
						/>
						<sgp-message-feedback
							[show]="erroNomeSelo"
							mensagem="Informe pelo menos um campo"
							data-cy="erro-nome-selo"
						></sgp-message-feedback>
					</div>
				</div>
			</div>
			<!-- UORG-->

			<div class="row" *ngSwitchCase="'uorg'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeUorg}">
					<input
						pInputText
						type="text"
						id="nome-uorg-requisito"
						[(ngModel)]="nomeUorgSelecionado"
						data-cy="campo-nome-uorg"
						placeholder="Escreva o nome da Uorg desejada"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeUorg"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-uorg"
					></sgp-message-feedback>
				</div>
			</div>
			<!-- UF-->

			<div class="row" *ngSwitchCase="'uf'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeUF}">
					<input
						pInputText
						type="text"
						id="nome-uf-requisito"
						[(ngModel)]="nomeUFSelecionada"
						data-cy="campo-nome-uf"
						placeholder="Escreva a sigla da UF desejada"
						minlength="2"
						maxlength="2"
					/>
					<sgp-message-feedback
						[show]="erroNomeUF"
						mensagem="Campo deve ter 2 dígitos"
						data-cy="erro-nome-uf"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Orgao -->
			<div class="row" *ngSwitchCase="'orgao'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroOrgao}">
					<p-autoComplete
						#autoCompleteObject
						[id]="orgaoSelecionado"
						[(ngModel)]="orgaoSelecionado"
						[suggestions]="filteredOrgaos"
						(completeMethod)="filtrarOrgaos($event)"
						field="name"
						dropdownAriaLabel="name"
						[minLength]="3"
						[dropdown]="true"
						[forceSelection]="true"
						placeholder="Comece a escrever o nome do órgão para exibir as sugestões"
						[showEmptyMessage]="true"
						emptyMessage="Nenhuma opção encontrada"
						(onSelect)="atualizarNgModel($event)"
						(onClear)="atualizarNgModel($event)"
					></p-autoComplete>
					<sgp-message-feedback
						[show]="erroOrgao"
						mensagem="O termo da busca deve possuir no mínimo 3 caracteres."
						data-cy="erro-orgao"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Área de interesse-->
			<div class="row" *ngSwitchCase="'areaInteresse'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroAreaInteresse}">
					<sgp-requisitos-area-interesse
						[exibir]="true"
						[erroMensagem]="erroAreaInteresse"
						[obrigatoriedade]="false"
						(obterArea)="updateAreaInteresse($event)"
						(obterErroCampoObrigatorio)="atualizarErroAreaObrigatoria($event)"
					></sgp-requisitos-area-interesse>
				</div>
			</div>

			<!-- Certificação -->
			<div class="row" *ngSwitchCase="'certificacao'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNomeCertificacao}">
					<input
						pInputText
						type="text"
						id="nome-certificacao-requisito"
						[(ngModel)]="nomeCertificacaoSelecionada"
						data-cy="campo-nome-certificacao"
						placeholder="Escreva o nome da certificação desejada"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroNomeCertificacao"
						mensagem="Campo obrigatório"
						data-cy="erro-nome-certificacao"
					></sgp-message-feedback>
				</div>
			</div>

			<!-- Área de atuação-->
			<div class="row" *ngSwitchCase="'areaAtuacao'">
				<div class="col-12 br-input">
					<sgp-requisitos-area-atuacao
						[exibir]="true"
						[erroMensagem]="erroAreaAtuacao"
						[obrigatoriedade]="false"
						(obterArea)="updateAreaAtuacao($event)"
						(obterErroCampoObrigatorio)="atualizarErroAreaObrigatoria($event)"
					></sgp-requisitos-area-atuacao>
				</div>
			</div>

			<!-- Formação -->
			<div class="row" *ngSwitchCase="'formacao'">
				<div class="col-12 br-input" [ngClass]="{'danger': erroNivelFormacao}">
					<label for="nivel-formacao-requisito" class="d-block">Nível da formação</label>
					<p-dropdown
						id="nivel-formacao-requisito"
						[options]="listaNiveisFormacao"
						[(ngModel)]="nivelFormacaoSelecionado"
						placeholder="Selecione um nível de formação"
						optionLabel="nome"
						[showClear]="true"
						[panelStyle]="{height: '150px', overflowY: 'hidden', position: 'fixed'}"
						[style]="{width: '100%', 'z-index': '999999', position:'fixed'}"
						(onChange)="erroNivelFormacao = false"
						data-cy="campo-nivel-formacao"
					></p-dropdown>
					<sgp-message-feedback
						[show]="erroNivelFormacao"
						mensagem="Campo obrigatório"
						[style]="{marginTop: '42px', position:'fixed'}"
						data-cy="erro-campo-nivel-formacao"
					></sgp-message-feedback>
				</div>
			</div>
			<div class="row" *ngSwitchCase="'formacao'">
				<div class="col-12 mt-3">
					<div class="br-input">
						<label for="nome-formacao-requisito">Nome da formação (Opcional)</label>
						<input
							pInputText
							type="text"
							id="nome-formacao-requisito"
							[(ngModel)]="nomeFormacaoSelecionada"
							maxlength="100"
							data-cy="campo-nome-formacao"
						/>
					</div>
				</div>
			</div>

			<div class="row" *ngSwitchCase="'conhecimentos'">
				<div class="col">
					<sgp-requisitos-area-conhecimento
						[exibir]="true"
						[erroMensagem]="erroConhecimento"
						[obrigatoriedade]="false"
						(obterArea)="updateAreaConhecimento($event)"
						(obterErroCampoObrigatorio)="atualizarErroAreaObrigatoria($event)"
					></sgp-requisitos-area-conhecimento>
				</div>
			</div>

			<div *ngSwitchDefault></div>
		</container-element>
	</div>

	<div botaoAdicionar>
		<p-button
			(click)="adicionarRequisito()"
			label="Adicionar requisito"
			styleClass="br-button primary"
			data-cy="botao-salvar-requisito"
		></p-button>
	</div>
</sgp-modal-adicionar-filtro>
