import { Component, OnDestroy } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { FiltroTabelasAdministrativas } from '@tabelasAdministrativas/models/filtro';
import { Idioma } from '@core/models/idioma';
import { IdiomaApiService } from '../services/idioma-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	templateUrl: './tabela-idioma.component.html',
	providers: [MessageService, ConfirmationService]
})
export class TabelaIdiomaComponent implements OnDestroy {
	pesquisarAoCarregar = true;
	habilitarFiltroCadastradoUsuario = false;
	filtros?: FiltroTabelasAdministrativas;
	limparFormularioPesquisa = false;
	resultado: any[] = [];
	loadingResultado = false;
	totalResultado?: number;
	pagina = 1;
	itensPorPagina = 10;
	primeiroItemResultado = 0;
	colunas: any;
	itemTabela = new Idioma();
	operacao?: string;
	exibirModalManterTabela = false;
	tituloModalManterTabela = '';
	subscriptions: Subscription[] = [];

	constructor(
		private confirmationService: ConfirmationService,
		private tabelaService: IdiomaApiService,
		private messageService: MessageService,
		private ui: UiService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	prepararCadastrar() {
		this.tituloModalManterTabela = 'Cadastrar idioma';
		this.itemTabela = new Idioma();
		this.operacao = 'cadastrar';
		this.exibirModalManterTabela = true;
	}

	prepararAlterar(event: any) {
		this.subscriptions.push(
			this.tabelaService.obterIdioma(event.id).subscribe(res => {
				this.itemTabela = new Idioma(res.id, res.nome, res.ativo, res.libras);
				this.tituloModalManterTabela = 'Alterar idioma';
				this.operacao = 'alterar';
				this.exibirModalManterTabela = true;
			})
		);
	}

	salvarItem(event: Idioma) {
		const idioma = new Idioma();
		idioma.id = event.id;
		idioma.nome = event.nome;
		idioma.ativo = event.ativo ? true : false;
		idioma.libras = event.libras ? true : false;
		if (this.operacao === 'cadastrar') {
			this.salvarNovo(idioma);
		} else if (idioma.id) {
			this.alterar(idioma.id, idioma);
		}
		this.operacao = '';
		this.exibirModalManterTabela = false;
	}

	executarPesquisar(event: any) {
		this.filtros = new FiltroTabelasAdministrativas(undefined, event.nome, event.ativo, event.libras);
		this.primeiroItemResultado = 0;
		this.pagina = 1;
		this.messageService.clear();
		this.pesquisar();
		this.criarColunas();
	}

	limparFormulario() {
		if (this.pesquisarAoCarregar) {
			this.executarPesquisar(new FiltroTabelasAdministrativas());
			this.limparFormularioPesquisa = true;
		} else {
			this.resultado = [];
		}
	}

	excluirItem(event: any) {
		const idioma = event;
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir o idioma ' + idioma.nome + '?',
			accept: async () => {
				this.tabelaService.excluirIdioma(event.id).subscribe(
					async (res: any) => {
						this.exibirMensagemSucesso(res);
					},
					err => {
						this.criarMensagem('error', err.error.message);
					}
				);
			}
		});
	}

	fecharModal() {
		this.exibirModalManterTabela = false;
		this.itemTabela = new Idioma();
		this.operacao = 'cadastrar';
	}

	paginar(event: any) {
		this.pagina = event.first / event.rows + 1;
		this.primeiroItemResultado = event.first;
		this.pesquisar();
	}

	getItensPorPagina(event: any) {
		this.itensPorPagina = event.rows;
	}

	private pesquisar() {
		this.resultado = [];
		this.loadingResultado = true;
		console.log('filtro', this.filtros);
		if (this.filtros) {
			this.subscriptions.push(
				this.tabelaService.obterIdiomas(this.filtros, this.pagina, this.itensPorPagina).subscribe(
					(resultado: any) => {
						this.loadingResultado = false;
						this.totalResultado = resultado.metadata.total;
						this.pagina = resultado.metadata.page;
						this.tratarDados(resultado.items);
					},
					err => {
						this.totalResultado = 0;
						this.resultado = [];
						this.criarMensagem('error', err.error.message);
					}
				)
			);
		}
	}

	private criarColunas() {
		this.colunas = [
			{ field: 'id', header: 'id' },
			{ field: 'nome', header: 'Idioma' },
			{ field: 'ativo', header: 'Ativo' }
		];
	}

	private tratarDados(itens: any[]) {
		for (const item of itens) {
			const resultadoTratado = {
				id: item.id,
				nome: item.nome,
				ativo: item.ativo ? 'Sim' : 'Não'
			};
			this.resultado.push(resultadoTratado);
		}
	}

	private salvarNovo(idioma: Idioma) {
		this.subscriptions.push(
			this.tabelaService.incluirIdioma(idioma).subscribe(
				(res: any) => {
					this.exibirMensagemSucesso(res);
				},
				err => {
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}

	private alterar(id: number, idioma: Idioma) {
		this.subscriptions.push(
			this.tabelaService.alterarIdioma(id, idioma).subscribe(
				(res: any) => {
					this.exibirMensagemSucesso(res);
				},
				err => {
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}

	private criarMensagem(criticidade: string, mensagem: string) {
		this.messageService.clear();
		this.messageService.add(this.ui.criarMensagem(criticidade, '', mensagem));
	}

	private exibirMensagemSucesso(res: any) {
		if (res) {
			this.criarMensagem('success', res.message);
			this.limparPesquisa();
		}
	}

	private limparPesquisa() {
		this.limparFormularioPesquisa = true;
		this.primeiroItemResultado = 0;
		this.pagina = 1;
		this.pesquisar();
	}
}
