import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CardEditaisComponent } from '@dashboard/card-editais/card-editais.component';
import { CardGraficoBarHorizontalComponent } from '@app/shared/card-grafico-bar-horizontal/card-grafico-bar-horizontal.component';
import { CardGraficoBarVerticalComponent } from '@app/shared/card-grafico-bar-vertical/card-grafico-bar-vertical.component';
import { CardGraficoDoughnutComponent } from '@app/shared/card-grafico-doughnut/card-grafico-doughnut.component';
import { CardGraficoLineComponent } from '@app/shared/card-grafico-line/card-grafico-line.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { DashboardComponent } from '@dashboard/dashboard/dashboard.component';
import { DashboardGestorGeralComponent } from '@dashboard/dashboard-gestor-geral/dashboard-gestor-geral.component';
import { DashboardGestorOportunidadesAutorizadorComponent } from '@dashboard/dashboard-gestor-oportunidades-autorizador/dashboard-gestor-oportunidades-autorizador.component';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { FormsModule } from '@angular/forms';
import { MensagemAlertaComponent } from './exibir-mensagem-alerta/exibir-mensagem-alerta.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MiniCardComponent } from '@app/shared/mini-card/mini-card.component';
import { SharedModule } from '@app/shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { environment } from '@environments/environment';

@NgModule({
	declarations: [
		DashboardComponent,
		DashboardGestorGeralComponent,
		DashboardGestorOportunidadesAutorizadorComponent,
		CardEditaisComponent,
		MensagemAlertaComponent
	],
	imports: [
		BrowserAnimationsModule,
		CoreModule,
		CommonModule,
		FormsModule,
		SharedModule,
		TagModule,
		AppRoutingModule,
		ButtonModule,
		MessagesModule,
		MessageModule,
		SkeletonModule,
		TableModule,
		TabViewModule,
		CardGraficoBarHorizontalComponent,
		CardGraficoDoughnutComponent,
		CardGraficoLineComponent,
		MiniCardComponent,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG),
		DsGovDirectivesModule
	],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule {}
