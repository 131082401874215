import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';

import { ConverterInterfaceEditalHelper } from '@app/core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-listar-oportunidades',
	templateUrl: './listar-oportunidades.component.html',
	providers: [MessageService, ConfirmationService]
})
export class ListarOportunidadesComponent implements OnDestroy, OnInit {
	@Input() edital!: Edital;
	salvando: Subject<boolean> = new Subject();
	oportunidade!: Oportunidade;
	oportunidades: Oportunidade[] = [];
	operacao = 'listar';
	subscriptions: Subscription[] = [];
	loading = false;

	constructor(
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private ui: UiService,
		private oportunidadesService: OportunidadesApiService,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper
	) {}

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	ngOnInit() {
		this.carregarOportunidades();
	}

	carregarOportunidades() {
		this.loading = true;
		this.subscriptions.push(
			this.oportunidadesService.consultarOportunidadesEdital(this.edital.id).subscribe(
				res => {
					if (res) {
						this.oportunidades = res;
						const temp = { oportunidades: res };
						this.edital.oportunidades = this.converterInterfaceEdital.converterRetornoParaOportunidades(temp);
						this.loading = false;
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	salvaComSucesso() {
		this.carregarOportunidades();
		this.operacao = 'listar';
		this.oportunidade = {};
		this.messageService.add(this.ui.criarMensagem('success', '', 'Oportunidade salva com sucesso'));
	}

	excluidaComSucesso() {
		this.carregarOportunidades();
		this.operacao = 'listar';
		this.oportunidade = {};
		this.messageService.add(this.ui.criarMensagem('success', '', 'Oportunidade excluída com sucesso'));
	}

	/**
	 * Cadastrar nova oportunidade
	 */
	novaOportunidade(): void {
		this.operacao = 'cadastrar';
		this.messageService.clear();
		this.oportunidade = {};
	}

	/**
	 * Retorna o código do órgão no formato string
	 */
	codigoOrgaoString(): string {
		if (this.edital.codigoOrgao) {
			return this.edital.codigoOrgao.toString();
		}
		return '';
	}

	/**
	 * Alterar oportunidade
	 * @param oportunidade oportunidade alterada
	 */
	alterarOportunidade(oportunidade: Oportunidade): void {
		this.operacao = 'alterar';
		this.messageService.clear();
		this.oportunidade = oportunidade;
	}

	/**
	 * Atualiza valor da oportunidade (automaticamente ao clicar em salvar na tela de cadastrar oportunidade)
	 * @param event oportunidade retornada da tela de cadastrar oportunidade
	 */
	async updateOportunidade(event: Oportunidade) {
		if (this.edital.oportunidades !== undefined) {
			if (this.operacao === 'cadastrar') {
				this.oportunidade = event;
				this.edital.oportunidades.push(this.oportunidade);
			} else if (this.operacao === 'alterar') {
				this.oportunidade = event;
				const index = this.edital.oportunidades.findIndex(item => item.id == this.oportunidade.id)!;
				this.edital.oportunidades[index] = this.oportunidade;
			}
			await this.atualizarEdital();
		}
	}

	/**
	 * Cancela o cadastramento/alteração de oportunidade, voltando a exibir a lista de oportunidades.
	 * @param event operacao='listar'
	 */
	cancelar(event: string): void {
		this.operacao = event;
		this.oportunidade = {};
	}

	/**
	 * Excluir oportunidade
	 * @param oportunidade Oportunidade a ser excluída
	 */
	excluirOportunidade(oportunidade: Oportunidade) {
		this.messageService.clear();
		const idOportunidade = oportunidade.id;
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir esta oportunidade?',
			accept: () => {
				if (this.edital.oportunidades !== undefined) {
					this.subscriptions.push(
						this.oportunidadesService.excluirOportunidades(idOportunidade!).subscribe(
							res => {
								if (res) {
									this.excluidaComSucesso();
								}
							},
							err => {
								this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
							}
						)
					);
				}
			}
		});
	}

	/**
	 * Testa se a operação é de listar oportunidades
	 * @returns true | false
	 */
	isListar(): boolean {
		return this.operacao === 'listar';
	}

	/**
	 * Testa se a operação é de cadastrar ou alterar
	 * @returns true | false
	 */
	isManter(): boolean {
		return this.operacao === 'cadastrar' || this.operacao === 'alterar';
	}

	private async atualizarEdital() {
		this.operacao = 'listar';
		this.salvando.next(false);
	}
}
