<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>

<div class="row mt-2">
	<div class="col-sm-4">
		<div class="br-input">
			<label for="tipo-anexo" #tipoEtapa>Tipo de anexo</label>
			<p-dropdown
				id="tipo-anexo"
				[options]="tipoAnexos"
				[(ngModel)]="anexo.tipoAnexo"
				placeholder="Selecione um tipo de anexo"
				optionLabel="descricao"
				[showClear]="true"
				[panelStyle]="{height: '150px'}"
				data-cy="campo-tipo-anexo"
			></p-dropdown>
			<sgp-message-feedback [show]="erroTipoAnexo" mensagem="Campo obrigatório" data-cy="erro-tipo-anexo"></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-6">
		<div class="br-input">
			<label for="descricao-anexo">Descrição</label>
			<input
				pInputText
				type="text"
				id="descricao-anexo"
				[(ngModel)]="anexo.descricaoAnexo"
				placeholder="Escreva um nome para o anexo"
				maxlength="100"
				data-cy="campo-descricao-anexo"
			/>
			<sgp-message-feedback [show]="erroDescricao" mensagem="Campo obrigatório" data-cy="erro-descricao-anexo"></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-6">
		<label class="upload-label" for="upload"><span>Envio de arquivo (máximo 5Mb)</span></label>
		<p-fileUpload
			mode="basic"
			name="demo[]"
			[accept]="tiposArquivos"
			chooseIcon="fas fa-upload"
			chooseLabel="Selecione o arquivo"
			(onSelect)="onFileSelected($event)"
			[customUpload]="true"
			[maxFileSize]="tamanhoMaximoArquivo"
			invalidFileTypeMessageSummary="Os arquivos devem ser no formato PDF e ter tamanho máximo de 5MB."
			invalidFileTypeMessageDetail=""
			invalidFileSizeMessageSummary="Os arquivos devem ser no formato PDF e ter tamanho máximo de 5MB."
			invalidFileSizeMessageDetail=""
			data-cy="campo-arquivo"
		></p-fileUpload>
		<sgp-message-feedback [show]="erroArquivo" mensagem="Campo obrigatório" data-cy="erro-arquivo-anexo"></sgp-message-feedback>
	</div>
</div>

<div class="row mt-4 mb-4">
	<div class="col-sm-12 text-right">
		<div class="br-input">
			<button class="secondary mt-3 mt-sm-0 ml-sm-3" (click)="cancelar()" type="button" pButton data-cy="botao-cancelar-anexo">
				Cancelar
			</button>
			<button class="primary mt-3 mt-sm-0 ml-sm-3" (click)="salvarAnexo()" type="button" pButton data-cy="botao-salvar-anexo">
				{{labelBotaoSalvar}}
			</button>
		</div>
	</div>
</div>
