<div class="row mb-3">
	<div class="col-sm-12 col-md-7 pt-1">
		<div data-cy="titulo-pagina" class="text-up-04 text-capitalize">Olá, {{ nomeUsuario?.toLowerCase() }}</div>
	</div>
	<div class="col-sm-12 col-md-5 text-right">
		<sgp-busca-orgao
			id="idOrgaos"
			inputId="idOrgaos"
			label="Órgão"
			[exibirLabel]="false"
			name="selectOrgao"
			[ngModel]="orgaoSelecionado"
			ngDefaultControl
			(ngModelUpdated)="atualizarOrgao($event)"
			data-cy="campo-orgao"
		></sgp-busca-orgao>
	</div>
</div>

<div id="mini-cards" class="row">
	<div class="col-sm-12 col-md-6">
		<div class="row">
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_escuro"
					icone="fa-file"
					[quantidade]="quantitativos.quantidadeEditaisPublicados || '0'"
					descricao="Editais vigentes"
					[ajuda]="true"
					textoAjuda="Editais vigentes são todos aqueles que já foram publicados e não foram cancelados, encerrados ou suspensos."
				></sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6" *ngIf="!codigoOrgaoSelecionado">
				<sgp-mini-card
					cor="azul_claro"
					icone="fa-archive"
					[quantidade]="quantitativos.quantidadeOrgaos || '0'"
					descricao="Órgãos com editais publicados"
					[ajuda]="true"
					textoAjuda="Total de órgãos que já publicaram edital."
				>
				</sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6" *ngIf="codigoOrgaoSelecionado">
				<sgp-mini-card
					cor="azul_claro"
					icone="fa-archive"
					[quantidade]="quantitativos.quantidadeUorgs || '0'"
					descricao="Uorgs com editais publicados"
					[ajuda]="true"
					textoAjuda="Total de unidades do órgão/entidade que já publicaram edital."
				>
				</sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_escuro"
					icone="fa-briefcase"
					[quantidade]="quantitativos.quantidadeOportunidadesPublicadas || '0'"
					descricao="Oportunidades publicadas"
					[ajuda]="true"
					textoAjuda="Todas oportunidades dos editais vigentes."
				>
				</sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_claro"
					icone="fa-user"
					[quantidade]="quantitativos.quantidadeCandidatosInscritos || '0'"
					descricao="Candidatos inscritos"
					[ajuda]="true"
					textoAjuda="Candidatos inscritos em todos os editais publicados"
				>
				</sgp-mini-card>
			</div>
		</div>
	</div>
	<div id="grafico_editais_por_situacao" class="col-sm-12 col-md-6" *ngIf="editaisPorSituacao">
		<sgp-card-grafico-doughnut
			titulo="Editais por situação"
			tipoGrafico="doughnut"
			[dados]="editaisPorSituacao"
			[height]="268"
		></sgp-card-grafico-doughnut>
	</div>
</div>

<div id="cards" class="row">
	<div id="grafico_evolucao_mensal_editais" class="col-sm-12 col-md-6">
		<sgp-card-grafico-line
			titulo="Editais publicados nos últimos 12 meses"
			[dados]="evolucaoMensalEditaisPublicados"
			[exibirLegenda]="false"
			[height]="268"
			*ngIf="evolucaoMensalEditaisPublicados"
		>
		</sgp-card-grafico-line>
	</div>

	<div id="grafico_evolucao_mensal_candidatos" class="col-sm-12 col-md-6">
		<sgp-card-grafico-line
			titulo="Candidatos inscritos nos últimos 12 meses"
			[dados]="evolucaoMensalCandidatosInscritos"
			[exibirLegenda]="false"
			[height]="268"
			*ngIf="evolucaoMensalCandidatosInscritos"
		>
		</sgp-card-grafico-line>
	</div>

	<div id="grafico_oportunidades_por_movimentacao" class="col-sm-12 col-md-6" *ngIf="oportunidadesPorMovimentacao">
		<sgp-card-grafico-doughnut
			titulo="Oportunidades por tipo de movimentação"
			tipoGrafico="doughnut"
			[dados]="oportunidadesPorMovimentacao"
			[height]="268"
		></sgp-card-grafico-doughnut>
	</div>

	<div id="grafico_orgaos_mais_editais" class="col-sm-12 col-md-6" *ngIf="codigoOrgaoSelecionado === undefined">
		<sgp-card-grafico-bar-horizontal
			titulo="Editais publicados por Órgão"
			[dados]="orgaosComMaisEditaisPublicados"
			[pagina]="paginaOrgaosComMaisEditaisPublicados"
			(anterior)="navegarOrgaosComMaisEditaisPublicados($event)"
			(proximo)="navegarOrgaosComMaisEditaisPublicados($event)"
			*ngIf="orgaosComMaisEditaisPublicados"
		>
		</sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_orgao_mais_oportunidades" class="col-sm-12 col-md-6" *ngIf="orgaosComMaisOportunidadesPublicadas">
		<sgp-card-grafico-bar-horizontal
			titulo="Oportunidades publicadas por Órgão"
			[dados]="orgaosComMaisOportunidadesPublicadas"
			[pagina]="paginaOrgaosComMaisOportunidadesPublicadas"
			(anterior)="navegarOrgaosComMaisOportunidadesPublicadas($event)"
			(proximo)="navegarOrgaosComMaisOportunidadesPublicadas($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_orgaos_mais_candidatos" class="col-sm-12 col-md-6" *ngIf="codigoOrgaoSelecionado === undefined">
		<sgp-card-grafico-bar-horizontal
			titulo="Candidados inscritos por Órgão"
			[dados]="orgaosComMaisCandidatosInscritos"
			[pagina]="paginaOrgaosComMaisCandidatosInscritos"
			(anterior)="navegarOrgaosComMaisCandidatosInscritos($event)"
			(proximo)="navegarOrgaosComMaisCandidatosInscritos($event)"
			*ngIf="orgaosComMaisCandidatosInscritos"
		>
		</sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_uorgs_mais_editais" class="col-sm-12 col-md-6" *ngIf="codigoOrgaoSelecionado">
		<sgp-card-grafico-bar-horizontal
			titulo="Editais publicados por UORG"
			[dados]="uorgsComMaisEditaisPublicados"
			[pagina]="paginaUorgsComMaisEditaisPublicados"
			(anterior)="navegarUorgsComMaisEditaisPublicados($event)"
			(proximo)="navegarUorgsComMaisEditaisPublicados($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_uorgs_mais_candidatos" class="col-sm-12 col-md-6" *ngIf="codigoOrgaoSelecionado">
		<sgp-card-grafico-bar-horizontal
			titulo="Candidados inscritos por UORG"
			[dados]="uorgsComMaisCandidatosInscritos"
			[pagina]="paginaUorgsComMaisCandidatosInscritos"
			(anterior)="navegarUorgsComMaisCandidatosInscritos($event)"
			(proximo)="navegarUorgsComMaisCandidatosInscritos($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>
</div>
