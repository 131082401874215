import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { DetalharLaudoService } from '../services/detalhar-laudo.service';
import { LoadingDialogService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-detalhar-laudo',
	templateUrl: './detalhar-laudo.component.html'
})
export class DetalharLaudoComponent implements OnInit {
	idCandidato?: number;
	idCargo?: number;

	constructor(
		private router: Router,
		private loadingDialogService: LoadingDialogService,
		private route: ActivatedRoute,
		protected detalharLaudoService: DetalharLaudoService
	) {}

	ngOnInit(): void {
		this.loadingDialogService.hideDialog();
		this.obterParametros();
		if (this.idCandidato && this.idCargo) {
			this.detalharLaudoService.detalharLaudo(this.idCandidato);
			this.detalharLaudoService.detalharQuestionariocandidato(this.idCandidato);
			this.detalharLaudoService.detalharCargo(this.idCargo);
			this.detalharLaudoService.downloadGraficoRadar(this.idCandidato);
		}
	}

	voltar(): void {
		this.router.navigate(['perfilProfissiografico/pesquisarLaudo'], { queryParams: { preservarParametros: true } });
	}

	private obterParametros() {
		this.idCandidato = Number(this.route.snapshot.paramMap.get('id'));
		this.idCargo = Number(this.route.snapshot.paramMap.get('idCargo'));
	}
}
