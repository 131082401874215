import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { ChartModule } from 'primeng/chart';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
	standalone: true,
	selector: 'sgp-card-grafico-doughnut',
	templateUrl: './card-grafico-doughnut.component.html',
	imports: [CommonModule, ChartModule, SkeletonModule]
})
export class CardGraficoDoughnutComponent implements AfterViewInit, OnChanges {
	@Input() titulo!: string;
	@Input() dados?: any[];
	@Input() height?: number;
	colors = ['#2C608A', '#C2850C', '#4C6424', '#4F97D1', '#FFBE2E', '#7D9B4E', '#AACDEC', '#FFE396', '#B8D293'];
	data: any;
	options: any;
	legendas?: string[];
	valores?: number[];
	total?: number;

	constructor() {
		this.data = undefined;
		this.dados = [];
		this.options = undefined;
	}
	ngOnChanges(): void {
		this.obterDados();
	}

	ngAfterViewInit() {
		this.obterDados();
	}

	private obterDados() {
		if (this.dados) {
			for (const dado of this.dados) {
				this.legendas = dado.legenda;
				this.valores = dado.dados;
				this.total = dado.total;
			}
		}

		this.data = {
			labels: this.legendas,
			datasets: [
				{
					data: this.valores,
					backgroundColor: this.colors,
					hoverBackgroundColor: this.colors
				}
			]
		};
		this.obterOpcoes(this.total!);
	}

	private obterOpcoes(total: number) {
		this.options = {
			plugins: {
				legend: {
					display: true,
					position: 'right'
				},
				tooltip: {
					callbacks: {
						label: function (tooltipItem: any) {
							const percentual = (tooltipItem.raw / total) * 100;
							return tooltipItem.label + ': ' + tooltipItem.raw + ' (' + percentual.toFixed(1) + '%)';
						}
					}
				}
			}
		};
	}
}
