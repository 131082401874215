<div class="row mt-4" *ngIf="exibeAlerta">
	<div class="col">
		<div class="br-message info" role="alert">
			<div class="content">
				<strong>{{ alerta.titulo }}</strong>
				<p>{{ alerta.mensagem }}</p>
				<p-scrollPanel [style]="{width: '100%', height: '200px', background: '#FFF'}" *ngIf="alerta.texto">
					<div class="p-1" [innerHtml]="alerta.texto"></div>
				</p-scrollPanel>

				<div *ngIf="alerta.autorizacao" class="text-right mt-2">
					<button (click)="alerta.rejeitar()" class="br-button secondary mr-2" *possuiPerfil="alerta.perfis">Rejeitar</button>
					<button (click)="alerta.autorizar()" class="br-button primary mr-2" *possuiPerfil="alerta.perfis">
						{{labelBotaoAutorizar}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<sgp-solicitar-acao
	id="RejeitarPublicacao"
	titulo="Rejeitar publicação de edital"
	[tipoAcao]="acoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL"
	[edital]="edital"
	labelBotaoAcao="Rejeitar"
	[showModal]="exibirModalRejeitarPublicacao"
	(fecharModal)="fecharModal($event)"
	(editalAtualizado)="atualizarEdital($event)"
	*ngIf="exibirModalRejeitarPublicacao"
></sgp-solicitar-acao>

<sgp-solicitar-acao
	id="RejeitarRetificacao"
	titulo="Rejeitar publicação de retificação do edital"
	[tipoAcao]="acoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO"
	[edital]="edital"
	labelBotaoAcao="Rejeitar"
	[showModal]="exibirModalRejeitarRetificacao"
	(fecharModal)="fecharModal($event)"
	(editalAtualizado)="atualizarEdital($event)"
	*ngIf="exibirModalRejeitarRetificacao"
></sgp-solicitar-acao>
