import { Component, Input } from '@angular/core';

import { Edital } from '@core/models/edital';

@Component({
	selector: 'sgp-visualizar-etapas-edital',
	templateUrl: './visualizar-etapas-edital.component.html'
})
export class VisualizarEtapasEditalComponent {
	@Input() edital!: Edital;
}
