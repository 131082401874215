<div *ngIf="(detalharLaudoService.detalharLaudo$ | async) as candidato">
	<div class="row">
		<div class="col-md-10">
			<h1>Detalhar perfil profissiográfico e relatório do candidato</h1>
		</div>
		<div class="col-md-2 mt-2 text-right">
			<sgp-botao-acao-profissiografico
				[listaAcoes]="candidato.acoesPossiveis!"
				[idCandidato]="idCandidato"
				[idCargo]="idCargo"
				[situacaoLaudo]="candidato.situacao!.codigo"
				exibicao="detalhamento"
			></sgp-botao-acao-profissiografico>
		</div>
	</div>
	<div class="row">
		<div class="col text-right mt-2">
			<button (click)="voltar()">Voltar</button>
		</div>
	</div>
	<div class="br-card">
		<div class="card-content">
			<div class="row">
				<div class="col">
					<div>
						<span class="text-semi-bold text-up-02">{{candidato.nomeCandidato}}</span>
						<i
							class="fas fa-exclamation-circle text-up-03 text-orange-30 ml-2"
							*ngIf="candidato.dataSolicitacaoPrioridade"
							alt="Prioridade"
							title="Prioridade"
						></i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="mt-2"><span class="text-semi-bold">Concurso:</span> {{candidato.nomeConcurso | imprimirDash}}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="mt-2"><span class="text-semi-bold">Órgão:</span> {{candidato.nomeOrgao | imprimirDash}}</div>
				</div>
				<div class="col-md-6">
					<div class="mt-2"><span class="text-semi-bold">Cargo:</span> {{candidato.nomeCargo | imprimirDash}}</div>
				</div>
			</div>
		</div>
	</div>

	<p-tabView>
		<p-tabPanel header="Relatório do candidato">
			<sgp-detalhar-laudo-candidato />
		</p-tabPanel>
		<p-tabPanel header="Perfil profissiográfico do candidato">
			<sgp-detalhar-perfil-candidato />
		</p-tabPanel>
		<p-tabPanel header="Detalhar cargo">
			<sgp-detalhar-cargo />
		</p-tabPanel>
	</p-tabView>

	<div class="br-divider mt-4"></div>
	<div class="row">
		<div class="col text-right mt-2">
			<button (click)="voltar()">Voltar</button>
		</div>
	</div>
</div>
