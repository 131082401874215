<p-messages [enableService]="true" data-cy="mensagens" [closable]="false"></p-messages>

<sgp-exibir-mensagem-alerta></sgp-exibir-mensagem-alerta>

<div *possuiPerfil="perfisEnum.GESTOR_GERAL; entao dashboard_gestor_geral; senao dashboard_gestor_oportunidades_autorizador"></div>

<ng-template #dashboard_gestor_geral>
	<sgp-dashboard-gestor-geral [dadosUsuario]="dadosUsuario" [nomeUsuario]="nomeUsuario"></sgp-dashboard-gestor-geral>
</ng-template>

<ng-template #dashboard_gestor_oportunidades_autorizador>
	<sgp-dashboard-gestor-oportunidades-autorizador
		*possuiPerfil="[perfisEnum.GESTOR, perfisEnum.AUTORIZADOR, perfisEnum.GESTOR_REFORMA, perfisEnum.AUTORIZADOR_REFORMA]"
		[dadosUsuario]="dadosUsuario"
		[nomeUsuario]="nomeUsuario"
		[eAutorizador]="eAutorizador!"
		(mensagemErro)="obterMensagem($event)"
	></sgp-dashboard-gestor-oportunidades-autorizador>
</ng-template>
