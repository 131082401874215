export enum SituacoesEnum {
	RAS = 'RAS', //'Rascunho',
	PSO = 'PSO', //'Publicação Solicitada',
	PUB = 'PUB', //'Publicado',
	RET = 'RET', //'Em Retificação',
	PRS = 'PRS', //'Publicação da Retificação Solicitada',
	PUR = 'PUR', //'Publicado com Retificação',
	SUS = 'SUS', //'Suspenso',
	ENC = 'ENC', //'Encerrado',
	PRE = 'PRE', //'Publicado Resultado do Edital'
	CAN = 'CAN' //'Cancelado'
}
