import { Component } from '@angular/core';
import { PesquisarQuestionarioProfissiograficoService } from '../services/pesquisar-questionario-profissiografico.service';

@Component({
	selector: 'sgp-resultado-questionario-profissiografico',
	templateUrl: './resultado-questionario.component.html'
})
export class ResultadoQuestionarioProfissiograficoComponent {
	loadingResultado = false;

	constructor(protected pesquisarService: PesquisarQuestionarioProfissiograficoService) {}

	paginar(event: any) {
		const page = event.first / event.rows + 1;
		const size = event.rows;
		this.pesquisarService.paginar(page, size);
	}
}
