import { TabelasAdministrativas } from './tabelasAdministrativas';

/**
 * Idioma.
 */
export class Idioma extends TabelasAdministrativas {
	libras?: boolean;

	constructor(id?: number, nome?: string, ativo?: boolean, libras?: boolean) {
		super(id, nome, ativo);
		this.libras = libras;
	}
}
