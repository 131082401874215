import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BotaoAcaoComponent } from './botao-acao/botao-acao.component';
import { BuscaLocalidadeComponent } from './busca-localidade/busca-localidade.component';
import { BuscaOrgaoComponent } from './busca-orgao/busca-orgao.component';
import { BuscaUnidadeComponent } from './busca-unidade/busca-unidade.component';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CoreModule } from '@core/core.module';
import { DialogModule } from 'primeng/dialog';
import { DicaPreenchimentoEditorComponent } from './dica-preenchimento-editor/dica-preenchimento-editor.component';
import { DropdownModule } from 'primeng/dropdown';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { EditorModule } from 'primeng/editor';
import { ExibirCurriculoComponent } from './exibir-curriculo/exibir-curriculo.component';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { ItemLabelDescricaoComponent } from './item-label-descricao/item-label-descricao.component';
import { MessageFeedbackComponent } from './message-feedback/message-feedback.component';
import { MessagesModule } from 'primeng/messages';
import { ModalEditarFavoritoComponent } from './modal-editar-favorito/modal-editar-favorito.component';
import { NenhumResultadoComponent } from './nenhum-resultado/nenhum-resultado.component';
import { RequisitosAreaAtuacaoComponent } from './requisitos-area-atuacao/requisitos-area-atuacao.component';
import { RequisitosAreaConhecimentoComponent } from './requisitos-area-conhecimento/requisitos-area-conhecimento.component';
import { RequisitosAreaInteresseComponent } from './requisitos-area-interesse/requisitos-area-interesse.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SolicitarAcaoComponent } from './solicitar-acao/solicitar-acao.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TextoAjudaComponent } from './texto-ajuda/texto-ajuda.component'
import { WizardComponent } from './wizard/wizard.component';

@NgModule({
	declarations: [
		WizardComponent,
		MessageFeedbackComponent,
		BuscaLocalidadeComponent,
		BuscaOrgaoComponent,
		BuscaUnidadeComponent,
		BotaoAcaoComponent,
		DicaPreenchimentoEditorComponent,
		ExibirCurriculoComponent,
		ItemLabelDescricaoComponent,
		NenhumResultadoComponent,
		RequisitosAreaConhecimentoComponent,
		SolicitarAcaoComponent,
		ModalEditarFavoritoComponent,
		RequisitosAreaAtuacaoComponent,
		RequisitosAreaInteresseComponent
	],
	imports: [
		CommonModule,
		AppRoutingModule,
		CoreModule,
		FormsModule,
		StepsModule,
		AutoCompleteModule,
		ConfirmDialogModule,
		DialogModule,
		DropdownModule,
		EditorModule,
		FileUploadModule,
		MessagesModule,
		SkeletonModule,
		SplitButtonModule,
		TableModule,
		DialogModule,
		ChipsModule,
		ButtonModule,
		TagModule,
		DsGovDirectivesModule,
		TextoAjudaComponent
	],
	exports: [
		WizardComponent,
		MessageFeedbackComponent,
		BuscaLocalidadeComponent,
		BuscaOrgaoComponent,
		BuscaUnidadeComponent,
		BotaoAcaoComponent,
		DicaPreenchimentoEditorComponent,
		ExibirCurriculoComponent,
		ItemLabelDescricaoComponent,
		NenhumResultadoComponent,
		RequisitosAreaConhecimentoComponent,
		RequisitosAreaInteresseComponent,
		RequisitosAreaAtuacaoComponent,
		SolicitarAcaoComponent,
		ModalEditarFavoritoComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
