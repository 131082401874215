import { Component, Input } from '@angular/core';

@Component({
	selector: 'sgp-message-feedback',
	templateUrl: './message-feedback.component.html'
})
export class MessageFeedbackComponent {
	@Input() show = false;
	//Pode ser danger, sucess, warning ou info.
	@Input() tipo = 'danger';
	@Input() mensagem = '';

	icones = new Map();

	constructor() {
		this.icones.set('danger', 'fas fa-times-circle');
		this.icones.set('success', 'fas fa-check-circle');
		this.icones.set('warning', 'fas fa-exclamation-triangle');
		this.icones.set('info', 'fas fa-info-circle');
	}

	getTipo(): string {
		return 'feedback ' + this.tipo;
	}

	getIcone(): string {
		return this.icones.get(this.tipo);
	}
}
