import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { CadastrarEditalApiService } from '../services/cadastrar-edital-api.service';
import { ConverterEditalInterfaceHelper } from '@core/helpers/converter-edital-interface.helper';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { DadosBasicosEditalComponent } from '@cadastrarEdital/dados-basicos-edital/dados-basicos-edital.component';
import { Edital } from '@core/models/edital';
import { IdComponent } from '@core/models/id-component';
import { ListarAnexosComponent } from '@cadastrarEdital/listar-anexos/listar-anexos.component';
import { ListarEtapasComponent } from '@cadastrarEdital/listar-etapas/listar-etapas.component';
import { ListarOportunidadesComponent } from '@cadastrarEdital/listar-oportunidades/listar-oportunidades.component';
import { Message } from 'primeng/api';
import { RevisarEditalComponent } from '@cadastrarEdital/revisar-edital/revisar-edital.component';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { UsuariosService } from '@core/services/usuarios.service';
import { WizardContent } from '@core/models/wizard-content';
import { WizardItems } from '@core/models/wizard-items';

@Component({
	templateUrl: './cadastrar-edital.component.html'
})
export class CadastrarEditalComponent implements OnInit, OnDestroy {
	nomeFuncionalidade?: 'Cadastrar novo edital' | 'Alterar edital' | 'Retificar edital';
	steps = 5;
	items!: WizardItems[];
	content!: WizardContent[];
	loading = true;
	salvando = false;
	bloqueadoAlteracao = false;
	usuarioAutorizado = false;
	msgErro: Message[] = [];

	edital!: Edital;
	subscriptions: Subscription[] = [];

	constructor(
		private cadastrarEditalApi: CadastrarEditalApiService,
		private cdr: ChangeDetectorRef,
		private converterEditalInterface: ConverterEditalInterfaceHelper,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private route: ActivatedRoute,
		private router: Router,
		private ui: UiService,
		private usuarioService: UsuariosService
	) {
		const id = Number(this.route.snapshot.paramMap.get('id'));
		this.prepararEdital(id);
	}

	ngOnInit(): void {
		this.getEtapasWizard();
		this.getConteudoWizard();
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	async salvarEdital() {
		if (this.edital.numeroEdital) {
			this.converterEditalInterface.converter(this.edital);
			this.salvando = false;
		}
	}

	async atualizarEdital() {
		if (this.edital.numeroEdital) {
			this.salvando = true;
			const iEdital = this.converterEditalInterface.converter(this.edital);
			this.subscriptions.push(
				this.cadastrarEditalApi.alterarEdital(iEdital).subscribe(
					res => {
						this.converterInterfaceEdital.converter(this.edital, res);
						this.salvando = false;
					},
					err => {
						this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
					}
				)
			);
		}
	}

	/**
	 * Cria um novo edital, com os parâmetros definidos
	 */
	private async prepararEdital(id: number) {
		if (id === undefined || id === 0) {
			this.nomeFuncionalidade = 'Cadastrar novo edital';
			await this.criarEdital();
		} else {
			await this.carregarEdital(id);
		}
	}

	private async carregarEdital(id: number) {
		this.subscriptions.push(
			this.cadastrarEditalApi.consultarEdital(id).subscribe(
				async res => {
					this.edital = await res;
					this.converterInterfaceEdital.converter(this.edital, res);
					this.loading = false;
					this.usuarioAutorizado = this.usuarioService.isMesmoOrgaoUsuario(this.edital.codigoOrgao!.toString());
					if (this.edital.situacao.codigo === SituacoesEnum.RAS) {
						this.nomeFuncionalidade = 'Alterar edital';
					} else if (this.edital.situacao.codigo === SituacoesEnum.RET) {
						this.nomeFuncionalidade = 'Retificar edital';
						this.router.navigate(['/retificarEdital', this.edital.id]);
					} else {
						this.bloqueadoAlteracao = true;
					}
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private async criarEdital() {
		this.edital = new Edital();
		const rascunho = {
			codigo: 'RAS',
			descricao: 'Em Rascunho'
		};
		this.edital.situacao = rascunho;

		await this.usuarioService.getOrgaoUsuario().then(orgao => {
			if (orgao) {
				this.edital.nomeOrgao = orgao.nome;
				this.edital.codigoOrgao = orgao.codigoSiape;
				this.usuarioAutorizado = true;
				this.loading = false;
			}
		});
	}

	/**
	 * Gera o conteúdo do wizard
	 */
	private getConteudoWizard() {
		this.content = [
			{
				ordem: 0,
				title: 'Dados básicos',
				idComponent: this.getDadosBasicosEdital(),
				active: true,
				backButton: false,
				forwardButton: true,
				cancelButton: true
			},
			{
				ordem: 1,
				title: 'Cadastrar oportunidades',
				idComponent: this.getListarOportunidades(),
				backButton: true,
				forwardButton: true,
				cancelButton: true
			},
			{
				ordem: 2,
				title: 'Cadastrar etapas',
				idComponent: this.getCadastrarEtapas(),
				backButton: true,
				forwardButton: true,
				cancelButton: true
			},
			{
				ordem: 3,
				title: 'Incluir anexos',
				idComponent: this.getIncluirAnexos(),
				backButton: true,
				forwardButton: true,
				cancelButton: true
			},
			{
				ordem: 4,
				title: 'Revisar dados',
				idComponent: this.getRevisarEdital(),
				backButton: true,
				forwardButton: false,
				cancelButton: true
			}
		];
	}

	private getEtapasWizard() {
		this.items = [
			{ ordem: 0, title: 'Dados básicos', active: true, disabled: false },
			{ ordem: 1, title: 'Cadastrar oportunidades', disabled: false },
			{ ordem: 2, title: 'Cadastrar etapas', disabled: false },
			{ ordem: 3, title: 'Incluir anexos', disabled: false },
			{ ordem: 4, title: 'Revisar dados', disabled: false }
		];
	}

	private getDadosBasicosEdital(): IdComponent {
		return new IdComponent(DadosBasicosEditalComponent, { name: 'Dados básicos do edital' }, this.edital);
	}

	private getListarOportunidades(): IdComponent {
		return new IdComponent(ListarOportunidadesComponent, { name: 'Oportunidades adicionadas' }, this.edital);
	}

	private getCadastrarEtapas(): IdComponent {
		return new IdComponent(ListarEtapasComponent, { name: 'Cadastrar etapas' }, this.edital);
	}

	private getIncluirAnexos(): IdComponent {
		return new IdComponent(ListarAnexosComponent, { name: 'Incluir anexos' }, this.edital);
	}

	private getRevisarEdital(): IdComponent {
		return new IdComponent(RevisarEditalComponent, { name: 'Cadastrar etapas' }, this.edital);
	}
}
