import { HttpClient, HttpParams } from '@angular/common/http';
import { publishLast, refCount } from 'rxjs/operators';

import { AreaAtuacao } from '@core/models/area-atuacao';
import { CestaCurriculo } from './../models/cesta-curriculo';
import { ColecaoIdsCesta } from '@app/cestaCurriculo/models/colecao-ids-cesta';
import { Curriculo } from '@core/models/curriculo';
import { Idioma } from '@core/models/idioma';
import { Injectable } from '@angular/core';
import { NivelCurso } from '@core/models/nivel-curso';
import { Observable } from 'rxjs';
import { ParametroListaCpfs } from './../../cestaCurriculo/models/parametro-lista-cpfs';
import { RetornoComboTags } from '../models/retorno-combo-tags';
import { RetornoTags } from './../models/retorno-tags';
import { TipoVinculo } from '@core/models/tipo-vinculo';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TalentosApiService {
	private cacheAreaAtuacao$?: Observable<Array<AreaAtuacao>>;
	private cacheCompetencias$?: Observable<any>;
	private cacheIdiomas$?: Observable<Array<Idioma>>;
	private cacheNivelCurso$?: Observable<Array<NivelCurso>>;
	private cacheVinculos$?: Observable<Array<TipoVinculo>>;

	constructor(private http: HttpClient) {}

	areasAtuacao() {
		this.cacheAreaAtuacao$ = this.areasAtuacaoService().pipe(publishLast(), refCount());
		return this.cacheAreaAtuacao$;
	}

	private areasAtuacaoService(): Observable<AreaAtuacao[]> {
		const url = environment.AREA_ATUACAO;
		return this.http.get<AreaAtuacao[]>(url);
	}

	consultarAreasAtuacaoPorTexto(texto: string) {
		const url = environment.AREA_ATUACAO + '/' + texto;
		return this.http.get<AreaAtuacao[]>(url);
	}

	areasConhecimento(texto: string, pagina: number, quantidade: number, ativo: boolean): Observable<any> {
		const url = environment.AREA_CONHECIMENTO + '/' + texto;
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (quantidade) {
			params = params.set('size', quantidade);
		}

		if (ativo) {
			params = params.set('ativo', ativo);
		}
		return this.http.get(url, { params: params });
	}

	competencias() {
		this.cacheCompetencias$ = this.competenciasService().pipe(publishLast(), refCount());
		return this.cacheCompetencias$;
	}

	private competenciasService(): Observable<any> {
		const url = environment.COMPETENCIA;

		return this.http.get<any>(url);
	}

	curriculoService(cpf: string): Observable<Curriculo> {
		const url = environment.CURRICULOS + '/' + cpf;
		return this.http.get<Curriculo>(url);
	}

	idiomas() {
		this.cacheIdiomas$ = this.idiomasService().pipe(publishLast(), refCount());
		return this.cacheIdiomas$;
	}

	private idiomasService(): Observable<Idioma[]> {
		const url = environment.IDIOMA;

		return this.http.get<Idioma[]>(url);
	}

	niveisCurso(classificacao: string) {
		if (!this.cacheNivelCurso$) {
			this.cacheNivelCurso$ = this.niveisCursoService(classificacao).pipe(publishLast(), refCount());
		}
		return this.cacheNivelCurso$;
	}

	private niveisCursoService(classificacao: string): Observable<NivelCurso[]> {
		const url = environment.NIVEL_CURSO + '/' + classificacao;

		return this.http.get<NivelCurso[]>(url);
	}

	tipoVinculo() {
		this.cacheVinculos$ = this.tipoVinculoService().pipe(publishLast(), refCount());
		return this.cacheVinculos$;
	}

	private tipoVinculoService(): Observable<TipoVinculo[]> {
		const url = environment.VINCULO;

		return this.http.get<TipoVinculo[]>(url);
	}

	inserirCestaCurriculo(idCurriculo: number, objeto: RetornoTags): Observable<any> {
		const url = environment.CESTA_CURRICULO + '/' + idCurriculo.toString();
		return this.http.post(url, objeto);
	}

	excluirCestaCurriculo(id: number): Observable<any> {
		const url = environment.CESTA_CURRICULO + '/' + id.toString();
		return this.http.delete(url);
	}

	consultarCestaCurriculo(id: number): Observable<CestaCurriculo> {
		const url = environment.CESTA_CURRICULO + '/' + id.toString();
		return this.http.get<CestaCurriculo>(url);
	}

	alterarCestaCurriculo(id: number, objeto: RetornoTags): Observable<any> {
		const url = environment.CESTA_CURRICULO + '/' + id.toString();
		return this.http.put(url, objeto);
	}

	carregarTagsComboCesta(): Observable<RetornoComboTags> {
		const url = environment.CESTA_CURRICULO + '/lista-tag';
		return this.http.get<RetornoComboTags>(url);
	}

	pesquisarCesta(pagina: number, quantidade: number, tags: RetornoComboTags): Observable<any> {
		let params = new HttpParams();

		if (pagina) {
			params = params.set('page', pagina);
		}
		if (quantidade) {
			params = params.set('size', quantidade);
		}

		const url = environment.CESTA_CURRICULO + '/pesquisar-cesta';
		return this.http.post(url, tags, { params: params });
	}

	baixarMultiplosCurriculosCesta(objeto: ParametroListaCpfs): Observable<ArrayBuffer> {
		const url = environment.CESTA_CURRICULO + '/curriculos/zip';
		return this.http.post(url, objeto, { responseType: 'arraybuffer' });
	}

	excluirItensCestaCurriculo(colecaoids: ColecaoIdsCesta): Observable<any> {
		const url = environment.CESTA_CURRICULO;
		//return this.http.request('delete', url, { body: colecaoids });
		return this.http.put(url, colecaoids);
	}
}
