<h4 data-cy="titulo-etapas">Etapas</h4>

<div class="row" *ngFor="let etapa of edital?.etapas">
	<div class="col-sm-12">
		<div class="br-card">
			<div class="card-header">
				<div class="d-flex">
					<div class="mb-2">
						<p class="text-weight-semi-bold text-up-02 text-primary-default mb-0" data-cy="nome-etapa">
							{{etapa.tipoEtapaEdital?.descricao}}
						</p>
					</div>
				</div>
			</div>
			<div class="card-content" data-cy="informacoes-etapa">
				<div class="row mb-2">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Descrição:</label>
					</div>
					<div class="col-sm-9">
						<span id="vagas-oportunidade-revisao">{{etapa.descricaoEtapa | imprimirDash}}</span>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Data inicial:</label>
					</div>
					<div class="col-sm-9">
						<span id="vagas-oportunidade-revisao">{{etapa.dataInicioEtapa}}</span>
						<span *ngIf="etapa.dataInicioEtapa === null">-</span>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Data final:</label>
					</div>
					<div class="col-sm-9">
						<span id="vagas-oportunidade-revisao">{{etapa.dataFimEtapa}}</span>
						<span *ngIf="etapa.dataFimEtapa === null">-</span>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Orientações:</label>
					</div>
					<div class="col-sm-9">
						<span *ngIf="!etapa.orientacoesEtapa">-</span>
						<span *ngIf="etapa.orientacoesEtapa" [innerHTML]="etapa.orientacoesEtapa"></span>
					</div>
				</div>

				<div class="row mb-1">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Candidatura:</label>
					</div>
					<div class="col-sm-9">
						<span *ngIf="!etapa.informacaoCandidatura">-</span>
						<span *ngIf="etapa.informacaoCandidatura" [innerHTML]="etapa.informacaoCandidatura"></span>
					</div>
				</div>

				<div class="row mb-1">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Permite Enviar Documentos:</label>
					</div>
					<div class="col-sm-9">
						<span id="vagas-oportunidade-revisao">{{etapa.permiteEnvioDocumentos | imprimirSimNao}}</span>
					</div>
				</div>

				<div class="row mb-1" *ngIf="etapa.permiteEnvioDocumentos === true">
					<div class="col-sm-3">
						<label for="vagas-oportunidade-revisao">Tipo de Documentos:</label>
					</div>
					<div class="col-sm-9">
						<span id="vagas-oportunidade-revisao">
							<span *ngIf="etapa.tiposDocumento?.length === 0; else exibirTiposDocumentos">-</span>
							<ng-template #exibirTiposDocumentos>
								<ul>
									<li *ngFor="let tipo of etapa?.tiposDocumento" class="text-capitalize">
										<span>{{tipo.nome?.toLowerCase()}} <span *ngIf="!tipo.ativo">(Inativo)</span></span>
									</li>
								</ul>
							</ng-template>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
