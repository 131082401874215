import { Component, Input } from '@angular/core';

import { AtividadeCargo } from '@app/core/models/perfil-profissiografico/atividade-cargo';
import { Observable } from 'rxjs';

@Component({
  selector: 'sgp-detalhar-atividades-cargo',
  templateUrl: './detalhar-atividades-cargo.component.html'
})
export class DetalharAtividadesCargoComponent {
  @Input() atividadesCargo?: Observable<AtividadeCargo>;

  constructor() {}
}
