<p-splitButton
	#btnAcao
	label="Ações"
	[model]="acoes"
	[disabled]="desabilitar"
	styleClass="lista-acoes"
	(onClick)="btnAcao.onDropdownButtonClick($event)"
></p-splitButton>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>

<div class="text-left">
	<!-- Solicitação de publicação -->
	<sgp-solicitar-acao
		id="SolicitarPublicacao"
		titulo="Solicitar publicação de edital"
		[tipoAcao]="acoesEnum.OPO_SOLICITAR_PUBLICACAO_EDITAL"
		[edital]="edital"
		labelBotaoAcao="Solicitar"
		[showModal]="exibirModalSolicitarPublicacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalSolicitarPublicacao"
	></sgp-solicitar-acao>

	<!-- Solicitação de publicação de retificação -->
	<sgp-solicitar-acao
		id="SolicitarRetificacao"
		titulo="Solicitar publicação de retificação de edital"
		[tipoAcao]="acoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO"
		[edital]="edital"
		labelBotaoAcao="Solicitar"
		[showModal]="exibirModalSolicitarRetificacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalSolicitarRetificacao"
	></sgp-solicitar-acao>

	<!-- Publicar resultado -->
	<sgp-solicitar-acao
		id="PublicarResultado"
		titulo="Publicar resultado"
		[tipoAcao]="acoesEnum.OPO_PUBLICAR_RESULTADO"
		[edital]="edital"
		labelBotaoAcao="Publicar resultado"
		[showModal]="exibirModalPublicarResultado"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalPublicarResultado"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="RejeitarPublicacao"
		titulo="Rejeitar publicação de edital"
		[tipoAcao]="acoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL"
		[edital]="edital"
		labelBotaoAcao="Rejeitar"
		[showModal]="exibirModalRejeitarPublicacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalRejeitarPublicacao"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="RejeitarRetificacao"
		titulo="Rejeitar publicação de retificação do edital"
		[tipoAcao]="acoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO"
		[edital]="edital"
		labelBotaoAcao="Rejeitar"
		[showModal]="exibirModalRejeitarRetificacao"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalRejeitarRetificacao"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="SuspenderEdital"
		titulo="Suspender edital"
		[tipoAcao]="acoesEnum.OPO_SUSPENDER_EDITAL"
		[edital]="edital"
		labelBotaoAcao="Confirmar suspensão"
		[showModal]="exibirModalSuspenderEdital"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalSuspenderEdital"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="CancelarEdital"
		titulo="Cancelar edital"
		[tipoAcao]="acoesEnum.OPO_CANCELAR_EDITAL"
		[edital]="edital"
		labelBotaoAcao="Confirmar cancelamento"
		[showModal]="exibirModalCancelarEdital"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalCancelarEdital"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="AutorizarPublicacao"
		titulo="Autorizar publicação do edital"
		[tipoAcao]="acoesEnum.OPO_AUTORIZAR_PUBLICACAO_EDITAL"
		[edital]="edital"
		labelBotaoAcao="Confirmar autorização"
		[showModal]="exibirModalAutorizarPublicacaoEdital"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalAutorizarPublicacaoEdital"
	></sgp-solicitar-acao>

	<sgp-solicitar-acao
		id="AutorizarRetificacao"
		titulo="Autorizar retificação do edital"
		[tipoAcao]="acoesEnum.OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO"
		[edital]="edital"
		labelBotaoAcao="Confirmar autorização"
		[showModal]="exibirModalAutorizarRetificacaoEdital"
		(fecharModal)="fecharModal($event)"
		(editalAtualizado)="atualizarEdital($event)"
		*ngIf="exibirModalAutorizarRetificacaoEdital"
	></sgp-solicitar-acao>
</div>
