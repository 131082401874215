export class FiltroTabelasAdministrativas {
	id?: number;
	nome?: string;
	ativo?: boolean;
	libras?: boolean;
	incluido_usuario?: boolean;
	ferramenta?: boolean;

	constructor(id?: number, nome?: string, ativo?: boolean, libras?: boolean, incluido_usuario?: boolean, ferramenta?: boolean) {
		this.id = id;
		this.nome = nome;

		if (ativo == undefined) {
			this.ativo = false;
		} else {
			this.ativo = ativo;
		}

		if (libras == undefined) {
			this.libras = false;
		} else {
			this.libras = libras;
		}

		if (incluido_usuario == undefined) {
			this.incluido_usuario = false;
		} else {
			this.incluido_usuario = incluido_usuario;
		}

		if (ferramenta == undefined) {
			this.ferramenta = false;
		} else {
			this.ferramenta = ferramenta;
		}
	}
}
