import * as moment from 'moment';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { CadastrarEditalApiService } from '../services/cadastrar-edital-api.service';
import { Edital } from '@app/core/models/edital';
import { Etapa } from '@core/models/etapa';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-listar-etapas',
	templateUrl: './listar-etapas.component.html',
	providers: [MessageService, ConfirmationService]
})
export class ListarEtapasComponent implements OnInit, OnDestroy {
	@Input() edital!: Edital;
	etapas: Etapa[] = [];
	etapa!: Etapa;
	idEtapaSelecionada?: number;
	operacao = 'listar';
	subscriptions: Subscription[] = [];
	loading = false;

	constructor(
		private cadastrarEditalApi: CadastrarEditalApiService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		private oportunidadesService: OportunidadesApiService,
		private ui: UiService
	) {}

	ngOnInit() {
		this.carregarEtapas();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	isListar(): boolean {
		this.desabilitarExclusaoEtapas();
		return this.operacao === 'listar';
	}

	isManter(): boolean {
		return this.operacao === 'cadastrar' || this.operacao === 'alterar';
	}

	prepararCadastrarEtapa(): void {
		this.operacao = 'cadastrar';
		this.messageService.clear();
		this.idEtapaSelecionada = undefined;
		this.etapa = {};
		this.etapa.tiposDocumento = [];
	}

	prepararAlterarEtapa(idEtapa: number): void {
		this.idEtapaSelecionada = idEtapa;
		this.messageService.clear();
		this.operacao = 'alterar';
	}

	cancelar(event: string): void {
		this.operacao = event;
		this.etapa = {};
	}

	excluirEtapa(idEtapa: number): void {
		this.messageService.clear();
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir esta etapa?',
			accept: async () => {
				this.loading = true;
				this.subscriptions.push(
					this.cadastrarEditalApi.excluirEtapa(idEtapa).subscribe(
						async res => {
							if (res) {
								this.loading = false;
								this.messageService.add(this.ui.criarMensagem('success', '', 'Etapa excluída com sucesso.'));
								this.atualizarEtapa();
							}
						},
						err => {
							this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
						}
					)
				);
			}
		});
	}

	salvoComSucesso() {
		this.carregarEtapas();
		this.operacao = 'listar';
		this.etapa = {};
		this.messageService.add(this.ui.criarMensagem('success', '', 'Etapa salva com sucesso'));
	}

	private carregarEtapas() {
		this.loading = true;
		this.subscriptions.push(
			this.oportunidadesService.consultarEtapasEdital(this.edital.id).subscribe(
				res => {
					if (res) {
						this.etapas = res;
						this.edital.etapas = this.etapas;
						this.loading = false;
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	private async atualizarEtapa() {
		this.operacao = 'listar';
		this.etapa = {};
		this.carregarEtapas();
	}

	private desabilitarExclusaoEtapas() {
		if (this.edital.situacao.codigo === SituacoesEnum.RET && this.etapas) {
			const agora = moment();
			for (const etapa of this.etapas) {
				etapa.desabilitarExclusao = etapa.dataFimEtapa && moment(etapa.dataFimEtapa).isBefore(agora);
			}
		}
	}
}
