import { Injectable } from '@angular/core';

/**
 * Helper com métodos auxiliares de validação
 */
@Injectable({
	providedIn: 'root'
})
export class ValidateHelper {
	/**
	 * Testa se um valor é nulo, undefined ou em branco
	 * @param valor valor a ser testado
	 * @returns true | false
	 */
	public isEmBranco(valor: any): boolean {
		return valor == null || valor + '' === '';
	}

	/**
	 * Testa se um valor é undefined ou nulo, com retorno previamente definido caso seja nulo ou undefined.
	 * @param valor valor a ser testado é undefined ou nulo
	 * @param retorno valor retornado se for undefined ou nulo
	 * @param retornoFormatado valor retornado se não for undefined ou nulo e for diferente do valor testado
	 * @returns um dos retornos definidos
	 */
	public validaCampoComRetorno(valor: any, retorno: any, retornoFormatado?: any): any {
		const alternativo = retornoFormatado;

		if (valor != null && alternativo != undefined) {
			return retornoFormatado;
		}

		if (valor != null) {
			return valor;
		}
		return retorno;
	}
}
