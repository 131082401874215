import { Component, OnDestroy } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';

import { FiltroTabelasAdministrativas } from '../models/filtro';
import { Subscription } from 'rxjs';
import { TipoMovimentacao } from '@app/core/models/tipo-movimentacao';
import { TipoMovimentacaoApiService } from '../services/tipo-movimentacao-api.service';
import { UiService } from '@app/core/services/ui.service';
import { ValidateHelper } from '@app/core/helpers/validate.helper';

@Component({
	templateUrl: './tabela-tipo-movimentacao.component.html',
	providers: [MessageService, ConfirmationService]
})
export class TabelaTipoMovimentacaoComponent implements OnDestroy {
	pesquisarAoCarregar = true;
	habilitarFiltroCadastradoUsuario = false;
	filtros?: FiltroTabelasAdministrativas;
	limparFormularioPesquisa = false;
	resultado: any[] = [];
	loadingResultado = false;
	totalResultado?: number;
	pagina = 1;
	itensPorPagina = 10;
	primeiroItemResultado = 0;
	colunas: any;
	tipoMovimentacao = new TipoMovimentacao();
	operacao?: string;

	exibirModalManterTabela = false;
	tituloModal = '';
	mensagemErro: Message[] = [];

	erroCodigo = false;
	mensagemErroCodigo = '';
	erroDescricao = false;
	mensagemErroDescricao = '';

	orgaoSelecionado?: any;
	orgaosCadastro: any[] = [];
	codigoOrgaoSelecionado?: number;

	subscriptions: Subscription[] = [];

	constructor(
		private confirmationService: ConfirmationService,
		private tabelaService: TipoMovimentacaoApiService,
		private messageService: MessageService,
		private ui: UiService,
		private validate: ValidateHelper
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	prepararCadastrar() {
		this.mensagemErro = [];
		this.tituloModal = 'Cadastrar tipo movimentação';
		this.operacao = 'cadastrar';
		this.exibirModalManterTabela = true;
		this.orgaosCadastro = [];
		this.tipoMovimentacao = new TipoMovimentacao();
		this.tipoMovimentacao.ativo = false;
	}

	prepararAlterar(event: TipoMovimentacao) {
		this.mensagemErro = [];
		this.orgaosCadastro = [];
		this.subscriptions.push(
			this.tabelaService.obterTipoMovimentacao(event.codigo!).subscribe(res => {
				this.tipoMovimentacao = res;
				this.converterOrgaosParaOrgaoCadastro();
				this.tituloModal = 'Alterar tipo movimentação';
				this.operacao = 'alterar';
				this.exibirModalManterTabela = true;
			})
		);
	}

	private converterOrgaosParaOrgaoCadastro() {
		if (this.tipoMovimentacao.orgaos && this.tipoMovimentacao.orgaos.length > 0) {
			for (const orgao of this.tipoMovimentacao.orgaos) {
				this.orgaosCadastro.push({ code: orgao.codigoSiape, name: orgao.nome });
			}
		}
	}

	salvar() {
		if (this.valido()) {
			this.tipoMovimentacao.orgaos = [];
			for (const orgao of this.orgaosCadastro) {
				this.tipoMovimentacao.orgaos.push(orgao.code);
			}

			if (this.operacao === 'cadastrar') {
				this.salvarNovo();
			} else if (this.tipoMovimentacao.codigo) {
				this.alterar();
			}
			this.operacao = '';
			this.exibirModalManterTabela = false;
		}
	}

	executarPesquisar(event: any) {
		this.filtros = new FiltroTabelasAdministrativas(undefined, event.nome, event.ativo);
		this.primeiroItemResultado = 0;
		this.pagina = 1;
		this.messageService.clear();
		this.pesquisar();
	}

	limparFormulario() {
		if (this.pesquisarAoCarregar) {
			this.executarPesquisar(new FiltroTabelasAdministrativas());
			this.limparFormularioPesquisa = true;
		} else {
			this.resultado = [];
		}
	}

	excluirItem(event: TipoMovimentacao) {
		const tipoMovimentacao = event;
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir o tipo de movimentação ' + tipoMovimentacao.descricao + '?',
			accept: async () => {
				this.tabelaService.excluirTipoMovimentacao(tipoMovimentacao.codigo!).subscribe(
					async (res: any) => {
						this.exibirMensagemSucesso(res);
					},
					err => {
						this.criarMensagem('error', err.error.message);
					}
				);
			}
		});
	}

	fecharModal() {
		this.exibirModalManterTabela = false;
		this.tipoMovimentacao = new TipoMovimentacao();
		this.operacao = '';
		this.orgaoSelecionado = undefined;
		this.mensagemErro = [];
	}

	paginar(event: any) {
		this.pagina = event.first / event.rows + 1;
		this.primeiroItemResultado = event.first;
		this.pesquisar();
	}

	getItensPorPagina(event: any) {
		this.itensPorPagina = event.rows;
	}

	atualizarOrgao(event: any) {
		this.orgaoSelecionado = event;
		this.codigoOrgaoSelecionado = event.code;
	}

	vincularOrgao() {
		if (this.orgaoSelecionado) {
			this.mensagemErro = [];
			const orgaoJaVinculado = this.orgaosCadastro.some(x => {
				return x.code === this.orgaoSelecionado.code;
			});

			if (!orgaoJaVinculado) {
				this.orgaosCadastro.push(this.orgaoSelecionado);
			} else {
				this.mensagemErro = this.ui.criarListaMensagem('error', '', 'Órgão já vinculado');
			}
		} else {
			this.mensagemErro = this.ui.criarListaMensagem('error', '', 'Selecione um órgão para vincular');
		}

		this.orgaoSelecionado = undefined;
	}

	desvincularOrgao(orgao: any) {
		const index = this.orgaosCadastro.indexOf(orgao)!;
		this.orgaosCadastro.splice(index, 1);
	}

	private pesquisar() {
		this.resultado = [];
		this.loadingResultado = true;
		if (this.filtros) {
			this.subscriptions.push(
				this.tabelaService.obterTipoMovimentacoes(this.filtros, this.pagina, this.itensPorPagina).subscribe(
					(resultado: any) => {
						this.loadingResultado = false;
						this.totalResultado = resultado.metadata.total;
						this.pagina = resultado.metadata.page;
						this.tratarDados(resultado.items);
					},
					err => {
						this.totalResultado = 0;
						this.resultado = [];
						this.criarMensagem('error', err.error.message);
					}
				)
			);
		}
	}

	private tratarDados(itens: any[]) {
		for (const item of itens) {
			const resultadoTratado = {
				codigo: item.codigo,
				descricao: item.descricao,
				ativo: item.ativo ? 'Sim' : 'Não',
				orgaos: item.orgaos
			};
			this.resultado.push(resultadoTratado);
		}
	}

	private valido(): boolean {
		return this.codigoValido() && this.descricaoValida();
	}

	private codigoValido(): boolean {
		this.tipoMovimentacao.codigo = this.tipoMovimentacao.codigo?.trim();
		if (this.validate.isEmBranco(this.tipoMovimentacao.codigo)) {
			this.erroCodigo = true;
			this.mensagemErroCodigo = 'Campo obrigatório';
			return false;
		} else if (this.tipoMovimentacao.codigo && this.tipoMovimentacao.codigo.length < 3) {
			this.erroCodigo = true;
			this.mensagemErroCodigo = 'A sigla deve possuir três letras';
			return false;
		} else {
			this.erroCodigo = false;
			return true;
		}
	}

	private descricaoValida(): boolean {
		this.tipoMovimentacao.descricao = this.tipoMovimentacao.descricao?.trim();
		if (this.validate.isEmBranco(this.tipoMovimentacao.descricao)) {
			this.erroDescricao = true;
			this.mensagemErroDescricao = 'Campo obrigatório';
			return false;
		} else if (this.tipoMovimentacao.descricao && this.tipoMovimentacao.descricao.length < 3) {
			this.erroDescricao = true;
			this.mensagemErroDescricao = 'O campo deve possuir pelo menos três caracteres';
			return false;
		} else {
			this.erroDescricao = false;
			return true;
		}
	}

	private salvarNovo() {
		this.subscriptions.push(
			this.tabelaService.incluirTipoMovimentacao(this.tipoMovimentacao).subscribe(
				(res: any) => {
					this.exibirMensagemSucesso(res);
				},
				err => {
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}

	private alterar() {
		this.subscriptions.push(
			this.tabelaService.alterarTipoMovimentacao(this.tipoMovimentacao.codigo!, this.tipoMovimentacao).subscribe(
				(res: any) => {
					this.exibirMensagemSucesso(res);
				},
				err => {
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}

	private criarMensagem(criticidade: string, mensagem: string) {
		this.messageService.clear();
		this.messageService.add(this.ui.criarMensagem(criticidade, '', mensagem));
	}

	private exibirMensagemSucesso(res: any) {
		if (res) {
			this.criarMensagem('success', res.message);
			this.limparPesquisa();
		}
	}

	private limparPesquisa() {
		this.limparFormularioPesquisa = true;
		this.primeiroItemResultado = 0;
		this.pagina = 1;
		this.pesquisar();
	}
}
