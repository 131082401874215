import {
	AreaAtuacaoMatch,
	AreaConhecimentoMatch,
	CapacitacaoMatch,
	CertificacaoMatch,
	CompetenciaMatch,
	ExperienciaMatch,
	FormacaoMatch,
	IdiomaMatch,
	ParametrosMatch,
	SeloMatch,
	VinculoMatch
} from '../models/parametros-match';

import { AreaInteresseMatch } from './../models/parametros-match';
import { FiltroOrgao } from '../models/filtros-curriculo';
import { Injectable } from '@angular/core';
import { Oportunidade } from '../models/oportunidade';

@Injectable({
	providedIn: 'root'
})
export class MatchService {
	parametros = new ParametrosMatch();

	verificarSePossuiRequisitosMatch(parametros: any): boolean {
		if (
			this.listaNaoEstaVazia(parametros.formacoes) ||
			this.listaNaoEstaVazia(parametros.experiencias) ||
			this.listaNaoEstaVazia(parametros.areasAtuacao) ||
			this.listaNaoEstaVazia(parametros.areasConhecimento) ||
			this.listaNaoEstaVazia(parametros.capacitacoes) ||
			this.listaNaoEstaVazia(parametros.certificacoes) ||
			this.listaNaoEstaVazia(parametros.competencias) ||
			this.listaNaoEstaVazia(parametros.vinculos) ||
			this.listaNaoEstaVazia(parametros.idiomas) ||
			parametros.nomeMunicipio !== undefined ||
			parametros.ufOrgao !== undefined ||
			parametros.cargo !== undefined ||
			parametros.codigoOrgao !== undefined ||
			parametros.nome !== undefined ||
			this.listaNaoEstaVazia(parametros.areasInteresse) ||
			this.listaNaoEstaVazia(parametros.selos) ||
			parametros.nomeUorg !== undefined
		) {
			return true;
		}
		return false;
	}

	montarParametrosMatch(
		oportunidade: Oportunidade,
		cidades?: any,
		orgao?: any,
		ufs?: any,
		cargos?: any,
		nome?: string,
		selos?: any
	): ParametrosMatch {
		this.parametros = new ParametrosMatch();
		this.adicionarParametrosAreasAtuacao(oportunidade);
		this.adicionarParametrosAreasConhecimento(oportunidade);
		this.adicionarParametrosCapacitacoes(oportunidade);
		this.adicionarParametrosCertificacoes(oportunidade);
		this.adicionarParametrosCompetencias(oportunidade);
		this.adicionarParametrosExperiencias(oportunidade);
		this.adicionarParametrosFormacoes(oportunidade);
		this.adicionarParametrosIdiomas(oportunidade);
		this.adicionarParametrosVinculos(oportunidade);
		this.adicionarParametrosCidades(cidades);
		this.adicionarParametrosUFs(ufs);
		this.adicionarParametrosCargos(cargos);
		this.adicionarParametrosOrgaos(orgao);
		this.adicionarParametrosNomes(nome!);
		this.adicionarParametrosSelos(selos!);

		return this.parametros;
	}

	/**
	 * Retira parâmetros que não serão passados para o serviço
	 * @param parametros
	 * @returns
	 */
	prepararParametrosParaPesquisaCurriculo(parametros: ParametrosMatch): ParametrosMatch {
		const param = new ParametrosMatch();
		for (const areaAtuacao of parametros.areasAtuacao) {
			param.areasAtuacao.push(new AreaAtuacaoMatch(areaAtuacao.idAreaAtuacao));
		}
		for (const areaConhecimento of parametros.areasConhecimento) {
			param.areasConhecimento.push(new AreaConhecimentoMatch(areaConhecimento.idAreaConhecimento));
		}
		for (const competencia of parametros.competencias) {
			param.competencias.push(new CompetenciaMatch(competencia.idCompetencia));
		}
		for (const formacao of parametros.formacoes) {
			param.formacoes.push(new FormacaoMatch(formacao.idNivelCurso, formacao.nomeFormacao));
		}
		for (const idioma of parametros.idiomas) {
			param.idiomas.push(new IdiomaMatch(idioma.idIdioma));
		}
		for (const vinculo of parametros.vinculos) {
			param.vinculos.push(new VinculoMatch(vinculo.id));
		}
		for (const capacitacao of parametros.capacitacoes) {
			param.capacitacoes.push(new CapacitacaoMatch(capacitacao.nomeCapacitacao));
		}
		for (const certificacao of parametros.certificacoes) {
			param.certificacoes.push(new CertificacaoMatch(certificacao.nomeCertificacao));
		}
		for (const experiencia of parametros.experiencias) {
			param.experiencias.push(new ExperienciaMatch(experiencia.nomeExperiencia));
		}
		for (const selo of parametros.selos) {
			param.selos.push(new SeloMatch(selo.nomeSelo, selo.nomeInstituicao));
		}
		if (parametros.ufOrgao) {
			param.ufOrgao = parametros.ufOrgao;
		}
		if (parametros.nome) {
			param.nome = parametros.nome;
		}
		if (parametros.cargo) {
			param.cargo = parametros.cargo;
		}
		if (parametros.orgao) {
			param.codigoOrgao = parametros.orgao.codigoOrgao;
		}
		if (parametros.nomeMunicipio) {
			param.nomeMunicipio = parametros.nomeMunicipio;
		}
		if (parametros.nomeUorg) {
			param.nomeUorg = parametros.nomeUorg;
		}
		for (const item of parametros.areasInteresse) {
			param.areasInteresse.push(new AreaInteresseMatch(item.idAreaInteresse));
		}
		return param;
	}

	private adicionarParametrosNomes(nome: string) {
		if (nome) {
			this.parametros.nome = nome;
		}
	}
	private adicionarParametrosCargos(cargo: string) {
		if (cargo) {
			this.parametros.cargo = cargo;
		}
	}
	private adicionarParametrosCidades(cidade: string) {
		if (cidade) {
			this.parametros.nomeMunicipio = cidade;
		}
	}
	private adicionarParametrosOrgaos(orgao: FiltroOrgao) {
		if (orgao) {
			this.parametros.codigoOrgao = orgao.id;
		}
	}
	private adicionarParametrosUFs(uf: string) {
		if (uf) {
			this.parametros.ufOrgao = uf;
		}
	}

	private adicionarParametrosVinculos(oportunidade: Oportunidade) {
		if (oportunidade.vinculos && oportunidade.vinculos.length > 0) {
			for (const vinculo of oportunidade.vinculos) {
				if (vinculo.idVinculo) {
					const vinculoTemp = new VinculoMatch(vinculo.idVinculo);
					this.parametros.vinculos.push(vinculoTemp);
				}
			}
		}
	}

	private adicionarParametrosIdiomas(oportunidade: Oportunidade) {
		if (oportunidade.idiomas && oportunidade.idiomas.length > 0) {
			for (const idioma of oportunidade.idiomas) {
				if (idioma.idIdioma) {
					const idiomaTemp = new IdiomaMatch(idioma.idIdioma);
					this.parametros.idiomas.push(idiomaTemp);
				}
			}
		}
	}

	private adicionarParametrosFormacoes(oportunidade: Oportunidade) {
		if (oportunidade.formacoes && oportunidade.formacoes.length > 0) {
			for (const formacao of oportunidade.formacoes) {
				if (formacao.idNivelCurso && formacao.nomeFormacao) {
					const formacaoTemp = new FormacaoMatch(formacao.idNivelCurso, formacao.nomeFormacao);
					this.parametros.formacoes.push(formacaoTemp);
				}
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private adicionarParametrosSelos(_selos?: any) {}

	private adicionarParametrosExperiencias(oportunidade: Oportunidade) {
		if (oportunidade.experiencias && oportunidade.experiencias.length > 0) {
			for (const experiencia of oportunidade.experiencias) {
				if (experiencia.nomeExperiencia) {
					const experienciaTemp = new ExperienciaMatch(experiencia.nomeExperiencia);
					this.parametros.experiencias.push(experienciaTemp);
				}
			}
		}
	}

	private adicionarParametrosCompetencias(oportunidade: Oportunidade) {
		if (oportunidade.competencias && oportunidade.competencias.length > 0) {
			for (const competencia of oportunidade.competencias) {
				if (competencia.idCompetencia) {
					const competenciasTemp = new CompetenciaMatch(competencia.idCompetencia);
					this.parametros.competencias.push(competenciasTemp);
				}
			}
		}
	}

	private adicionarParametrosCertificacoes(oportunidade: Oportunidade) {
		if (oportunidade.certificacoes && oportunidade.certificacoes.length > 0) {
			for (const certificacao of oportunidade.certificacoes) {
				if (certificacao.nomeCertificacao) {
					const certificacaoTemp = new CertificacaoMatch(certificacao.nomeCertificacao);
					this.parametros.certificacoes.push(certificacaoTemp);
				}
			}
		}
	}

	private adicionarParametrosCapacitacoes(oportunidade: Oportunidade) {
		if (oportunidade.capacitacoes && oportunidade.capacitacoes.length > 0) {
			for (const capacitacao of oportunidade.capacitacoes) {
				if (capacitacao.nomeCapacitacao) {
					const capacitacaoTemp = new CapacitacaoMatch(capacitacao.nomeCapacitacao);
					this.parametros.capacitacoes.push(capacitacaoTemp);
				}
			}
		}
	}

	private adicionarParametrosAreasConhecimento(oportunidade: Oportunidade) {
		if (oportunidade.areasConhecimento && oportunidade.areasConhecimento.length > 0) {
			for (const area of oportunidade.areasConhecimento) {
				if (area.idAreaConhecimento) {
					const areaConhecimentoTemp = new AreaConhecimentoMatch(area.idAreaConhecimento);
					this.parametros.areasConhecimento.push(areaConhecimentoTemp);
				}
			}
		}
	}

	private adicionarParametrosAreasAtuacao(oportunidade: Oportunidade) {
		if (oportunidade.areasAtuacao && oportunidade.areasAtuacao.length > 0) {
			for (const area of oportunidade.areasAtuacao) {
				if (area.idAreaAtuacao) {
					const areaAtuacaoTemp = new AreaAtuacaoMatch(area.idAreaAtuacao);
					this.parametros.areasAtuacao.push(areaAtuacaoTemp);
				}
			}
		}
	}

	private listaNaoEstaVazia(lista: any): boolean {
		return lista !== undefined && lista.length > 0;
	}
}
