<div id="resultado" class="mt-4" *ngIf="resultado && resultado.metadata.total > 0">
	<div class="br-table small">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">
					<div class="row">
						<div class="col-sm-6">
							<span class="text-semi-bold" *ngIf="resultado!.metadata.total === 1">Foi encontrado 1 currículo</span>
							<span class="text-semi-bold" *ngIf="resultado!.metadata.total > 1">
								Foram encontrados {{ resultado.metadata.total | numero }} currículos
							</span>
						</div>
						<div class="col-sm-6 text-right">
							<div
								[ngClass]="{'d-inline': true, 'cursor-not-allowed' : this.curriculosSelecionados.length < 1, 'cursor-pointer': this.curriculosSelecionados.length > 1}"
							>
								<p-button
									styleClass="circle"
									icon="far fa-file-excel"
									title="Exportar tabela"
									alt="Exportar tabela"
									(click)="exportarTabela()"
									data-cy="botao-exportar-tabela"
									[disabled]="this.curriculosSelecionados.length < 1"
								></p-button>
								<p-button
									styleClass="circle"
									[ngClass]="{'cursor-no-events' : this.curriculosSelecionados.length < 1}"
									icon="fas fa-file-download"
									title="Download de currículos"
									alt="Download de currículos"
									(click)="baixarMultiplosCurriculos()"
									data-cy="botao-download-curriculos"
									title="Baixar currículos selecionados"
									[disabled]="this.curriculosSelecionados.length < 1"
								></p-button>
								<p-button
									styleClass="br-button circle"
									title="Limpar a Cesta"
									alt="Limpar a Cesta"
									(click)="limparItensCesta()"
									data-cy="botao-limpar-a-cesta"
									icon="fas fa-trash-alt"
									[disabled]="this.curriculosSelecionados.length < 1"
									title="Excluir currículos selecionados"
									[ngClass]="{'cursor-no-events' : this.curriculosSelecionados.length < 1}"
								></p-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<p-table
			[value]="resultado.items"
			dataKey="nrCpf"
			[(selection)]="curriculosSelecionados"
			[autoLayout]="true"
			[rowHover]="true"
			[responsive]="true"
			responsiveLayout="stack"
			[paginator]="true"
			[rows]="itemsPorPagina"
			[first]="primeiroItemResultado"
			[totalRecords]="totalResultado!"
			[rowsPerPageOptions]="[10,20,50,100]"
			(onPage)="paginar($event)"
			[lazy]="true"
			(onLazyLoad)="getItensPorPagina($event)"
			*ngIf="resultado"
			data-selection="data-selection"
			data-cy="lista-curriculos-cesta"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="checkbox" class="checkbox">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th id="nome">Nome</th>
					<th id="tag">Etiquetas</th>
					<th id="acoes" class="text-center" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
				<tr>
					<td class="checkbox">
						<p-tableCheckbox [value]="item" [index]="rowIndex"></p-tableCheckbox>
					</td>
					<td headers="nome">
						<a
							[routerLink]=""
							(click)="exibirModalCurriculo(item)"
							class="text-decoration-none text-weight-semi-bold text-primary-default text-capitalize text-up-01 cursor-pointer"
						>
							<i class="far fa-file"></i>
							<span *ngIf="item.nome; else semNome"> {{ item.nome.toLowerCase() }} </span>
							<ng-template #semNome>
								<span>Sem nome no Banco de talentos</span>
							</ng-template>
						</a>
					</td>
					<td headers="tag">
						<span *ngFor="let tag of item.tags">
							<span class="br-tag bg-blue-warm-10 text-down-01 text-gray-80 ml-2 text-capitalize mb-1"
								>{{ tag.nomeTag.toUpperCase() }}</span
							>
						</span>
					</td>
					<td headers="acoes" class="text-center">
						<button
							pButton
							class="br-button circle"
							title="Alterar etiqueta"
							(click)="prepararAlterar(item)"
							data-cy="botao-alterar-item-cesta"
							icon="fas fa-edit"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir currículo da cesta"
							(click)="excluirCurriculoDaCesta(item.idCestaCurriculo)"
							data-cy="botao-excluir-item-cesta"
							icon="fas fa-trash-alt"
						></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<sgp-nenhum-resultado
	[exibir]="resultado.metadata.total === 0"
	titulo="Cesta de currículos vazia"
	mensagem="Nenhum currículo adicionado na cesta"
	*ngIf="resultado"
></sgp-nenhum-resultado>

<sgp-exibir-curriculo
	[cpf]="curriculoASerExibido.nrCpf!"
	[nome]="curriculoASerExibido.nome!"
	[exibir]="exibirCurriculo"
	(atualizarExibir)="fecharModalCurriculo($event)"
	*ngIf="exibirCurriculo && curriculoASerExibido"
></sgp-exibir-curriculo>

<sgp-modal-editar-favorito
	[exibirModalEditarFavoritos]="exibirModalEditarFavoritos"
	(resposta)="retornoRespostaModalCesta($event)"
	[situacaoFavorito]="foiAlterado"
	[idCurriculo]="idCurriculoSelecionado"
	[idCestaCurriculo]="idCestaCurriculoSelecionado"
	[permitidoExcluir]="false"
	[vetorNomeTags]="vetorNomeTags"
	[tagsSelecionadas]="tagsSelecionadas"
	*ngIf="exibirModalEditarFavoritos"
></sgp-modal-editar-favorito>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
