import * as FileSaver from 'file-saver';

import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { TagCestaCurriculo } from '../models/tag-cesta-curriculo';
import { TopicosCurriculo } from '../models/topicos-curriculo';

/**
 * Service com métodos úteis de interface
 */
@Injectable({
	providedIn: 'root'
})
export class UiService {
	criarListaMensagem(tipo: string, titulo: string, texto: string): Message[] {
		return [this.criarMensagem(tipo, titulo, texto)];
	}

	criarMensagem(tipo: string, titulo: string, texto: string): Message {
		return { severity: tipo, summary: titulo, detail: texto };
	}

	downloadFile(data: any, type: string) {
		const blob = new Blob([data], { type: type });
		const url = window.URL.createObjectURL(blob);
		window.open(url);
	}

	downloadSaveFile(data: any, type: string, filename: string) {
		const blob = new Blob([data.body], { type: type });
		FileSaver.saveAs(blob, filename);
	}

	capitalize(texto: string) {
		if (texto) {
			const textoArray = texto.trim().split(/(\s+)/);
			let textoCapitalizado = '';
			for (const subtexto of textoArray) {
				textoCapitalizado = textoCapitalizado + subtexto[0].toUpperCase() + subtexto.slice(1).toLowerCase();
			}

			return textoCapitalizado;
		}
		return texto;
	}

	downloadZip(data: any, type: string, filename: string) {
		const blob = new Blob([data], { type: type });
		FileSaver.saveAs(blob, filename + '.zip');
	}

	downloadResponse(response: any) {
		try {
			const tipo = response.headers.get('content-type');
			const contentDisposition = response.headers.get('content-disposition');
			if (contentDisposition.length > 0) {
				const filename = contentDisposition.split(';')[1].trim().split('=')[1];
				this.downloadSaveFile(response, tipo, filename);
			} else {
				this.downloadZip(response, 'application/zip', 'arquivo.zip');
			}
		} catch (error) {
			this.downloadFile(response, 'application/zip');
		}
	}

	exportarExcel(dados: any, nomePlanilha: string) {
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(dados);
			const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, {
				bookType: 'xlsx',
				type: 'array'
			});
			this.saveAsExcelFile(excelBuffer, nomePlanilha);
		});
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
	}

	tratarVetorNomeTags(tagsSelecionadas: TagCestaCurriculo[]): string[] {
		const v: string[] = [];
		tagsSelecionadas?.forEach(item => {
			v.push(item.nomeTag!);
		});
		return v;
	}

	tratarVetorTopicos(topicos: TopicosCurriculo[]): [string[]] {
		const v: [string[]] = [[]];
		topicos?.forEach(item => {
			v.push([item.topico!, item.score!, item.tipo!, item.termos!]);
		});
		return v;
	}
}
