import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';

import { AutoComplete } from 'primeng/autocomplete';
import { CadastrarEditalApiService } from '../services/cadastrar-edital-api.service';
import { ConverterEditalInterfaceHelper } from '@core/helpers/converter-edital-interface.helper';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { IEdital } from '@core/interfaces/edital.interface';
import { Router } from '@angular/router';
import { UiService } from '@core/services/ui.service';
import { ValidateHelper } from '@core/helpers/validate.helper';

@Component({
	selector: 'sgp-dados-basicos-edital',
	templateUrl: './dados-basicos-edital.component.html',
	providers: [MessageService]
})
export class DadosBasicosEditalComponent implements OnDestroy {
	@Input() edital!: Edital;
	salvando: Subject<boolean> = new Subject();
	filteredOrgaos!: any[];
	orgaos!: any[];
	subscriptions: Subscription[] = [];
	msgErro: Message[] = [];
	erroEmail = false;
	mensagemErroEmail = '';
	exibirDica = false;

	@ViewChild('autoCompleteObject') autoCompleteObject!: AutoComplete;
	loading = false;

	constructor(
		private messageService: MessageService,
		private cadastrarEditalApi: CadastrarEditalApiService,
		private converterEditalInterface: ConverterEditalInterfaceHelper,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private router: Router,
		private ui: UiService,
		private validate: ValidateHelper
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	filterOrgao(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.orgaos) {
			for (const orgao of this.orgaos) {
				if (orgao.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(orgao);
				}
			}
		}

		this.filteredOrgaos = filtered;
	}

	salvarEdital() {
		if (this.isEmailValido()) {
			this.limparMensagemErroEmail();
			this.salvando.next(true);
			const iEdital = this.converterEditalInterface.converter(this.edital);
			if (this.edital.id) {
				this.atualizarEdital(iEdital);
			} else {
				this.cadastrarEdital(iEdital);
			}
		}
	}

	private async cadastrarEdital(iEdital: IEdital) {
		this.messageService.clear();
		this.subscriptions.push(
			this.cadastrarEditalApi.incluirEdital(iEdital).subscribe(
				res => {
					this.converterInterfaceEdital.converter(this.edital, res);
					this.salvando.next(false);
					this.messageService.add(this.ui.criarMensagem('success', '', 'Dados básicos cadastrados com sucesso'));
					this.router.navigate(['alterarEdital/', this.edital.id]);
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private async atualizarEdital(iEdital: IEdital) {
		this.messageService.clear();
		this.subscriptions.push(
			this.cadastrarEditalApi.alterarEditalDadosBasicos(iEdital).subscribe(
				res => {
					this.converterInterfaceEdital.converter(this.edital, res);
					this.salvando.next(false);
					this.messageService.add(this.ui.criarMensagem('success', '', 'Dados básicos alterados com sucesso'));
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private isEmailValido(): boolean {
		if (!this.validate.isEmBranco(this.edital.emailContato)) {
			// eslint-disable-next-line no-useless-escape
			const regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
			const testeRegex = regexp.test(this.edital.emailContato!);
			if (!testeRegex) {
				this.exibirErroEmail('Email inválido');
				return false;
			}
			this.limparMensagemErroEmail();
			return true;
		}
		this.exibirErroEmail('Campo obrigatório');
		return false;
	}

	private limparMensagemErroEmail() {
		this.erroEmail = false;
		this.mensagemErroEmail = '';
	}

	private exibirErroEmail(frase: string) {
		this.erroEmail = true;
		this.mensagemErroEmail = frase;
	}
}
