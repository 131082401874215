import { Pipe, PipeTransform } from '@angular/core';

import { RatingIdiomaEnum } from '../enums/ratingIdioma.enum';

/**
 * Transforma a nota de rating que está gravado no banco no formato para exibir em estrelas
 */
@Pipe({ name: 'rating' })
export class RatingPipe implements PipeTransform {
	transform(valor: any): any {
		for (const [key, value] of RatingIdiomaEnum.entries()) {
			if (value === valor) return key;
		}
		return -1;
	}
}
