<div class="br-card">
	<div class="card-content" *ngIf="dados; else skeleton">
		<div *ngIf="dados.length > 0; else semDados">
			<div class="titulo">{{ titulo }}</div>
			<div class="grafico_doughnut">
				<p-chart type="doughnut" [data]="data" [options]="options" height="{{height}}"></p-chart>
			</div>
		</div>
	</div>
	<ng-template #skeleton>
		<p-skeleton width="50rem" height="23rem"></p-skeleton>
	</ng-template>
	<ng-template #semDados>
		<div class="titulo">{{ titulo }}</div>
		<div class="text-center">
			<img
				src="assets/images/pie-chart.png"
				height="140"
				style="margin-top: 50px; margin-bottom: 50px"
				alt="Analítica ícones criados por Xinh Studio - Flaticon"
			/>
			<p class="text-up-02 text-gray-cool-40 text-weight-semi-bold">Sem dados para exibir</p>
		</div>
	</ng-template>
</div>
