import { Pipe, PipeTransform } from '@angular/core';

import { AcoesEnum } from '@core/enums/acoes.enum';

@Pipe({ name: 'podeSerAcao' })
export class TipoAcaoPipe implements PipeTransform {
	/**
	 * Testa se uma ação passada corresponde a uma das ações listadas. Podem ser até 4 ações listadas.
	 * @param acaoInformada ação para comparacao
	 * @param acao1 ação desejada 1
	 * @param acao2 ação desejada 2
	 * @param acao3 ação desejada 3
	 * @param acao4 ação desejada 4
	 * @returns true| false
	 */
	transform(acaoInformada: any, acao1: AcoesEnum, acao2?: AcoesEnum, acao3?: AcoesEnum, acao4?: AcoesEnum): boolean {
		return acaoInformada === acao1 || acaoInformada === acao2 || acaoInformada === acao3 || acaoInformada === acao4;
	}
}
