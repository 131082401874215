<div class="loading medium" *ngIf="loading"></div>

<div class="br-table" *ngIf="!loading">
	<p-table
		[value]="listaNotificacoes"
		dataKey="id"
		[rowHover]="true"
		[responsive]="true"
		responsiveLayout="stack"
		[autoLayout]="true"
		[paginator]="true"
		[rows]="5"
		data-cy="tabela-notificacoes-candidato"
	>
		<ng-template pTemplate="header">
			<tr>
				<th id="exibir-mensagem" style="width: 3rem"></th>
				<th id="cabecalho-enviado-por" data-cy="cabecalho-enviado-por">Enviado por</th>
				<th id="cabecalho-assunto" data-cy="cabecalho-assunto">Assunto</th>
				<th id="cabecalho-enviado-em" data-cy="cabecalho-enviado-em">Enviado em</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-notificacao let-expanded="expanded">
			<tr style="display: block">
				<td headers="exibir-mensagem">
					<button
						type="button"
						pButton
						pRipple
						[pRowToggler]="notificacao"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
					></button>
				</td>
				<td headers="cabecalho-enviado-por" data-cy="enviado-por">
					<span class="p-column-title mb-2">Enviado por</span>
					<span class="text-capitalize">{{notificacao.nome_operador.toLowerCase()}}</span>
				</td>
				<td headers="cabecalho-assunto" data-cy="assunto">
					<span class="p-column-title mb-2">Assunto</span>
					{{ notificacao.assunto }}
				</td>
				<td headers="cabecalho-enviado-em" data-cy="enviado-em" class="text-center">
					<span class="p-column-title mb-2">Enviado em</span>
					<div>{{notificacao.data_hora_notificacao.split(" ")[0] }}</div>
					<div class="text-down-01 text-gray-50">{{notificacao.data_hora_notificacao.split(" ")[1] }}</div>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="rowexpansion" let-notificacao>
			<tr>
				<td colspan="4">
					<div [innerHTML]="notificacao.mensagem" class="mensagem"></div>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="5" data-cy="mensagem-nenhum-anexo">Nenhuma notificação enviada.</td>
			</tr>
		</ng-template>
	</p-table>
</div>
