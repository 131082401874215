import { Pipe, PipeTransform } from '@angular/core';

/**
 * Imprime um número com o ponto separando a cada 3 dígitos
 */
@Pipe({ name: 'numero' })
export class NumeroPipe implements PipeTransform {
	transform(value: string | number): string {
		return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
	}
}
