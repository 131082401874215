import { Pipe, PipeTransform } from '@angular/core';

import { ValidateHelper } from '../helpers/validate.helper';

/**
 * imprime um dash ('-') no caso de o valor não ser informado.
 */
@Pipe({ name: 'imprimirSimNao' })
export class ImprimirSimNaoPipe implements PipeTransform {
	constructor(private validate: ValidateHelper) {}

	transform(value: boolean | undefined): string {
		if (value === true) {
			return 'Sim';
		}
		return 'Não';
	}
}
