export class ParametrosPesquisaEdital {
	textoPesquisa?: string;
	codigoSituacao?: string;
	situacoesPossiveis?: string[];
	codigoOrgao?: string;
	dataInicial?: string;
	dataFinal?: string;
	localidade?: string;
	pagina?: number;
	tamanho?: number;
}
