import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ItemRetornoCurriculoTags, RetornoCurriculoTags } from '../models/retorno-curriculo-tags';

import { CestaCurriculo } from '@app/core/models/cesta-curriculo';
import { ColecaoIdsCesta } from '../models/colecao-ids-cesta';
import { FeedbackModal } from '@app/core/models/feedback-modal';
import { ParametroListaCpfs } from '../models/parametro-lista-cpfs';
import { ParametrosPaginacao } from '../models/parametros-paginacao';
import { Subscription } from 'rxjs';
import { TagCestaCurriculo } from '@app/core/models/tag-cesta-curriculo';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { UiService } from '@app/core/services/ui.service';
import moment from 'moment';

@Component({
	selector: 'sgp-resultado-cesta-curriculo',
	templateUrl: './resultado-cesta-curriculo.component.html',
	providers: [ConfirmationService]
})
export class ResultadoCestaCurriculoComponent implements OnDestroy {
	@Input() resultado?: RetornoCurriculoTags;
	@Input() itemsPorPagina!: number;
	@Input() pagina!: number;
	@Input() primeiroItemResultado!: number;
	@Input() totalResultado?: number;
	@Output() resposta: EventEmitter<FeedbackModal> = new EventEmitter<FeedbackModal>();
	@Output() repesquisar: EventEmitter<ParametrosPaginacao> = new EventEmitter<ParametrosPaginacao>();

	curriculosSelecionados: ItemRetornoCurriculoTags[] = [];
	curriculosExportaveis: ItemRetornoCurriculoTags[] = [];
	curriculoASerExibido?: ItemRetornoCurriculoTags;
	exibirCurriculo = false;

	idCestaCurriculoSelecionado?: number;
	idCurriculoSelecionado?: number;
	exibirModalEditarFavoritos = false;

	cesta?: CestaCurriculo;
	tagsSelecionadas?: TagCestaCurriculo[] = [];
	vetorNomeTags?: string[] = [];

	foiAlterado = false;

	subscriptions: Subscription[] = [];

	constructor(
		private confirmationService: ConfirmationService,
		private talentosApi: TalentosApiService,
		private messageService: MessageService,
		private ui: UiService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	baixarMultiplosCurriculos() {
		if (this.curriculosSelecionados.length >= 1) {
			const temp: string[] = [];
			this.curriculosSelecionados.forEach(itemtag => {
				temp.push(itemtag.nrCpf!);
			});
			const objeto = new ParametroListaCpfs();
			objeto.lista_cpfs = temp;
			this.subscriptions.push(
				this.talentosApi.baixarMultiplosCurriculosCesta(objeto).subscribe(
					response => {
						const data = moment().date() + '-' + moment().add(1, 'M').month() + '-' + moment().year();
						const nomeArquivo = 'Currículos da cesta data - ' + data;
						this.ui.downloadZip(response, 'application/zip', nomeArquivo);
					},
					err => {
						this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
					}
				)
			);
		}
	}

	limparItensCesta() {
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir os currículos selecionados da cesta?',
			accept: async () => {
				if (this.curriculosSelecionados.length >= 1) {
					const temp: number[] = [];
					this.curriculosSelecionados.forEach(itemtag => {
						temp.push(itemtag.idCestaCurriculo!);
					});
					const colecaoIds = new ColecaoIdsCesta();
					colecaoIds.listaIdCestaCurriculo = temp;
					this.subscriptions.push(
						this.talentosApi.excluirItensCestaCurriculo(colecaoIds).subscribe(
							async res => {
								if (res) {
									const mensagem = this.ui.criarListaMensagem(
										'success',
										'',
										'Currículos excluídos da cesta com sucesso!'
									);
									const feedback = new FeedbackModal(true, mensagem, true);
									this.resposta.emit(feedback);
								}
							},
							err => {
								this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
							}
						)
					);
				}
			}
		});
	}

	excluirCurriculoDaCesta(id: number) {
		//TODO: Chamar o modal de confirmação (não está abrindo)
		//console.log('excluir', id);
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir o currículo da cesta?',
			accept: async () => {
				//console.log('aceitou');
				this.subscriptions.push(
					this.talentosApi.excluirCestaCurriculo(id).subscribe(
						res => {
							if (res) {
								const mensagem = this.ui.criarListaMensagem('success', '', 'Currículo excluído da cesta com sucesso!');
								const feedback = new FeedbackModal(true, mensagem, true);
								this.resposta.emit(feedback);
							}
						},
						err => {
							this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
						}
					)
				);
			}
		});
	}

	exportarTabela() {
		this.curriculosExportaveis = this.prepararExportacao();
		const data = moment().date() + '-' + moment().add(1, 'M').month() + '-' + moment().year();

		this.ui.exportarExcel(this.curriculosExportaveis, 'Cesta de currículos - Exportado em ' + data);
	}

	exibirModalCurriculo(item: ItemRetornoCurriculoTags) {
		this.curriculoASerExibido = item;
		//console.log('curriculoASerExibido', this.curriculoASerExibido);
		this.exibirCurriculo = true;
	}

	fecharModalCurriculo(event: boolean) {
		this.exibirCurriculo = event;
	}

	prepararAlterar(item: ItemRetornoCurriculoTags) {
		this.idCestaCurriculoSelecionado = item?.idCestaCurriculo;
		this.idCurriculoSelecionado = item?.idCurriculo;
		this.vetorNomeTags = this.ui.tratarVetorNomeTags(item.tags!);
		this.exibirModalEditarFavoritos = true;
		//console.log(item);
		//console.log('vetorNomeTags', this.vetorNomeTags);
	}

	retornoRespostaModalCesta(event: FeedbackModal) {
		//console.log('retornoRespostaModal', event);
		this.exibirModalEditarFavoritos = false;
		if (event.reload) {
			this.resposta.emit(event);
		}
	}

	paginar(event: any) {
		this.pagina = event.first / event.rows + 1;
		this.primeiroItemResultado = event.first;
		const parametrosPaginacao = new ParametrosPaginacao(this.pagina, this.itemsPorPagina);
		this.repesquisar.emit(parametrosPaginacao);
	}

	getItensPorPagina(event: any) {
		this.itemsPorPagina = event.rows;
		const parametrosPaginacao = new ParametrosPaginacao(this.pagina, this.itemsPorPagina);
		this.repesquisar.emit(parametrosPaginacao);
	}

	private prepararExportacao(): any[] {
		const listaCandidatos: any[] = [];
		if (this.curriculosSelecionados.length > 0) {
			this.curriculosSelecionados.forEach(item => {
				const candidato = {
					nome: item.nome,
					cargo: item.cargoPublico ? item.cargoPublico : '-',
					lotacao: item.lotacao ? item.lotacao : '-',
					etiquetas: this.listaTagsToString(item.tags!),
					curriculo: item.linkPublico
				};
				listaCandidatos.push(candidato);
			});
		}
		return listaCandidatos;
	}

	private listaTagsToString(tags: TagCestaCurriculo[]): string {
		let listaTags = '';
		if (tags.length > 0) {
			const ultimaTag = tags[tags.length - 1];
			tags.forEach(tag => {
				listaTags += tag.nomeTag;
				if (tag.nomeTag != ultimaTag.nomeTag) {
					listaTags += ', ';
				}
			});
		}
		return listaTags;
	}
}
