<p-dialog
	id="adicionarRequisito"
	[header]="'Currículo resumido de ' + this.nome"
	[(visible)]="exibir"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="false"
	[closable]="false"
	styleClass="modal-60vw"
	[contentStyle]="{maxHeight: '600px', overflowX: 'hidden'}"
	[baseZIndex]="10000"
	[resizable]="false"
	(onHide)="fecharModalCurriculo()"
	data-cy="modal-requisito"
	*ngIf="exibir"
>
	<div class="text-medium" *ngIf="curriculoCarregado; else skeleton">
		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Nome no currículo</p>
				<div class="text-base" *ngIf="curriculo?.nome">
					<span class="text-capitalize">{{ curriculo?.nome!.toLowerCase() }} </span>
				</div>
				<span *ngIf="curriculo?.nome === undefined">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Resumo</p>
				<div class="text-base" *ngIf="curriculo?.resumoCurriculo!.length > 0">
					<span *ngFor="let resumo of curriculo?.resumoCurriculo">{{ resumo.texto}}</span>
				</div>
				<div class="text-base" *ngIf="curriculo?.resumoCurriculo!.length === 0">Não informado</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<p class="text-bold mt-3">Experiências profissionais</p>
				<div class="text-base" *ngIf="curriculo?.experienciasProfissionais!.length > 0">
					<div class="mb-3" *ngFor="let experiencia of curriculo?.experienciasProfissionais">
						<div *ngIf="!experiencia.ocultaExperiencia">
							<div class="text-base text-capitalize">
								{{ experiencia.nomeCargo?.toLowerCase() }}
								<span class="text-lowercase" *ngIf="experiencia.nomeCargo"> em </span>
								{{ experiencia.nomeInstituicao?.toLowerCase() }}
							</div>
							<div class="text-regular">
								<span *ngIf="experiencia.mesInicio">De </span>
								{{ experiencia.mesInicio}}<span *ngIf="experiencia.mesInicio && experiencia.anoInicio">/</span
								>{{experiencia.anoInicio }}
								<span *ngIf="experiencia.mesInicio"> até </span>
								{{experiencia.mesFim}}<span *ngIf="experiencia.mesFim && experiencia.anoFim">/</span>{{experiencia.anoFim}}
								<span *ngIf="!experiencia.anoFim">o momento</span>
							</div>
							<div class="text-regular" *ngIf="experiencia.vinculo">{{ experiencia.vinculo.nome}}</div>
						</div>
					</div>
				</div>
				<div class="mb-3" *ngIf="curriculo?.experienciasProfissionais!.length === 0">Não informado</div>
			</div>
		</div>

		<div class="row mt-1">
			<div class="col-12">
				<p class="text-bold mt-3">Formação acadêmica</p>
				<div class="text-base" *ngIf="curriculo?.formacoesAcademicas!.length > 0">
					<div *ngFor="let formacao of curriculo?.formacoesAcademicas">
						<div class="mb-3" *ngIf="!formacao.ocultaFormacao && formacao.curso?.nivelCurso">
							<div class="text-base text-capitalize">
								{{ formacao.curso?.nivelCurso?.nome?.toLowerCase() }}
								<span class="text-lowercase" *ngIf="formacao.curso?.nivelCurso?.nome"> em </span>
								{{ formacao.curso?.nome?.toLowerCase()}}
							</div>
							<div class="text-regular" *ngIf="formacao.anoFim; else SemConclusao">
								Concluído <span *ngIf="formacao.anoFim"> em </span>
								{{formacao.anoFim}}
							</div>
							<ng-template #SemConclusao>
								<div class="text-regular">Não concluído</div>
							</ng-template>
						</div>
					</div>
				</div>
				<div class="mb-3" *ngIf="curriculo?.formacoesAcademicas!.length === 0">Não informado</div>
			</div>
		</div>

		<div class="row mt-1 mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Área de interesse</p>
				<div class="text-base" *ngIf="curriculo?.areasAtuacao!.length > 0">
					<div
						class="br-tag text text-base bg-blue-warm-10 text-gray-80 mr-2"
						*ngFor="let areaAtuacao of curriculo?.areasAtuacao"
					>
						<span class="text-capitalize">{{ areaAtuacao.nome?.toLowerCase() }} </span>
					</div>
				</div>
				<span *ngIf="curriculo?.areasAtuacao!.length === 0">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Área de conhecimento</p>
				<div class="text-base" *ngIf="curriculo?.areasConhecimento!.length > 0">
					<div
						class="br-tag text text-base bg-blue-warm-10 text-gray-80 mr-2"
						*ngFor="let areaConhecimento of curriculo?.areasConhecimento"
					>
						<span class="text-capitalize">{{ areaConhecimento.nome?.toLowerCase() }} </span>
					</div>
				</div>
				<span *ngIf="curriculo?.areasConhecimento!.length === 0">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Idiomas</p>
				<div class="text-base" *ngIf="curriculo?.idiomas!.length > 0">
					<div class="br-tag text text-base bg-blue-warm-10 text-gray-80 mr-2" *ngFor="let idioma of curriculo?.idiomas">
						<span class="text-capitalize">{{ idioma.nome?.toLowerCase() }} </span>
					</div>
				</div>
				<span *ngIf="curriculo?.idiomas!.length === 0">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">UF</p>
				<div class="text-base" *ngIf="curriculo?.ufOrgao">
					<span class="text-capitalize">{{ curriculo?.ufOrgao }} </span>
				</div>
				<span *ngIf="curriculo?.ufOrgao === undefined">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Município</p>
				<div class="text-base" *ngIf="curriculo?.nomeMunicipio!.length > 0">
					<span class="text-capitalize" *ngFor="let municipio of curriculo?.nomeMunicipio">{{ municipio.nome }} </span>
				</div>
				<span *ngIf="curriculo?.nomeMunicipio!.length === 0">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Órgão de Exercício</p>
				<div class="text-base" *ngIf="curriculo?.orgaoExercicio">
					<span class="text-capitalize">{{ curriculo?.orgaoExercicio!.toLowerCase() }} </span>
				</div>
				<span *ngIf="curriculo?.orgaoExercicio === undefined">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">UORG</p>
				<div class="text-base" *ngIf="curriculo?.uorgs!.length > 0">
					<div class="br-tag text text-base bg-blue-warm-10 text-gray-80 mr-2" *ngFor="let item of curriculo?.uorgs">
						<span>{{ item.siglaUorg?.toUpperCase() }} - </span>
						<span>{{ item.nomeUorg?.toUpperCase() }} </span>
					</div>
				</div>
				<span *ngIf="curriculo?.uorgs!.length === 0">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Órgão de Lotação</p>
				<div class="text-base" *ngIf="curriculo?.lotacao">
					<span class="text-capitalize">{{ curriculo?.lotacao!.toLowerCase() }} </span>
				</div>
				<span *ngIf="curriculo?.lotacao === undefined">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Cargo Público</p>
				<div class="text-base">
					<span *ngIf="curriculo?.cargoPublico; else semCargo" class="text-capitalize"
						>{{ curriculo?.cargoPublico!.toLowerCase() }}</span
					>
					<ng-template #semCargo>Sem cargo público</ng-template>
				</div>
				<span *ngIf="curriculo?.cargoPublico === undefined">Não informado</span>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Medalhas Digitais</p>
				<div class="row" *ngIf="curriculo?.selos!.length > 0">
					<div class="col-6 mb-4" *ngFor="let selo of curriculo?.selos">
						<div>
							<img style="width: 110px; height: 120px" [src]="selo.imagemSelo" [alt]="selo.nomeSelo?.toUpperCase()" />
							<div>
								<span>Emissão: {{converterParaData(selo.dataSelo)}}</span><br />
								<span class="text-capitalize">{{selo.nomeInstituicaoEmissora}}</span><br />
								<span> {{selo.urlSelo}} </span>
							</div>
						</div>
					</div>
				</div>
				<span *ngIf="curriculo?.selos!.length === 0">Não informado</span>
			</div>
		</div>
	</div>

	<!-- loading -->
	<ng-template #skeleton>
		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Nome no currículo</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Resumo</p>
				<p-skeleton width="80rem" height="2rem"></p-skeleton>
				<p-skeleton width="80rem" height="2rem"></p-skeleton>
				<p-skeleton width="80rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<p class="text-bold mt-3">Experiências profissionais</p>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
			</div>
		</div>

		<div class="row mt-1">
			<div class="col-12">
				<p class="text-bold mt-3">Formação acadêmica</p>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
				<div class="mb-3"><p-skeleton width="35rem" height="2rem"></p-skeleton></div>
			</div>
		</div>

		<div class="row mt-1 mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Área de interesse</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Área de conhecimento</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Idiomas</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">UF</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Município</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Órgão de Exercício</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">UORG</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Órgão de Lotação</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Cargo Público</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-12">
				<p class="text-bold mt-3">Medalhas Digitais</p>
				<p-skeleton width="10rem" height="2rem"></p-skeleton>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="footer">
		<p-button
			(click)="gerarPdfCurriculo(this.cpf, this.nome)"
			icon="fa fa-file"
			[label]="labelBotaoDownload"
			[disabled]="gerandoPDF"
			styleClass="br-button secondary mr-2"
			data-cy="botao-download-curriculo"
		></p-button>
		<p-button
			(click)="fecharModalCurriculo()"
			label="Fechar"
			styleClass="br-button primary"
			data-cy="botao-cancelar-requisito"
		></p-button>
		<ng-content select="[botaoAdicionar]"></ng-content>
	</ng-template>
</p-dialog>
