import { Injectable } from '@angular/core';
import { OportunidadesApiService } from './oportunidades-api.service';
import { PerfisEnum } from '@core/enums/perfis.enum';
import { UserService } from '@sigepe/ngx-sigepe-seguranca';
/**
 * Service com métodos úteis de interface
 */
@Injectable({
	providedIn: 'root'
})
export class UsuariosService {
	dadosUsuario: any;

	constructor(private userService: UserService, private oportunidadesService: OportunidadesApiService) {
		this.userService.userData$.subscribe(async data => {
			if (data) {
				this.dadosUsuario = data;
			}
		});
	}

	async isAutorizador() {
		return this.dadosUsuario.perfis.includes(PerfisEnum.AUTORIZADOR);
	}

	async isAutorizadorAntesReformaAdministrativa() {
		return this.dadosUsuario.perfis.includes(PerfisEnum.AUTORIZADOR_REFORMA);
	}

	async isGestor() {
		return this.dadosUsuario.perfis.includes(PerfisEnum.GESTOR);
	}

	async isGestorAntesReformaAdministrativa() {
		return this.dadosUsuario.perfis.includes(PerfisEnum.GESTOR_REFORMA);
	}

	async isGestorGeral() {
		return this.dadosUsuario.perfis.includes(PerfisEnum.GESTOR_GERAL);
	}

	isMesmoOrgaoUsuario(codigoOrgao: string) {
		return this.dadosUsuario.codigoOrgaoSiape === codigoOrgao;
	}

	isMesmaUorgUsuario(codigoUorg: number) {
		return this.dadosUsuario.codigoUorgSiape === codigoUorg.toString();
	}

	getCodigoUnidadeUsuario(): string {
		return this.dadosUsuario.codigoUorgSiape;
	}

	getCodigoOrgaoUsuario(): string {
		return this.dadosUsuario.codigoOrgaoSiape;
	}

	getDadosUsuario(): any {
		return this.dadosUsuario;
	}

	async getOrgaoUsuario() {
		return this.oportunidadesService.consultarOrgao(this.dadosUsuario.codigoOrgaoSiape.toString()).toPromise();
	}
}
