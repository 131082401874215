import { Component, OnDestroy, OnInit } from '@angular/core';

import { MensagemAlerta } from '@app/core/models/MensagemAlerta';
import { MessageService } from 'primeng/api';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'sgp-exibir-mensagem-alerta',
	templateUrl: './exibir-mensagem-alerta.component.html'
})
export class MensagemAlertaComponent implements OnInit, OnDestroy {
	exibirMensagemAlerta = false;
	subscriptions: Subscription[] = [];
	mensagemAlerta?: MensagemAlerta;

	constructor(private oportunidadeApi: OportunidadesApiService, private messageService: MessageService) {}

	ngOnInit(): void {
		this.chamarMensagemAlerta();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	chamarMensagemAlerta() {
		this.exibirMensagemAlerta = false;
		this.subscriptions.push(
			this.oportunidadeApi.obterMensagemAlertaAtiva().subscribe(mensagemTipo => {
				if (mensagemTipo && mensagemTipo.mensagem) {
					this.mensagemAlerta = mensagemTipo;
					this.exibirMensagemAlerta = true;
				}
			})
		);
	}

	esconderMensagemAlerta() {
		this.exibirMensagemAlerta = false;
	}
}
