export class GravarAlterarLaudo {
	id?: number;
	emitirLaudo?: boolean;
	analiseCurricular?: string;
	resultadoQuestionario?: string;
	sugestaoAlocacao?: string;
	comentariosAnaliseEixo?: AnaliseEixo[];
}

export class AnaliseEixo {
	id?: number;
	texto?: string;
}
