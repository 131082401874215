import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { InstituicaoAcademica } from '@core/models/instituicao.academica';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class InstituicaoAcademicaApiService {
	constructor(private http: HttpClient) {}

	URL_INSTITUICAO = environment.HOST_OPORTUNIDADE + '/' + 'instituicao';

	obterInstituicoes(instituicao: InstituicaoAcademica, pagina: number, tamanho: number): Observable<any[]> {
		let params = new HttpParams();

		if (instituicao.nome) {
			params = params.set('nome', instituicao.nome);
		}

		if (instituicao.ativo) {
			params = params.set('ativo', instituicao.ativo ? 1 : 0);
		}

		if (instituicao.incluido_usuario) {
			params = params.set('incluido_usuario', instituicao.incluido_usuario ? 1 : 0);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any[]>(this.URL_INSTITUICAO, { params: params });
	}

	obterInstituicao(id: number): Observable<InstituicaoAcademica> {
		const url = this.URL_INSTITUICAO + '/' + id.toString();
		return this.http.get<InstituicaoAcademica>(url);
	}

	incluirInstituicao(instituicao: InstituicaoAcademica) {
		return this.http.post(this.URL_INSTITUICAO, instituicao);
	}

	alterarInstituicao(id: number, instituicao: InstituicaoAcademica) {
		const url = this.URL_INSTITUICAO + '/' + id.toString();
		return this.http.put(url, instituicao);
	}

	excluirInstituicao(id: number) {
		const url = this.URL_INSTITUICAO + '/' + id.toString();
		return this.http.delete(url);
	}
}
