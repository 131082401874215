<div class="br-input">
	<label for="id" *ngIf="exibirLabel">{{label}}</label>
	<p-autoComplete
		#autoCompleteObject
		[id]="id"
		[(ngModel)]="ngModel"
		[suggestions]="filteredOrgaos"
		(completeMethod)="filtrarOrgaos($event)"
		field="name"
		dropdownAriaLabel="name"
		[minLength]="3"
		[dropdown]="true"
		[attr.required]="required"
		[forceSelection]="true"
		placeholder="Comece a escrever o nome do órgão para exibir as sugestões"
		[showEmptyMessage]="true"
		emptyMessage="Nenhuma opção encontrada"
		(onSelect)="atualizarNgModel($event)"
		(onClear)="atualizarNgModel($event)"
	></p-autoComplete>
	<span *ngIf="mensagemCampoObrigatorio" class="feedback danger mt-1" role="alert">
		<i class="fas fa-times-circle" aria-hidden="true"></i><span>Campo obrigatório</span>
	</span>
</div>
