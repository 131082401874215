<p-messages [closable]="false"></p-messages>

<div id="listar-etapas" *ngIf="isListar()">
	<div class="br-table">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">Etapas do cronograma</div>
				<button
					class="primary mt-3 mt-sm-0 ml-sm-3"
					(click)="prepararCadastrarEtapa()"
					type="button"
					pButton
					data-cy="botao-adicionar-etapa"
				>
					Nova etapa
				</button>
			</div>
		</div>
		<p-table [value]="etapas" dataKey="id" [rowHover]="true" [responsive]="true" [paginator]="true" [rows]="10" data-cy="tabela-etapas">
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-etapa" data-cy="cabecalho-etapa">Etapa</th>
					<th id="cabecalho-periodo" data-cy="cabecalho-periodo">Período</th>
					<th id="cabecalho-acoes" data-cy="cabecalho-acoes" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-etapa>
				<tr>
					<td headers="cabecalho-etapa" data-cy="nome-etapa">{{etapa.tipoEtapaEdital.descricao}}</td>
					<td headers="cabecalho-etapa" data-cy="datafim-etapa">
						{{etapa.dataInicioEtapa}}
						<span *ngIf="etapa.dataInicioEtapa && etapa.dataFimEtapa; else tracinho"> até </span>
						<ng-template #tracinho>-</ng-template>
						{{etapa.dataFimEtapa}}
					</td>
					<td headers="cabecalho-acoes">
						<button
							pButton
							class="br-button circle"
							title="Alterar"
							(click)="prepararAlterarEtapa(etapa.id)"
							data-cy="botao-alterar-etapa-etapa.id"
							icon="fas fa-edit"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir"
							(click)="excluirEtapa(etapa.id)"
							data-cy="botao-excluir-etapa"
							icon="fas fa-trash-alt"
							[disabled]="etapa.desabilitarExclusao"
						></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5" data-cy="mensagem-nenhuma-etapa">Nenhuma etapa cadastrada.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<div id="cadastrar-etapas" *ngIf="isManter()">
	<sgp-cadastrar-etapas
		[idEdital]="edital.id"
		[idEtapa]="idEtapaSelecionada"
		[operacao]="operacao"
		[situacao]="edital.situacao"
		[etapasCadastradas]="etapas"
		(salvaComSucesso)="salvoComSucesso()"
		(obterOperacao)="cancelar($event)"
	></sgp-cadastrar-etapas>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
