<p-dialog
	id="buscaUnidade"
	header="Buscar unidade organizacional"
	[(visible)]="showModal"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-60vw"
	[style]="{ minHeight: '350px'}"
>
	<div class="row">
		<div class="col-12 br-input mt-2 mr-2" [ngClass]="{'danger': erroTamanhoBusca}">
			<div class="p-inputgroup">
				<input
					pInputText
					type="text"
					id="busca-unidade"
					[(ngModel)]="unidadeQuery"
					data-cy="campo-unidade-procurado"
					minlength="5"
					placeholder="Escreva a unidade desejada. Ex: Coordenação"
				/>
				<button
					type="button"
					pButton
					pRipple
					icon="fas fa-search"
					styleClass="p-button-warn ml-2"
					label="Buscar"
					(click)="pesquisar()"
					data-cy="botao-busca"
				></button>
			</div>
			<sgp-message-feedback
				[show]="erroTamanhoBusca"
				mensagem="O termo da busca deve possuir no mínimo 3 caracteres"
				data-cy="erro-tamanho-termo"
			></sgp-message-feedback>
		</div>
	</div>

	<div class="text-center" *ngIf="loading">Carregando...</div>

	<div class="row" *ngIf="metadado.total >= 0">
		<div class="col-12 mt-3">
			<label for="lista-conhecimentos" *ngIf="metadado.total > 0"
				>Selecione uma das {{ listaUnidades.length }} unidades encontradas abaixo:</label
			>
			<p-table
				[value]="listaUnidades"
				dataKey="codigoSiape"
				[rowHover]="true"
				[responsive]="true"
				[autoLayout]="true"
				selectionMode="single"
				[(selection)]="unidadeSelecionada"
				[scrollable]="true"
				scrollHeight="150px"
				data-cy="tabela-areas-conhecimento"
			>
				<ng-template pTemplate="body" let-area>
					<tr [pSelectableRow]="area">
						<td headers="cabecalho-tipo" data-cy="tipo-anexo">{{ area.nome }}</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5" data-cy="mensagem-nenhum-anexo">Nenhuma unidade encontrada com este termo.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<sgp-message-feedback [show]="erroMensagemObrigatorio" mensagem="Campo obrigatório" data-cy="erro-conhecimento"></sgp-message-feedback>

	<ng-template pTemplate="footer">
		<p-button label="Cancelar" (click)="esconderModal()" styleClass="br-button secondary"></p-button>
		<p-button label="Selecionar unidade" (click)="selecionarUnidade()" styleClass="br-button primary"></p-button>
	</ng-template>
</p-dialog>
