<p-messages [enableService]="true" [closable]="false"></p-messages>

<!-- Ver rabiscoframe em https://www.figma.com/board/4Iv1XVq3nAOp0pEuoNxWre/Mini-Inception---Perfil-profissiogr%C3%A1fico?node-id=1060-1382&t=WY2zmuzum6bs4zN6-0 -->

<div class="row mb-3">
	<div class="col-sm-12 col-md-7 pt-1">
		<div data-cy="titulo-pagina" class="text-up-04 text-capitalize">Olá, {{ nomeUsuario?.toLowerCase() }}</div>
		<div data-cy="titulo-pagina" class="text-up-01 text-capitalize text-gray-40">
			{{ (this.orgaoUsuario$ | async)?.nome.toLowerCase() }}
		</div>
	</div>
</div>
<!-- Cabeçalho com nome e nome do órgão (se for gestor pessoas) -->

<sgp-filtro-dashboard-profissiografico />

<div *ngIf="dashboardService.renderizarDados">
	<!-- minicard | minicard -->
	<div id="mini-cards" class="row">
		<div class="col-sm-12">
			<div class="row">
				<div class="col-sm-12 col-md-6">
					<sgp-mini-card
						cor="azul_escuro"
						icone="fa-clipboard-check"
						descricao="Total de questionarios enviados"
						[ajuda]="false"
						[quantidade]="dashboardService.resultado.totalQuestionariosEnviados || '0'"
					></sgp-mini-card>
				</div>
				<div class="col-sm-12 col-md-6">
					<sgp-mini-card
						cor="azul_claro"
						icone="fa-file"
						descricao="Total de relatórios priorizados e não emitidos"
						[ajuda]="false"
						[quantidade]="dashboardService.resultado.totalLaudosPriorizados || '0'"
					></sgp-mini-card>
				</div>
			</div>
		</div>
	</div>
	<!-- card grafico bar vertical | card gráfico pizza -->

	<div id="graficos" class="row">
		<div class="col-sm-12">
			<div class="row">
				<div class="col-md-6">
					<sgp-card-bar-horizontal
						titulo="Situação de liberação dos questionários"
						[pagina]="paginaSituacaoLiberacaoQuestionario"
						[dados]="dashboardService.resultado.situcaoLiberacaoQuestionario"
						(anterior)="navegerSituacaoLiberacaoQuestionario($event)"
						(proximo)="navegerSituacaoLiberacaoQuestionario($event)"
					>
					</sgp-card-bar-horizontal>
				</div>
				<div class="col-md-6">
					<sgp-card-grafico-doughnut
						titulo="Situação dos relatórios"
						tipoGrafico="doughnut"
						[dados]="dashboardService.resultado.situacaoLaudos"
						[height]="268"
					></sgp-card-grafico-doughnut>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<sgp-card-grafico-doughnut
						titulo="Situação de ciência de relatórios emitidos"
						[dados]="dashboardService.resultado.situacaoCienciaLaudos"
						tipoGrafico="doughnut"
						[height]="268"
					></sgp-card-grafico-doughnut>
				</div>
				<div class="col-md-6">
					<sgp-card-grafico-doughnut
						titulo="Situação de atribuição de relatórios"
						[dados]="dashboardService.resultado.situacaoAtribuicaoLaudos"
						tipoGrafico="doughnut"
						[height]="268"
					></sgp-card-grafico-doughnut>
				</div>
			</div>
		</div>
	</div>
	<!-- card grafico pizza | card gráfico pizza -->
</div>
