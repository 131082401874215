<div *ngIf="(detalharLaudoService.detalharLaudo$ | async) as laudo">
	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Relatório do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<div class="row">
				<div class="col">
					<div>
						<span class="text-semi-bold">Pesquisador responsável: </span>{{laudo.responsavelLaudo | imprimirDash | titlecase }}
					</div>
					<div class="mt-2">
						<span class="text-semi-bold">Situação da priorização: </span>
						<span *ngIf="laudo.dataSolicitacaoPrioridade; else naoPriorizado">Priorizado</span>
						<ng-template #naoPriorizado>Não priorizado</ng-template>
					</div>
					<div class="mt-2"><span class="text-semi-bold">Situação do relatório: </span>{{laudo.situacao?.descricao}}</div>
					<div class="mt-2">
						<span class="text-semi-bold">Ciência por parte do gestor do órgão: </span>
						<span *ngIf="laudo.dataCienciaLaudo; else semCiencia">{{laudo.dataCienciaLaudo | date: 'dd/MM/YYYY'}}</span>
						<ng-template #semCiencia>
							<span>--</span>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Resumo do perfil do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<div class="text-semi-bold mt-2">Resumo do currículo:</div>
			<div class="mt-2">
				<div *ngIf="laudo.resumoCurriculo; else semResumo">
					{{laudo.resumoCurriculo}}
					<div class="pt-2x text-down-01">* Informações geradas com base nos dados do currículo da(o) candidata(a).</div>
				</div>
				<ng-template #semResumo>
					<p class="text-base">Não foi possível gerar resumo para o currículo.</p>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Resumo da avaliação do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<img
				[src]="detalharLaudoService.radar"
				alt="Imagem gerada gráfico de radar contendo as características do candidato"
				*ngIf="detalharLaudoService.radar; else semRadar"
			/>
			<ng-template #semRadar>
				<p class="text-base">Não foi possível gerar o gráfico de radar.</p>
			</ng-template>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Eixos de Avaliação</span>
			</div>
		</div>
		<div class="card-content" *ngFor="let comentarioAnaliseEixo of laudo.comentariosAnaliseEixo">
			<div class="label">{{comentarioAnaliseEixo.eixoAvaliacao?.nome }}</div>
			{{ comentarioAnaliseEixo.texto }}
		</div>
		<div class="card-content text-down-01">
			* As competências transversais da Adm. Pública Federal podem ser encontradas na Instrução Normativa da Política Nacional de
			Desenvolvimento de Pessoas - PNDP: <a href="https://l1nk.dev/X9OgX"> https://l1nk.dev/X9OgX</a>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Conclusão do Relatório</span>
			</div>
		</div>
		<div class="card-content">
			<div class="label">Análise Curricular</div>
			{{ laudo.analiseCurricular }}
			<br /><br />
			<div class="label">Resultado do Questionário</div>
			{{ laudo.resultadoQuestionario }}
			<br /><br />
			<div class="label">Sugestão de Alocação</div>
			{{ laudo.sugestaoAlocacao }}
		</div>
	</div>
</div>
