import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

import { Candidato } from '@core/models/candidato';
import { CandidatoPlanilha } from '@app/visualizarEdital/models/candidato-planilha';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { VisualizarEditalApiService } from '../services/visualizar-edital-api.service';
import moment from 'moment';

@Component({
	selector: 'sgp-visualizar-candidatos',
	templateUrl: './visualizar-candidatos.component.html',
	styleUrls: ['./visualizar-candidatos.component.scss'],
	providers: [MessageService]
})
export class VisualizarCandidatosComponent implements OnChanges, OnDestroy {
	@Input() idOportunidade!: number;
	@Input() nomeOportunidade!: string;
	@Input() codigoOrgao!: string;
	@Input() usuarioMesmoOrgao = false;
	@Input() situacaoEdital!: string;
	@Input() numeroEdital?: string;
	@Input() editalArquivado = false;
	@Output() gerandoDocumento: EventEmitter<boolean> = new EventEmitter<boolean>();
	nomeOrgao?: string;
	candidatos: Candidato[] = [];
	subscriptions: Subscription[] = [];
	candidatosSelecionados: Candidato[] = [];
	candidatosExportados: CandidatoPlanilha[] = [];
	mensagens: Message[] = [];
	editalComSituacaoFinalistica = false;
	desabilitarDownloadTodosAnexos = false;
	exibirModalNotificacao = false;

	candidatoDetalhado?: Candidato;
	operacao = 'listar';

	constructor(
		private oportunidadesService: OportunidadesApiService,
		private ui: UiService,
		private visualizarEditalApi: VisualizarEditalApiService
	) {}

	ngOnChanges(): void {
		this.obterCandidatos();
		this.verificarSeEditalTemSituacaoFinalistica();
		this.obterNomeOrgao();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	baixarMultiplosCurriculos() {
		if (this.editalArquivado) {
			this.mensagens = this.ui.criarListaMensagem(
				'error',
				'',
				'Arquivo(s) não mais disponível(is) - edital encerrado ou cancelado há mais de dois anos.'
			);
		} else {
			if (this.candidatosSelecionados.length > 1) {
				const idsCandidatos: number[] = [];
				for (const candidato of this.candidatosSelecionados) {
					if (candidato.id) {
						idsCandidatos.push(candidato.id);
					}
				}

				this.subscriptions.push(
					this.visualizarEditalApi.visualizarMultiplosCurriculos(this.idOportunidade, idsCandidatos).subscribe(
						response => {
							const data = moment().date() + '-' + moment().add(1, 'M').month() + '-' + moment().year();
							const nomeArquivo =
								'Currículos de candidatos a ' + this.nomeOportunidade + ' - Edital ' + this.numeroEdital + ' - Em ' + data;
							this.ui.downloadZip(response, 'application/zip', nomeArquivo);
							this.mensagens = [];
						},
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						err => {
							this.mensagens = this.ui.criarListaMensagem(
								'error',
								'',
								'Não foi possível realizar o download do(s) arquivo(s)'
							);
						}
					)
				);
			}
		}
	}

	baixarMultiplosAnexos() {
		if (this.editalArquivado) {
			this.mensagens = this.ui.criarListaMensagem(
				'error',
				'',
				'Arquivo(s) não mais disponível(is) - edital encerrado ou cancelado há mais de dois anos.'
			);
		} else {
			if (this.candidatosSelecionados.length >= 1) {
				const idsCandidatos: number[] = [];
				for (const candidato of this.candidatosSelecionados) {
					if (candidato.id) {
						idsCandidatos.push(candidato.id);
					}
				}
				this.subscriptions.push(
					this.visualizarEditalApi.baixarAnexosDeMultiplosCandidatos(this.idOportunidade, idsCandidatos).subscribe(
						response => {
							this.ui.downloadResponse(response);
							this.mensagens = [];
						},
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						err => {
							this.mensagens = this.ui.criarListaMensagem(
								'error',
								'',
								'Não foi possível realizar o download do(s) arquivo(s)'
							);
						}
					)
				);
			}
		}
	}

	enviarNotificacaoMultiplosCandidatos() {
		this.exibirModalNotificacao = true;
	}

	fecharModalNotificacao(event: boolean) {
		this.exibirModalNotificacao = event;
	}

	exibirMensagemSucessoNotificacao(event: Message[]) {
		this.mensagens = event;
	}

	exportarTabela() {
		this.prepararExportarCandidatos();
		const data = moment().date() + '-' + moment().add(1, 'M').month() + '-' + moment().year();

		this.ui.exportarExcel(
			this.candidatosExportados,
			'Candidatos a ' + this.nomeOportunidade + ' - Edital ' + this.numeroEdital + ' - Exportado em ' + data
		);
	}

	marcarDesmarcarFavorito(idCandidato: number) {
		if (!this.editalComSituacaoFinalistica) {
			this.subscriptions.push(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				this.visualizarEditalApi.marcarCandidatoFavorito(idCandidato).subscribe(res => {
					const candidato = this.candidatos.find(x => x.id == idCandidato);
					candidato!.favorito = !candidato!.favorito;
				})
			);
		}
	}

	baixarCurriculo(idCandidato: number) {
		this.subscriptions.push(
			this.visualizarEditalApi
				.visualizarCurriculo(this.idOportunidade, idCandidato)
				.subscribe(response => this.ui.downloadFile(response, 'application/pdf'))
		);
	}

	marcarDesmarcarAnalisado(idCandidato: number) {
		if (!this.editalComSituacaoFinalistica) {
			this.subscriptions.push(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				this.visualizarEditalApi.marcarCandidatoAnalisado(idCandidato).subscribe(res => {
					const candidato = this.candidatos.find(x => x.id == idCandidato);
					candidato!.marcado = !candidato!.marcado;
				})
			);
		}
	}

	detalharCandidato(candidato: Candidato) {
		this.candidatoDetalhado = candidato;
		this.operacao = 'detalhar';
	}

	exibirLista() {
		this.candidatoDetalhado = undefined;
		this.operacao = 'listar';
		this.obterCandidatos();
	}

	private obterCandidatos() {
		if (this.usuarioMesmoOrgao) {
			this.subscriptions.push(
				this.visualizarEditalApi.getCandidatosOportunidade(this.idOportunidade).subscribe(res => {
					this.candidatos = res;
				})
			);
		}
	}

	private verificarSeEditalTemSituacaoFinalistica() {
		if (this.situacaoEdital === 'ENC' || this.situacaoEdital === 'SUS' || this.situacaoEdital === 'CAN') {
			this.editalComSituacaoFinalistica = true;
		} else {
			this.editalComSituacaoFinalistica = false;
		}
	}

	private prepararExportarCandidatos() {
		let listaCandidatos: Candidato[] = [];
		this.candidatosExportados = [];
		if (this.candidatosSelecionados.length > 0) {
			listaCandidatos = this.candidatosSelecionados;
		} else {
			listaCandidatos = this.candidatos;
		}

		for (const candidato of listaCandidatos) {
			const candidatoJson = new CandidatoPlanilha(
				this.ui.capitalize(candidato.nomeCandidato!),
				candidato.emailCandidato?.toLowerCase(),
				candidato.dataHoraInscricao,
				candidato.nomeVinculo,
				this.ui.capitalize(candidato.localidadeResidencia!),
				candidato.quantidadeAnexos,
				candidato.favorito,
				candidato.marcado
			);
			this.candidatosExportados.push(candidatoJson);
		}
	}

	private obterNomeOrgao() {
		this.subscriptions.push(
			this.oportunidadesService.consultarOrgao(this.codigoOrgao).subscribe(
				res => {
					this.nomeOrgao = res.nome;
				},
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				err => {
					this.nomeOrgao = 'Não foi possível obter o nome do órgão';
				}
			)
		);
	}
}
