import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { AcoesEnum } from '@core/enums/acoes.enum';
import { AcoesService } from '@core/services/acoes.service';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { Message } from 'primeng/api';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@core/enums/perfis.enum';
import { Router } from '@angular/router';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { UsuariosService } from '@core/services/usuarios.service';

@Component({
	selector: 'sgp-visualizar-mensagem-alerta',
	templateUrl: './visualizar-mensagem-alerta.component.html'
})
export class VisualizarMensagemAlertaComponent implements AfterViewInit, OnDestroy {
	@Input() edital!: Edital;
	@Input() texto?: string;
	@Output() mensagemFeedback: EventEmitter<Message> = new EventEmitter<Message>();
	@Output() editalAtualizado: EventEmitter<Edital> = new EventEmitter<Edital>();
	situacao?: string;
	exibeAlerta = false;
	exibirModalRejeitarPublicacao = false;
	exibirModalRejeitarRetificacao = false;
	subscriptions: Subscription[] = [];

	labelBotaoAutorizar = 'Autorizar';

	alertas?: any[];
	alerta: any;

	constructor(
		private acoesService: AcoesService,
		private oportunidadesService: OportunidadesApiService,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private ui: UiService,
		private usuarioService: UsuariosService,
		private router: Router
	) {}

	ngAfterViewInit() {
		this.getAlerta();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public get situacaoEnum(): typeof SituacoesEnum {
		return SituacoesEnum;
	}

	get acoesEnum(): typeof AcoesEnum {
		return AcoesEnum;
	}

	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}

	/**
	 * Fecha o modal de acordo com o tipo de ação
	 * @param event tipo de ação
	 */
	fecharModal(event: string) {
		if (event == 'RejeitarPublicacao') {
			this.exibirModalRejeitarPublicacao = false;
		} else if (event == 'RejeitarRetificacao') {
			this.exibirModalRejeitarRetificacao = false;
		}
		this.router.navigate(['/edital', this.edital.id]);
	}

	atualizarEdital(event: Edital) {
		this.edital = event;
		this.editalAtualizado.emit(this.edital);
		this.getAlerta();
	}

	/**
	 * Tipos de alertas possíveis e suas propriedades
	 * @returns lista de alertas
	 */
	private obterAlertas(): any[] {
		return [
			{
				situacao: this.situacaoEnum.PSO,
				titulo: 'Publicação solicitada',
				mensagem: this.mensagemPublicacaoSolicitadaGestor(),
				autorizacao: false
			},
			{
				situacao: this.situacaoEnum.PSO,
				titulo: 'Publicação solicitada',
				mensagem: 'Revise os dados do edital antes de autorizar ou rejeitar a publicação.',
				autorizacao: true,
				perfis: [this.perfisEnum.AUTORIZADOR],
				rejeitar: () => {
					this.rejeitarPublicacao();
				},
				autorizar: () => {
					this.executarAcao(this.acoesEnum.OPO_AUTORIZAR_PUBLICACAO_EDITAL);
				}
			},
			{
				situacao: this.situacaoEnum.RET,
				titulo: 'Em retificação',
				mensagem: 'Este edital está em retificação.',
				autorizacao: false
			},
			{
				situacao: this.situacaoEnum.PRS,
				titulo: 'Retificação solicitada',
				mensagem: this.mensagemRetificacaoSolicitadaGestor(),
				autorizacao: false
			},
			{
				situacao: this.situacaoEnum.PRS,
				titulo: 'Retificação solicitada',
				mensagem: 'Revise o texto da retificação abaixo e os dados do edital antes de autorizar ou rejeitar a publicação.',
				texto: this.getTextoRetificacao(),
				autorizacao: true,
				perfis: [this.perfisEnum.AUTORIZADOR, this.perfisEnum.AUTORIZADOR_REFORMA],
				rejeitar: () => {
					this.rejeitarPublicacaoRetificacao();
				},
				autorizar: () => {
					this.executarAcao(this.acoesEnum.OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO);
				}
			},
			{
				situacao: this.situacaoEnum.SUS,
				titulo: 'Edital suspenso',
				mensagem: 'Este edital está temporariamente suspenso.',
				autorizacao: false
			},
			{
				situacao: this.situacaoEnum.CAN,
				titulo: 'Edital cancelado',
				mensagem: 'Este edital foi cancelado.',
				autorizacao: false
			}
		];
	}

	private mensagemRetificacaoSolicitadaGestor() {
		if (this.edital.historico && this.edital.historico.length > 0) {
			const nomeAutorizadora = this.edital.historico[this.edital.historico.length - 1].nomeUnidadeAutorizadora;
			return (
				'Este edital teve a retificação solicitada junto à Unidade "' +
				nomeAutorizadora +
				'"  e está aguardando autorização para ser publicado.'
			);
		}
		return '';
	}

	private mensagemPublicacaoSolicitadaGestor(): string {
		if (this.edital.historico && this.edital.historico.length > 0) {
			const nomeAutorizadora = this.edital.historico[this.edital.historico.length - 1].nomeUnidadeAutorizadora;
			return (
				'Este edital teve a publicação solicitada junto à Unidade "' +
				nomeAutorizadora +
				'" e está aguardando autorização para ser publicado.'
			);
		}
		return '';
	}

	/**
	 * Obter alerta de acordo com a situação do edital e o permissão do usuário
	 */
	private getAlerta() {
		this.exibeAlerta = false;
		if (this.edital.situacao.codigo != undefined) {
			this.alertas = this.obterAlertas();
			this.alertas.forEach(alerta => {
				if (alerta.situacao === this.edital.situacao.codigo) {
					if (
						alerta.autorizacao &&
						(this.edital.situacao.codigo === SituacoesEnum.PSO || this.edital.situacao.codigo === SituacoesEnum.PRS)
					) {
						this.verificarPermissaoAcoes(alerta);
					} else {
						this.alerta = alerta;
						this.exibeAlerta = true;
					}
				}
			});
		}
	}

	private verificarPermissaoAcoes(alerta: any) {
		this.subscriptions.push(
			this.oportunidadesService.consultarAcoesEdital(this.edital.id).subscribe(async data => {
				if (data.length > 0) {
					this.alerta = alerta;
					this.exibeAlerta = true;
				}
			})
		);
	}

	/**
	 * Retorna o último texto de retificação do usuário, baseado no histórico.
	 * @returns texto da retificação
	 */
	private getTextoRetificacao(): any {
		if (this.edital.situacao.codigo === this.situacaoEnum.PRS && this.edital.historico) {
			const ultimoHistorico = this.edital.historico[this.edital.historico.length - 1];
			return ultimoHistorico.textoRetificacao?.replace(/^(javascript\\:)/, '');
		}
		return '';
	}

	private rejeitarPublicacao() {
		this.exibirModalRejeitarPublicacao = true;
	}

	private rejeitarPublicacaoRetificacao() {
		this.exibirModalRejeitarRetificacao = true;
	}

	private executarAcao(tipoAcao: AcoesEnum) {
		this.labelBotaoAutorizar = 'Aguarde...';
		const params = this.acoesService.montarParametrosAcao(tipoAcao);
		this.subscriptions.push(
			this.oportunidadesService.executarAcaoEdital(this.edital.id, params).subscribe(
				async res => {
					this.labelBotaoAutorizar = 'Autorizado';
					this.mensagemFeedback.emit(this.ui.criarMensagem('success', 'Publicação realizada com sucesso!', ''));
					this.converterInterfaceEdital.converter(this.edital, await res);
					this.editalAtualizado.emit(this.edital);
				},
				err => {
					this.labelBotaoAutorizar = 'Autorizar';
					this.mensagemFeedback.emit(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}
}
