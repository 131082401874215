<div class="row">
	<div class="col-12 col-md-6 col-lg-8">
		<h4 data-cy="titulo-oportunidades">Oportunidades</h4>
		<div *ngIf="edital.oportunidades?.length === 0">Nenhuma oportunidade cadastrada.</div>

		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-6" *ngFor="let oportunidade of edital.oportunidades">
				<div class="br-card">
					<div class="card-header">
						<div class="d-flex">
							<div class="mb-2">
								<p class="text-weight-semi-bold text-up-02 text-primary-default mb-0">
									<a
										role="button"
										(click)="visualizarOportunidade(oportunidade)"
										class="cursor-pointer text-decoration-none"
										data-cy="nome-oportunidade"
										>{{oportunidade.nomeOportunidade}}</a
									>
								</p>
							</div>
						</div>
					</div>
					<div class="card-content">
						<div>{{oportunidade.nomeUorg}}, {{oportunidade.nomeLocalidade}}</div>
						<div class="mt-1">Quantidade de vagas: {{oportunidade.numeroVagas}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6 col-lg-4">
		<h4 data-cy="titulo-card-etapas">Etapas do edital</h4>
		<div class="br-card">
			<div class="card-content">
				<p-timeline [value]="timeline" data-cy="timeline-etapas">
					<ng-template pTemplate="marker" let-event>
						<span class="custom-marker p-shadow-2" [style.backgroundColor]="event.color">
							<i class="fas fa-check" *ngIf="event.color === passado"></i>
							<i class="fas fa-hand-point-right" *ngIf="event.color === presente"></i>
						</span>
					</ng-template>
					<ng-template pTemplate="content" let-event>
						<small class="p-text-secondary">{{event.date}}</small>
					</ng-template>
					<ng-template pTemplate="opposite" let-event> {{event.status}} </ng-template>
				</p-timeline>
			</div>
		</div>
	</div>
</div>
