import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

/**
 * Interceptor para todos os erros de serviço do sistema, exibindo mensagem avisando caso seja um erro de comunicação com a API.
 * Fonte: https://www.ti-enxame.com/pt/angular/captura-de-erros-em-angular-httpclient/834336179/
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.error instanceof Error) {
					// A client-side or network error occurred. Handle it accordingly.
					console.error('An error occurred:', error.error.message);
				} else {
					// The backend returned an unsuccessful response code.
					// The response body may contain clues as to what went wrong,
					if (error.status === 0) {
						return throwError(
							new HttpErrorResponse({
								error: { message: 'Falha na comunicação com o servidor. Tente novamente mais tarde.' }
							})
						);
					}
				}

				return throwError(error);
			})
		);
	}
}
