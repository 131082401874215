export enum AcoesEnum {
	OPO_SOLICITAR_PUBLICACAO_EDITAL = 1,
	OPO_EXCLUIR_EDITAL = 2,
	OPO_AUTORIZAR_PUBLICACAO_EDITAL = 3,
	OPO_REJEITAR_PUBLICACAO_EDITAL = 4,
	OPO_RETIFICAR_EDITAL = 5,
	OPO_DESFAZER_RETIFICACAO_EDITAL = 6,
	OPO_SOLICITAR_PUBLICACAO_RETIFICACAO = 7,
	OPO_REJEITAR_PUBLICACAO_RETIFICACAO = 8,
	OPO_AUTORIZAR_PUBLICACAO_RETIFICACAO = 9,
	OPO_CANCELAR_EDITAL = 10,
	OPO_ENCERRAR_EDITAL = 11,
	OPO_SUSPENDER_EDITAL = 12,
	OPO_ALTERAR_EDITAL = 13,
	OPO_PUBLICAR_RESULTADO = 14
}
