import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
	selector: 'sgp-texto-ajuda',
	standalone: true,
	imports: [CommonModule, TooltipModule],
	template: `<i
			class="fas fa-info-circle cursor-pointer {{ corTexto }}"
			pTooltip="{{ texto }}"
			aria-describedby="tooltip"
			tooltipEvent="hover"
		></i>
		<span id="tooltip" class="sr-only">{{ texto }}</span> `
})
export class TextoAjudaComponent {
	@Input({ required: true }) texto!: string;
	@Input() corTexto = 'text-blue-warm-vivid-70';
}
