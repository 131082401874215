import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AreaAtuacao } from '@core/models/area-atuacao';
import { AreaConhecimento } from '@core/models/area-conhecimento';
import { AreaConhecimentoItem } from '@core/models/area-conhecimento-items';
import { Capacitacao } from '@core/models/capacitacao';
import { Certificacao } from '@core/models/certificacao';
import { EditalService } from '@app/cadastrarEdital/services/edital.service';
import { Idioma } from '@core/models/idioma';
import { NivelCurso } from '@core/models/nivel-curso';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadeCompetencia } from '@core/models/oportunidade-competencia';
import { RequisitosEdital } from '@core/models/requisitos-edital';
import { Subscription } from 'rxjs';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { TipoVinculo } from '@core/models/tipo-vinculo';
import { TiposRequisitosEdital } from '@cadastrarEdital/models/tipos-requisitos-edital';

@Component({
	selector: 'sgp-requisitos-oportunidade',
	templateUrl: './requisitos-oportunidade.component.html'
})
export class RequisitosOportunidadeComponent implements OnInit, OnDestroy {
	@Input() oportunidade!: Oportunidade;
	@Output() oportunidadeComRequisitos: EventEmitter<Oportunidade> = new EventEmitter<Oportunidade>();

	tiposRequisitos: TiposRequisitosEdital[] = [];
	requisitoSelecionado: any;
	adicionarRequisito = false;
	requisitos: RequisitosEdital = new RequisitosEdital();
	erroRequisito: Map<string, boolean> = new Map<string, boolean>();

	listaTiposVinculos: TipoVinculo[] = [];
	listaSoftskills: OportunidadeCompetencia[] = [];
	listaAreaAtuacao: AreaAtuacao[] = [];
	listaConhecimentos: AreaConhecimentoItem[] = [];
	listaCertificacoes: Certificacao[] = [];
	listaIdiomas: Idioma[] = [];
	listaNiveisFormacao: NivelCurso[] = [];
	filteredIdioma: any[] = [];
	estrelas = 3;
	subscriptions: Subscription[] = [];

	textoErro = '';

	constructor(private talentosService: TalentosApiService, private editalService: EditalService) {}

	ngOnInit() {
		this.getRequisitos();
		this.obterResidenciaPreenchida();
		this.obterOrgaoLotacaoPreenchido();
		this.obterOrgaoExercicioPreenchido();
		this.criarMensagensErro();
		this.getTiposVinculos();
		this.getSoftskills();
		this.getAreasAtuacao();
		this.getNiveisFormacao();
		this.getIdiomas();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	//Exibe o modal com o requisito selecionado
	exibirModal(chave: string) {
		this.tiposRequisitos.forEach(requisito => {
			if (requisito.chave.indexOf(chave) == 0) {
				this.requisitoSelecionado = requisito;
			}
		});
		this.limparMensagensErro();
		this.adicionarRequisito = true;
	}

	//Esconde o modal
	esconderModal() {
		this.requisitos = new RequisitosEdital();
		this.requisitoSelecionado = {};
		this.limparMensagensErro();
		this.adicionarRequisito = false;
		this.obterResidenciaPreenchida();
		this.obterOrgaoLotacaoPreenchido();
		this.obterOrgaoExercicioPreenchido();
	}

	//Seta todos as exibições das mensagens de erro para falso
	limparMensagensErro() {
		this.criarMensagensErro();
	}

	//Atualiza o valor da localidade. Necessário por causa do componente de localidade
	atualizarLocalidade(event: any) {
		this.requisitos.localidade = event;
	}

	//Atualiza o valor do órgão. Necessário por causa do componente de órgão
	atualizarOrgaoLotacao(event: any) {
		this.requisitos.orgaoLotacao = event;
	}

	//Atualiza o valor do órgão. Necessário por causa do componente de órgão
	atualizarOrgaoExercicio(event: any) {
		this.requisitos.orgaoExercicio = event;
	}

	//Atualiza área de conhecimento. Necessário por causa do componente
	updateArea(event: AreaConhecimento) {
		this.requisitos.areaConhecimento = event;
	}

	//Atualiza obrigatoriedade de área de conhecimento. Necessário por causa do componente
	updateAreaObrigatoriedade(event: boolean) {
		this.requisitos.conhecimentoObrigatorio = event;
	}

	//Filtra o combo de tipo de idioma
	filterIdioma(event: any): void {
		const filtered: any[] = [];
		const query = event.query;
		if (this.listaIdiomas) {
			for (const idioma of this.listaIdiomas) {
				if (idioma.ativo && idioma.nome!.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(idioma);
				}
			}
		}
		this.filteredIdioma = filtered;
	}

	//Atualiza o valor da oportunidade com o valor obtido componente filho (exibir requisitos)
	updateOportunidade(event: Oportunidade) {
		this.oportunidade = event;
		this.atualizarOportunidade();
	}

	private obterResidenciaPreenchida() {
		if (this.oportunidade.codigoMunicipioResidencia && this.oportunidade.nomeMunicipioResidencia != '') {
			this.requisitos.localidade = {};
			this.requisitos.localidade.code = this.oportunidade.codigoMunicipioResidencia;
			this.requisitos.localidade.name = this.oportunidade.nomeMunicipioResidencia;
		}
	}
	private obterOrgaoLotacaoPreenchido() {
		if (this.oportunidade.codigoOrgaoLotacao && this.oportunidade.nomeOrgaoLotacao != '') {
			this.requisitos.orgaoLotacao = {};
			this.requisitos.orgaoLotacao.code = this.oportunidade.codigoMunicipioResidencia?.toString();
			this.requisitos.orgaoLotacao.name = this.oportunidade.nomeOrgaoLotacao;
		}
	}
	private obterOrgaoExercicioPreenchido() {
		if (this.oportunidade.codigoOrgaoExercicio && this.oportunidade.nomeOrgaoExercicio != '') {
			this.requisitos.orgaoExercicio = {};
			this.requisitos.orgaoExercicio.code = this.oportunidade.codigoOrgaoExercicio?.toString();
			this.requisitos.orgaoExercicio.name = this.oportunidade.nomeOrgaoExercicio;
		}
	}

	//Propriedades dos botões de requisitos da oportunidade
	private getRequisitos() {
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('vinculo', 'Tipos de vínculos', 'O candidato deve ser:', () => {
				this.adicionarVinculo();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('residencia', 'Residir na localidade?', 'O candidato deve residir em:', () => {
				this.adicionarResidencia();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('orgao_lotacao', 'Órgão de lotação', 'O candidato deve estar lotado em:', () => {
				this.adicionarOrgaoLotacao();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('orgao_exercicio', 'Órgão de exercício', 'O candidato deve estar em exercício em:', () => {
				this.adicionarOrgaoExercicio();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital(
				'conhecimentos',
				'Conhecimentos técnicos',
				'O candidato deve possuir o seguinte conhecimento técnico:',
				() => {
					this.adicionarConhecimento();
				}
			)
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('softskills', 'Softskills', 'O candidato deve possuir as seguintes softskills:', () => {
				this.adicionarSoftskills();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('idiomas', 'Idiomas', 'O candidato deve saber o idioma:', () => {
				this.adicionarIdioma();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('certificacoes', 'Certificações', 'O candidato deve possuir a seguinte certificação:', () => {
				this.adicionarCertificacao();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('capacitacoes', 'Capacitações', 'O candidato deve possuir a seguinte capacitação:', () => {
				this.adicionarCapacitacao();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital(
				'formacao_academica',
				'Formação acadêmica',
				'O candidato deve possuir a seguinte formação acadêmica:',
				() => {
					this.adicionarFormacao();
				}
			)
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('experiencia', 'Experiência profissional', 'O candidato deve possuir a seguinte experiência:', () => {
				this.adicionarExperiencia();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('area_atuacao', 'Área de atuação', 'O candidato deve atuar na seguinte área:', () => {
				this.adicionarAreaAtuacao();
			})
		);
		this.tiposRequisitos.push(
			new TiposRequisitosEdital('outro', 'Outro ', 'Outro requisito necessário:', () => {
				this.adicionarOutro();
			})
		);
	}

	//Cria o mapeamento das mensagens de erro dos campos do requisito
	private criarMensagensErro() {
		this.erroRequisito.set('erroVinculo', false);
		this.erroRequisito.set('erroLocalidade', false);
		this.erroRequisito.set('erroOrgaoLotacao', false);
		this.erroRequisito.set('erroOrgaoExercicio', false);
		this.erroRequisito.set('erroConhecimento', false);
		this.erroRequisito.set('erroSoftskills', false);
		this.erroRequisito.set('erroIdioma', false);
		this.erroRequisito.set('erroCertificacao', false);
		this.erroRequisito.set('erroNivelFormacao', false);
		this.erroRequisito.set('erroNomeFormacao', false);
		this.erroRequisito.set('erroNomeExperiencia', false);
		this.erroRequisito.set('erroMesesExperiencia', false);
		this.erroRequisito.set('erroAreaAtuacao', false);
		this.erroRequisito.set('erroOutroRequisito', false);
		this.erroRequisito.set('erroCapacitacao', false);
	}

	//Atualiza o valor da oportunidade para o componente pai (cadastrar oportunidade)
	private atualizarOportunidade() {
		this.oportunidadeComRequisitos.emit(this.oportunidade);
	}

	//Obter a lista de tipos de vínculos
	private getTiposVinculos() {
		this.subscriptions.push(
			this.talentosService.tipoVinculo().subscribe(dados => {
				if (dados) {
					this.listaTiposVinculos = dados;
				}
			})
		);
	}

	//Obter a lista de tipos de softskills
	private getSoftskills() {
		this.subscriptions.push(
			this.talentosService.competencias().subscribe(dados => {
				if (dados) {
					this.listaSoftskills = dados.items;
				}
			})
		);
	}

	//Obter a lista de tipos de area de atuação
	private getAreasAtuacao() {
		this.subscriptions.push(
			this.talentosService.areasAtuacao().subscribe(dados => {
				if (dados) {
					this.listaAreaAtuacao = dados;
				}
			})
		);
	}

	//Obter a lista de tipos de niveis de formação
	private getNiveisFormacao() {
		this.subscriptions.push(
			this.talentosService.niveisCurso('FOR').subscribe(dados => {
				if (dados) {
					this.listaNiveisFormacao = dados;
				}
			})
		);
	}

	//Obter a lista de tipos de idiomas
	private getIdiomas() {
		this.subscriptions.push(
			this.talentosService.idiomas().subscribe(dados => {
				if (dados) {
					this.listaIdiomas = dados;
				}
			})
		);
	}

	//Adicionar requisito de residência na oportunidade. Se não preencher o tipo de vínculo, apresenta mensagem de erro.
	private adicionarVinculo() {
		if (this.requisitos.tipoVinculo == undefined) {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroVinculo', true);
		} else {
			if (this.oportunidade.vinculos == undefined) {
				this.oportunidade.vinculos = [];
			}
			const vinculo = this.editalService.adicionarVinculo(this.requisitos);
			const existe = this.oportunidade.vinculos.filter(x => x.idVinculo == vinculo?.idVinculo);
			if (existe.length === 0) {
				this.oportunidade.vinculos.push(vinculo);
				this.atualizarOportunidade();
				this.erroRequisito.set('erroVinculo', false);
				this.esconderModal();
			} else {
				this.textoErro = 'Tipo de vínculo já adicionado';
				this.erroRequisito.set('erroVinculo', true);
			}
		}
	}

	//Adicionar requisito de residência na oportunidade. Se não preencher a localidade, apresenta mensagem de erro.
	private adicionarResidencia() {
		if (this.requisitos.localidade != undefined) {
			this.oportunidade.codigoMunicipioResidencia = this.requisitos.localidade.code;
			this.oportunidade.nomeMunicipioResidencia = this.requisitos.localidade.name;
			this.atualizarOportunidade();
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroLocalidade', true);
		}
	}

	//Adicionar requisito de órgão de lotação na oportunidade. Se não preencher o orgao de lotação, apresenta mensagem de erro.
	private adicionarOrgaoLotacao() {
		if (this.requisitos.orgaoLotacao != undefined) {
			this.oportunidade.codigoOrgaoLotacao = Number(this.requisitos.orgaoLotacao.code);
			this.oportunidade.nomeOrgaoLotacao = this.requisitos.orgaoLotacao.name;
			this.atualizarOportunidade();
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroOrgaoLotacao', true);
		}
	}

	//Adicionar requisito de órgão de exercício na oportunidade. Se não preencher o orgao de exercicio, apresenta mensagem de erro.
	private adicionarOrgaoExercicio() {
		if (this.requisitos.orgaoExercicio != undefined) {
			this.oportunidade.codigoOrgaoExercicio = Number(this.requisitos.orgaoExercicio.code);
			this.oportunidade.nomeOrgaoExercicio = this.requisitos.orgaoExercicio.name;
			this.atualizarOportunidade();
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroOrgaoExercicio', true);
		}
	}

	//Adicionar outro requisito na oportunidade. Se não preencher o nome do outro requisito, apresenta mensagem de erro.
	private adicionarOutro() {
		if (this.requisitos.outroRequisito != undefined) {
			this.oportunidade.outrosRequisitos = this.requisitos.outroRequisito;
			this.atualizarOportunidade();
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroOutroRequisito', true);
		}
	}

	//Adicionar requisito de áreas de conhecimento na oportunidade. Se não preencher a área de conhecimento, apresenta mensagem de erro.
	private adicionarConhecimento() {
		if (this.requisitos.areaConhecimento == undefined) {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroConhecimento', true);
		} else {
			if (this.oportunidade.areasConhecimento == undefined) {
				this.oportunidade.areasConhecimento = [];
			}
			const conhecimentoOportunidade = this.editalService.adicionarAreaConhecimento(this.requisitos);
			const existe = this.oportunidade.areasConhecimento.filter(
				x => x.idAreaConhecimento == conhecimentoOportunidade.idAreaConhecimento
			);
			if (existe.length === 0) {
				this.oportunidade.areasConhecimento.push(conhecimentoOportunidade);
				this.atualizarOportunidade();
				this.erroRequisito.set('erroConhecimento', false);
				this.esconderModal();
			} else {
				this.textoErro = 'Conhecimento já adicionado';
				this.erroRequisito.set('erroConhecimento', true);
			}
		}
	}

	//Adicionar requisito de softskill na oportunidade. Se não preencher pelo menos uma softskill, apresenta mensagem de erro.
	private adicionarSoftskills() {
		const listaSoftskills = this.editalService.adicionarListaSoftskillls(this.requisitos);
		if (listaSoftskills.length > 0) {
			listaSoftskills.forEach(softskill => {
				if (this.oportunidade.competencias && this.oportunidade.competencias.length > 0) {
					const encontrou = this.oportunidade.competencias.find(x => x.idCompetencia == softskill.idCompetencia);
					if (encontrou === undefined) {
						this.oportunidade.competencias.push(softskill);
					}
				} else {
					this.oportunidade.competencias = listaSoftskills;
				}
			});
			this.atualizarOportunidade();
			this.esconderModal();
		} else {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroSoftskills', true);
		}
	}

	//Adicionar requisito de certificacao na oportunidade. Se não preencher o nome da certificação, apresenta mensagem de erro.
	private adicionarCertificacao() {
		if (this.oportunidade.certificacoes == undefined) {
			this.oportunidade.certificacoes = [];
		}
		if (this.requisitos.nomeCertificacao != undefined && this.requisitos.nomeCertificacao.valueOf().length >= 3) {
			const certificacao = new Certificacao(0, this.requisitos.nomeCertificacao, this.requisitos.certificacaoObrigatoria);
			const existe = this.oportunidade.certificacoes.filter(
				x => x.nomeCertificacao?.toUpperCase() == certificacao.nomeCertificacao?.toUpperCase()
			);
			if (existe.length == 0) {
				this.oportunidade.certificacoes.push(certificacao);
				this.atualizarOportunidade();
			}
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroCertificacao', true);
		}
	}

	private adicionarCapacitacao() {
		if (this.oportunidade.capacitacoes == undefined) {
			this.oportunidade.capacitacoes = [];
		}
		if (this.requisitos.nomeCapacitacao != undefined && this.requisitos.nomeCapacitacao.length >= 3) {
			const capacitacao = new Capacitacao(0, this.requisitos.nomeCapacitacao, this.requisitos.capacitacaoObrigatoria);
			const existe = this.oportunidade.capacitacoes.filter(
				x => x.nomeCapacitacao?.toUpperCase() == capacitacao.nomeCapacitacao?.toUpperCase()
			);
			if (existe.length == 0) {
				this.oportunidade.capacitacoes.push(capacitacao);
				this.atualizarOportunidade();
			}
			this.esconderModal();
		} else {
			this.erroRequisito.set('erroCapacitacao', true);
		}
	}

	//Adicionar requisito de certificacao na oportunidade. Se não preencher o nível de formação e o nome dela, apresenta mensagem de erro.
	private adicionarFormacao() {
		if (this.oportunidade.formacoes == undefined) {
			this.oportunidade.formacoes = [];
		}
		const formacao = this.editalService.adicionarFormacao(this.requisitos);
		if (this.requisitos.nomeFormacao == undefined || this.requisitos.nomeFormacao.length < 3) {
			this.erroRequisito.set('erroNomeFormacao', true);
		}
		if (formacao.idNivelCurso != undefined) {
			const existe = this.oportunidade.formacoes.filter(
				x => x.idNivelCurso === formacao.idNivelCurso && x.nomeFormacao?.toUpperCase() === formacao.nomeFormacao?.toUpperCase()
			);
			if (existe.length == 0) {
				this.oportunidade.formacoes.push(formacao);
				this.atualizarOportunidade();
				this.esconderModal();
			} else {
				this.textoErro = 'Formação já adicionada';
				this.erroRequisito.set('erroNivelFormacao', true);
			}
		}
		if (this.requisitos.nivelFormacao == undefined) {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroNivelFormacao', true);
		}
	}

	//Adicionar experiência na oportunidade. Se não preencher as informações de experiência, apresenta mensagem de erro.
	private adicionarExperiencia() {
		if (this.oportunidade.experiencias == undefined) {
			this.oportunidade.experiencias = [];
		}
		const experiencia = this.editalService.adicionarExperiencia(this.requisitos);
		if (this.editalService.validarPreenchimentoExperiencia(this.requisitos)) {
			const existe = this.oportunidade.experiencias.filter(
				x => x.nomeExperiencia?.toUpperCase() === experiencia.nomeExperiencia?.toUpperCase()
			);
			if (existe.length == 0) {
				this.oportunidade.experiencias.push(experiencia);
				this.atualizarOportunidade();
				this.esconderModal();
			} else {
				this.textoErro = 'Experiência já adicionada';
				this.erroRequisito.set('erroNomeExperiencia', true);
			}
		}
		this.validarExperiencia();
	}

	private validarExperiencia() {
		if (this.requisitos.nomeExperiencia == undefined) {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroNomeExperiencia', true);
		}
		if (this.requisitos.qtdMinimaMesesExperiencia == undefined) {
			this.erroRequisito.set('erroMesesExperiencia', true);
		}
	}

	//Adicionar área de atuação da oportunidade. Se não preencher a área de atuação, apresenta mensagem de erro.
	private adicionarAreaAtuacao() {
		if (this.oportunidade.areasAtuacao == undefined) {
			this.oportunidade.areasAtuacao = [];
		}
		const areaAtuacao = this.editalService.adicionarAreaAtuacao(this.requisitos);
		if (areaAtuacao.idAreaAtuacao != undefined) {
			const existe = this.oportunidade.areasAtuacao.filter(x => x.idAreaAtuacao == areaAtuacao.idAreaAtuacao);
			if (existe.length === 0) {
				this.oportunidade.areasAtuacao.push(areaAtuacao);
				this.atualizarOportunidade();
				this.esconderModal();
			} else {
				this.textoErro = 'Área de atuação já adicionada';
				this.erroRequisito.set('erroAreaAtuacao', true);
			}
		} else {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroAreaAtuacao', true);
		}
	}

	//Adicionar requisito de idioma na oportunidade. Se não preencher o tipo de idioma, apresenta mensagem de erro.
	private adicionarIdioma() {
		if (this.requisitos.tipoIdioma == undefined) {
			this.textoErro = 'Campo obrigatorio';
			this.erroRequisito.set('erroIdioma', true);
		} else {
			if (this.oportunidade.idiomas == undefined) {
				this.oportunidade.idiomas = [];
			}
			const idiomaOportunidade = this.editalService.adicionarIdioma(this.requisitos);
			const existe = this.oportunidade.idiomas.filter(x => x.idIdioma == idiomaOportunidade.idIdioma);
			if (existe.length === 0) {
				this.oportunidade.idiomas.push(idiomaOportunidade);
				this.atualizarOportunidade();
				this.erroRequisito.set('erroIdioma', false);
				this.esconderModal();
			} else {
				this.textoErro = 'Idioma já adicionado';
				this.erroRequisito.set('erroIdioma', true);
			}
		}
	}
}
