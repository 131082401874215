export class FiltroPesquisarLaudo {
	idConcurso?: number;
	codigoOrgao?: number;
	codigoCargo?: number;
	situacao?: string;
	cpfCandidato?: number;
	dataInicioEnvio?: string;
	dataFimEnvio?: string;
	dataInicioGeracao?: string;
	dataFimGeracao?: string;
	dataInicioEmissao?: string;
	dataFimEmissao?: string;
	prioridade?: boolean;
	atribuidosMim?: boolean;
	page = 1;
	size = 10;
	first = 0;

	calcularFirst() {
		this.first = (this.page - 1) * this.size;
	}

	calcularPage(first: number, size: number) {
		this.page = first / size + 1;
	}
}
