import { Injectable, OnDestroy } from '@angular/core';

import { AreaAtuacaoOportunidade } from '@core/models/area-atuacao-oportunidade';
import { AreaConhecimentoOportunidade } from '@core/models/area-conhecimento-oportunidade';
import { CadastrarEditalApiService } from './cadastrar-edital-api.service';
import { ConverterEditalInterfaceHelper } from '@core/helpers/converter-edital-interface.helper';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { Experiencia } from '@core/models/experiencia';
import { Formacao } from '@core/models/formacao';
import { OportunidadeCompetencia } from '@core/models/oportunidade-competencia';
import { OportunidadeIdioma } from '@core/models/oportunidade-idioma';
import { RatingIdiomaEnum } from '@core/enums/ratingIdioma.enum';
import { RequisitosEdital } from '@core/models/requisitos-edital';
import { Subscription } from 'rxjs';
import { TipoVinculo } from '@core/models/tipo-vinculo';
import { UiService } from '../../core/services/ui.service';
import { Vinculo } from '@core/models/vinculo';

@Injectable({
	providedIn: 'root'
})
export class EditalService implements OnDestroy {
	subscriptions: Subscription[] = [];

	constructor(
		private cadastrarEditalApi: CadastrarEditalApiService,
		private converterEditalInterface: ConverterEditalInterfaceHelper,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private ui: UiService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 * Salva um edital na base. Se for um edital novo, incluir, caso contrário, atualiza.
	 */
	async salvarEdital(edital: Edital) {
		const iEdital = this.converterEditalInterface.converter(edital);

		if (iEdital.id == undefined) {
			this.subscriptions.push(
				this.cadastrarEditalApi.incluirEdital(iEdital).subscribe(
					async res => {
						await this.converterInterfaceEdital.converter(edital, res);
					},
					err => {
						return err.error.message;
					}
				)
			);
		} else {
			this.subscriptions.push(
				this.cadastrarEditalApi.alterarEdital(iEdital).subscribe(
					async res => {
						await this.converterInterfaceEdital.converter(edital, res);
					},
					err => {
						return err.error.message;
					}
				)
			);
		}
	}

	async downloadAnexo(idAnexo: number) {
		this.subscriptions.push(
			this.cadastrarEditalApi.downloadAnexo(idAnexo).subscribe(response => {
				this.ui.downloadFile(response, 'application/pdf');
			})
		);
	}

	adicionarVinculo(requisitos: RequisitosEdital): Vinculo {
		if (requisitos.tipoVinculo != undefined) {
			const tipoVinculo = new TipoVinculo(requisitos.tipoVinculo.id, requisitos.tipoVinculo.nome, requisitos.tipoVinculo.ativo);
			return new Vinculo(
				0,
				requisitos.tipoVinculo.id,
				requisitos.tipoVinculo.nome,
				requisitos.excetoEstagioProbatorio,
				tipoVinculo,
				requisitos.tipoVinculoObrigatorio
			);
		}
		return {};
	}

	adicionarAreaConhecimento(requisitos: RequisitosEdital): AreaConhecimentoOportunidade {
		if (requisitos.areaConhecimento != undefined) {
			return new AreaConhecimentoOportunidade(
				0,
				requisitos.areaConhecimento.id,
				requisitos.areaConhecimento.nome,
				requisitos.conhecimentoObrigatorio
			);
		}
		return {};
	}

	adicionarListaSoftskillls(requisitos: RequisitosEdital): OportunidadeCompetencia[] {
		const listaSoftskills: OportunidadeCompetencia[] = [];
		if (requisitos.softskills !== undefined && requisitos.softskills.length > 0) {
			for (const skill of requisitos.softskills) {
				listaSoftskills.push(new OportunidadeCompetencia(0, skill.id, skill.nome, requisitos.softskillObrigatorio));
			}
		}
		return listaSoftskills;
	}

	adicionarFormacao(requisitos: RequisitosEdital): Formacao {
		if (requisitos.nivelFormacao != undefined && requisitos.nomeFormacao != undefined && requisitos.nomeFormacao.length >= 3) {
			return new Formacao(
				0,
				requisitos.nivelFormacao?.id,
				requisitos.nivelFormacao?.nome,
				requisitos.nomeFormacao,
				requisitos.formacaoObrigatoria
			);
		}
		return {};
	}

	adicionarExperiencia(requisitos: RequisitosEdital): Experiencia {
		if (this.validarPreenchimentoExperiencia(requisitos)) {
			const experiencia = new Experiencia();
			experiencia.id = 0;
			experiencia.nomeExperiencia = requisitos.nomeExperiencia;
			experiencia.qtdMinimaMesesExperiencia = requisitos.qtdMinimaMesesExperiencia;
			experiencia.servicoPublico = requisitos.noServicoPublico;
			experiencia.obrigatorio = requisitos.experienciaObrigatoria;

			return experiencia;
		}
		return {};
	}

	validarPreenchimentoExperiencia(requisitos: RequisitosEdital) {
		return requisitos.nomeExperiencia != undefined && requisitos.qtdMinimaMesesExperiencia != undefined;
	}

	adicionarAreaAtuacao(requisitos: RequisitosEdital): AreaAtuacaoOportunidade {
		if (requisitos.areaAtuacao != undefined) {
			return new AreaAtuacaoOportunidade(
				0,
				requisitos.areaAtuacao?.id,
				requisitos.areaAtuacao?.nome,
				requisitos.areaAtuacaoObrigatorio
			);
		}
		return {};
	}

	adicionarIdioma(requisitos: RequisitosEdital): OportunidadeIdioma {
		if (requisitos.tipoIdioma != undefined) {
			const novoIdioma = new OportunidadeIdioma();
			novoIdioma.id = 0;
			novoIdioma.idIdioma = Number(requisitos.tipoIdioma.id);
			novoIdioma.nome = requisitos.tipoIdioma.nome;
			novoIdioma.nivelCompreensao = this.converteRatingEmEnum(requisitos.nivelCompreensao);
			novoIdioma.nivelEscrita = this.converteRatingEmEnum(requisitos.nivelEscrita);
			novoIdioma.nivelFala = this.converteRatingEmEnum(requisitos.nivelFala);
			novoIdioma.nivelLeitura = this.converteRatingEmEnum(requisitos.nivelLeitura);
			novoIdioma.obrigatorio = requisitos.idiomaObrigatorio;

			return novoIdioma;
		}
		return {};
	}

	private converteRatingEmEnum(valor: number): any {
		if (valor == undefined) {
			return RatingIdiomaEnum.get(0);
		}
		return RatingIdiomaEnum.get(valor);
	}
}
