<div class="br-card">
	<div class="card-content" *ngIf="dados; else skeleton">
		<div class="titulo">{{ titulo }}</div>
		<div class="grafico_linha">
			<p-chart type="line" [data]="data" [options]="options" height="{{height}}"></p-chart>
		</div>
	</div>
	<ng-template #skeleton>
		<p-skeleton width="50rem" height="23rem"></p-skeleton>
	</ng-template>
</div>
