import { CargoProfissiografico } from '@app/core/models/perfil-profissiografico/cargo-profissiografico';
import { ConcursoProfissiografico } from '@app/core/models/perfil-profissiografico/concurso-profissiografico';
import { FiltroPesquisarQuestionarioProfissiografico } from '../../core/models/perfil-profissiografico/filtro-pesquisar-questionario-profissiografico';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrgaoProfissiografico } from '@app/core/models/perfil-profissiografico/orgao-profissiografico';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { ResultadoPesquisarQuestionarioProfissiografico } from '@app/core/models/perfil-profissiografico/resultado-pesquisar-questionario-profissiografico';
import { SituacaoQuestionarioCargo } from '@app/core/models/perfil-profissiografico/situacao-questionario-cargo';

@Injectable({
	providedIn: 'any'
})
export class PesquisarQuestionarioProfissiograficoService {
	filtro = new FiltroPesquisarQuestionarioProfissiografico();
	resultado$?: Observable<ResultadoPesquisarQuestionarioProfissiografico>;

	concursos$?: Observable<ConcursoProfissiografico[]>;
	cargos$?: Observable<CargoProfissiografico[]>;
	orgaos$?: Observable<OrgaoProfissiografico[]>;
	situacoes$?: Observable<SituacaoQuestionarioCargo[]>;

	constructor(private pesquisarApi: PerfilProfissiograficoApiService) {}

	pesquisar(): void {
		this.resultado$ = this.pesquisarApi.pesquisarQuestionario(this.filtro);
		this.filtro.calcularFirst();
	}

	paginar(page: number, size: number): void {
		this.filtro.page = page;
		this.filtro.size = size;
		this.pesquisar();
	}

	limpar(): void {
		this.filtro = new FiltroPesquisarQuestionarioProfissiografico();
		this.resultado$ = undefined;
		this.listarConcursos();
		this.listarSituacoes();
		this.orgaos$ = undefined;
		this.cargos$ = undefined;
	}

	listarConcursos(codigoSituacao?: string): void {
		this.concursos$ = this.pesquisarApi.getConcursos(codigoSituacao);
	}

	listarOrgaosConcurso(idConcurso: number): void {
		this.orgaos$ = this.pesquisarApi.getOrgaosConcurso(idConcurso);
	}

	listarCargos(idConcurso: number, codigoOrgao: number): void {
		this.cargos$ = this.pesquisarApi.getCargos(idConcurso, codigoOrgao);
	}

	listarSituacoes(): void {
		this.situacoes$ = this.pesquisarApi.getSituacaoQuestionario();
	}
}
