<div *ngIf="exibir">
	<div class="row">
		<div class="col-12 br-input" [ngClass]="{'danger': erroTamanhoBusca}">
			<div class="p-inputgroup">
				<input
					pInputText
					type="text"
					id="busca-area-interesse"
					[(ngModel)]="interesse"
					data-cy="campo-area-interesse-procurado"
					minlength="5"
					placeholder="Escreva a área de interesse desejada. Ex: Gestão"
				/>
				<button
					type="button"
					pButton
					pRipple
					icon="fas fa-search"
					styleClass="p-button-warn"
					label="Buscar"
					(click)="pesquisaInicial()"
					data-cy="botao-busca"
				></button>
			</div>
			<sgp-message-feedback
				[show]="erroTamanhoBusca"
				mensagem="O termo da busca deve possuir no mínimo 3 caracteres"
				data-cy="erro-tamanho-termo"
			></sgp-message-feedback>
		</div>
	</div>

	<div class="row" *ngIf="areasInteresse.length > 0">
		<div class="col-12 mt-3">
			<label for="lista-area-interesse">Selecione uma das áreas de interesse listadas abaixo:</label>
			<p-table
				[value]="areasInteresse"
				dataKey="id"
				[rowHover]="true"
				[responsive]="true"
				[autoLayout]="true"
				selectionMode="single"
				[(selection)]="areaInteresseSelecionada"
				(onRowSelect)="atualizarValorArea()"
				(onRowUnselect)="atualizarValorArea()"
				[scrollable]="true"
				scrollHeight="250px"
				data-cy="tabela-areas-interesse"
			>
				<ng-template pTemplate="body" let-area>
					<tr [pSelectableRow]="area">
						<td headers="cabecalho-tipo" data-cy="tipo-anexo">{{ area.nome }}</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5" data-cy="mensagem-nenhum-anexo">Nenhuma área encontrada com este termo.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
	<sgp-message-feedback [show]="erroMensagem" [mensagem]="textoErroMensagem" data-cy="erro-area-interesse"></sgp-message-feedback>

	<div class="row" *ngIf="obrigatoriedade">
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-area-interesse-requisito"
					type="checkbox"
					[(ngModel)]="interesseObrigatorio"
					data-cy="campo-obrigatorio-area-interesse"
					(change)="atualizaAreaObrigatoria()"
				/>
				<label for="obrigatorio-area-interesse-requisito">Obrigatório</label>
			</div>
		</div>
	</div>
</div>
