/**
 * Certificação
 */
export class Certificacao {
	id?: number;
	idOportunidade?: number;
	nomeCertificacao?: string;
	obrigatorio?: boolean;

	constructor(id?: number, nomeCertificacao?: string, obrigatorio?: boolean) {
		this.id = id;
		this.nomeCertificacao = nomeCertificacao;
		this.obrigatorio = obrigatorio;
	}
}
