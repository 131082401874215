import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AnexoCandidato } from '@app/core/models/anexoCandidato';
import { Message } from 'primeng/api/message';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { VisualizarEditalApiService } from './../services/visualizar-edital-api.service';

@Component({
	selector: 'sgp-detalhar-anexos-candidato',
	templateUrl: './detalhar-anexos-candidato.component.html'
})
export class DetalharAnexosCandidatoComponent implements OnInit, OnDestroy {
	@Input() idCandidato!: number;
	@Input() idOportunidade!: number;
	@Output() mensagemFeedback = new EventEmitter<Message[]>();

	listaAnexos: AnexoCandidato[] = [];
	desabilitarDownloadTodosAnexos = false;
	loading = false;
	subscriptions: Subscription[] = [];

	constructor(private ui: UiService, private visualizarEditalApi: VisualizarEditalApiService) {}

	ngOnInit(): void {
		this.exibirAnexos();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	private exibirAnexos() {
		this.loading = true;
		this.subscriptions.push(
			this.visualizarEditalApi.consultarAnexosCandidato(this.idCandidato).subscribe(
				res => {
					this.loading = false;
					this.listaAnexos = res;
					this.verificarSeAnexosEstaoAtivos(res);
				},
				err => {
					this.loading = false;
					if (err.status === 404) {
						this.mensagemFeedback.emit([]);
					} else {
						this.mensagemFeedback.emit(this.ui.criarListaMensagem('error', '', err.message));
					}
					this.listaAnexos = [];
				}
			)
		);
	}

	downloadTodosAnexos() {
		this.subscriptions.push(
			this.visualizarEditalApi.downloadTodosAnexosCandidato(this.idOportunidade, this.idCandidato).subscribe(
				response => {
					this.ui.downloadResponse(response);
					this.mensagemFeedback.emit([]);
				},
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				err => {
					this.mensagemFeedback.emit(
						this.ui.criarListaMensagem('error', '', 'Não foi possível realizar o download do(s) arquivo(s)')
					);
				}
			)
		);
	}

	async downloadAnexo(anexo: AnexoCandidato) {
		this.subscriptions.push(
			this.visualizarEditalApi.downloadAnexoCandidatoOportunidade(anexo.id!).subscribe(
				response => {
					const tipo = response.headers.get('content-type');
					const contentDisposition = response.headers.get('content-disposition');
					const filename = contentDisposition.split(';')[1].trim().split('=')[1];
					this.ui.downloadSaveFile(response, tipo, filename);
				},
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				err => {
					this.mensagemFeedback.emit(
						this.ui.criarListaMensagem('error', '', 'Não foi possível realizar o download do(s) arquivo(s)')
					);
				}
			)
		);
	}

	private verificarSeAnexosEstaoAtivos(listaAnexos: AnexoCandidato[]) {
		if (listaAnexos && !listaAnexos[0].ativo) {
			this.desabilitarDownloadTodosAnexos = true;
			this.mensagemFeedback.emit(
				this.ui.criarListaMensagem(
					'error',
					'',
					'Arquivo(s) não mais disponível(is) - edital encerrado ou cancelado há mais de dois anos.'
				)
			);
		}
	}
}
