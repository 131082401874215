import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

/**
 * Helper com métodos de data.
 */
@Injectable({
	providedIn: 'root'
})
export class DateHelper {
	constructor(private datePipe: DatePipe) {}

	/**
	 * Retorna a data atual no formato DD/MM/YYYY
	 * @returns data atual em string
	 */
	public getDataAtual(): string {
		let dataFormatada!: any;
		const hoje = new Date();
		// eslint-disable-next-line prefer-const
		dataFormatada = this.transformDateToString(hoje);
		return dataFormatada.toString();
	}

	/**
	 * Transforma um objeto do tipo data em string
	 * @param date date
	 * @returns data em string
	 */
	public transformDateToString(date: Date): string {
		return this.datePipe.transform(date, 'dd/MM/yyyy')!;
	}

	/**
	 * transforma um objeto do tipo string (no formato dd/mm/yyyy) em data
	 * @param date string
	 */
	public transformStringToDate(strDate: string): Date {
		const str = strDate.split('/');
		const day = +str[0];
		const month = +str[1] - 1;
		const year = +str[2];
		return new Date(year, month, day);
	}

	/**
	 * Compara duas datas e retorna um número que representa:
	 * 0 se as datas forem iguais
	 * 1 Se a primeira data for maior que a segunda.
	 * -1 Se a primeira data for menos que a segunda.
	 * -2 Se der algo errado.
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 */
	public compararDatas(date1: Date, date2: Date): number {
		const d1 = new Date(date1);
		const d2 = new Date(date2);
		const same = d1.getTime();

		// Verifica se as datas são iguais
		if (same === d2.getTime()) return 0;

		// Verifica se a primeira é maior que segunda
		if (d1 > d2) return 1;

		// Verifica se a primeira é menor que a segunda
		if (d1 < d2) return -1;

		return -2;
	}

	public diferencaEmDias(date1: Date, date2: Date): number {
		const d1 = new Date(date1);
		const d2 = new Date(date2);

		return Math.floor((d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24);
	}

	/**
	 * Verifica se a primeira data é menor que a segunda data
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 * @returns true | false
	 */
	public eMenorQue(date1: Date, date2: Date): boolean {
		const retorno = this.compararDatas(date1, date2);
		if (retorno === -1) {
			return true;
		}
		return false;
	}

	/**
	 * Verifica se a primeira data é maior que a segunda data
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 * @returns true | false
	 */
	public eMaiorQue(date1: Date, date2: Date): boolean {
		const retorno = this.compararDatas(date1, date2);
		if (retorno === 1) {
			return true;
		}
		return false;
	}

	/**
	 * Verifica se a primeira data é menor ou igual a segunda data
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 * @returns true | false
	 */
	public eMenorOuIgual(date1: Date, date2: Date): boolean {
		const retorno = this.compararDatas(date1, date2);
		if (retorno === 0 || retorno === -1) {
			return true;
		}
		return false;
	}

	/**
	 * Verifica se a primeira data é maior ou igual a segunda data
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 * @returns true | false
	 */
	public eMaiorOuIgual(date1: Date, date2: Date): boolean {
		const retorno = this.compararDatas(date1, date2);
		if (retorno === 0 || retorno === 1) {
			return true;
		}
		return false;
	}

	/**
	 * Verifica se uma data está em um intervalo de datas.
	 * @param date1 Primeira data
	 * @param date2 Segunda data
	 * @returns true | false
	 */
	public estaNoIntervalo(date: Date, dataMinima: Date, dataMaxima: Date): boolean {
		return this.eMaiorOuIgual(date, dataMinima) && this.eMenorOuIgual(date, dataMaxima);
	}

	public temposAtras(date1: any, date2: any, reverso?: boolean): string {
		const data1 = +new Date(this.transformStringToDate(date1)).getTime();
		const data2 = +new Date(this.transformStringToDate(date2)).getTime();

		let diferenca;
		if (reverso) {
			diferenca = Math.floor((data1 - data2) / 1000);
		} else {
			diferenca = Math.floor((data2 - data1) / 1000);
		}

		const dia = 60 * 60 * 24;

		const mes = dia * 30;
		const ano = mes * 12;

		if (diferenca < dia) {
			return 'Atrasado';
		}

		if (diferenca == dia) {
			return 'Hoje';
		}

		if (diferenca >= dia && diferenca < mes) {
			if (diferenca < dia * 2) {
				return '1 dia';
			}
			return Math.floor(diferenca / dia) + ' dias';
		}

		if (diferenca >= mes && diferenca < ano) {
			if (diferenca < mes * 2) {
				return '1 mês';
			}
			return Math.floor(diferenca / mes) + ' meses';
		}

		if (diferenca >= ano) {
			if (diferenca < ano * 2) {
				return '1 ano';
			}
			return Math.floor(diferenca / ano) + ' anos';
		}

		return '';
	}
}
