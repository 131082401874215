import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'sgp-busca-orgao',
	templateUrl: './busca-orgao.component.html'
})
export class BuscaOrgaoComponent implements OnInit, OnDestroy {
	@Input() id!: string;
	@Input() label!: string;
	@Input() ngModel: any;
	@Output() ngModelUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Input() required? = true;
	@Input() exibirLabel? = true;
	@Input() mensagemCampoObrigatorio = false;

	filteredOrgaos!: any[];
	orgaos: any[] = [];
	subscriptions: Subscription[] = [];

	constructor(private oportunidadesService: OportunidadesApiService) {}

	ngOnInit() {
		this.getOrgaos();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	filtrarOrgaos(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.orgaos) {
			for (const orgao of this.orgaos) {
				if (orgao.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(orgao);
				}
			}
		}

		this.filteredOrgaos = filtered;
	}

	/** Atualiza o valor com a opção seleciona */
	atualizarNgModel(event: any) {
		if (event?.value) {
			this.ngModelUpdated.emit(event.value);
		} else {
			this.ngModelUpdated.emit(event);
		}
	}

	private getOrgaos() {
		this.orgaos = [];
		this.subscriptions.push(
			this.oportunidadesService.orgaos().subscribe((dados: any[]) => {
				if (dados !== null) {
					dados.forEach(dado => {
						this.orgaos.push({ name: this.descricaoOrgao(dado.sigla, dado.nome, dado.situacao), code: dado.codigoSiape });
					});
					this.orgaos.sort((a, b) => a.name.localeCompare(b.name));
				}
			})
		);
	}

	private descricaoOrgao(sigla: string, nome: string, situacao: string): string {
		if (situacao === 'PENDENTE_EXTINCAO_SIAPE') return sigla + ' - ' + nome + ' (Pendente de extinção)';
		else if (situacao === 'DESATIVADO_EXTINTO') return sigla + ' - ' + nome + ' (Extinto)';
		else return sigla + ' - ' + nome;
	}
}
