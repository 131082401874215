<p-messages [(value)]="msgErro" [enableService]="false" [closable]="false"></p-messages>
<p-messages [closable]="false"></p-messages>

<div class="row mt-2">
	<div class="col-sm-2">
		<label for="numero-edital">Identificador do edital</label>
	</div>
	<div class="col-sm-4">
		<span id="id_edital" data-cy="id_edital" *ngIf="edital.id; else editalSemId">{{ edital.id }}</span>
		<ng-template #editalSemId>
			<span>O identificador será gerado quando o edital for salvo</span>
		</ng-template>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-2">
		<label for="numero-edital">Número do edital</label>
	</div>
	<div class="col-sm-4">
		<span data-cy="numero_edital" *ngIf="edital.numeroEdital; else editalSemNumero">{{ edital.numeroEdital }}</span>
		<ng-template #editalSemNumero>
			<span>O número será gerado quando o edital for publicado</span>
		</ng-template>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-2">
		<label for="situacao-edital">Situação</label>
	</div>
	<div class="col-sm-4"><span>{{ edital.situacao.descricao }}</span></div>
</div>

<div class="row mt-2 mb-4">
	<div class="col-sm-2">
		<label for="orgao">Órgão</label>
	</div>
	<div class="col-sm-4"><span>{{ edital.nomeOrgao }}</span></div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-textarea">
			<label for="preambulo"
				>Preâmbulo do edital (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor id="preambulo" [(ngModel)]="edital.preambulo" [style]="{'height':'150px'}" data-cy="campo-preambulo">
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>

			<p class="text-base mt-1">Informe o texto do preâmbulo do edital</p>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-textarea">
			<label for="sobre-unidade"
				>Informações sobre a unidade do edital (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor id="sobre-unidade" [(ngModel)]="edital.sobreUnidade" [style]="{'height':'150px'}" data-cy="campo-sobre-unidade">
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>

			<p class="text-base mt-1">Informe um descritivo sobre a unidade que está elaborando o edital</p>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-textarea">
			<label for="exigencias-legais"
				>Exigências legais para abertura da oportunidade (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor
				id="exigencias-legais"
				[(ngModel)]="edital.exigenciaLegal"
				[style]="{'height':'150px'}"
				data-cy="campo-exigencias-legais"
			>
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>

			<p class="text-base mt-1">
				Descreva aqui as exigências legais, tais como leis, normas ou instruções normativas, que amparam e normatizam a esta oferta
				de oportunidade(s).
			</p>
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-sm-10">
		<div class="br-textarea">
			<label for="sobre-vagas-oportunidades"
				>Informações sobre as oportunidades do edital (opcional)
				<i
					class="fas fa-question-circle text-blue-warm-vivid-70 cursor-pointer"
					title="Dicas de preenchimento"
					(click)="exibirDica = true"
				></i>
			</label>
			<p-editor
				id="sobre-vagas-oportunidades"
				[(ngModel)]="edital.sobreVagasOportunidades"
				[style]="{'height':'150px'}"
				data-cy="campo-sobre-vagas-oportunidades"
			>
				<ng-template pTemplate="header">
					<span class="ql-formats">
						<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
						<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
						<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
						<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
						<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
						<span class="ql-format-separator"></span>
						<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
					</span>
				</ng-template>
			</p-editor>

			<p class="text-base mt-1">Informe um descritivo sobre as vagas e oportunidades que serão disponibilizadas neste edital</p>
		</div>
	</div>
</div>

<div class="row mt-2 mb-4">
	<div class="col-sm-8">
		<div class="br-input" [ngClass]="{'danger': erroEmail}">
			<label for="email">Email de contato</label>
			<input
				id="email"
				[(ngModel)]="edital.emailContato"
				type="email"
				pInputText
				data-cy="campo-email"
				[email]="true"
				maxlength="100"
			/>
			<sgp-message-feedback [show]="erroEmail" [mensagem]="mensagemErroEmail"></sgp-message-feedback>
		</div>
	</div>
</div>

<div class="row mt-2 mb-4">
	<div class="col-sm-12 text-right">
		<button class="br-button primary" type="button" (click)="salvarEdital()" data-cy="botao-salvar-dados-basicos">
			Salvar dados básicos
		</button>
	</div>
</div>

<p-dialog
	header="Dicas de preenchimento"
	[(visible)]="exibirDica"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	[baseZIndex]="10000"
	[resizable]="false"
	styleClass="modal-40vw"
	[contentStyle]="{overflow: 'hidden'}"
	(onHide)="exibirDica = false"
	data-cy="modal-dica-pesquisa"
>
	<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>

	<ng-template pTemplate="footer">
		<p-button (click)="exibirDica = false" label="Fechar" styleClass="br-button secondary" data-cy="botao-fechar-dica"></p-button>
	</ng-template>
</p-dialog>
