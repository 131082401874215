<p-messages [enableService]="true" [closable]="false"></p-messages>

<form (ngSubmit)="pesquisar()">
	<div class="my-3 p-4 shadow-sm bg-gray-2">
		<p class="text-weight-semi-bold">Filtro de pesquisa</p>
		<div class="row">
			<div class="col-sm-12 col-md-8 br-input">
				<input
					#elTextoPesquisa
					name="textoPesquisa"
					pInputText
					type="text"
					data-cy="campo-texto"
					[(ngModel)]="textoPesquisa"
					[ngModelOptions]="{standalone: true}"
					maxlength="100"
					placeholder="Busque aqui pelo termo desejado"
				/>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-sm-12 col-md-2 br-checkbox d-inline mt-2">
				<input
					id="ativo"
					[(ngModel)]="ativo"
					[ngModelOptions]="{standalone: true}"
					type="checkbox"
					aria-label="Somente ativo"
					data-cy="campo-somente-ativos"
				/>
				<label for="ativo">Somente ativos</label>
			</div>
			<div
				class="col-sm-12 br-checkbox d-inline mt-2"
				[ngClass]="{'col-md-6': !habilitarFiltroFerramenta, 'col-md-3': habilitarFiltroFerramenta}"
			>
				<ng-template [ngIf]="habilitarFiltroCadastradoUsuario">
					<input
						id="cadastradoUsuario"
						[(ngModel)]="cadastradoUsuario"
						[ngModelOptions]="{standalone: true}"
						type="checkbox"
						aria-label="Somente incluídos pelos usuários"
						data-cy="campo-somente-incluidos-usuario"
					/>
					<label for="cadastradoUsuario">Somente incluídos pelos usuários</label>
				</ng-template>
			</div>
			<div class="col-sm-12 col-md-3 br-checkbox d-inline mt-2" *ngIf="habilitarFiltroFerramenta">
				<input
					id="ferramenta"
					[(ngModel)]="ferramenta"
					[ngModelOptions]="{standalone: true}"
					type="checkbox"
					aria-label="Somente ferramentas"
				/>
				<label for="ferramenta">Somente ferramentas</label>
			</div>
			<div class="col-sm-12 col-md-4 text-right">
				<button id="btnPesquisar" data-cy="botao-pesquisar" class="primary" (click)="pesquisar()" type="button" pButton>
					Pesquisar
				</button>
				<button id="btnLimpar" data-cy="botao-limpar" class="secondary ml-3" (click)="limpar()" type="button" pButton>
					Limpar
				</button>
			</div>
		</div>
	</div>
</form>
