import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Anexo } from '@core/models/anexo';
import { EditalService } from '@app/cadastrarEdital/services/edital.service';
import { Message } from 'primeng/api';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-visualizar-anexos',
	templateUrl: './visualizar-anexos.component.html'
})
export class VisualizarAnexosComponent implements OnInit, OnDestroy {
	@Input() editalArquivado = false;
	anexos!: Anexo[];
	subscriptions: Subscription[] = [];
	modalAdicionarAnexo = false;
	labelBotaoSalvar = 'Salvar';
	msgErro: Message[] = [];
	loading = true;

	constructor(
		private editalService: EditalService,
		private oportunidadesService: OportunidadesApiService,
		private route: ActivatedRoute,
		private ui: UiService
	) {}

	ngOnInit() {
		const id = Number(this.route.snapshot.paramMap.get('id'));
		this.carregarAnexos(id);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	async download(idAnexo: number) {
		await this.editalService.downloadAnexo(idAnexo);
	}

	private carregarAnexos(id: number) {
		this.subscriptions.push(
			this.oportunidadesService.consultarAnexosEdital(id).subscribe(
				res => {
					if (res) {
						this.anexos = res;
						this.loading = false;
						this.alertaEditalArquivado();
					}
				},
				err => {
					this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private alertaEditalArquivado() {
		if (this.editalArquivado && this.anexos?.length > 0) {
			this.msgErro = this.ui.criarListaMensagem(
				'info',
				'',
				'Arquivo(s) não mais disponível(is) - edital encerrado ou cancelado há mais de dois anos.'
			);
		}
	}
}
