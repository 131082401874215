/**
 * Oportunidade idioma.
 */
export class OportunidadeIdioma {
	id?: number;
	idIdioma?: number;
	idOportunidade?: number;
	nome?: string;
	nivelCompreensao?: any;
	nivelEscrita?: any;
	nivelFala?: any;
	nivelLeitura?: any;
	obrigatorio?: boolean;
}
