import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SolicitacaoBase } from '../models/solicitacao-base';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'any'
})
export class SolicitarBaseCurriculosApiService {
	constructor(private http: HttpClient) {}

	URL = environment.HOST_OPORTUNIDADE + '/' + 'solicitacao-base';

	obterSolicitacoesBase(): Observable<SolicitacaoBase[]> {
		return this.http.get<SolicitacaoBase[]>(this.URL, {});
	}

	download(id_solicitacao: number): Observable<any> {
		const url = this.URL + '/download/' + id_solicitacao.toString();
		return this.http.get(url, { responseType: 'blob', observe: 'response' });
	}

	incluirSolicitacaoBase() {
		return this.http.post(this.URL, {});
	}
}
