<h4 data-cy="titulo-dados-basicos">Dados básicos do edital</h4>

<div class="row">
	<div class="col-sm-12">
		<div class="br-card">
			<div class="card-content" data-cy="dados-basicos-edital">
				<div class="row mb-4">
					<div class="col-sm-3">
						<label for="preambulo">Preâmbulo:</label>
					</div>
					<div class="col-sm-9">
						<p id="preambulo" class="text-justify">
							<span *ngIf="!edital.preambulo">-</span>
							<span *ngIf="edital.preambulo" [innerHTML]="edital.preambulo"></span>
						</p>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-sm-3">
						<label for="sobre-unidade">Sobre a unidade:</label>
					</div>
					<div class="col-sm-9">
						<p id="sobre-unidade" class="text-justify">
							<span *ngIf="!edital.sobreUnidade">-</span>
							<span *ngIf="edital.sobreUnidade" [innerHTML]="edital.sobreUnidade"></span>
						</p>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-sm-3">
						<label for="exigencia-legal">Exigência legal:</label>
					</div>
					<div class="col-sm-9">
						<p id="exigencia-legal" class="text-justify">
							<span *ngIf="!edital.exigenciaLegal">-</span>
							<span *ngIf="edital.exigenciaLegal" [innerHTML]="edital.exigenciaLegal"></span>
						</p>
					</div>
				</div>

				<div class="row mb-4">
					<div class="col-sm-3">
						<label for="sobre-vagas-oportunidade">Sobre a(s) vaga(s) das oportunidades:</label>
					</div>
					<div class="col-sm-9">
						<p id="sobre-vagas-oportunidade" class="text-justify">
							<span *ngIf="!edital.sobreVagasOportunidades">-</span>
							<span *ngIf="edital.sobreVagasOportunidades" [innerHTML]="edital.sobreVagasOportunidades"></span>
						</p>
					</div>
				</div>

				<div class="row mb-2">
					<div class="col-sm-3">
						<label for="email-contato">Email de contato:</label>
					</div>
					<div class="col-sm-9">
						<p id="email-contato">
							<a href="mailto:{{edital.emailContato}}"> {{edital.emailContato}}</a>
							<span *ngIf="!edital.emailContato">Nenhum email cadastrado.</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
