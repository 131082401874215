<sgp-skeleton-visualizar-edital *ngIf="loading"></sgp-skeleton-visualizar-edital>

<div class="mt-5" *ngIf="!loading && !edital">
	<p-messages [(value)]="mensagens" [enableService]="false" [closable]="false" data-cy="mensagens"></p-messages>
</div>

<p-messages [enableService]="true" [closable]="false"></p-messages>

<div *ngIf="!loading && edital">
	<header>
		<div class="row">
			<div class="col-sm-12 col-md-10">
				<h3 data-cy="titulo-edital">{{ edital.id }} - Edital {{edital.numeroEdital}} - {{edital.nomeOrgao}}</h3>
				<p data-cy="situacao-edital">Situação: {{edital.situacao.descricao}}</p>
			</div>
			<div class="col-sm-12 col-md-2 mt-4 text-right">
				<sgp-botao-acao
					id="botaoAcao"
					[edital]="edital"
					pagina="visualizar_edital"
					(mensagemFeedback)="atualizarMensagem($event)"
					(editalAtualizado)="atualizarEdital($event)"
					data-cy="botao-acoes"
					*ngIf="edital"
				></sgp-botao-acao>
			</div>
		</div>
	</header>

	<p-menubar [model]="navbar" data-cy="navbar"></p-menubar>

	<sgp-visualizar-mensagem-alerta
		[edital]="edital"
		*ngIf="edital && this.template === 'principal'"
		(mensagemFeedback)="atualizarMensagem($event)"
		(editalAtualizado)="atualizarEdital($event)"
	></sgp-visualizar-mensagem-alerta>

	<div id="main-content">
		<sgp-visualizar-dados-edital
			*ngIf="this.template === 'principal' && edital"
			[edital]="edital"
			(oportunidadeSelecionada)="irOportunidadeSelecionada($event)"
		></sgp-visualizar-dados-edital>
		<sgp-visualizar-dados-basicos *ngIf="this.template === 'dados-basicos'" [edital]="edital"></sgp-visualizar-dados-basicos>
		<sgp-visualizar-oportunidade
			*ngIf="this.template === 'oportunidade'"
			[oportunidadeSelecionada]="oportunidadeSelecionada"
			[situacaoEdital]="edital.situacao.codigo"
			[orgaoEdital]="edital.codigoOrgao!.toString()"
			[numeroEdital]="edital.numeroEdital"
			[editalArquivado]="editalArquivado"
		></sgp-visualizar-oportunidade>
		<sgp-visualizar-etapas-edital *ngIf="this.template === 'etapas'" [edital]="edital"></sgp-visualizar-etapas-edital>
		<sgp-visualizar-anexos [editalArquivado]="editalArquivado" *ngIf="this.template === 'anexos'"></sgp-visualizar-anexos>
		<sgp-visualizar-historico-edital
			*ngIf="this.template === 'historico'"
			[edital]="edital"
			(gerandoPDF)="atualizarGerarPdf($event)"
		></sgp-visualizar-historico-edital>
	</div>
</div>
