import { Pipe, PipeTransform } from '@angular/core';

import { Etapa } from '@core/models/etapa';

/**
 * Imprime o periodo exibindo 'dd/mm/yyyy até dd/mm/yyyy' sem ambos forem informados. Senão, imprime apenas um.
 */
@Pipe({ name: 'dataEtapa' })
export class DataEtapaPipe implements PipeTransform {
	transform(etapa: Etapa): any {
		if (etapa.dataInicioEtapa != undefined && etapa.dataFimEtapa != undefined)
			return etapa.dataInicioEtapa + ' até ' + etapa.dataFimEtapa;
		if (etapa.dataInicioEtapa != undefined) return etapa.dataInicioEtapa;
		if (etapa.dataFimEtapa != undefined) return etapa.dataFimEtapa;
		return '-';
	}
}
