export class NotificacaoCandidato {
	assunto?: string;
	mensagem?: string;
	candidatos?: number[];

	//create constructor for NotificacaoCandidatos with all parameters

	constructor(assunto?: string, mensagem?: string, candidatos?: number[]) {
		this.assunto = assunto;
		this.mensagem = mensagem;
		this.candidatos = candidatos;
	}
}
