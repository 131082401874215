import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { DataEtapaPipe } from './pipes/dataEtapa.pipe';
import { DataItensResultadoQuestionarioPipe } from './pipes/dataItensResultadoQuestionario';
import { DateAgoPipe } from './pipes/dateAgo.pipe';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { IdiomaPipe } from './pipes/idioma.pipe';
import { ImprimirDashPipe } from './pipes/imprimirDash.pipe';
import { ImprimirSimNaoPipe } from './pipes/imprimirSimNao.pipe';
import { IncluirHttpPipe } from './pipes/incluirHttp.pipe';
import { NumeroEditalPipe } from './pipes/numeroEdital.pipe';
import { NumeroPipe } from './pipes/numero.pipe';
import { RatingPipe } from './pipes/rating.pipe';
import { RequisitosObrigatoriosPipe } from './pipes/requisitosObrigatorios.pipe';
import { SgpComponentDirective } from './directives/sgpcomponent.directive';
import { SituacaoPipe } from './pipes/situacao.pipe';
import { TipoAcaoPipe } from './pipes/tipoAcao.pipe';
import { TruncarEmailPipe } from './pipes/truncarEmail.pipe';

@NgModule({
	declarations: [
		SgpComponentDirective,
		DateAgoPipe,
		DataEtapaPipe,
		DataItensResultadoQuestionarioPipe,
		IdiomaPipe,
		ImprimirDashPipe,
		ImprimirSimNaoPipe,
		IncluirHttpPipe,
		NumeroPipe,
		NumeroEditalPipe,
		RatingPipe,
		RequisitosObrigatoriosPipe,
		SituacaoPipe,
		TipoAcaoPipe,
		TruncarEmailPipe
	],
	imports: [DsGovDirectivesModule],
	exports: [
		SgpComponentDirective,
		DateAgoPipe,
		DataEtapaPipe,
		DataItensResultadoQuestionarioPipe,
		IdiomaPipe,
		ImprimirDashPipe,
		ImprimirSimNaoPipe,
		IncluirHttpPipe,
		NumeroPipe,
		NumeroEditalPipe,
		RatingPipe,
		RequisitosObrigatoriosPipe,
		SituacaoPipe,
		TipoAcaoPipe,
		TruncarEmailPipe
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {}
