import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, catchError } from 'rxjs';

import { CandidatoLaudo } from '@app/core/models/perfil-profissiografico/candidato-laudo';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { QuestionarioCargo } from '@app/core/models/perfil-profissiografico/questionario-cargo';
import { RespostaCandidato } from '@app/core/models/perfil-profissiografico/resposta-candidato';

@Injectable({
	providedIn: 'any'
})
export class DetalharLaudoService {
	detalharRespostaCandidato$?: Observable<RespostaCandidato>;
	detalharCargo$?: Observable<QuestionarioCargo>;
	detalharLaudo$?: Observable<CandidatoLaudo>;
	radar?: SafeUrl;

	constructor(
		private perfilProfissiograficoApi: PerfilProfissiograficoApiService,
		private messageService: MessageService,
		private sanitizer: DomSanitizer
	) {}

	detalharLaudo(idCandidato: number) {
		this.detalharLaudo$ = this.perfilProfissiograficoApi.detalharLaudo(idCandidato).pipe(
			catchError(error => {
				this.handleError(error.error.message, '');
				throw error;
			})
		);
	}

	detalharQuestionariocandidato(idCandidato: number) {
		this.detalharRespostaCandidato$ = this.perfilProfissiograficoApi.detalharQuestionariocandidato(idCandidato).pipe(
			catchError(error => {
				this.handleError(error.error.message, '');
				throw error;
			})
		);
	}

	detalharCargo(idCargo: number) {
		this.detalharCargo$ = this.perfilProfissiograficoApi.detalharCargo(idCargo).pipe(
			catchError(error => {
				this.handleError(error.error.message, '');
				throw error;
			})
		);
	}

	downloadGraficoRadar(idCandidato: number) {
		this.perfilProfissiograficoApi.downloadGraficoRadar(idCandidato).subscribe(imageBlob => {
			const objectURL = URL.createObjectURL(imageBlob);
			this.radar = this.sanitizer.bypassSecurityTrustUrl(objectURL);
		});
	}

	private handleError(descricao: string, detalhe: string) {
		this.messageService.add({
			severity: 'error',
			summary: descricao,
			detail: detalhe
		});
	}
}
