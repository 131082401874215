import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoDocumento } from '@app/core/models/tipo-documento';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TipoDocumentoApiService {
	constructor(private http: HttpClient) {}

	URL_TIPO_DOCUMENTO = environment.HOST_OPORTUNIDADE + '/' + 'tipo-documento';

	obterTipoDocumentos(tipoDocumento: TipoDocumento, pagina: number, tamanho: number): Observable<TipoDocumento[]> {
		let params = new HttpParams();

		if (tipoDocumento.nome) {
			params = params.set('nome', tipoDocumento.nome);
		}

		if (tipoDocumento.ativo) {
			params = params.set('ativo', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<TipoDocumento[]>(this.URL_TIPO_DOCUMENTO, { params: params });
	}

	obterTipoDocumento(id: number): Observable<TipoDocumento> {
		const url = this.URL_TIPO_DOCUMENTO + '/' + id.toString();
		return this.http.get<TipoDocumento>(url);
	}

	incluirTipoDocumento(tipoDocumento: TipoDocumento) {
		return this.http.post(this.URL_TIPO_DOCUMENTO, tipoDocumento);
	}

	alterarTipoDocumento(id: number, tipoDocumento: TipoDocumento) {
		const url = this.URL_TIPO_DOCUMENTO + '/' + id.toString();
		return this.http.put(url, tipoDocumento);
	}

	excluirTipoDocumento(id: number) {
		const url = this.URL_TIPO_DOCUMENTO + '/' + id.toString();
		return this.http.delete(url);
	}
}
