<p-toast position="top-right"></p-toast>

<ng-template [ngIf]="exibicao === 'detalhamento'">
	<p-splitButton
		#btnAcao
		label="Ações"
		[model]="acoesPossiveis"
		[disabled]="desabilitar"
		styleClass="secondary"
		(onClick)="btnAcao.onDropdownButtonClick($event)"
	></p-splitButton>
</ng-template>

<ng-template [ngIf]="exibicao === 'tabela'">
	<p-menu #menu [model]="acoesPossiveis" [popup]="true"></p-menu>
	<button
		pButton
		type="button"
		class="br-button circle"
		aria-label="Ações"
		(click)="menu.toggle($event)"
		[disabled]="acoesPossiveis.length === 0"
		icon="fas fa-ellipsis-v"
	></button>
</ng-template>

<p-dialog
	id="liberarQuestionario"
	header="Liberar Questionário"
	[(visible)]="showModalLiberarQuestionario"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-60vw text-left"
	[style]="{ minHeight: '350px'}"
>
	<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>
	<div class="row">
		<div class="col-sm-12">
			<!--form [formGroup]="form"-->
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-envio">Período:</label>
							<p-calendar
								[showIcon]="true"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-liberacao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa até dd/mm/aaaa"
								data-cy="campo-periodo-liberacao"
								[style]="{'minWidth':'350px', 'minHeight': '200px'}"
								[(ngModel)]="periodo"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="erroPeriodo"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>

					<div class="text-center" *ngIf="loading">Carregando...</div>

					<div class="card-footer">
						<div class="row mt-4">
							<div class="col text-right">
								<p-button
									label="Cancelar"
									(click)="esconderModalLiberarQuestionario()"
									styleClass="br-button secondary"
									*ngIf="!finalizado"
								></p-button>
								<p-button
									label="Fechar"
									(click)="esconderModalEncerrarLiberacaoQuestionario()"
									styleClass="br-button primary ml-2"
									*ngIf="finalizado"
								></p-button>
								<p-button
									label="Confirmar"
									(click)="confirmarLiberarQuestionario()"
									styleClass="br-button primary ml-2"
									*ngIf="!finalizado"
								></p-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--/form-->
		</div>
	</div>
</p-dialog>

<p-dialog
	id="encerrarLiberacaoQuestionario"
	header="Alterar Data de Encerramento de Liberação do Questionário"
	[(visible)]="showModalAlterarEncerramento"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-60vw text-left"
	[style]="{ minHeight: '400px'}"
>
	<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>

	<div class="row">
		<div class="col-sm-12">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-envio">Data encerramento:</label>
							<p-calendar
								[showIcon]="true"
								inputId="campo-periodo-liberacao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="dd/mm/aaaa"
								icon="fas fa-calendar-alt"
								[monthNavigator]="true"
								[yearNavigator]="true"
								data-cy="campo-data-encerramento"
								[style]="{'minWidth':'350px'}"
								[(ngModel)]="dataEncerramento"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="erroDataEncerramentoObrigatorio"
								mensagem="Campo obrigatório"
							></sgp-message-feedback>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12 mt-2 br-textarea">
							<!--text area: criar classe para espandir 100% da largura -->
							<label for="campo-justificativa-envio">Justifique a alteração da data de encerramento (Obrigatório):</label>
							<textarea
								id="justificativa"
								[(ngModel)]="textoJustificativa"
								rows="4"
								data-cy="campo-justificativa"
								[disabled]="desabilitar"
								maxlength="200"
							></textarea>
							<sgp-message-feedback [show]="erroJustificativaPreenchida" mensagem="Campo obrigatório"></sgp-message-feedback>
						</div>
					</div>
					<div class="text-center" *ngIf="loading">Carregando...</div>

					<div class="card-footer">
						<div class="row mt-4">
							<div class="col text-right">
								<p-button
									label="Cancelar"
									(click)="esconderModalEncerrarLiberacaoQuestionario()"
									styleClass="br-button secondary"
									*ngIf="!finalizado"
								></p-button>
								<p-button
									label="Fechar"
									(click)="esconderModalEncerrarLiberacaoQuestionario()"
									styleClass="br-button primary"
									*ngIf="finalizado"
								></p-button>
								<button pButton (click)="alterarEncerramento()" class="br-button primary ml-2" *ngIf="!finalizado">
									Confirmar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
