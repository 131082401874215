import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AreaAtuacao } from '@core/models/area-atuacao';
import { AreaConhecimentoOportunidade } from '@core/models/area-conhecimento-oportunidade';
import { Capacitacao } from '@core/models/capacitacao';
import { Certificacao } from '@core/models/certificacao';
import { Experiencia } from '@core/models/experiencia';
import { Formacao } from '@core/models/formacao';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadeCompetencia } from '@core/models/oportunidade-competencia';
import { OportunidadeIdioma } from '@core/models/oportunidade-idioma';
import { Vinculo } from '@core/models/vinculo';

@Component({
	selector: 'sgp-exibir-requisitos-oportunidade',
	templateUrl: './exibir-requisitos-oportunidade.component.html'
})
export class ExibirRequisitosOportunidadeComponent {
	@Input() oportunidade!: Oportunidade;

	@Output() obterOportunidade: EventEmitter<Oportunidade> = new EventEmitter<Oportunidade>();

	removerVinculo(vinculo: Vinculo) {
		const index = this.oportunidade.vinculos!.indexOf(vinculo)!;
		this.oportunidade.vinculos!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerLocalidade() {
		this.oportunidade.codigoMunicipioResidencia = undefined;
		this.atualizarOportunidade();
	}

	removerOrgaoLotacao() {
		this.oportunidade.codigoOrgaoLotacao = undefined;
		this.atualizarOportunidade();
	}

	removerOrgaoExercicio() {
		this.oportunidade.codigoOrgaoExercicio = undefined;
		this.atualizarOportunidade();
	}

	removerAreaConhecimento(area: AreaConhecimentoOportunidade) {
		const index = this.oportunidade.areasConhecimento!.indexOf(area)!;
		this.oportunidade.areasConhecimento!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerCompetencia(competencia: OportunidadeCompetencia) {
		const index = this.oportunidade.competencias!.indexOf(competencia)!;
		this.oportunidade.competencias!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerIdioma(idioma: OportunidadeIdioma) {
		const index = this.oportunidade.idiomas!.indexOf(idioma)!;
		this.oportunidade.idiomas!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerCertificacao(certificacao: Certificacao) {
		const index = this.oportunidade.certificacoes!.indexOf(certificacao)!;
		this.oportunidade.certificacoes!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerCapacitacao(capacitacao: Capacitacao) {
		const index = this.oportunidade.capacitacoes!.indexOf(capacitacao)!;
		this.oportunidade.capacitacoes!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerFormacao(formacao: Formacao) {
		const index = this.oportunidade.formacoes!.indexOf(formacao)!;
		this.oportunidade.formacoes!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerExperiencia(experiencia: Experiencia) {
		const index = this.oportunidade.experiencias!.indexOf(experiencia)!;
		this.oportunidade.experiencias!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerAreaAtuacao(areaAtuacao: AreaAtuacao) {
		const index = this.oportunidade.areasAtuacao!.indexOf(areaAtuacao)!;
		this.oportunidade.areasAtuacao!.splice(index, 1);
		this.atualizarOportunidade();
	}

	removerOutroRequisito() {
		this.oportunidade.outrosRequisitos = undefined;
		this.atualizarOportunidade();
	}

	private atualizarOportunidade() {
		this.obterOportunidade.emit(this.oportunidade);
	}
}
