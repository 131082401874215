import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ConsultasApiService } from '../services/consultas-api.service';
import { ConverterEditalInterfaceHelper } from '@core/helpers/converter-edital-interface.helper';
import { MatchService } from '@core/services/match.service';
import { Message } from 'primeng/api/message';
import { Oportunidade } from '@core/models/oportunidade';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';
import { UsuariosService } from '@core/services/usuarios.service';
import { ValidateHelper } from '@core/helpers/validate.helper';

@Component({
	selector: 'sgp-cadastrar-oportunidades',
	templateUrl: './cadastrar-oportunidades.component.html'
})
export class CadastrarOportunidadesComponent implements OnInit, OnDestroy {
	@Input() oportunidade!: Oportunidade;
	@Input() codigoOrgao!: string;
	@Input() operacao!: string;
	@Input() idEdital!: number;
	@Output() obterOportunidade: EventEmitter<Oportunidade> = new EventEmitter<Oportunidade>();
	@Output() obterOperacao: EventEmitter<string> = new EventEmitter<string>();
	@Output() salvaComSucesso: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('mensagemErro') mensagemErro!: ElementRef;

	oportunidade$: Oportunidade = {};

	@ViewChild('labelNomeOportunidade', { static: true }) labelNomeOportunidade!: ElementRef;
	@ViewChild('labelTipoMovimentacao', { static: true }) labelTipoMovimentacao!: ElementRef;
	@ViewChild('labelUnidade', { static: true }) labelUnidade!: ElementRef;
	@ViewChild('labelProgramaGestao', { static: true }) labelProgramaGestao!: ElementRef;
	@ViewChild('labelTipoJornada', { static: true }) labelTipoJornada!: ElementRef;
	@ViewChild('labelQuantidadeVagas', { static: true }) labelQuantidadeVagas!: ElementRef;
	labelBotaoSalvar = '';

	erroNomeOportunidade = false;
	erroTipoMovimentacao = false;
	erroUnidade = false;
	erroProgramaGestao = false;
	erroTipoJornada = false;
	erroQuantidadeVagas = false;
	existemRequisitos = false;

	unidades!: any[];
	nomeUnidadePadrao?: string;
	exibirModalBuscaUnidade = false;

	filteredLocalidade!: any[];
	todasLocalidades: any[] = [];

	filteredTipoMovimentacao!: any[];
	tiposMovimentacao!: any[];

	filteredTipoJornada!: any[];
	tiposJornada!: any[];

	filteredGraficacao!: any[];
	gratificacoes!: any[];

	filteredProgramaGestao!: any[];
	programasGestao!: any[];

	subscriptions: Subscription[] = [];

	possuiRequisito = false;
	exibirDica = false;
	msgErro: Message[] = [];

	constructor(
		private cadastrarOportunidadeApi: ConsultasApiService,
		private matchService: MatchService,
		private oportunidadesApi: OportunidadesApiService,
		private validate: ValidateHelper,
		private usuariosService: UsuariosService,
		private ui: UiService,
		private converter: ConverterEditalInterfaceHelper
	) {}

	ngOnInit(): void {
		this.oportunidade$ = Object.assign({}, this.oportunidade);
		this.getLabelBotao();
		this.getUnidadePadrao();
		this.getLocalidades();
		this.getTiposMovimentacao();
		this.getTiposJornada();
		this.getProgramasGestao();
		this.getGratificacoes();
		this.possuiRequisito = this.matchService.verificarSePossuiRequisitosMatch(this.oportunidade$);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	getLocalidades() {
		this.subscriptions.push(
			this.oportunidadesApi.localidades('*').subscribe(dados => {
				if (dados) {
					for (const dado of dados) {
						this.todasLocalidades.push({ name: dado.nome + ' - ' + dado.sigla_uf, code: dado.cod_ibge });
					}
					this.todasLocalidades.sort((a, b) => a.name.localeCompare(b.name));
				}
			})
		);
	}

	private verificarExistenciaRequisitos(_oportunidade: any) {
		const testeFormacao = !('formacoes' in _oportunidade) || _oportunidade.formacoes?.length === 0;
		const testeExperiencia = !('experiencias' in _oportunidade) || _oportunidade.experiencias?.length === 0;
		const testeAreaAtuacao = !('areasAtuacao' in _oportunidade) || _oportunidade.areasAtuacao?.length === 0;
		const testeAreaConhecimento = !('areasConhecimento' in _oportunidade) || _oportunidade.areasConhecimento?.length === 0;
		const testeCapacitacao = !('capacitacoes' in _oportunidade) || _oportunidade.capacitacoes?.length === 0;
		const testeCertificacao = !('certificacoes' in _oportunidade) || _oportunidade.certificacoes?.length === 0;
		const testeCompetencia = !('competencias' in _oportunidade) || _oportunidade.competencias?.length === 0;
		const testeVinculo = !('vinculos' in _oportunidade) || _oportunidade.vinculos?.length === 0;
		const testeIdioma = !('idiomas' in _oportunidade) || _oportunidade.idiomas?.length === 0;
		const testeOutrosRequisitos = _oportunidade.outrosRequisitos == null || _oportunidade.outrosRequisitos.length == 0;
		if (
			testeFormacao &&
			_oportunidade.codigoMunicipioResidencia == null &&
			_oportunidade.nomeOrgaoLotacao == null &&
			_oportunidade.nomeOrgaoExercicio == null &&
			testeExperiencia &&
			testeAreaAtuacao &&
			testeAreaConhecimento &&
			testeCapacitacao &&
			testeCertificacao &&
			testeCompetencia &&
			testeVinculo &&
			testeIdioma &&
			testeOutrosRequisitos
		) {
			return false;
		}
		return true;
	}

	/**
	 * Retorna o valor para o listar oportunidades para salvar
	 */
	salvarOportunidade() {
		this.prepararSalvarOportunidade();

		const ioportunidade = this.converter.criarIOportunidadeAPartirDeUmaOportunidade(this.oportunidade$);

		this.existemRequisitos = this.verificarExistenciaRequisitos(ioportunidade);

		if (!this.existemRequisitos) {
			this.msgErro = this.ui.criarListaMensagem('error', '', 'Deve-se selecionar pelo menos um requisito');
			this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}

		if (this.eValido(this.oportunidade$) && this.existemRequisitos) {
			if (this.operacao === 'alterar') {
				this.subscriptions.push(
					this.oportunidadesApi.alterarOportunidade(ioportunidade).subscribe(
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						_ => {
							this.salvaComSucesso.emit();
						},
						err => {
							this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
							this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}
					)
				);
			} else {
				this.subscriptions.push(
					this.oportunidadesApi.incluirOportunidade(this.idEdital, ioportunidade).subscribe(
						_ => {
							this.salvaComSucesso.emit();
						},
						err => {
							this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
							this.mensagemErro.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
						}
					)
				);
			}
		}
	}

	updateOportunidade(event: Oportunidade) {
		this.oportunidade$ = event;
		this.possuiRequisito = this.matchService.verificarSePossuiRequisitosMatch(this.oportunidade$);
	}

	/**
	 * Cancela a operação, voltando para o listar oportunidade
	 */
	cancelar() {
		this.operacao = 'listar';
		this.obterOperacao.emit(this.operacao);
	}

	filterLocalidade(event: any): void {
		const localidadesEncontradas: any[] = [];

		const query = event.query;
		this.todasLocalidades.forEach((localidade: { name: string }) => {
			if (localidade.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
				localidadesEncontradas.push(localidade);
			}
		});
		this.filteredLocalidade = localidadesEncontradas;
	}

	/**
	 * Filtra o combo de tipo de movimentação
	 * @param event
	 */
	filterTipoMovimentacao(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.tiposMovimentacao) {
			for (const tipoMovimentacao of this.tiposMovimentacao) {
				if (tipoMovimentacao.descricao.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(tipoMovimentacao);
				}
			}
		}

		this.filteredTipoMovimentacao = filtered;
	}

	/**
	 * Filtra o combo de tipo de jornada
	 * @param event
	 */
	filterTipoJornada(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.tiposJornada) {
			for (const tipoJornada of this.tiposJornada) {
				if (tipoJornada.descricao.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(tipoJornada);
				}
			}
		}

		this.filteredTipoJornada = filtered;
	}

	/**
	 * Filtra o combo de gratificações
	 * @param event
	 */
	filterGratificacao(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.gratificacoes) {
			for (const gratificacao of this.gratificacoes) {
				if (gratificacao.descricao.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(gratificacao);
				}
			}
		}

		this.filteredGraficacao = filtered;
	}

	/**
	 * Filtra o combo de programa de gestão
	 * @param event
	 */
	filterProgramaGestao(event: any): void {
		const filtered: any[] = [];
		const query = event.query;

		if (this.programasGestao) {
			for (const programaGestao of this.programasGestao) {
				if (programaGestao.descricao.toLowerCase().indexOf(query.toLowerCase()) != -1) {
					filtered.push(programaGestao);
				}
			}
		}

		this.filteredProgramaGestao = filtered;
	}

	/**
	 * Retorna o label do botão salvar, dependendo da operação
	 */
	private getLabelBotao() {
		if (this.operacao === 'cadastrar') {
			this.labelBotaoSalvar = 'Adicionar oportunidade';
		} else if (this.operacao === 'alterar') {
			this.labelBotaoSalvar = 'Alterar oportunidade';
		}
	}

	private getUnidadePadrao() {
		if (!this.oportunidade$.codigoUorg) {
			this.oportunidade$.codigoUorg = +this.usuariosService.getCodigoUnidadeUsuario();
			this.subscriptions.push(
				this.cadastrarOportunidadeApi
					.nomeUnidadeOrganizacional(this.codigoOrgao, this.oportunidade$.codigoUorg.toString())
					.subscribe(data => {
						this.nomeUnidadePadrao = data;
						this.oportunidade$.unidade = { codigo: this.oportunidade$.codigoUorg, descricao: this.nomeUnidadePadrao };
					})
			);
		} else {
			this.nomeUnidadePadrao = this.oportunidade$.nomeUorg;
		}
	}

	/**
	 * Carrega a combo de tipos de movimentação
	 */
	private getTiposMovimentacao(): void {
		this.tiposMovimentacao = [];
		this.subscriptions.push(
			this.cadastrarOportunidadeApi.tiposMovimentacao(this.codigoOrgao).subscribe(dados => {
				if (dados !== null) {
					dados.forEach(dado => {
						if (dado.ativo) {
							this.tiposMovimentacao.push({ descricao: dado.descricao, codigo: dado.codigo });
							this.tiposMovimentacao.sort((a, b) => a.descricao.localeCompare(b.descricao));
						}
					});
				}
			})
		);
	}

	/**
	 * Carrega a combo de tipos de jornada
	 */
	private getTiposJornada(): void {
		this.tiposJornada = [];
		this.subscriptions.push(
			this.cadastrarOportunidadeApi.tiposJornada().subscribe(dados => {
				if (dados !== null) {
					dados.forEach(dado => {
						this.tiposJornada.push({ descricao: dado.descricao, codigo: dado.codigo });
						this.tiposJornada.sort((a, b) => a.descricao.localeCompare(b.descricao));
					});
				}
			})
		);
	}

	/**
	 * Carrega a combo de programas de gestão
	 */
	private getProgramasGestao(): void {
		this.programasGestao = [];
		this.subscriptions.push(
			this.cadastrarOportunidadeApi.programasGestao().subscribe(dados => {
				if (dados !== null) {
					dados.forEach(dado => {
						this.programasGestao.push({ descricao: dado.descricao, codigo: dado.codigo });
						this.programasGestao.sort((a, b) => a.descricao.localeCompare(b.descricao));
					});
				}
			})
		);
	}

	/**
	 * Carrega a combo de incentivos/gratificações
	 */
	private getGratificacoes(): void {
		this.gratificacoes = [];
		this.subscriptions.push(
			this.cadastrarOportunidadeApi.incentivos().subscribe(dados => {
				if (dados !== null) {
					dados.forEach(dado => {
						if (dado.ativo) {
							this.gratificacoes.push({ descricao: dado.nome, codigo: dado.id });
							this.gratificacoes.sort((a, b) => a.descricao.localeCompare(b.descricao));
						}
					});
				}
			})
		);
	}

	atualizarUnidade(event: any) {
		this.nomeUnidadePadrao = event.nome;
		this.oportunidade$.unidade = event;
		this.oportunidade$.codigoUorg = event.codigoSiape;
		this.oportunidade$.nomeUorg = event.nome;
	}

	atualizarExibirModalBuscaUnidade(event: boolean) {
		this.exibirModalBuscaUnidade = event;
	}

	atualizarLocalidade(event: any) {
		if (event?.value) {
			this.oportunidade$.localidade = event.value;
		} else {
			this.oportunidade$.localidade = event;
		}
	}

	atualizarIncentivo(event: any) {
		if (event?.value) {
			this.oportunidade$.incentivo = event.value;
		} else {
			this.oportunidade$.incentivo = event;
		}
	}

	/**
	 * Faz validações e ajustes antes de salvar a oportunidade.
	 */
	private prepararSalvarOportunidade() {
		if (this.oportunidade$.localidade?.code !== undefined) {
			this.oportunidade$.codigoLocalidade = +this.oportunidade$.localidade?.code;
			this.oportunidade$.nomeLocalidade = this.oportunidade$.localidade?.name;
		}

		if (this.oportunidade$.unidade?.codigo !== undefined) {
			this.oportunidade$.codigoUorg = +this.oportunidade$.unidade.codigo;
			this.oportunidade$.nomeUorg = this.oportunidade$.unidade.descricao;
		}

		this.oportunidade$.idIncentivo = this.oportunidade$.incentivo?.codigo ? 
										+this.oportunidade$.incentivo.codigo
										: undefined;
	}

	/**
	 * Validação dos campos da oportunidade
	 * @param oportunidade oportunidade validada
	 * @returns true | false
	 */
	private eValido(oportunidade: Oportunidade): boolean {
		let encontrouErro = false;

		if (oportunidade === undefined) return false;

		//Validação do nome
		if (this.validate.isEmBranco(oportunidade.nomeOportunidade)) {
			this.erroNomeOportunidade = true;
			this.labelNomeOportunidade.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroNomeOportunidade = false;
		}

		//validação da unidade
		if (this.validate.isEmBranco(oportunidade.codigoUorg)) {
			this.erroUnidade = true;
			this.labelUnidade.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroUnidade = false;
		}

		//validação do tipo de movimentação
		if (this.validate.isEmBranco(oportunidade.tipoMovimentacao)) {
			this.erroTipoMovimentacao = true;
			this.labelTipoMovimentacao.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroTipoMovimentacao = false;
		}

		//validação do programa de gestão
		if (this.validate.isEmBranco(oportunidade.programaGestao)) {
			this.erroProgramaGestao = true;
			this.labelProgramaGestao.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroProgramaGestao = false;
		}

		//validação do tipo jornada
		if (this.validate.isEmBranco(oportunidade.tipoJornada)) {
			this.erroTipoJornada = true;
			this.labelTipoJornada.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroTipoJornada = false;
		}

		//Validação da quantidade de vagas
		if (this.validate.isEmBranco(oportunidade.numeroVagas)) {
			this.erroQuantidadeVagas = true;
			this.labelQuantidadeVagas.nativeElement.scrollIntoView();
			encontrouErro = true;
		} else {
			this.erroQuantidadeVagas = false;
		}

		if (encontrouErro) {
			return false;
		}

		return true;
	}
}
