import { AnexoCandidato } from '@app/core/models/anexoCandidato';
import { Candidato } from '@app/core/models/candidato';
import { DetalheCandidato } from '../models/detalhe-candidato';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notificacao } from './../models/notificacao';
import { NotificacaoCandidato } from '../models/notificacao-candidato';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class VisualizarEditalApiService {
	constructor(private http: HttpClient) {}

	getCandidatosOportunidade(idOportunidade: number): Observable<Candidato[]> {
		const url = environment.EDITAL + '/oportunidades/' + idOportunidade.toString() + '/candidatos';

		return this.http.get<Candidato[]>(url);
	}

	//TODO: Alterar tipo de retorno para Candidato
	getCandidato(idCandidato: number): Observable<DetalheCandidato> {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/' + idCandidato.toString();
		return this.http.get<DetalheCandidato>(url);
	}

	consultarHistoricoEdital(id: number): Observable<any> {
		const url = environment.EDITAL + '/' + id.toString() + '/historico';
		return this.http.get(url);
	}

	marcarCandidatoFavorito(idCandidato: number) {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/' + idCandidato.toString() + '/favoritar';
		return this.http.put(url, null);
	}

	marcarCandidatoAnalisado(idCandidato: number) {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/' + idCandidato.toString() + '/marcar';
		return this.http.put(url, null);
	}

	downloadEditalPublicado(idHistorico: number): Observable<any> {
		const url = environment.SERVICOS_TERCEIROS + '/editais/' + idHistorico.toString() + '/download';
		return this.http.get(url, {
			responseType: 'arraybuffer'
		});
	}

	gerarPdfEdital(id: number): Observable<Blob> {
		const url = environment.EDITAL + '/' + id.toString() + '/edital.pdf';
		return this.http.get(url, { responseType: 'blob' });
	}

	visualizarCurriculo(idOportunidade: number, idCandidato: number): Observable<any> {
		const url = environment.OPORTUNIDADE + '/' + idOportunidade.toString() + '/candidatos/' + idCandidato.toString();
		return this.http.get(url, {
			responseType: 'arraybuffer'
		});
	}

	visualizarMultiplosCurriculos(idOportunidade: number, idsCandidatos: number[]): Observable<any> {
		const url = environment.OPORTUNIDADE + '/' + idOportunidade.toString() + '/candidatos/zip';

		return this.http.post(url, { lista_curriculos: idsCandidatos }, { responseType: 'arraybuffer' });
	}

	consultarAnexosCandidato(idCandidato: number): Observable<AnexoCandidato[]> {
		const url = environment.SERVICOS_TERCEIROS + '/candidatos/' + idCandidato.toString() + '/anexos';

		return this.http.get<AnexoCandidato[]>(url);
	}

	excluirAnexoCandidato(idAnexo: number): Observable<any> {
		const url = environment.SERVICOS_TERCEIROS + '/candidatos/anexos/' + idAnexo.toString();

		return this.http.delete(url);
	}

	downloadAnexoCandidatoOportunidade(idAnexo: number): Observable<any> {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/anexos/' + idAnexo.toString();
		return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
	}

	downloadTodosAnexosCandidato(idOportunidade: number, idCandidato: number): Observable<any> {
		const url = environment.OPORTUNIDADE + '/' + idOportunidade.toString() + '/candidatos/anexos?candidato_id=' + idCandidato;
		return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
	}

	baixarAnexosDeMultiplosCandidatos(idOportunidade: number, idsCandidatos: number[]): Observable<any> {
		let candidatos = '?';
		for (let i = 0; i < idsCandidatos.length; i++) {
			candidatos = candidatos + 'candidato_id=' + idsCandidatos[i];
			if (i + 1 < idsCandidatos.length) {
				candidatos = candidatos + '&';
			}
		}
		const url = environment.OPORTUNIDADE + '/' + idOportunidade.toString() + '/candidatos/anexos' + candidatos;
		return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
	}

	notificarCandidatos(notificacao: NotificacaoCandidato): Observable<any> {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/notificar/';
		return this.http.put(url, notificacao);
	}

	consultarNotificacoesCandidato(idCandidato: number): Observable<Notificacao[]> {
		const url = environment.CANDIDATO_OPORTUNIDADE + '/candidatos/' + idCandidato.toString() + '/notificacoes';
		return this.http.get<Notificacao[]>(url);
	}
}
