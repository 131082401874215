<div class="row">
	<div class="col">
		<button pButton class="br-button" icon="fas fa-chevron-left" label="Voltar" (click)="voltarParaLista()"></button>
	</div>
</div>

<div class="mt-3">
	<p-messages [(value)]="mensagens" [enableService]="false" [closable]="true"></p-messages>
</div>

<div class="row mt-2" *ngIf="!loading && candidato">
	<!-- coluna de informações do usuario -->
	<aside class="col-sm-4" id="informacoes-candidato">
		<div class="br-card">
			<div class="card-header text-center">
				<div class="d-flex">
					<div id="avatar-candidato">
						<img
							src="{{ candidato.foto }}"
							class="mr-2"
							alt="Foto do candidato"
							[ngStyle]="{'width': '54px', 'height': '54px', 'border-radius': '50%', 'background-color': '#dedede'}"
						/>
					</div>
					<div class="ml-1" id="nome-candidato">
						<div class="text-weight-semi-bold text-up-02 text-left text-capitalize">
							{{ candidato.nomeCandidato?.toLowerCase() }}
						</div>
						<div class="text-base text-left">Oportunidade: {{ candidato.nomeOportunidade }}</div>
					</div>
				</div>

				<div class="mt-2" id="icones-acao">
					<div
						[ngClass]="{'d-inline mr-2': true, 'cursor-not-allowed' : editalComSituacaoFinalistica, 'cursor-pointer': !editalComSituacaoFinalistica}"
					>
						<div
							class="br-tag icon small tag-table"
							[ngClass]="{'bg-gray-20': !candidato.favorito, 'bg-gold-vivid-20': candidato.favorito, 'cursor-no-events': editalComSituacaoFinalistica}"
							(click)="marcarDesmarcarFavorito(candidato.id!)"
							title="Marcar como favorito"
						>
							<i class="fas fa-star text-down-01"></i>
						</div>
					</div>

					<div
						[ngClass]="{'d-inline': true, 'cursor-not-allowed' : editalComSituacaoFinalistica, 'cursor-pointer': !editalComSituacaoFinalistica}"
					>
						<div
							class="br-tag icon small tag-table"
							[ngClass]="{'bg-gray-20': !candidato.marcado, 'bg-green-vivid-30': candidato.marcado, 'cursor-no-events' : editalComSituacaoFinalistica}"
							(click)="marcarDesmarcarAnalisado(candidato.id!)"
							title="Marcar currículo como analisado"
						>
							<i class="fas fa-check text-down-01"></i>
						</div>
					</div>
				</div>

				<div class="my-4" id="botoes">
					<button
						pButton
						class="br-button secondary mr-2"
						type="button"
						(click)="enviarNotificacao()"
						title="Enviar notificação para email do candidato"
					>
						Notificar
					</button>
					<button pButton class="br-button secondary" type="button" (click)="baixarCurriculo()">Baixar currículo</button>
				</div>

				<span class="br-divider my-3"></span>
			</div>

			<div class="card-content">
				<div class="text-weight-bold text-up-01 mb-3">Contato</div>
				<div class="text-base"><i class="fas fa-envelope"></i> {{ candidato.emailCandidato | imprimirDash}}</div>
				<div class="text-base mt-2">
					<i class="fas fa-phone-alt"></i> <span class="display-sm-none">{{ candidato.celular | imprimirDash}}</span>
					<a href="tel:+55{{candidato.celular}}" class="display-sm-inline" *ngIf="candidato.celular">{{ candidato.celular }}</a>
				</div>

				<div class="text-base mt-4">
					<div class="text-weight-bold text-up-01 mb-3">Informações do candidato</div>
					<div>
						<span class="text-weight-semi-bold d-block">Tipo de vínculo:</span>
						<span class="text-capitalize">{{ candidato.nomeVinculo?.toLowerCase()| imprimirDash}}</span>
					</div>
					<div class="mt-2">
						<span class="text-weight-semi-bold d-block">Órgão de lotação:</span>
						<span class="text-capitalize">{{candidato.orgaoLotacao?.toLowerCase()| imprimirDash}}</span>
					</div>
					<div class="mt-2">
						<span class="text-weight-semi-bold d-block">Órgão de exercício:</span>
						<span class="text-capitalize">{{candidato.orgaoExercicio?.toLowerCase()| imprimirDash}}</span>
					</div>
					<div class="mt-2">
						<span class="text-weight-semi-bold d-block">Cargo:</span>
						<span class="text-capitalize">{{candidato.cargo?.toLowerCase()| imprimirDash }}</span>
					</div>
					<div class="mt-2">
						<span class="text-weight-semi-bold d-block">Programa de gestão:</span>
						<span class="text-capitalize">{{candidato.programaGestao?.toLowerCase()| imprimirDash}}</span>
					</div>
					<div class="mt-2">
						<span class="text-weight-semi-bold d-block">Localidade de residência:</span>
						<span class="text-capitalize">{{candidato.localidadeResidencia?.toLowerCase()| imprimirDash}}</span>
					</div>
				</div>
			</div>
		</div>
	</aside>

	<!-- Anexos e mensagens -->
	<section class="col-sm-8" id="conteudo-candidato">
		<div class="br-card">
			<div class="card-content">
				<p-tabView>
					<p-tabPanel header="Anexos">
						<sgp-detalhar-anexos-candidato
							[idCandidato]="idCandidato"
							[idOportunidade]="idOportunidade"
							(mensagemFeedback)="obterMensagem($event)"
						></sgp-detalhar-anexos-candidato>
					</p-tabPanel>
					<p-tabPanel header="Notificações">
						<sgp-detalhar-mensagens-candidato
							[idCandidato]="idCandidato"
							[atualizarNotificacoes]="atualizarNotificacoes"
							(mensagemFeedback)="obterMensagem($event)"
							(retornoAtualizarNotificacoes)="atualizarNotificacoes = $event"
						></sgp-detalhar-mensagens-candidato>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
	</section>

	<sgp-enviar-notificacao
		[candidatos]="candidatoSelecionado"
		[numeroEdital]="numeroEdital"
		[nomeOrgao]="nomeOrgao"
		[nomeOportunidade]="nomeOportunidade"
		[exibirModal]="exibirModalNotificacao"
		(eventShowModal)="fecharModalNotificacao($event)"
		(mensagemFeedback)="obterMensagem($event)"
		*ngIf="exibirModalNotificacao"
	></sgp-enviar-notificacao>
</div>
