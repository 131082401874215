<h3 data-cy="titulo-pagina">Disparar mensagem de alerta</h3>

<p-messages [value]="mensagem" [closable]="false"></p-messages>

<div class="mt-6">
	<form [formGroup]="form">
		<div class="row mt-2">
			<div class="col-sm-12">
				<div class="br-textarea" [ngClass]="{ 'danger': erroMensagem }">
					<label for="mensagem">Mensagem:</label>
					<textarea id="mensagem" rows="" cols="" formControlName="mensagem" maxlength="524" #textoMensagem></textarea>
					<p class="text-base mt-1 text-right">Máximo de caracteres: {{textoMensagem.value.length}}/524</p>
					<sgp-message-feedback [mensagem]="textoErroMensagem" [show]="erroMensagem"></sgp-message-feedback>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-sm-12">
				<div class="br-input" [ngClass]="{ 'danger': erroLink }">
					<label for="link">Link para mais informações (opcional):</label>
					<input id="link" type="url" formControlName="link" placeholder="Exemplo: https://www.gov.br" />
					<sgp-message-feedback
						mensagem="O link precisa ser de um endereço válido do Governo Federal (conter .gov.br)"
						[show]="erroLink"
					></sgp-message-feedback>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-12 col-sm-4">
				<div class="br-input has-icon" [ngClass]="{ 'danger': erroPeriodo }">
					<label for="periodo">Período de divulgação:</label>
					<p-calendar
						formControlName="periodo"
						selectionMode="range"
						[showIcon]="true"
						inputId="periodo"
						icon="fas fa-calendar-alt"
						yearRange="2020:2040"
						dateFormat="dd/mm/yy"
						rangeSeparator=" até "
						placeholder="dd/mm/aaaa até dd/mm/aaaa"
					></p-calendar>
					<sgp-message-feedback mensagem="Campo obrigatório" [show]="erroPeriodo"></sgp-message-feedback>
				</div>
			</div>
		</div>
	</form>

	<div class="row mt-2">
		<div class="col-sm-12 mt-4 text-right">
			<p-button label="Limpar" (click)="limpar()" styleClass="br-button secondary"></p-button>
			<p-button
				label="Excluir"
				(click)="prepararExcluir()"
				styleClass="br-button secondary ml-2"
				[disabled]="!mensagemAlerta?.mensagem"
			></p-button>
			<p-button label="Salvar" (click)="prepararSalvarMensagem()" styleClass="br-button primary ml-2"></p-button>
		</div>
	</div>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
