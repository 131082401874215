<h3 data-cy="titulo-pagina">Cesta de Currículos</h3>

<p-messages [(value)]="messages" [closable]="false"></p-messages>

<div class="my-3 p-4 shadow-sm bg-gray-2">
	<div class="row">
		<div class="col-sm-6">
			<label for="campo-selecionar-tags">Filtrar etiquetas</label>
			<p-multiSelect
				[options]="tags"
				[filter]="false"
				data-cy="campo-selecionar-tags"
				[showToggleAll]="false"
				[showHeader]="false"
				[(ngModel)]="tagsSelecionadasString"
				defaultLabel="Selecione uma ou mais etiquetas"
				emptyMessage="Nenhuma etiqueta cadastrada"
				[panelStyle]="{overflowY: 'hidden'}"
			></p-multiSelect>
		</div>
	</div>
	<div class="row mt-4">
		<div class="col-sm-12 text-right">
			<button
				id="btnPesquisar"
				data-cy="botao-pesquisar"
				class="primary mt-3 mt-sm-0 ml-sm-3"
				(click)="pesquisar()"
				type="submit"
				pButton
			>
				Pesquisar
			</button>
			<button id="btnLimpar" data-cy="botao-limpar" class="secondary mt-3 mt-sm-0 ml-sm-3" (click)="limpar()" type="button" pButton>
				Limpar
			</button>
		</div>
	</div>
</div>

<sgp-resultado-cesta-curriculo
	[resultado]="retorno"
	(resposta)="atualizarResultado($event)"
	[itemsPorPagina]="itemsPorPagina"
	[pagina]="pagina"
	[primeiroItemResultado]="primeiroItemPesquisa"
	[totalResultado]="totalResultado"
	(repesquisar)="rePesquisar($event)"
	*ngIf="retorno"
></sgp-resultado-cesta-curriculo>
