import { HttpClient, HttpParams } from '@angular/common/http';
import {
	ResultadoPesquisarDashboardProfissiogafico,
	TotaisDashboardPsicografico
} from '../models/perfil-profissiografico/resultado-pesquisar-dashboard-profissiogafico';

import { FiltroDashboardProfissiografico } from '../models/perfil-profissiografico/filtro-dashboard-profissiografico';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'any'
})
export class PerfilProfissiograficoDashboardApiService {
	constructor(private http: HttpClient) {}

	getTotais(idConcurso?: number, codigoOrgao?: number): Observable<any> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<any>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/totalizador-questionarios-laudos`, {
			params: params
		});
	}
	getSituacaoLiberacaoQuestionario(idConcurso?: number, codigoOrgao?: number, pagina?: number, tamanho?: number): Observable<any> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		if (pagina) {
			params = params.set('page', pagina);
		}
		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/situacao-liberacao-questionario`, {
			params: params
		});
	}

	getTotalAtribiucaoLaudos(idConcurso?: number, codigoOrgao?: number): Observable<any> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<any>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/total-atribuicao-laudos`, {
			params: params
		});
	}

	getTotalCienciaLaudos(idConcurso?: number, codigoOrgao?: number): Observable<any> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<any>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/total-ciencia-laudos`, { params: params });
	}

	/* getTotalQuestionariosEnviados(idConcurso?: number, codigoOrgao?: number): Observable<TotaisDashboardPsicografico> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<TotaisDashboardPsicografico>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/total-questionarios-enviados`, {
			params: params
		});
	} */

	/* getTotalLaudosPrioridados(idConcurso?: number, codigoOrgao?: number): Observable<TotaisDashboardPsicografico> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<TotaisDashboardPsicografico>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/total-laudos-priorizados`, {
			params: params
		});
	} */

	getTotalSituacaoLaudos(idConcurso?: number, codigoOrgao?: number): Observable<any> {
		let params = new HttpParams();
		if (idConcurso) {
			params = params.append('id_concurso', idConcurso);
		}
		if (codigoOrgao) {
			params = params.append('codigo_orgao', codigoOrgao);
		}
		return this.http.get<any>(`${environment.PERFIL_PROFISSIOGRAFICO_DASHBOARD}/total-situacao-laudos`, {
			params: params
		});
	}

	getAll(filtro: FiltroDashboardProfissiografico): ResultadoPesquisarDashboardProfissiogafico {
		const result = new ResultadoPesquisarDashboardProfissiogafico();
		//const a = new Observable<ResultadoPesquisarDashboardProfissiogafico>();
		console.log(filtro);
		if (filtro.idConcurso && filtro.codigoOrgao) {
			this.getSituacaoLiberacaoQuestionario(filtro.idConcurso, filtro.codigoOrgao).subscribe(resultado => {
				result.situcaoLiberacaoQuestionario = { dados: resultado.dados, legenda: resultado.legenda, total: resultado.dados.length };
			});
			/* this.getTotalLaudosPrioridados(filtro.idConcurso, filtro.codigoOrgao).subscribe((resultado: TotaisDashboardPsicografico) => {
				result.totalLaudosPriorizados = resultado;
			}); */
			this.getTotalAtribiucaoLaudos(filtro.idConcurso, filtro.codigoOrgao).subscribe(resultado => {
				result.situacaoAtribuicaoLaudos = resultado;
			});
			this.getTotalCienciaLaudos(filtro.idConcurso, filtro.codigoOrgao).subscribe(resultado => {
				result.situacaoCienciaLaudos = resultado;
			});
			/* this.getTotalQuestionariosEnviados(filtro.idConcurso, filtro.codigoOrgao).subscribe(
				(resultado: TotaisDashboardPsicografico) => {
					result.totalQuestionariosEnviados = resultado;
				}
			); */
			this.getTotalSituacaoLaudos(filtro.idConcurso, filtro.codigoOrgao).subscribe(resultado => {
				result.situacaoLaudos = resultado;
			});
		}

		return result;
	}
}
