<div class="row" style="width: 90%">
	<div class="col">
		<span>Para que o texto seja exibido adequadamente, evite:</span>
		<ul>
			<li>Copiar textos de sites ou portais;</li>
			<li>Usar os caracteres "<" e ">";</li>
			<li>Usar caracteres especiais.</li>
		</ul>

		<span>Caso seja necessário, você pode limpar a formatação do texto usando o botão "Tx"</span>
	</div>
</div>
