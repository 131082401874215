<div class="row">
	<div class="br-list col-8 mb-4 ml-4" role="list">
		<!-- vinculo -->
		<div *ngIf="oportunidade.vinculos && oportunidade.vinculos.length > 0" data-cy="requisito-vinculo">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-vinculo">Tipos de vínculos</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let vinculo of oportunidade.vinculos">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerVinculo(vinculo)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-vinculo">
						{{ vinculo.vinculo?.nome }} <span *ngIf="vinculo.obrigatorio"> - Obrigatório</span
						><span *ngIf="vinculo.excetoEstagioProbatorio">: Exceto em estágio probatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- residência -->
		<div *ngIf="oportunidade.codigoMunicipioResidencia" data-cy="requisito-residencia">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-residencia">
				Residir na localidade
			</div>
			<div class="pl-3 text-red-vivid-60">
				<i class="fas fa-exclamation-triangle text-red-vivid-60"></i> Não faz parte do cálculo de estatística
			</div>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerLocalidade()"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-residencia">{{ oportunidade.nomeMunicipioResidencia }}</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- órgão de lotação -->
		<div *ngIf="oportunidade.codigoOrgaoLotacao" data-cy="requisito-orgao-lotacao">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-orgao-lotacao">Órgão de lotação</div>
			<div class="pl-3 text-red-vivid-60"><i class="fas fa-exclamation-triangle"></i> Não faz parte do cálculo de estatística</div>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerOrgaoLotacao()"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-orgao-lotacao">{{ oportunidade.nomeOrgaoLotacao }} - Obrigatório</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- órgão de exercício -->
		<div *ngIf="oportunidade.codigoOrgaoExercicio" data-cy="requisito-orgao-exercicio">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-orgao-exercicio">
				Órgão de exercício
			</div>
			<div class="pl-3 text-red-vivid-60"><i class="fas fa-exclamation-triangle"></i> Não faz parte do cálculo de estatística</div>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerOrgaoExercicio()"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-orgao-exercicio">{{ oportunidade.nomeOrgaoExercicio }} - Obrigatório</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- area conhecimento -->
		<div *ngIf="oportunidade.areasConhecimento && oportunidade.areasConhecimento.length > 0" data-cy="requisito-area-conhecimento">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-area-conhecimento">
				Conhecimentos técnicos
			</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let area of oportunidade.areasConhecimento">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerAreaConhecimento(area)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-area-conhecimento">
						{{ area.nome }} <span *ngIf="area.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- softskills -->
		<div *ngIf="oportunidade.competencias && oportunidade.competencias.length > 0" data-cy="requisito-softskill">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-softskill">Softskills</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let competencia of oportunidade.competencias">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerCompetencia(competencia)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-softskill">
						{{ competencia.nome }} <span *ngIf="competencia.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- idiomas -->
		<div *ngIf="oportunidade.idiomas && oportunidade.idiomas.length > 0" data-cy="requisito-idiomas">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-idiomas">Idiomas</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let idioma of oportunidade.idiomas">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerIdioma(idioma)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col">
						<div data-cy="item-lista-idiomas">{{ idioma.nome }} <span *ngIf="idioma.obrigatorio"> - Obrigatório</span></div>
						<div class="row mt-1">
							<div class="col-3">
								Nível compreensão:
								<p-rating
									[ngModel]="idioma.nivelCompreensao | rating"
									(ngModelChange)="idioma.nivelCompreensao=$event"
									[stars]="3"
									iconOnClass="fas fa-star"
									iconOffClass="far fa-star"
									[disabled]="true"
									[cancel]="false"
								></p-rating>
							</div>
							<div class="col-3">
								<span *ngIf="idioma?.idIdioma === 39; else naoLibras">Nível expressão:</span>
								<ng-template #naoLibras>Nível fala:</ng-template>
								<p-rating
									[ngModel]="idioma.nivelFala | rating"
									(ngModelChange)="idioma.nivelFala=$event"
									[stars]="3"
									iconOnClass="fas fa-star"
									iconOffClass="far fa-star"
									[disabled]="true"
									[cancel]="false"
								></p-rating>
							</div>
							<div class="col-3" *ngIf="idioma?.idIdioma !== 39">
								Nível escrita:
								<p-rating
									[ngModel]="idioma.nivelEscrita | rating"
									(ngModelChange)="idioma.nivelEscrita=$event"
									[stars]="3"
									iconOnClass="fas fa-star"
									iconOffClass="far fa-star"
									[disabled]="true"
									[cancel]="false"
								></p-rating>
							</div>
							<div class="col-3" *ngIf="idioma?.idIdioma !== 39">
								Nível leitura:
								<p-rating
									[ngModel]="idioma.nivelLeitura | rating"
									(ngModelChange)="idioma.nivelLeitura=$event"
									[stars]="3"
									iconOnClass="fas fa-star"
									iconOffClass="far fa-star"
									[disabled]="true"
									[cancel]="false"
								></p-rating>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- certificacoes -->
		<div *ngIf="oportunidade.certificacoes && oportunidade.certificacoes.length > 0" data-cy="requisito-certificacoes">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-certificacoes">Certificações</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let certificacao of oportunidade.certificacoes">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerCertificacao(certificacao)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-certificacoes">
						{{ certificacao.nomeCertificacao }} <span *ngIf="certificacao.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- capacitacoes -->
		<div *ngIf="oportunidade.capacitacoes && oportunidade.capacitacoes.length > 0" data-cy="requisito-capacitacoes">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-capacitacoes">Capacitações</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let capacitacao of oportunidade.capacitacoes">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerCapacitacao(capacitacao)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-capacitacoes">
						{{ capacitacao.nomeCapacitacao }} <span *ngIf="capacitacao.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- formacoes -->
		<div *ngIf="oportunidade.formacoes && oportunidade.formacoes.length > 0" data-cy="requisito-formacoes">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-formacoes">Formação acadêmica</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let formacao of oportunidade.formacoes">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerFormacao(formacao)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-formacoes">
						<span class="text-capitalize">{{ formacao.nomeNivelCurso?.toLowerCase() }}</span>
						<span class="text-capitalize" *ngIf="formacao.nomeFormacao"> em {{ formacao.nomeFormacao.toLowerCase() }}</span>
						<span *ngIf="formacao.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- experiencias -->
		<div
			data-cy="requisito-experiencia"
			id="requisito-experiencia"
			*ngIf="oportunidade.experiencias && oportunidade.experiencias.length > 0"
		>
			<div
				class="align-items-center br-item subtitle"
				role="listitem"
				data-cy="titulo-requisito-experiencia"
				id="titulo-requisito-experiencia"
			>
				Experiência profissional
			</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let experiencia of oportunidade.experiencias">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerExperiencia(experiencia)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div data-cy="item-lista-experiencia" class="item-lista-experiencia">
						Experiência: {{ experiencia.nomeExperiencia }}
						<span *ngIf="experiencia.servicoPublico"> - No serviço público</span>
						<span *ngIf="experiencia.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
				<div class="row mt-1">
					<div class="col ml-5">Meses de experiência: {{experiencia.qtdMinimaMesesExperiencia}}</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- area_atuacao -->
		<div *ngIf="oportunidade.areasAtuacao && oportunidade.areasAtuacao.length > 0" data-cy="requisito-area-atuacao">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-area-atuacao">Área de atuação</div>
			<div class="align-items-center br-item" role="listitem" *ngFor="let areaAtuacao of oportunidade.areasAtuacao">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerAreaAtuacao(areaAtuacao)"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-area-atuacao">
						{{ areaAtuacao.nome }}<span *ngIf="areaAtuacao.obrigatorio"> - Obrigatório</span>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>

		<!-- outros requisitos -->
		<div *ngIf="oportunidade.outrosRequisitos" data-cy="requisito-outro">
			<div class="align-items-center br-item subtitle" role="listitem" data-cy="titulo-requisito-outro">Outro requisito</div>
			<div class="pl-3 text-red-vivid-60"><i class="fas fa-exclamation-triangle"></i> Não faz parte do cálculo de estatística</div>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col-auto">
						<i
							class="fas fa-times close-button"
							(click)="removerOutroRequisito()"
							alt="Remover requisito"
							title="Remover requisito"
						></i>
					</div>
					<div class="col" data-cy="item-lista-outro">{{ oportunidade.outrosRequisitos }}</div>
				</div>
			</div>
			<span class="br-divider"></span>
		</div>
	</div>
</div>
