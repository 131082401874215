<div class="row mb-2">
	<div class="col-sm-2">
		<label for="id">{{label}}:</label>
	</div>
	<div class="col-sm-10">
		<span id="id" *ngIf="!renderizarHTML">{{conteudo | imprimirDash}}</span>
		<span id="id" *ngIf="renderizarHTML && !conteudo">-</span>
		<span id="id" *ngIf="renderizarHTML && conteudo" [innerHTML]="conteudo"></span>
		<sgp-message-feedback
			[show]="obrigatorioPublicacao && !conteudo"
			mensagem="Preenchimento obrigatório para publicação do edital"
			tipo="info"
		></sgp-message-feedback>
	</div>
</div>
