import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Edital } from '@core/models/edital';
import { IdComponent } from 'src/app/core/models/id-component';
import { SgpComponentDirective } from '@core/directives/sgpcomponent.directive';
import { WizardContent } from '@core/models/wizard-content';
import { WizardItems } from '@core/models/wizard-items';

@Component({
	selector: 'sgp-wizard',
	templateUrl: './wizard.component.html'
})
export class WizardComponent implements OnInit {
	@Input() steps!: number;
	@Input() items!: WizardItems[];
	@Input() contents!: WizardContent[];
	@Input() edital!: Edital;
	@Input() loading = false;
	@Output() salvarEdital: EventEmitter<any> = new EventEmitter();

	currentStep!: WizardItems;
	currentContent!: WizardContent;
	activeIndex!: number;
	//Serve para usar um template customizado com um componente a ser carregado.
	@ViewChild(SgpComponentDirective, { static: true }) sgpComponent!: SgpComponentDirective;
	mensagem = '';
	exibirAlerta = false;

	constructor(private componentFactoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.activeIndex = 0;
		this.ativarStep(this.activeIndex);
		const elemento = document.querySelector('.br-wizard .wizard-progress .wizard-progress-btn::before');
		if (elemento !== null) {
			elemento.setAttribute('content', '1');
		}
	}

	/**
	 * Avançar para o próximo passo
	 */
	nextStep(): void {
		if (this.edital.id) {
			if (this.activeIndex < this.steps) {
				this.desativarStep(this.activeIndex);
				this.activeIndex = this.activeIndex + 1;
				this.ativarStep(this.activeIndex);
			}
		} else {
			this.exibirAlerta = true;
		}
	}

	/**
	 * Voltar para o passo anterior
	 */
	previousStep(): void {
		if (this.activeIndex < this.steps) {
			this.desativarStep(this.activeIndex);
			this.activeIndex = this.activeIndex - 1;
			this.ativarStep(this.activeIndex);
		}
	}

	/**
	 * Seta o step que foi clicado como o ativo, desativando o anterior
	 * @param item item do step
	 */
	setActiveStep(item: WizardItems): void {
		if (this.edital.id) {
			const index = item.ordem;
			if (index !== this.activeIndex) {
				this.desativarStep(this.activeIndex);
				this.ativarStep(index);
				this.activeIndex = index;
			}
		} else {
			this.exibirAlerta = true;
		}
	}

	/**
	 * Carrega os componentes usados em cada passo do wizard no template customizado.
	 */
	loadComponent(): void {
		const currentComponent = this.currentContent.idComponent as IdComponent;
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(currentComponent.component);

		const viewContainerRef = this.sgpComponent.viewContainerRef;
		viewContainerRef.clear();

		const componentRef = viewContainerRef.createComponent<IdComponent>(componentFactory);
		componentRef.instance.data = currentComponent.data;
		componentRef.instance.edital = this.edital;
		componentRef.instance.salvando?.subscribe(x => {
			this.salvarEdital.emit(x);
		});

		this.cd.detectChanges();
	}

	/**
	 * Retorna o número do item a ser exibido
	 * @param item item a ser exibido
	 * @returns string
	 */
	getNumeroItem(item: WizardItems): string {
		return (item.ordem + 1).toString();
	}

	/**
	 * Ativar o conteúdo e o passo indicado pelo índice
	 * @param index índice
	 */
	private ativarStep(index: number) {
		this.currentStep = this.items[index];
		this.currentStep.active = true;
		this.currentContent = this.contents[index];
		this.currentContent.active = true;
		this.loadComponent();
	}

	/**
	 * Desativar o conteúdo e o passo indicado pelo índice
	 * @param index índice
	 */
	private desativarStep(index: number) {
		this.currentStep = this.items[index];
		this.currentStep.active = false;
		this.currentContent = this.contents[index];
		this.currentContent.active = false;
	}
}
