<div class="br-wizard" collapsed="collapsed" scroll="scroll" step="5" data-cy="wizard">
	<div class="wizard-progress">
		<button
			*ngFor="let item of items"
			class="wizard-progress-btn"
			type="button"
			[attr.title]="item.title"
			[attr.disabled]="item.disabled ? item.disabled : null"
			[attr.active]="item.active ? item.active : null"
			(click)="setActiveStep(item)"
			[attr.step]="getNumeroItem(item)"
			data-cy="step-botao-wizard"
		>
			<span class="info mt-4" data-cy="step-nome-wizard">{{item.title}}</span>
		</button>
	</div>
	<div class="wizard-form">
		<div class="wizard-panel" [attr.active]="currentContent.active ? currentContent.active : null">
			<div class="wizard-panel-content pl-1 pt-3" data-cy="conteudo-wizard">
				<div class="h3 mb-4" data-cy="titulo-step-selecionado">{{currentContent.title}}</div>
				<div class="text">
					<ng-template sgpComponent></ng-template>
				</div>
			</div>
			<div class="wizard-panel-btn pr-5 row">
				<div class="ml-2 mt-2 col" *ngIf="edital.numeroEdital" data-cy="indicador-edital-salvo">
					<i class="fas fa-check font-primary-default"></i> Edital salvo.
					<a [routerLink]="['/edital', edital.id]">Você pode visualizá-lo aqui.</a>
				</div>
				<div class="ml-2 mt-2 col" *ngIf="!edital.numeroEdital" data-cy="indicador-edital-nao-salvo">
					<i class="fas fa-exclamation-circle font-primary-default"></i> Edital ainda não está salvo.
				</div>
				<div class="col">
					<button
						*ngIf="currentContent.forwardButton"
						class="br-button secondary wizard-btn-next"
						type="button"
						(click)="nextStep()"
						data-cy="botao-avancar"
					>
						Avançar
					</button>

					<button
						*ngIf="currentContent.backButton"
						class="br-button secondary wizard-btn-prev"
						type="button"
						(click)="previousStep()"
						data-cy="botao-voltar"
					>
						Voltar
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog header="Aviso" [(visible)]="exibirAlerta" [modal]="true" [draggable]="false" [resizable]="false">
	<p>Lembre-se de salvar os dados básicos antes de avançar.</p>
	<ng-template pTemplate="footer">
		<p-button icon="fas fa-check" (click)="exibirAlerta=false" label="Ok" styleClass="primary"></p-button>
	</ng-template>
</p-dialog>
