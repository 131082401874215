import { Component, OnDestroy, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';
import { SolicitacaoBase } from '../models/solicitacao-base';
import { SolicitarBaseCurriculosApiService } from '../services/solicitar-base-curriculos-api.service';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';

@Component({
	templateUrl: './solicitar-base-curriculos.component.html',
	providers: [MessageService]
})
export class SolicitarBaseCurriculosComponent implements OnDestroy, OnInit {
	resultado: SolicitacaoBase[] = [];
	loadingResultado = false;
	totalResultado?: number;
	pagina = 1;
	itensPorPagina = 10;
	primeiroItemResultado = 0;
	colunas: any;
	itemTabela = new SolicitacaoBase();
	operacao?: string;
	exibirModalManterTabela = false;
	exibirModalManual = false;
	tituloModalManterTabela = '';
	subscriptions: Subscription[] = [];
	flagSituacaoDisponivelOuSolicitada = false;
	flagSituacaoSolicitada = false;
	vemDoIncluir = false;

	constructor(private tabelaService: SolicitarBaseCurriculosApiService, private messageService: MessageService, private ui: UiService) {}
	ngOnInit(): void {
		this.vemDoIncluir = false;
		console.log('disparadado init');
		this.pesquisar();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	pesquisar() {
		this.resultado = [];
		this.loadingResultado = true;
		this.subscriptions.push(
			this.tabelaService.obterSolicitacoesBase().subscribe(
				(resultado: SolicitacaoBase[]) => {
					this.loadingResultado = false;
					this.totalResultado = resultado.length;
					//this.totalResultado = 0;
					this.pagina = 1;
					this.resultado = resultado;
					this.tratarDados(resultado);
				},
				err => {
					this.totalResultado = 0;
					this.resultado = [];
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}
	tratarDados(resultado: SolicitacaoBase[]) {
		const v1 = resultado.filter(
			item => item.situacao?.toLowerCase().substring(0, 3) === 'dis' || item.situacao?.toLowerCase().substring(0, 3) === 'sol'
		);
		this.flagSituacaoDisponivelOuSolicitada = v1 !== null && v1.length > 0;

		const v2 = resultado.filter(item => item.situacao?.toLowerCase().substring(0, 3) === 'sol');
		console.log(v2, this.vemDoIncluir);
		this.flagSituacaoSolicitada = v2 !== null && v2.length > 0;
	}

	paginar(event: any) {
		this.pagina = event.first / event.rows + 1;
		this.primeiroItemResultado = event.first;
		this.pesquisar();
	}

	getItensPorPagina(event: any) {
		this.itensPorPagina = event.rows;
	}

	private criarMensagem(criticidade: string, mensagem: string) {
		this.messageService.clear();
		this.messageService.add(this.ui.criarMensagem(criticidade, '', mensagem));
	}

	private exibirMensagemSucesso(res: any) {
		if (res) {
			this.criarMensagem('success', res.message);
			this.limparPesquisa();
		}
	}

	private limparPesquisa() {
		this.primeiroItemResultado = 0;
		this.pagina = 1;
		this.pesquisar();
	}

	incluir() {
		this.subscriptions.push(
			this.tabelaService.incluirSolicitacaoBase().subscribe(
				(res: any) => {
					this.vemDoIncluir = true;
					this.exibirMensagemSucesso(res);
				},
				err => {
					this.criarMensagem('error', err.error.message);
				}
			)
		);
	}

	async download(id: number) {
		console.log(id);
		await this.downloadAsync(id);
	}
	async downloadAsync(id: number) {
		this.subscriptions.push(
			this.tabelaService.download(id).subscribe(response => {
				const tipo = response.headers.get('content-type');
				const contentDisposition = response.headers.get('content-disposition');
				const filename = contentDisposition.split(';')[1].trim().split('=')[1];
				this.ui.downloadSaveFile(response, tipo, filename);
			})
		);
	}
}
