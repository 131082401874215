/**
 * Capacitação
 */
export class Capacitacao {
	id?: number;
	idOportunidade?: number;
	nomeCapacitacao?: string;
	obrigatorio?: boolean;

	constructor(id?: number, nomeCapacitacao?: string, obrigatorio?: boolean) {
		this.id = id;
		this.nomeCapacitacao = nomeCapacitacao;
		this.obrigatorio = obrigatorio;
	}
}
