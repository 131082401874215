import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Subscription } from 'rxjs';
import { UnidadeOrganizacional } from '@core/models/unidade-organizacional';

@Component({
	selector: 'sgp-busca-unidade',
	templateUrl: './busca-unidade.component.html'
})
export class BuscaUnidadeComponent implements OnDestroy {
	@Input() showModal = false;
	@Input() codigoOrgao!: string;
	@Output() atualizarUnidade: EventEmitter<UnidadeOrganizacional> = new EventEmitter<UnidadeOrganizacional>();
	@Output() atualizarShowModal: EventEmitter<boolean> = new EventEmitter<boolean>();
	unidadeQuery?: string;
	unidadeSelecionada?: any;
	listaUnidades: UnidadeOrganizacional[] = [];
	erroTamanhoBusca = false;
	erroMensagemObrigatorio = false;
	subscriptions: Subscription[] = [];
	loading = false;
	metadado = { total: -1 };

	constructor(private oportunidadeService: OportunidadesApiService) {}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	esconderModal() {
		this.showModal = false;
		this.atualizarShowModal.emit(false);
		this.unidadeQuery = undefined;
		this.unidadeSelecionada = undefined;
		this.erroTamanhoBusca = false;
		this.erroMensagemObrigatorio = false;
	}

	pesquisar() {
		if (this.unidadeQuery && this.unidadeQuery.valueOf().length >= 3) {
			this.listaUnidades = [];
			this.loading = true;
			this.erroTamanhoBusca = false;
			this.subscriptions.push(
				this.oportunidadeService.unidadesOrganizacionaisPorNome(this.codigoOrgao, this.unidadeQuery).subscribe(res => {
					this.listaUnidades = res;
					this.loading = false;
					this.metadado = { total: res.length };
				})
			);
		} else {
			this.erroTamanhoBusca = true;
			this.metadado = { total: -1 };
		}
	}

	selecionarUnidade() {
		if (!this.unidadeSelecionada) {
			this.erroMensagemObrigatorio = true;
		} else {
			this.atualizarUnidade.emit(this.unidadeSelecionada);
			this.esconderModal();
		}
	}
}
