<div class="template-erro">
	<div class="row my-2">
		<div class="col imagem-erro">
			<img src="assets/images/not_found.png" alt="imagem de erro" />
		</div>
		<div class="col erro-text">
			<div class="text-primary-darken-01">
				<p class="text-up-06 text-semi-bold my-3">Procuramos esta página em todos os lugares e não encontramos!</p>
			</div>
			<div class="">
				<p class="text-up-03 text-medium my-3">Mas podemos ajudá-lo a encontrar o que está procurando de outra forma.</p>
			</div>
			<p class="text-up-01 my-3">
				Talvez você tenha se equivocado ao digitar o endereço URL ou quem sabe nós tenhamos cometido uma falha por aqui.
				<a routerLink="/">Volte para página inicial</a> e tente novamente.
			</p>
		</div>
	</div>

	<div class="row mb-4">
		<div class="col">
			<div class="buttons-auxiliary my-4 text-center">
				<a routerLink="/" class="br-button crumb" role="button">
					<i class="fas fa-home" aria-hidden="true"></i><span>Ir para Página Principal</span>
				</a>
			</div>
		</div>
	</div>
</div>
