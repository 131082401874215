export enum PerfisEnum {
	GESTOR = 'OPO_GESTOR',
	AUTORIZADOR = 'OPO_AUTORIZADOR',
	GESTOR_GERAL = 'OPO_GESTOR_GERAL',
	GESTOR_REFORMA = 'OPO_GESTOR_REFORMA', //Criado por conta da reforma administrativa
	AUTORIZADOR_REFORMA = 'OPO_AUTORIZADOR_REFORMA', //Criado por conta da reforma administrativa
	GESTOR_DADOS = 'OPO_GESTOR_DADOS',
	PESQUISADOR = 'OPO_PESQUISADOR', //CriadO por conta do perfil profissiografico
	GESTOR_PESSOAS = 'OPO_GESTOR_PESSOAS', //Criado por conta do perfil profissiografico
	GESTOR_PRODUTO = 'OPO_GESTOR_PRODUTO' //Criado por conta do perfil profissiografico
}
