export class TabelasAdministrativas {
	id?: number;
	nome?: string;
	ativo?: boolean;

	constructor(id?: number, nome?: string, ativo?: boolean) {
		this.id = id;
		this.nome = nome;
		if (ativo == undefined) {
			this.ativo = false;
		} else {
			this.ativo = ativo;
		}
	}
}
