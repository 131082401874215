/**
 * Formação educacional
 */
import { NivelCurso } from './nivel-curso';
export class Formacao {
	id?: number;
	idOportunidade?: number;
	nivelCurso?: NivelCurso;
	idNivelCurso?: number;
	nomeNivelCurso?: string;
	nomeFormacao?: string;
	obrigatorio?: boolean;

	constructor(id?: number, idNivelCurso?: number, nomeNivelCurso?: string, nomeFormacao?: string, obrigatorio?: boolean) {
		this.id = id;
		this.idNivelCurso = idNivelCurso;
		this.nomeNivelCurso = nomeNivelCurso;
		this.nomeFormacao = nomeFormacao;
		this.obrigatorio = obrigatorio;
	}
}
