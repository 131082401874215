import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'idioma'
})
export class IdiomaPipe implements PipeTransform {
	transform(valor: any): string {
		switch (valor) {
			case 'BAS':
				return 'Básico';
			case 'INT':
				return 'Intermediário';
			case 'AVA':
				return 'Avançado';
			default:
				return 'Nenhum';
		}
	}
}
