/**
 * Dados do candidato na planilha - o nome da variável será usado para o título da coluna na planilha.
 */
export class CandidatoPlanilha {
	nome?: string;
	email?: string;
	inscricao?: string;
	vinculo?: string;
	localidade?: string;
	anexos?: string;
	favorito?: string;
	analisado?: string;

	constructor(
		nome: string,
		email?: string,
		inscricao?: string,
		vinculo?: string,
		localidade?: string,
		quantidadeAnexos?: number,
		favorito?: boolean,
		marcado?: boolean
	) {
		this.nome = nome;
		this.email = email ? email : '-';
		this.inscricao = inscricao ? inscricao : '-';
		this.vinculo = vinculo ? vinculo : '-';
		this.localidade = localidade ? localidade : '-';
		this.anexos = quantidadeAnexos! > 0 ? 'Sim' : 'Não';
		this.favorito = favorito ? 'Sim' : 'Não';
		this.analisado = marcado ? 'Sim' : 'Não';
	}
}
