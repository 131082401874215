import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';

import { AcoesEnum } from '@core/enums/acoes.enum';
import { AcoesService } from '@core/services/acoes.service';
import { ConverterInterfaceEditalHelper } from '@core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	selector: 'sgp-botao-acao',
	templateUrl: './botao-acao.component.html',
	providers: [ConfirmationService]
})
export class BotaoAcaoComponent implements OnInit, OnDestroy {
	VISUALIZAR_EDITAL = 'visualizar_edital';
	PESQUISAR_EDITAL = 'pesquisar_edital';

	acoes: MenuItem[] = [];
	carregando = false;

	exibirModalSolicitarPublicacao = false;
	exibirModalSolicitarRetificacao = false;
	exibirModalRejeitarPublicacao = false;
	exibirModalRejeitarRetificacao = false;
	exibirModalSuspenderEdital = false;
	exibirModalCancelarEdital = false;
	exibirModalAutorizarPublicacaoEdital = false;
	exibirModalAutorizarRetificacaoEdital = false;
	exibirConfirmacaoEncerramento = false;
	exibirModalPublicarResultado = false;

	subscriptions: Subscription[] = [];

	@Input() edital!: Edital;
	@Input() pagina = this.VISUALIZAR_EDITAL;
	@Input() desabilitar = true;
	@Output() mensagemFeedback: EventEmitter<Message> = new EventEmitter<Message>();
	@Output() editalAtualizado: EventEmitter<Edital> = new EventEmitter<Edital>();
	mensagem?: Message;
	@ViewChild('btnAcao') btnAcao!: ElementRef;

	constructor(
		private oportunidadesService: OportunidadesApiService,
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private confirmationService: ConfirmationService,
		private acoesService: AcoesService,
		private router: Router,
		private ui: UiService
	) {}

	ngOnInit() {
		this.acoes = [];
		if (this.edital) {
			this.acoesService.nextSituacaoEdital(this.edital.situacao);
			this.acoesService.nextHistoricoEdital(this.edital.historico);
			this.obterAcoesEdital();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get acoesEnum(): typeof AcoesEnum {
		return AcoesEnum;
	}

	/**
	 * Fecha o modal de acordo com o tipo de ação
	 * @param event tipo de ação
	 */
	fecharModal(event: string) {
		switch (event) {
			case 'SolicitarPublicacao':
				this.exibirModalSolicitarPublicacao = false;
				break;
			case 'SolicitarRetificacao':
				this.exibirModalSolicitarRetificacao = false;
				break;
			case 'PublicarResultado':
				this.exibirModalPublicarResultado = false;
				break;
			case 'RejeitarPublicacao':
				this.exibirModalRejeitarPublicacao = false;
				break;
			case 'RejeitarRetificacao':
				this.exibirModalRejeitarRetificacao = false;
				break;
			case 'SuspenderEdital':
				this.exibirModalSuspenderEdital = false;
				break;
			case 'CancelarEdital':
				this.exibirModalCancelarEdital = false;
				break;
			case 'AutorizarPublicacao':
				this.exibirModalAutorizarPublicacaoEdital = false;
				break;
			case 'AutorizarRetificacao':
				this.exibirModalAutorizarRetificacaoEdital = false;
				break;
			default:
				break;
		}
	}

	atualizarEdital(event: Edital) {
		this.edital = event;
		this.editalAtualizado.emit(this.edital);
	}

	/**
	 * Consulta, através de serviço, a lista de ações de um edital, adicionando essas ações no botão de acoes.
	 */
	private async obterAcoesEdital() {
		this.subscriptions.push(
			this.oportunidadesService.consultarAcoesEdital(this.edital.id).subscribe(async data => {
				if (data !== undefined) {
					for (const item of data) {
						if (this.acoesService.exibirAcao(item.codigo, this.pagina)) {
							this.acoes.push(this.criarItemBotao(item));
						}
					}
					this.desabilitar = this.acoes.length === 0;
				}
			})
		);
	}

	/**
	 * Cria um novo item de botão a partir de dados do serviço
	 * @param item dados do item de botão
	 * @returns um item de botão
	 */
	private criarItemBotao(item: any): any {
		return {
			id: item.codigo,
			label: item.descricao,
			command: () => {
				this.executarAcao(Number(item.codigo), this.edital.id);
			}
		};
	}

	/**
	 * Prepara os parâmetros para executar uma ação de acordo com o tipo de ação.
	 * Se a ação for alterar, redireciona para a página de edição. Se for excluir, executa o método de exclusão.
	 * Quando houver justificativa ou texto de retificação, exibir modal
	 * @param codigoAcao código da ação
	 */
	private async executarAcao(codigoAcao: any, idEdital: string) {
		if (this.acoesService.isAcaoExcluirEdital(codigoAcao)) {
			this.excluirEdital();
		} else if (this.acoesService.isAcaoAlterarEdital(codigoAcao)) {
			this.router.navigate(['/alterarEdital', idEdital]);
		} else if (this.acoesService.isAcaoSolicitacaoPublicacaoEdital(codigoAcao)) {
			this.exibirModalSolicitarPublicacao = true;
		} else if (this.acoesService.isAcaoSolicitacaoPublicacaoRetificacao(codigoAcao)) {
			this.exibirModalSolicitarRetificacao = true;
		} else if (this.acoesService.isAcaoRejeitarPublicacao(codigoAcao)) {
			this.exibirModalRejeitarPublicacao = true;
		} else if (this.acoesService.isAcaoRejeitarRetificacao(codigoAcao)) {
			this.exibirModalRejeitarRetificacao = true;
		} else if (this.acoesService.isAcaoSuspenderEdital(codigoAcao)) {
			this.exibirModalSuspenderEdital = true;
		} else if (this.acoesService.isAcaoCancelarEdital(codigoAcao)) {
			this.exibirModalCancelarEdital = true;
		} else if (this.acoesService.isAcaoAutorizarPublicacao(codigoAcao)) {
			this.exibirModalAutorizarPublicacaoEdital = true;
		} else if (this.acoesService.isAcaoAutorizarRetificacao(codigoAcao)) {
			this.exibirModalAutorizarRetificacaoEdital = true;
		} else if (this.acoesService.isAcaoPublicarResultado(codigoAcao)) {
			this.exibirModalPublicarResultado = true;
		} else {
			this.prepararExecutarServicoDeAcao(codigoAcao, idEdital);
		}
	}

	/** Chama o serviço de executar ação quando for uma ação simples, sem justificativa,
	 * seleção de unidade ou texto de retificação */
	private prepararExecutarServicoDeAcao(codigoAcao: any, idEdital: string) {
		if (
			this.acoesService.isAcaoDesfazerRetificacaoEdital(codigoAcao) ||
			this.acoesService.isAcaoEncerrarEdital(codigoAcao) ||
			this.acoesService.isAcaoAutorizacao(codigoAcao) ||
			this.acoesService.isAcaoRetificarEdital(codigoAcao)
		) {
			this.confirmationService.confirm({
				message: 'Deseja executar a operação solicitada?',
				header: 'Executar ação',
				accept: async () => {
					this.executarServico(codigoAcao, idEdital);
				}
			});
		}
	}

	private executarServico(codigoAcao: any, idEdital: string) {
		const params = this.acoesService.montarParametrosAcao(codigoAcao);
		this.subscriptions.push(
			this.oportunidadesService.executarAcaoEdital(this.edital.id, params).subscribe(
				async res => {
					this.converterInterfaceEdital.converter(this.edital, await res);
					this.editalAtualizado.emit(this.edital);
					this.mensagemFeedback.emit(this.ui.criarMensagem('success', '', 'Ação realizada com sucesso.'));
					if (this.acoesService.isAcaoRetificarEdital(params.codigoAcao)) {
						this.router.navigate(['/retificarEdital', idEdital]);
					}
					if (this.acoesService.isAcaoAutorizacao(params.codigoAcao)) {
						this.router.navigate(['/edital', idEdital]);
					}
				},
				err => {
					this.mensagemFeedback.emit(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	/**
	 * Exclui um edital, exibe mensagem de confirmação
	 */
	private excluirEdital() {
		const numeroEdital = this.edital.numeroEdital;
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja excluir o edital Nº ' + numeroEdital + '?',
			accept: async () => {
				this.carregando = true;
				if (this.edital !== undefined) {
					this.subscriptions.push(
						this.oportunidadesService.excluirEdital(Number(this.edital.id)).subscribe(
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							res => {
								this.excluirEditalComSucesso();
							},
							err => {
								const message = this.ui.criarMensagem('error', '', err.error.message);
								this.mensagemFeedback.emit(message);
							}
						)
					);
				}
			}
		});
	}

	/**
	 * Exibe mensagem de sucesso e redireciona (quando estiver no visualizar) ou recarrega a página (se estiver no pesquisar).
	 * @param numeroEditalFormatado
	 */
	private excluirEditalComSucesso() {
		const message = this.ui.criarMensagem('success', '', 'Edital excluído com sucesso.');
		this.mensagemFeedback.emit(message);
		this.carregando = false;
		if (this.pagina == this.VISUALIZAR_EDITAL) {
			this.router.navigate(['/dashboard'], { state: { message: message.detail } });
		} else {
			this.router.navigate(['/pesquisarEdital']);
		}
	}
}
