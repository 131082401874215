<sgp-exibir-requisitos-oportunidade
	[oportunidade]="oportunidade"
	(obterOportunidade)="updateOportunidade($event)"
></sgp-exibir-requisitos-oportunidade>

<div class="row">
	<div id="Adicionar requisitos" class="col-sm-12">
		<p-button
			*ngFor="let requisito of tiposRequisitos"
			[label]="requisito.nomeRequisito"
			icon="fas fa-plus"
			id="{{requisito.chave}}"
			styleClass="br-button small secondary mr-2 mb-2"
			(click)="exibirModal(requisito.chave)"
			data-cy="adicionar-requisito"
		></p-button>
	</div>
</div>

<p-dialog
	*ngIf="requisitoSelecionado"
	id="adicionarRequisito"
	[header]="requisitoSelecionado.nomeRequisito"
	[(visible)]="adicionarRequisito"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-60vw"
	[contentStyle]="{minHeight: '300px', overflowX: 'hidden'}"
	[baseZIndex]="10000"
	[resizable]="false"
	data-cy="modal-requisito"
>
	<p>{{ requisitoSelecionado.textoAuxiliar }}</p>

	<!-- quem_pode -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'vinculo'">
		<div class="col-12" [ngClass]="{'danger': erroRequisito.get('erroVinculo')!}">
			<p-dropdown
				id="vinculo-requisito"
				[options]="listaTiposVinculos"
				[(ngModel)]="requisitos.tipoVinculo"
				placeholder="Selecione um vínculo"
				optionLabel="nome"
				[showClear]="true"
				[panelStyle]="{height: '150px'}"
				data-cy="campo-tipo-vinculo"
				(onChange)="this.erroRequisito.set('erroVinculo', false)"
			></p-dropdown>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroVinculo')!"
				[mensagem]="textoErro"
				data-cy="erro-tipo-vinculo"
			></sgp-message-feedback>
		</div>
		<div class="col-12 mt-3" *ngIf="requisitos.tipoVinculo?.id === 1">
			<div class="br-switch right mr-5">
				<input
					id="exceto-estagio-probatorio-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.excetoEstagioProbatorio"
					data-cy="campo-estagio-probatorio"
				/>
				<label for="exceto-estagio-probatorio-requisito">Exceto estágio probatório</label>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-vinculo-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.tipoVinculoObrigatorio"
					data-cy="campo-obrigatorio-vinculo"
				/>
				<label for="obrigatorio-vinculo-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- residencia -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'residencia'">
		<div class="col-12" [ngClass]="{'danger': erroRequisito.get('erroLocalidade')!}">
			<sgp-busca-localidade
				#autocomplete
				id="localidade-requisito"
				label="Localidade de residência"
				[ngModel]="requisitos.localidade"
				ngDefaultControl
				(ngModelUpdated)="atualizarLocalidade($event)"
				data-cy="campo-residencia"
			></sgp-busca-localidade>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroLocalidade')!"
				mensagem="Campo obrigatório"
				data-cy="erro-residencia"
			></sgp-message-feedback>
		</div>
	</div>

	<!-- orgao_lotacao -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'orgao_lotacao'">
		<div class="col-12" [ngClass]="{'danger': erroRequisito.get('erroOrgaoLotacao')!}">
			<sgp-busca-orgao
				id="orgao-lotacao-requisito"
				label="Órgão de lotação"
				[ngModel]="requisitos.orgaoLotacao"
				ngDefaultControl
				(ngModelUpdated)="atualizarOrgaoLotacao($event)"
				data-cy="campo-orgao-lotacao"
			></sgp-busca-orgao>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroOrgaoLotacao')!"
				mensagem="Campo obrigatório"
				data-cy="erro-orgao-lotacao"
			></sgp-message-feedback>
			<div class="col-12 mt-3">
				<div class="br-switch right mr-5">
					<input
						id="obrigatorio-orgao-lotacao-requisito"
						type="checkbox"
						value="true"
						data-cy="campo-obrigatorio-orgao-lotacao"
						disabled="true"
						checked="checked"
					/>
					<label for="obrigatorio-orgao-exercicio-requisito">Obrigatório</label>
				</div>
			</div>
		</div>
	</div>

	<!-- orgao_exercicio -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'orgao_exercicio'">
		<div class="col-12" [ngClass]="{'danger': erroRequisito.get('erroOrgaoExercicio')!}">
			<sgp-busca-orgao
				id="orgao-exercicio-requisito"
				label="Órgão de exercício"
				[ngModel]="requisitos.orgaoExercicio"
				ngDefaultControl
				(ngModelUpdated)="atualizarOrgaoExercicio($event)"
				data-cy="campo-orgao-exercicio"
			></sgp-busca-orgao>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroOrgaoExercicio')!"
				mensagem="Campo obrigatório"
				data-cy="erro-orgao-exercicio"
			></sgp-message-feedback>
		</div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-orgao-exercicio-requisito"
					type="checkbox"
					value="true"
					data-cy="campo-obrigatorio-orgao-exercicio"
					disabled="true"
					checked="checked"
				/>
				<label for="obrigatorio-orgao-exercicio-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- conhecimentos -->
	<sgp-requisitos-area-conhecimento
		[exibir]="requisitoSelecionado.chave === 'conhecimentos'"
		[erroMensagem]="erroRequisito.get('erroConhecimento')!"
		[textoErroMensagem]="textoErro"
		(obterArea)="updateArea($event)"
		(obterAreaObrigatoria)="updateAreaObrigatoriedade($event)"
	></sgp-requisitos-area-conhecimento>

	<!-- softskills -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'softskills'">
		<div class="col-12 br-input" [ngClass]="{'danger': erroRequisito.get('erroSoftskills')!}">
			<label for="conhecimento-requisito">Softskills desejadas</label>
			<p-multiSelect
				id="softskill"
				[options]="listaSoftskills"
				[(ngModel)]="requisitos.softskills"
				optionLabel="nome"
				[filter]="false"
				data-cy="campo-softskill"
				[showToggleAll]="false"
				[showHeader]="false"
				[style]="{'z-index': '999999'}"
				[panelStyle]="{height: '150px', overflowY: 'hidden'}"
				(onChange)="this.erroRequisito.set('erroSoftskills', false)"
			></p-multiSelect>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroSoftskills')!"
				[mensagem]="textoErro"
				data-cy="erro-softskill"
			></sgp-message-feedback>
		</div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-softskill-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.softskillObrigatorio"
					data-cy="campo-obrigatorio-softskill"
				/>
				<label for="obrigatorio-softskill-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- idiomas -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'idiomas'">
		<div class="col-12" [ngClass]="{'danger': erroRequisito.get('erroIdioma')!}">
			<p-autoComplete
				id="idioma-requisito"
				[(ngModel)]="requisitos.tipoIdioma"
				[suggestions]="filteredIdioma"
				(completeMethod)="filterIdioma($event)"
				field="nome"
				scrollHeight="200px"
				[size]="200"
				[dropdown]="true"
				[multiple]="false"
				placeholder="Comece a escrever o idioma para exibir as sugestões"
				[forceSelection]="true"
				[showEmptyMessage]="true"
				emptyMessage="Nenhuma opção encontrada"
				data-cy="campo-idioma"
				(onSelect)="this.erroRequisito.set('erroIdioma', false)"
			></p-autoComplete>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroIdioma')!"
				[mensagem]="textoErro"
				data-cy="erro-idioma"
			></sgp-message-feedback>
		</div>
	</div>
	<div class="row mt-3" *ngIf="requisitoSelecionado.chave === 'idiomas' && requisitos.tipoIdioma">
		<div class="col-6">
			<label>Nivel de compreensão</label>
			<p-rating
				[(ngModel)]="requisitos.nivelCompreensao"
				[stars]="estrelas"
				iconOnClass="fas fa-star"
				iconOffClass="far fa-star"
				iconCancelClass="fas fa-ban"
				data-cy="rating-compreensao"
			></p-rating>
		</div>
		<div class="col-6" *ngIf="!requisitos.tipoIdioma?.libras">
			<label>Nivel de escrita</label>
			<p-rating
				[(ngModel)]="requisitos.nivelEscrita"
				[stars]="estrelas"
				iconOnClass="fas fa-star"
				iconOffClass="far fa-star"
				iconCancelClass="fas fa-ban"
				data-cy="rating-escrita"
			></p-rating>
		</div>
		<div class="col-6 mt-2">
			<label>
				<span *ngIf="requisitos.tipoIdioma?.libras; else naoLibras">Nível de expressão</span>
				<ng-template #naoLibras>Nível de fala</ng-template>
			</label>
			<p-rating
				[(ngModel)]="requisitos.nivelFala"
				[stars]="estrelas"
				iconOnClass="fas fa-star"
				iconOffClass="far fa-star"
				iconCancelClass="fas fa-ban"
				data-cy="rating-fala"
			></p-rating>
		</div>
		<div class="col-6 mt-2" *ngIf="!requisitos.tipoIdioma?.libras">
			<label>Nivel de leitura</label>
			<p-rating
				[(ngModel)]="requisitos.nivelLeitura"
				[stars]="estrelas"
				iconOnClass="fas fa-star"
				iconOffClass="far fa-star"
				iconCancelClass="fas fa-ban"
				data-cy="rating-leitura"
			></p-rating>
		</div>
		<div class="col-6 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-idioma-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.idiomaObrigatorio"
					data-cy="campo-obrigatorio-idioma"
				/>
				<label for="obrigatorio-idioma-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- certificacoes -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'certificacoes'">
		<div class="col-12 mt-3">
			<div class="br-input" [ngClass]="{'danger': erroRequisito.get('erroCertificacao')!}">
				<label for="nome-certificacao-requisito">Nome da certificação</label>
				<input
					pInputText
					type="text"
					id="nome-certificacao-requisito"
					[(ngModel)]="requisitos.nomeCertificacao"
					data-cy="campo-certificacao"
					maxlength="100"
					minlength="3"
					placeholder="Informe o nome da certificação desejada"
				/>
				<sgp-message-feedback
					[show]="erroRequisito.get('erroCertificacao')!"
					mensagem="Certificação deve possuir, no mínimo, 3 caracteres."
					data-cy="erro-certificacao"
				></sgp-message-feedback>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-certificacao-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.certificacaoObrigatoria"
					data-cy="campo-obrigatorio-certificacao"
				/>
				<label for="obrigatorio-certificacao-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- capacitacoes -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'capacitacoes'">
		<div class="col-12 mt-3">
			<div class="br-input" [ngClass]="{'danger': erroRequisito.get('erroCapacitacao')!}">
				<label for="nome-capacitacao-requisito">Nome da capacitação</label>
				<input
					pInputText
					type="text"
					id="nome-capacitacao-requisito"
					[(ngModel)]="requisitos.nomeCapacitacao"
					data-cy="campo-capacitacao"
					maxlength="100"
				/>
				<sgp-message-feedback
					[show]="erroRequisito.get('erroCapacitacao')!"
					mensagem="Capacitação deve possuir, no mínimo, 3 caracteres."
					data-cy="erro-capacitacao"
				></sgp-message-feedback>
			</div>
		</div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-capacitacao-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.capacitacaoObrigatoria"
					data-cy="campo-obrigatorio-capacitacao"
				/>
				<label for="obrigatorio-capacitacao-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- formacao_academica -->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'formacao_academica'">
		<div class="col-6 mt-3" [ngClass]="{'danger': erroRequisito.get('erroNivelFormacao')!}">
			<label for="nivel-formacao-requisito" class="d-block">Nível da formação</label>
			<p-dropdown
				id="nivel-formacao-requisito"
				[options]="listaNiveisFormacao"
				[(ngModel)]="requisitos.nivelFormacao"
				placeholder="Selecione um nível"
				optionLabel="nome"
				[showClear]="true"
				[panelStyle]="{height: '150px'}"
				data-cy="campo-nivel-formacao"
				(onChange)="erroRequisito.set('erroNivelFormacao', false)"
			></p-dropdown>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroNivelFormacao')!"
				[mensagem]="textoErro"
				data-cy="erro-campo-nivel-formacao"
			></sgp-message-feedback>
		</div>
		<div class="w-100"></div>
		<div class="col-6 mt-3">
			<div class="br-input" [ngClass]="{'danger': erroRequisito.get('erroNomeFormacao')!}">
				<label for="nome-formacao-requisito">Nome da formação</label>
				<input
					pInputText
					type="text"
					id="nome-formacao-requisito"
					[(ngModel)]="requisitos.nomeFormacao"
					maxlength="100"
					minlength="3"
					data-cy="campo-nome-formacao"
				/>
				<sgp-message-feedback
					[show]="erroRequisito.get('erroNomeFormacao')!"
					mensagem="Nome da formação deve possuir, no mínimo, 3 caracteres."
					data-cy="erro-campo-nome-formacao"
				></sgp-message-feedback>
			</div>
		</div>
		<div class="w-100"></div>
		<div class="col-6 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="obrigatorio-formacao-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.formacaoObrigatoria"
					data-cy="campo-obrigatorio-formacao"
				/>
				<label for="obrigatorio-formacao-requisito">Obrigatório</label>
			</div>
		</div>
	</div>

	<!-- experiencia -->
	<div *ngIf="requisitoSelecionado.chave === 'experiencia'">
		<div class="row">
			<div class="col-md-12 col-lg-6 col-xl-6 mt-3">
				<div class="br-switch right mr-5">
					<input
						id="servico-publico"
						type="checkbox"
						[(ngModel)]="requisitos.noServicoPublico"
						(click)="limparMensagensErro()"
						data-cy="campo-no-servico-publico"
					/>
					<label for="servico-publico" data-cy="label-no-servico-publico">No serviço público</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 col-lg-7 col-xl-6 mt-3">
				<div class="br-input" [ngClass]="{'danger': erroRequisito.get('erroNomeExperiencia')!}">
					<label for="nome-experiencia-requisito">Nome da experiência</label>
					<input
						pInputText
						type="text"
						id="nome-experiencia-requisito"
						[(ngModel)]="requisitos.nomeExperiencia"
						data-cy="campo-nome-experiencia"
						maxlength="100"
					/>
					<sgp-message-feedback
						[show]="erroRequisito.get('erroNomeExperiencia')!"
						[mensagem]="textoErro"
						data-cy="erro-nome-experiencia"
					></sgp-message-feedback>
				</div>
			</div>
			<div class="col-md-12 col-lg-5 col-xl-3 mt-3">
				<div class="br-input" [ngClass]="{'danger': erroRequisito.get('erroMesesExperiencia')!}">
					<label for="meses-experiencia-requisito">Quantidade mínima de meses</label>
					<p-inputNumber
						inputId="meses-experiencia-requisito"
						[(ngModel)]="requisitos.qtdMinimaMesesExperiencia"
						data-cy="campo-meses-experiencia"
						[showButtons]="false"
						mode="decimal"
						[maxlength]="4"
						[min]="1"
						[max]="9999"
					></p-inputNumber>
					<p class="text-base mt-1">Mínimo de 1 mês</p>
					<sgp-message-feedback
						[show]="erroRequisito.get('erroMesesExperiencia')!"
						mensagem="Campo obrigatório"
						data-cy="erro-meses-experiencia"
					></sgp-message-feedback>
				</div>
			</div>
			<div class="col-md-12 col-lg-6 col-xl-6 mt-3">
				<div class="br-switch right mr-5">
					<input
						id="experienciaObrigatoria-requisito"
						type="checkbox"
						[(ngModel)]="requisitos.experienciaObrigatoria"
						data-cy="campo-obrigatorio-experiencia"
					/>
					<label for="experienciaObrigatoria-requisito">Obrigatório</label>
				</div>
			</div>
		</div>
	</div>

	<!-- area_atuacao-->
	<div class="row" *ngIf="requisitoSelecionado.chave === 'area_atuacao'">
		<div class="col-12 mt-3" [ngClass]="{'danger': erroRequisito.get('erroAreaAtuacao')!}">
			<p-dropdown
				id="area-atuacao-requisito"
				[options]="listaAreaAtuacao"
				[(ngModel)]="requisitos.areaAtuacao"
				placeholder="Selecione uma área de atuação"
				optionLabel="nome"
				[showClear]="true"
				data-cy="campo-area-atuacao"
				(onChange)="erroRequisito.set('erroAreaAtuacao',false)"
			></p-dropdown>
			<sgp-message-feedback
				[show]="erroRequisito.get('erroAreaAtuacao')!"
				[mensagem]="textoErro"
				data-cy="erro-area-atuacao"
			></sgp-message-feedback>
		</div>
		<div class="w-100"></div>
		<div class="col-12 mt-3">
			<div class="br-switch right mr-5">
				<input
					id="area-atuacao-obrigatorio-requisito"
					type="checkbox"
					[(ngModel)]="requisitos.areaAtuacaoObrigatorio"
					data-cy="campo-obrigatorio-area-atuacao"
				/>
				<label for="area-atuacao-obrigatorio-requisito">Obrigatorio</label>
			</div>
		</div>
	</div>

	<!-- outro -->
	<div
		class="br-textarea"
		[ngClass]="{'danger': erroRequisito.get('erroOutroRequisito')!}"
		*ngIf="requisitoSelecionado.chave === 'outro'"
	>
		<textarea
			id="outro-requisito"
			[(ngModel)]="requisitos.outroRequisito"
			rows="10"
			cols="60"
			data-cy="campo-outro-requisito"
			maxlength="1000"
		></textarea>
		<sgp-message-feedback
			[show]="erroRequisito.get('erroOutroRequisito')!"
			mensagem="Campo obrigatório"
			data-cy="erro-outro-requisito"
		></sgp-message-feedback>
	</div>

	<ng-template pTemplate="footer">
		<p-button (click)="esconderModal()" label="Cancelar" styleClass="br-button secondary" data-cy="botao-cancelar-requisito"></p-button>
		<p-button
			(click)="requisitoSelecionado.metodo()"
			label="Adicionar requisito"
			styleClass="br-button primary"
			data-cy="botao-salvar-requisito"
		></p-button>
	</ng-template>
</p-dialog>
