import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, firstValueFrom, take } from 'rxjs';

import { ConcursoProfissiografico } from '@app/core/models/perfil-profissiografico/concurso-profissiografico';
import { DashboardProfissiograficoService } from '../services/dashboard-profissiografico.service';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { UsuarioService } from '@sigepe/sigepe-template';

//filtro-pesquisar-laudo.component.ts
@Component({
	selector: 'sgp-filtro-dashboard-profissiografico',
	templateUrl: './filtro-dashboard-profissiografico.component.html'
})
export class FiltroDashboardProfissiograficoComponent implements OnInit {
	form!: FormGroup;
	submetido = false;
	orgaoUsuario$?: Observable<any>;
	perfisEnum = PerfisEnum;
	codigoOrgao : number | undefined = undefined;
	somenteGestorPessoas = false;
	perfisUsuario?: string[];

	constructor(
		protected dashboardService: DashboardProfissiograficoService,
		private fb: FormBuilder,
		private usuarioService: UsuarioService,
		private oportunidadesService: OportunidadesApiService
	) {}

	async ngOnInit() {
		this.somenteGestorPessoas = false;
		this.verificarSeGestorPessoas();
		this.criarFormulario();
		this.carregarCombos();
		this.selecionarConcursoMaisRecente();
		this.obterNomeOrgaoUsuario();
			

		// pegar o primeiro valor do observavel (este valor é usado como filtro de pesquisa)
		await firstValueFrom(this.dashboardService.concursos$!);
		this.dashboardService.pesquisar();
	}

	private criarFormulario() {
		this.form = this.fb.group({
			idConcurso: [this.dashboardService.filtro.idConcurso, Validators.required],
			codigoOrgao: [this.dashboardService.filtro.codigoOrgao]
		});
	}

	private carregarCombos() {
		if (!this.dashboardService.concursos$) {
			this.dashboardService.listarConcursos();
		}
	}

	limpar() {
		this.submetido = false;
		this.form.reset();
		//this.dashboardService.limpar();
		this.selecionarConcursoMaisRecente();
		this.obterNomeOrgaoUsuario();
		this.dashboardService.renderizarDados = false;
	}

	pesquisar() {
		this.submetido = true;
		if (this.form.valid) {
			this.preparaFiltro();
			this.dashboardService.pesquisar();
			this.submetido = false;
		} else {
			this.form.markAllAsTouched();
		}
	}

	private preparaFiltro() {
		this.dashboardService.filtro.idConcurso = this.form.get('idConcurso')!.value;
		this.dashboardService.filtro.codigoOrgao = this.form.get('codigoOrgao')!.value;
		if (!this.dashboardService.filtro.codigoOrgao && this.somenteGestorPessoas)
			this.dashboardService.filtro.codigoOrgao = this.codigoOrgao;
	}

	selecionarConcurso(evento: any) {
		const idConcurso = evento.value;
		if (!this.somenteGestorPessoas) {
			if (idConcurso != null) {
				this.form.get('codigoOrgao')?.reset();
				this.form.get('codigoOrgao')?.enable();
				this.dashboardService.listarOrgaosConcurso(idConcurso);
			} else {
				this.dashboardService.orgaos$ = undefined;
				this.form.get('codigoOrgao')?.reset();
				this.form.get('codigoOrgao')?.disable();
			}
		}

	}

	private selecionarConcursoMaisRecente() {
		this.dashboardService.concursos$?.pipe(take(1)).subscribe((concurso: ConcursoProfissiografico[]) => {
			this.form.get('idConcurso')?.setValue(concurso[0].id);
			this.selecionarConcurso({ value: concurso[0].id });
			this.dashboardService.filtro.idConcurso = concurso[0].id;
		});
	}

	private obterNomeOrgaoUsuario() {
		this.form.get('codigoOrgao')?.reset();
		this.dashboardService.filtro.codigoOrgao = undefined;
		if (this.somenteGestorPessoas) {
			this.codigoOrgao = this.usuarioService.getUsuario().codigoOrgaoSiape;
			this.orgaoUsuario$ = this.oportunidadesService.consultarOrgao(this.codigoOrgao!.toString());
			this.form.get('codigoOrgao')?.setValue(this.codigoOrgao);
			this.dashboardService.filtro.codigoOrgao = this.codigoOrgao;
		} else {
			this.codigoOrgao = 0;
			this.form.controls['codigoOrgao'].setValue(this.dashboardService.filtro.codigoOrgao);
		}
	}

	private verificarSeGestorPessoas() {
		this.perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (this.perfisUsuario?.includes(PerfisEnum.GESTOR_PESSOAS) && !this.perfisUsuario?.includes(PerfisEnum.GESTOR_PRODUTO) && !this.perfisUsuario?.includes(PerfisEnum.PESQUISADOR)) {
			     this.somenteGestorPessoas = true;
		}
	}
		
}

