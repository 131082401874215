import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { Observable, Subscription, catchError, tap } from 'rxjs';

import { AcoesPossiveis } from '@app/core/models/perfil-profissiografico/acoes-possiveis';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { MessageFeedbackComponent } from '../message-feedback/message-feedback.component';
import { MessagesModule } from 'primeng/messages';
import { PerfilProfissiograficoApiService } from '@app/core/services/perfil-profissiografico-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { Router } from '@angular/router';
import { SharedModule } from '../shared.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { UiService } from '@app/core/services/ui.service';
import { UsuarioService } from '@sigepe/sigepe-template';
import moment from 'moment';

@Component({
	standalone: true,
	selector: 'sgp-botao-acao-profissiografico',
	templateUrl: './botao-acao-profissiografico.component.html',
	providers: [MessageService, ConfirmationService, MessageFeedbackComponent],
	imports: [
		CommonModule,
		CalendarModule,
		SplitButtonModule,
		DialogModule,
		ConfirmDialogModule,
		ButtonModule,
		MenuModule,
		ToastModule,
		SharedModule,
		FormsModule,
		MessagesModule
	]
})
export class BotaoAcaoProfissiograficoComponent implements OnInit, OnDestroy {
	@Input() listaAcoes!: AcoesPossiveis[];
	@Input() idCargo?: number;
	@Input() idCandidato?: number;
	@Input() exibicao: 'tabela' | 'detalhamento' = 'tabela';
	@Input() origemQuestionario?: 'pesquisarQuestionario';
	@Input() origemLaudo?: 'pesquisarLaudo';
	@Input() situacaoLaudo?: string;
	@Output() acaoRealizada = new EventEmitter<void>();
	@ViewChild('btnAcao') btnAcao!: ElementRef;
	showModalLiberarQuestionario = false;
	showModalAlterarEncerramento = false;
	erroTamanhoBusca = false;
	erroPeriodo = false;
	loading = false;
	submetido = false;
	acoesPossiveis: MenuItem[] = [];
	desabilitar = false;
	usuarioPesquisador = false;
	periodo: Date[] = [];
	dataInicioLiberacao?: Date;
	dataFimLiberacao?: Date;

	textoJustificativa = '';
	erroJustificativaPreenchida = false;

	dataEncerramento?: Date;
	erroDataEncerramentoObrigatorio = false;
	erroDataEncerramentoAnteriorDiaAtual = false;

	subscriptions: Subscription[] = [];
	mensagemFeedback: Message[] = [];
	visible = true;
	finalizado = false;

	constructor(
		private perfilProfissiograficoApi: PerfilProfissiograficoApiService,
		private messageService: MessageService,
		private router: Router,
		private ui: UiService,
		private confirmationService: ConfirmationService,
		private usuarioService: UsuarioService
	) {}

	ngOnInit(): void {
		this.verificarPerfil();
		this.montarMenu();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	montarMenu() {
		//console.log(this.listaAcoes);
		if (this.listaAcoes.length === 0) {
			this.desabilitar = true;
			return;
		}

		for (const acao of this.listaAcoes) {
			if (this.exibicao === 'detalhamento' && (acao.id === 1 || acao.id === 7)) {
				continue;
			}
			if (acao.id === 11) {
				acao.nomeAcao = this.situacaoLaudo === 'EMI'?
					acao.nomeAcao + ' Final' :  acao.nomeAcao + ' Preliminar'
			}
			this.acoesPossiveis.push({
				label: acao.nomeAcao,
				command: () => {
					this.executarAcao(acao.id!);
				}
			});
		}
		if (this.acoesPossiveis.length === 0) {
			this.desabilitar = true;
		}
	}

	private verificarPerfil(): void {
		const perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (perfisUsuario?.includes(PerfisEnum.PESQUISADOR)) {
			this.usuarioPesquisador = true;
		}
	}

	// DETALHAR_QUESTIONARIO = 1, 'Detalhar Questionário' //questionario
	// LIBERAR_QUESTIONARIO = 2, 'Liberar Questionário' //questionario
	// ENCERRAR_QUESTIONARIO = 3, 'Encerrar Questionário' //questionario
	// DAR_CIENCIA = 6, 'Dar ciência' //questionario

	// ATRIBUIR_MIM = 4, 'Atribuir para mim' //laudo
	// SOLICITAR_PRIORIZACAO = 5, 'Solicitar Priorização' //laudo
	// DETALHAR_LAUDO = 7, 'Detalhar Relatório' //laudo
	// ALTERAR_LAUDO = 8, 'Alterar Relatório' //laudo
	// DOWNLOAD_CURRICULO = 9, 'Download do Currículo' //laudo
	// DESPRIORIZAR_LAUDO = 10, 'Despriorizar Relatório' //laudo
	// DOWNLOAD_LAUDO = 11, 'Download do Relatório' //laudo
	private acoes: Record<string, () => void> = {
		[1]: () => this.detalharQuestionario(),
		[2]: () => this.liberarQuestionario(),
		[3]: () => this.exibirModalAlterarEncerramento(),
		[6]: () => this.darCiencia(),
		[4]: () => this.atribuirMim(),
		[5]: () => this.solicitarPriorizacao(),
		[7]: () => this.detalharLaudo(),
		[8]: () => this.alterarLaudo(),
		[9]: () => this.downloadCurriculo(),
		[10]: () => this.despriorizarLaudo(),
		[11]: () => this.downloadLaudo()
	};

	private executarAcao(idAcao: number) {
		const funcaoAcao = this.acoes[idAcao];
		funcaoAcao();
	}

	private detalharQuestionario() {
		if (this.origemQuestionario != undefined && this.origemQuestionario == 'pesquisarQuestionario') {
			this.router.navigate([`/perfilProfissiografico/questionario/${this.idCargo}`], { state: { origem: this.origemQuestionario } });
			return;
		}
		this.router.navigate([`/perfilProfissiografico/questionario/${this.idCargo}`]);
	}

	private liberarQuestionario() {
		this.limparTela();
		this.showModalLiberarQuestionario = true;
	}

	private exibirModalAlterarEncerramento() {
		this.limparTela();
		this.showModalAlterarEncerramento = true;
	}

	private limparErros() {
		this.erroPeriodo = false;
		this.erroJustificativaPreenchida = false;
		this.erroDataEncerramentoObrigatorio = false;
		this.erroDataEncerramentoAnteriorDiaAtual = false;
	}

	private limparTela() {
		this.limparErros();
		this.periodo = [];
		this.textoJustificativa = '';
		this.dataEncerramento = undefined;
	}

	private atribuirMim() {
		this.messageService.clear();
		this.subscriptions.push(
			this.perfilProfissiograficoApi
				.atribuirLaudo([this.idCandidato!])
				.pipe(
					tap(() => {
						this.messageService.add({ severity: 'success', summary: 'Relatório atribuído.' });
						this.rerenderizar();
					}),
					catchError(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro ao atribuir relatório',
							detail: 'Tente novamente mais tarde.'
						});
						return [];
					})
				)
				.subscribe()
		);
	}

	private solicitarPriorizacao() {
		this.messageService.clear();
		this.subscriptions.push(
			this.perfilProfissiograficoApi
				.priorizarLaudo(this.idCandidato!)
				.pipe(
					tap(() => {
						this.messageService.add({ severity: 'success', summary: 'Relatório priorizado.' });
						this.rerenderizar();
					}),
					catchError(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro ao priorizar relatório',
							detail: 'Tente novamente mais tarde.'
						});
						return [];
					})
				)
				.subscribe()
		);
	}

	despriorizarLaudo() {
		this.messageService.clear();
		this.subscriptions.push(
			this.perfilProfissiograficoApi
				.despriorizarLaudo(this.idCandidato!)
				.pipe(
					tap(() => {
						this.messageService.add({ severity: 'success', summary: 'Relatório despriorizado.' });
						this.rerenderizar();
					}),
					catchError(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro ao despriorizar relatório',
							detail: 'Tente novamente mais tarde.'
						});
						return [];
					})
				)
				.subscribe()
		);
	}

	private darCiencia() {
		this.confirmationService.confirm({
			message: 'Esta ação não poderá ser desfeita.',
			header: 'Deseja dar ciência a esse relatório?',
			accept: async () => {
				this.loading = true;
				this.subscriptions.push(
					this.perfilProfissiograficoApi.darCienciaLaudo(this.idCandidato!).subscribe({
						next: async res => {
							if (res) {
								this.messageService.add(this.ui.criarMensagem('success', '', 'Ciência dada com sucesso.'));
								this.rerenderizar();
							}
						},
						error: err => {
							this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
						}
					})
				);
			}
		});
	}

	private detalharLaudo() {
		this.router.navigate([`/perfilProfissiografico/laudo/${this.idCandidato}/${this.idCargo}`]);
	}

	private alterarLaudo() {
		if (this.origemLaudo != undefined && this.origemLaudo == 'pesquisarLaudo') {
			this.router.navigate([`/perfilProfissiografico/alterar-laudo/${this.idCandidato}`], { state: { origem: this.origemLaudo } });
			return;
		}
		this.router.navigate([`/perfilProfissiografico/alterar-laudo/${this.idCandidato}`]);
	}

	private downloadCurriculo() {
		this.messageService.clear();
		this.subscriptions.push(
			this.perfilProfissiograficoApi
				.downloadCurriculo(this.idCandidato!)
				.pipe(
					tap(response => {
						// console.log(response);
						const contentType = 'application/pdf';
						const contentDisposition = response.headers.get('content-disposition');
						const contentFilename = this.usuarioPesquisador ? undefined : contentDisposition?.split(';')[1].trim().split('=')[1];
						const filename = contentFilename ?? 'curriculo.pdf';
						this.ui.downloadSaveFile(response, contentType, filename);
					}),
					catchError(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro ao baixar currículo',
							detail: 'Tente novamente mais tarde.'
						});
						return [];
					})
				)
				.subscribe()
		);
	}

	private downloadLaudo() {
		this.messageService.clear();
		this.subscriptions.push(
			this.perfilProfissiograficoApi
				.downloadLaudo(this.idCandidato!)
				.pipe(
					tap(response => {
						// console.log(response);
						const contentType = 'application/pdf';
						const contentDisposition = response.headers.get('content-disposition');
						const contentFilename = this.usuarioPesquisador ? undefined : contentDisposition?.split(';')[1].trim().split('=')[1];
						const filename = contentFilename ?? 'relatorio.pdf'; 
						this.ui.downloadSaveFile(response, contentType, filename);
					}),
					catchError(() => {
						this.messageService.add({
							severity: 'error',
							summary: 'Erro ao baixar relatório',
							detail: 'Tente novamente mais tarde.'
						});
						return [];
					})
				)
				.subscribe()
		);
	}

	private rerenderizar() {
		new Observable(subscriber => {
			setTimeout(() => {
				this.acaoRealizada.emit();
				subscriber.next();
			}, 1000);
		}).subscribe();
	}

	esconderModalLiberarQuestionario() {
		this.showModalLiberarQuestionario = false;
	}
	confirmarLiberarQuestionario() {
		this.erroPeriodo = false;
		if (this.periodo.length > 1 && this.periodo[0] && this.periodo[1]) {
			const dataInicio: string = moment(this.periodo[0]).format('DD/MM/YYYY');
			const dataFinal: string = moment(this.periodo[1]).format('DD/MM/YYYY');
			this.subscriptions.push(
				this.perfilProfissiograficoApi
					.liberarQuestionarioCargo(this.idCargo!, dataInicio, dataFinal)
					.pipe(
						tap(res => {
							if (res) {
								this.messageService.add({ severity: 'success', summary: 'Questionário Liberado com sucesso!' });
								this.esconderModalLiberarQuestionario();
								this.rerenderizar();
							}
						}),
						catchError(err => {
							// this.messageService.add({
							// 	severity: 'error',
							// 	summary: 'Erro ao tentar liberar questionário',
							// 	detail: err.error.message
							// });
							this.mensagemFeedback = this.ui.criarListaMensagem('error', '', err.error.message);
							return [this.mensagemFeedback];
						})
					)
					.subscribe()
			);
		} else {
			this.erroPeriodo = true;
		}
	}

	private converterParaData(data: any) {
		if (typeof data === 'string') {
			const dia = +data.substring(0, 2);
			const mes = +data.substring(3, 5);
			const ano = +data.substring(6);
			return moment(new Date(ano, mes - 1, dia)).startOf('day');
		} else {
			return moment(data);
		}
	}

	esconderModalEncerrarLiberacaoQuestionario() {
		this.showModalAlterarEncerramento = false;
	}

	private validarAlterarEncerramento(): boolean {
		this.erroJustificativaPreenchida = false;
		this.erroDataEncerramentoObrigatorio = false;
		// this.erroDataEncerramentoAnteriorDiaAtual = false;

		if (this.textoJustificativa.length <= 0) {
			this.erroJustificativaPreenchida = true;
		}
		if (!this.dataEncerramento) {
			this.erroDataEncerramentoObrigatorio = true;
		}
		// const dataEncerramentoMoment = this.converterParaData(this.dataEncerramento);
		// if (this.dataEncerramento && dataEncerramentoMoment.isBefore(new Date())) {
		// 	this.erroDataEncerramentoAnteriorDiaAtual = true;
		// }
		if (this.erroDataEncerramentoObrigatorio || this.erroJustificativaPreenchida) {
			return false;
		}
		//console.log(this.erroJustificativaPreenchida, this.erroDataEncerramentoObrigatorio, this.erroDataEncerramentoAnteriorDiaAtual);
		return true;
	}

	alterarEncerramento() {
		if (this.validarAlterarEncerramento()) {
			// this.erroJustificativaPreenchida = false;

			this.subscriptions.push(
				this.perfilProfissiograficoApi
					.alterarEncerrarQuestionarioCargo(
						this.idCargo!,
						this.textoJustificativa,
						moment(this.dataEncerramento).format('DD/MM/YYYY')
					)
					.pipe(
						tap(res => {
							if (res) {
								this.messageService.add({
									severity: 'success',
									summary: 'Data de Encerramento alterada com sucesso!'
								});
								this.rerenderizar();
								this.esconderModalEncerrarLiberacaoQuestionario();
							}
						}),
						catchError(err => {
							// this.messageService.add({
							// 	severity: 'error',
							// 	summary: 'Erro ao alterar data encerramento',
							// 	detail: err.error.message
							// });
							this.mensagemFeedback = this.ui.criarListaMensagem('error', '', err.error.message);
							return [this.mensagemFeedback];
						})
					)
					.subscribe()
			);
		} else {
			this.erroJustificativaPreenchida = true;
		}
	}
}
