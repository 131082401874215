import { Component, OnDestroy } from '@angular/core';

import { CandidatoLaudo } from '@app/core/models/perfil-profissiografico/candidato-laudo';
import { PesquisarLaudoService } from './../services/pesquisar-laudo.service';

@Component({
	selector: 'sgp-resultado-pesquisar-laudo',
	templateUrl: './resultado-pesquisar-laudo.component.html',
	styleUrls: ['./resultado-pesquisar-laudo.component.scss']
})
export class ResultadoPesquisarLaudoComponent implements OnDestroy {
	candidatosSelecionados: CandidatoLaudo[] = [];

	constructor(protected pesquisarService: PesquisarLaudoService) {}

	ngOnDestroy() {
		this.pesquisarService.fecharSubscriptions();
	}

	atribuirEmLote() {
		const listaIdCandidatosSelecionados: number[] = [];
		for (const candidato of this.candidatosSelecionados) {
			listaIdCandidatosSelecionados.push(candidato.idCandidato!);
		}
		this.pesquisarService.atribuirEmLote(listaIdCandidatosSelecionados!);
	}

	permiteSelecionarParaAtribuirEmLote(candidato: CandidatoLaudo) {
		return candidato.situacao?.codigo == 'GER';
	}

	paginar(event: any) {
		const page = event.first / event.rows + 1;
		const size = event.rows;
		this.pesquisarService.paginar(page, size);
	}
}
