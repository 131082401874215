import { Pipe, PipeTransform } from '@angular/core';

import { SituacoesEnum } from '@core/enums/situacoes.enum';

@Pipe({ name: 'podeSerSituacao' })
export class SituacaoPipe implements PipeTransform {
	/**
	 * Testa se uma situação passada corresponde a uma das situações listadas. Podem ser até 4 situações listadas.
	 * @param situacaoInformada situacao para comparacao
	 * @param situacao1 situação desejada 1
	 * @param situacao2 situação desejada 2
	 * @param situacao3 situação desejada 3
	 * @param situacao4 situação desejada 4
	 * @returns true| false
	 */
	transform(
		situacaoInformada: any,
		situacao1: SituacoesEnum,
		situacao2?: SituacoesEnum,
		situacao3?: SituacoesEnum,
		situacao4?: SituacoesEnum
	): boolean {
		if (situacaoInformada) {
			const codigoSituacao = situacaoInformada?.codigo;
			return (
				codigoSituacao === situacao1 || codigoSituacao === situacao2 || codigoSituacao === situacao3 || codigoSituacao === situacao4
			);
		}
		return false;
	}
}
