import { Pipe, PipeTransform } from '@angular/core';

import { ItensResultadoQuestionario } from '../models/perfil-profissiografico/resultado-pesquisar-questionario-profissiografico';

/**
 * Imprime o periodo exibindo 'dd/mm/yyyy até dd/mm/yyyy' sem ambos forem informados. Senão, imprime apenas um.
 */
@Pipe({ name: 'dataItensResultadoQuestionario' })
export class DataItensResultadoQuestionarioPipe implements PipeTransform {
	transform(etapa: ItensResultadoQuestionario): any {
		if (etapa.dataInicioLiberacao != undefined && etapa.dataFimLiberacao != undefined)
			return (
				etapa.dataInicioLiberacao.substring(0, 10).split('-').reverse().join('/') +
				' até ' +
				etapa.dataFimLiberacao.substring(0, 10).split('-').reverse().join('/')
			);
		if (etapa.dataInicioLiberacao != undefined) return etapa.dataInicioLiberacao.substring(0, 10).split('-').reverse().join('/');
		if (etapa.dataFimLiberacao != undefined) return etapa.dataFimLiberacao.substring(0, 10).split('-').reverse().join('/');
		return '-';
	}
}
