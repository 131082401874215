import { HttpClient, HttpParams } from '@angular/common/http';
import { publishLast, refCount } from 'rxjs/operators';

import { Combo } from '@app/core/models/combo';
import { Incentivo } from '@app/core/models/incentivo';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametrosMatch } from '@app/core/models/parametros-match';
import { TipoEtapa } from '@app/core/models/tipo-etapa';
import { TipoMovimentacao } from '@app/core/models/tipo-movimentacao';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ConsultasApiService {
	private cacheTiposJornada$?: Observable<Array<Combo>>;
	private cacheProgramasGestao$?: Observable<Array<Combo>>;
	private cacheIncentivos$?: Observable<Array<Incentivo>>;
	private cacheTipoEtapa$?: Observable<Array<TipoEtapa>>;

	constructor(private http: HttpClient) {}

	tiposMovimentacao(codigoOrgao: string): Observable<TipoMovimentacao[]> {
		const url = environment.TIPO_MOVIMENTACAO + '/' + codigoOrgao;

		return this.http.get<Combo[]>(url);
	}

	tiposJornada() {
		if (!this.cacheTiposJornada$) {
			this.cacheTiposJornada$ = this.tiposJornadaService().pipe(publishLast(), refCount());
		}
		return this.cacheTiposJornada$;
	}

	private tiposJornadaService(): Observable<Combo[]> {
		const url = environment.TIPO_JORNADA;

		return this.http.get<Combo[]>(url);
	}

	programasGestao() {
		if (!this.cacheProgramasGestao$) {
			this.cacheProgramasGestao$ = this.programasGestaoService().pipe(publishLast(), refCount());
		}
		return this.cacheProgramasGestao$;
	}

	private programasGestaoService(): Observable<Combo[]> {
		const url = environment.PROGRAMA_GESTAO;

		return this.http.get<Combo[]>(url);
	}

	incentivos() {
		if (!this.cacheIncentivos$) {
			this.cacheIncentivos$ = this.incentivosService().pipe(publishLast(), refCount());
		}
		return this.cacheIncentivos$;
	}

	private incentivosService(): Observable<Incentivo[]> {
		const url = environment.INCENTIVO;

		return this.http.get<Incentivo[]>(url);
	}

	tiposEtapa() {
		if (!this.cacheTipoEtapa$) {
			this.cacheTipoEtapa$ = this.tiposEtapaService().pipe(publishLast(), refCount());
		}
		return this.cacheTipoEtapa$;
	}

	private tiposEtapaService(): Observable<TipoEtapa[]> {
		const url = environment.TIPO_ETAPA;

		return this.http.get<TipoEtapa[]>(url);
	}

	tiposDocumentos(): Observable<any> {
		const url = environment.HOST_OPORTUNIDADE + '/' + 'tipo-documento';
		let params = new HttpParams();
		params = params.set('ativo', 1);
		return this.http.get<any>(url, { params: params });
	}

	nomeUnidadeOrganizacional(codigoOrgao: string, codigoUnidade: string): Observable<string> {
		const url = environment.UNIDADE_ORGANIZACIONAL + '/' + codigoOrgao + '/' + codigoUnidade;
		return this.http.get<string>(url);
	}

	matchPorRequisitosService(parametros: ParametrosMatch): Observable<any> {
		const url = environment.MATCH + '/requisitos';
		return this.http.post(url, parametros);
	}
}
