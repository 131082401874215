<p-dialog
	id="adicionarRequisito"
	[header]="nomeRequisito"
	[(visible)]="exibirModal"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-50vw"
	[contentStyle]="{minHeight: '150px', overflow: 'hidden'}"
	[baseZIndex]="10000"
	[resizable]="false"
	(onHide)="fecharModal()"
	data-cy="modal-requisito"
>
	<ng-content select="[formulario]"></ng-content>

	<ng-template pTemplate="footer">
		<p-button
			(click)="fecharModal()"
			label="Cancelar"
			styleClass="br-button secondary mr-2"
			data-cy="botao-cancelar-requisito"
		></p-button>
		<ng-content select="[botaoAdicionar]"></ng-content>
	</ng-template>
</p-dialog>
