import { Component, Input } from '@angular/core';

@Component({
	selector: 'sgp-nenhum-resultado',
	templateUrl: './nenhum-resultado.component.html'
})
export class NenhumResultadoComponent {
	@Input() exibir = false;
	@Input() titulo = 'Ops! Não encontramos o que você procura!';
	@Input() mensagem = 'Verifique se os filtros de pesquisa estão corretos e consulte novamente.';
}
