import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AcoesEnum } from '@core/enums/acoes.enum';
import { AcoesService } from '@core/services/acoes.service';
import { Anexo } from '@core/models/anexo';
import { ConverterInterfaceEditalHelper } from '@app/core/helpers/converter-interface-edital.helper';
import { Edital } from '@core/models/edital';
import { EditalService } from '@app/cadastrarEdital/services/edital.service';
import { Message } from 'primeng/api/message';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@core/enums/perfis.enum';
import { SituacoesEnum } from '@core/enums/situacoes.enum';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';

@Component({
	selector: 'sgp-revisar-edital',
	templateUrl: './revisar-edital.component.html'
})
export class RevisarEditalComponent implements OnDestroy, OnInit {
	MENSAGEM_PENDENCIAS_SOLICITAR_PUBLICACAO = 'Existem pendências a resolver antes de solicitar a publicação do edital';

	@Input() edital!: Edital;

	edital$?: Edital;
	exibirModalSolicitarPublicacao = false;
	exibirModalSolicitarRetificacao = false;
	mensagemFeedback: Message[] = [];
	anexos!: Anexo[];
	subscriptions: Subscription[] = [];
	desabilitarSolicitarPublicacao = true;
	mensagemBotaoSolicitarPublicacao = this.MENSAGEM_PENDENCIAS_SOLICITAR_PUBLICACAO;

	constructor(
		private converterInterfaceEdital: ConverterInterfaceEditalHelper,
		private acoesService: AcoesService,
		private editalService: EditalService,
		private oportunidadesService: OportunidadesApiService,
		private ui: UiService
	) {}

	ngOnInit() {
		this.carregarEdital(this.edital.id);
		this.carregarAnexos(this.edital.id);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get situacoesEnum(): typeof SituacoesEnum {
		return SituacoesEnum;
	}

	get acoesEnum(): typeof AcoesEnum {
		return AcoesEnum;
	}

	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}

	/**
	 * Fecha o modal de acordo com o tipo de ação
	 * @param event tipo de ação
	 */
	fecharModal(event: string) {
		if (event == 'SolicitarPublicacao') {
			this.exibirModalSolicitarPublicacao = false;
		} else if (event == 'SolicitarRetificacao') {
			this.exibirModalSolicitarRetificacao = false;
		}
	}

	atualizarEdital(event: Edital) {
		this.edital$ = event;
	}

	private carregarEdital(id: number) {
		let edital_temp: Edital;
		this.subscriptions.push(
			this.oportunidadesService.consultarEditalDadosBasicos(id).subscribe(
				res => {
					if (res) {
						edital_temp = new Edital(id);
						this.converterInterfaceEdital.converter(edital_temp, res);
						this.edital$ = edital_temp;
						if (this.edital$.situacao.codigo === SituacoesEnum.RAS) {
							this.habilitarBotaoSolicitarPublicacao();
						}
						this.acoesService.nextSituacaoEdital(this.edital$.situacao);
						this.acoesService.nextHistoricoEdital(this.edital$.historico);
					}
				},
				err => {
					this.mensagemFeedback = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private carregarAnexos(id: number) {
		this.subscriptions.push(
			this.oportunidadesService.consultarAnexosEdital(id).subscribe(res => {
				if (res) {
					this.anexos = res;
				}
			})
		);
	}

	private habilitarBotaoSolicitarPublicacao() {
		let todasEtapasPreenchidas = true;
		this.mensagemBotaoSolicitarPublicacao = this.MENSAGEM_PENDENCIAS_SOLICITAR_PUBLICACAO;
		if (this.edital$ && this.edital$.oportunidades != null && this.edital$.oportunidades.length > 0) {
			for (const etapa of this.edital$.etapas!) {
				if (etapa.dataInicioEtapa === null || etapa.dataFimEtapa === null) {
					todasEtapasPreenchidas = false;
					break;
				}
			}
			if (todasEtapasPreenchidas) {
				this.desabilitarSolicitarPublicacao = false;
				this.mensagemBotaoSolicitarPublicacao = '';
			}
		}
	}

	async download(idAnexo: number) {
		await this.editalService.downloadAnexo(idAnexo);
	}
}
