/**
 * Área de conhecimento da oportunidade
 */
export class AreaConhecimentoOportunidade {
	id?: number;
	idAreaConhecimento?: number;
	idOportunidade?: number;
	nome?: string;
	obrigatorio?: boolean;

	constructor(id?: number, idAreaConhecimento?: number, nome?: string, obrigatorio?: boolean) {
		this.id = id;
		this.idAreaConhecimento = idAreaConhecimento;
		this.nome = nome;
		this.obrigatorio = obrigatorio;
	}
}
