import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { FeedbackModal } from '@app/core/models/feedback-modal';
import { RetornoTags } from './../../core/models/retorno-tags';
import { Subscription } from 'rxjs';
import { TagCestaCurriculo } from './../../core/models/tag-cesta-curriculo';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import { UiService } from '@app/core/services/ui.service';

@Component({
	selector: 'sgp-modal-editar-favorito',
	templateUrl: './modal-editar-favorito.component.html',
	providers: [ConfirmationService]
})
export class ModalEditarFavoritoComponent implements OnInit, OnDestroy {
	@Input() vetorNomeTags?: string[] = [];
	@Input() tagsSelecionadas?: TagCestaCurriculo[] = [];
	@Input() exibirModalEditarFavoritos = false;
	@Input() situacaoFavorito = false;
	@Input() idCurriculo?: number;
	@Input() idCestaCurriculo?: number;
	@Input() permitidoExcluir = false;
	@Output() resposta: EventEmitter<FeedbackModal> = new EventEmitter<FeedbackModal>();
	titulo: 'Incluir item na cesta' | 'Editar item da cesta' = 'Incluir item na cesta';
	subscriptions: Subscription[] = [];
	curriculo: any;
	houveAlteracao = false;

	constructor(private confirmationService: ConfirmationService, private talentosApi: TalentosApiService, private ui: UiService) {}

	ngOnInit() {
		if (this.idCestaCurriculo) {
			this.titulo = 'Editar item da cesta';
		} else {
			this.titulo = 'Incluir item na cesta';
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	fecharModalEditarFavoritos() {
		if (!this.houveAlteracao) {
			const feedback = new FeedbackModal(false);
			this.resposta.emit(feedback);
		}
	}

	salvarFavoritos() {
		if (this.idCestaCurriculo === null) {
			this.inserirNaCesta();
		} else {
			this.alterarNaCesta();
		}
		this.exibirModalEditarFavoritos = false;
	}

	excluirFavoritos() {
		this.subscriptions.push(
			this.talentosApi.excluirCestaCurriculo(this.idCestaCurriculo!).subscribe(
				res => {
					if (res) {
						this.exibirModalEditarFavoritos = false;
						this.vetorNomeTags = [];
						const mensagem = this.ui.criarListaMensagem('success', '', 'Currículo excluído da cesta com sucesso!');
						const feedback = new FeedbackModal(this.exibirModalEditarFavoritos, mensagem, true);
						this.resposta.emit(feedback);
					}
				},
				err => {
					const mensagem = this.ui.criarListaMensagem('error', '', err.message);
					const feedback = new FeedbackModal(this.situacaoFavorito, mensagem);
					this.resposta.emit(feedback);
				}
			)
		);
	}

	private inserirNaCesta() {
		this.houveAlteracao = true;
		const rtags = this.montarCestaCurriculoParaInserir(this.vetorNomeTags!);
		this.subscriptions.push(
			this.talentosApi.inserirCestaCurriculo(this.idCurriculo!, rtags).subscribe(
				res => {
					if (res) {
						const mensagem = this.ui.criarListaMensagem('success', '', 'Currículo incluído na cesta com sucesso!');
						const feedback = new FeedbackModal(true, mensagem, true);
						this.resposta.emit(feedback);
					}
				},
				err => {
					const mensagem = this.ui.criarListaMensagem('error', '', err.message);
					const feedback = new FeedbackModal(this.situacaoFavorito, mensagem);
					this.resposta.emit(feedback);
				}
			)
		);
	}

	private alterarNaCesta() {
		this.houveAlteracao = true;
		const rtags = this.montarCestaCurriculoParaAlterar(this.vetorNomeTags!, this.tagsSelecionadas);
		this.subscriptions.push(
			this.talentosApi.alterarCestaCurriculo(this.idCestaCurriculo!, rtags).subscribe(
				res => {
					if (res) {
						const mensagem = this.ui.criarListaMensagem('success', '', 'Currículo alterado na cesta com sucesso!');
						const feedback = new FeedbackModal(true, mensagem, true);
						this.resposta.emit(feedback);
					}
				},
				err => {
					const mensagem = this.ui.criarListaMensagem('error', '', err.message);
					const feedback = new FeedbackModal(this.situacaoFavorito, mensagem);
					this.resposta.emit(feedback);
				}
			)
		);
	}

	private montarCestaCurriculoParaInserir(tags: string[]): any {
		if (tags.length === 0) {
			const retornoTags = new RetornoTags();
			retornoTags.tags = [];
			return retornoTags;
		}

		let lista: TagCestaCurriculo[] = [];

		tags.forEach(item => {
			const temp = new TagCestaCurriculo(0, 0, item.toLowerCase().trim());
			lista.push(temp);
		});

		lista = this.excluirTagsRepetidas(lista);

		const retornoTags = new RetornoTags();
		retornoTags.tags = lista;
		return retornoTags;
	}

	private montarCestaCurriculoParaAlterar(novo: string[], antigo: TagCestaCurriculo[] | undefined): any {
		if (novo.length === 0) {
			const retornoTags = new RetornoTags();
			retornoTags.tags = [];
			return retornoTags;
		}
		let lista: TagCestaCurriculo[] = [];
		// novo - eh a nova cara da lista
		// antigo  - eh um TagCestaCurriculo[] com a cara antiga
		//
		// ------- Processo de Merge --------
		//
		//1. excluir de antigo todos os elementos que nao estao mais dentro de novo
		//   quero de antigo todos os elementos que estao em novo
		const va = antigo?.filter(item => novo.includes(item.nomeTag!));

		//2. copiar va para lista
		va?.forEach(item => lista.push(item));

		//3. excluir de novo todos os elementos que tem o mesmo nome na lista
		//   quero uma lista de novo no qual os elementos tem nomes diferentes
		const temp: string[] = [];
		lista.forEach(item => temp.push(item.nomeTag!));
		const vn = novo.filter(item => !temp.includes(item));

		//4. inserir em lista todos os elementos de vn
		vn.forEach(nome => {
			const t1 = new TagCestaCurriculo(0, 0, nome.toLowerCase().trim());
			// t1.id = 0;
			// t1.idCestaCurriculo = 0;
			// t1.nomeTag = nome;
			lista.push(t1);
		});

		lista = this.excluirTagsRepetidas(lista);

		//5. retornar no formato RetornoTags
		const retornoTags = new RetornoTags();
		retornoTags.tags = lista;
		return retornoTags;
	}

	private excluirTagsRepetidas(lista: TagCestaCurriculo[]): TagCestaCurriculo[] {
		const nomeTags: string[] = [];
		const listaSemDuplicados: TagCestaCurriculo[] = [];
		lista.forEach(item => {
			if (!nomeTags.includes(item.nomeTag!.toLowerCase())) {
				nomeTags.push(item.nomeTag!);
				listaSemDuplicados.push(item);
			}
		});
		return listaSemDuplicados;
	}
}
