export class TiposRequisitosEdital {
	chave!: string;
	nomeRequisito!: string;
	textoAuxiliar!: string;
	metodo!: () => any;

	constructor(chave: string, nomeRequisito: string, textoAuxiliar: string, metodo: () => any) {
		this.chave = chave;
		this.nomeRequisito = nomeRequisito;
		this.textoAuxiliar = textoAuxiliar;
		this.metodo = metodo;
	}
}
