<!--div class="bg-yellow-10 my-5 p-2">{{ pesquisarService.resultado$ | async | json}}</div-->

<div *ngIf="(pesquisarService.resultado$ | async) as resultado">
	<div class="br-table medium mt-4" *ngIf="resultado.items!.length; else semResultado">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">
					<span *ngIf="resultado.metadata!.total === 1">Foi encontrado 1 resultado</span>
					<span *ngIf="resultado.metadata!.total! > 1">Foram encontrados {{ resultado.metadata!.total }} resultados</span>
				</div>
			</div>
		</div>
		<p-table
			[value]="resultado.items!"
			[lazy]="true"
			(onPage)="paginar($event)"
			[first]="pesquisarService.filtro.first!"
			[totalRecords]="resultado.metadata!.total"
			dataKey="id"
			[rowHover]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="pesquisarService.filtro.size"
			[rowsPerPageOptions]="[10,20,50,100]"
			sortField="nome"
			data-cy="resultado-pesquisa"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-acoes" style="width: 10%">Ações</th>
					<th id="cabecalho-orgao">Órgão</th>
					<th id="cabecalho-cargo">Cargo</th>
					<th id="cabecalho-situacao">Situação do questionário</th>
					<th id="cabecalho-periodo">Período de liberação para respostas</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td headers="cabecalho-acoes">
						<sgp-botao-acao-profissiografico
							[listaAcoes]="rowData.acoesPossiveis"
							[idCargo]="rowData.idCargo"
							origemQuestionario="pesquisarQuestionario"
							(acaoRealizada)="pesquisarService.pesquisar()"
						></sgp-botao-acao-profissiografico>
					</td>
					<td headers="cabecalho-orgao">{{rowData.nomeOrgao}}</td>
					<td headers="cabecalho-cargo">
						<a
							[routerLink]="['/perfilProfissiografico/questionario', rowData.idCargo]"
							[state]="{ origem: 'pesquisarQuestionario' }"
							>{{rowData.nomeCargo}}</a
						>
					</td>
					<td headers="cabecalho-situacao">{{rowData.situacao.descricao}}</td>
					<td headers="cabecalho-periodo">{{rowData | dataItensResultadoQuestionario}}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<ng-template #semResultado>
		<sgp-nenhum-resultado [exibir]="true" />
	</ng-template>
</div>
