/**
 * Vínculo
 */
import { TipoVinculo } from './tipo-vinculo';

export class Vinculo {
	excetoEstagioProbatorio?: boolean;
	id?: number;
	idVinculo?: number;
	nome?: string;
	vinculo?: TipoVinculo;
	obrigatorio?: boolean;

	constructor(
		id?: number,
		idVinculo?: number,
		nome?: string,
		excetoEstagioProbatorio?: boolean,
		tipoVinculo?: TipoVinculo,
		obrigatorio?: boolean
	) {
		this.id = id;
		this.idVinculo = idVinculo;
		this.nome = nome;
		this.excetoEstagioProbatorio = excetoEstagioProbatorio;
		this.vinculo = tipoVinculo;
		this.obrigatorio = obrigatorio;
	}
}
