import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { ItemFiltroPesquisaComponent } from '@pesquisarCurriculo/item-filtro-pesquisa/item-filtro-pesquisa.component';
import { ItemResultadoCurriculoComponent } from '@pesquisarCurriculo/item-resultado-curriculo/item-resultado-curriculo.component';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ModalAdicionarFiltroComponent } from '@pesquisarCurriculo/modal-adicionar-filtro/modal-adicionar-filtro.component';
import { NgModule } from '@angular/core';
import { PesquisarCurriculoComponent } from '@pesquisarCurriculo/pesquisar-curriculo/pesquisar-curriculo.component';
import { SharedModule } from '@app/shared/shared.module';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		AutoCompleteModule,
		ButtonModule,
		DataViewModule,
		DialogModule,
		DropdownModule,
		MessageModule,
		MessagesModule,
		TagModule,
		ToastModule,
		ChipsModule,
		DsGovDirectivesModule
	],
	declarations: [PesquisarCurriculoComponent, ItemFiltroPesquisaComponent, ModalAdicionarFiltroComponent, ItemResultadoCurriculoComponent]
})
export class PesquisarCurriculoModule {}
