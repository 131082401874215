import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AreaConhecimento } from '@core/models/area-conhecimento';
import { TalentosApiService } from '@app/core/services/talentos-api.service';

@Component({
	selector: 'sgp-requisitos-area-conhecimento',
	templateUrl: './requisitos-area-conhecimento.component.html'
})
export class RequisitosAreaConhecimentoComponent implements OnChanges {
	@Input() exibir!: boolean;
	@Input() erroMensagem = false;
	@Input() textoErroMensagem = 'Campo obrigatório';
	@Input() obrigatoriedade? = true;
	@Output() obterArea: EventEmitter<AreaConhecimento> = new EventEmitter<AreaConhecimento>();
	@Output() obterAreaObrigatoria: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() obterErroCampoObrigatorio: EventEmitter<boolean> = new EventEmitter<boolean>();

	conhecimento = '';
	erroTamanhoBusca = false;
	areaConhecimentoSelecionada?: AreaConhecimento;
	conhecimentoObrigatorio = false;

	metadado = { total: -1 };
	paginaInicial = 1;
	areasConhecimento: any[] = [];
	loading = false;
	itensPorPagina = 10;

	constructor(private talentosService: TalentosApiService) {}

	ngOnChanges(): void {
		if (!this.exibir) {
			this.conhecimento = '';
			this.areaConhecimentoSelecionada = undefined;
			this.conhecimentoObrigatorio = false;
			this.areasConhecimento = [];
			this.metadado = { total: -1 };
		}
	}

	filterAreaConhecimento(): void {
		const pagina = Math.trunc(this.paginaInicial / this.itensPorPagina) + 1;

		const filtered: any[] = [];
		if (this.conhecimento.valueOf().length >= 3) {
			this.erroTamanhoBusca = false;
			this.loading = true;
			this.talentosService.areasConhecimento(this.conhecimento, pagina, this.itensPorPagina, true).subscribe(res => {
				if (res && this.loading) {
					for (const item of res.items!) {
						filtered.push(item);
					}
					this.metadado = res.metadata;
					this.areasConhecimento = filtered;
					this.loading = false;
				}
			});
		} else {
			this.erroTamanhoBusca = true;
			this.metadado = { total: -1 };
		}
	}

	paginarAreaConhecimento(event: any) {
		this.paginaInicial = event.first;
		this.filterAreaConhecimento();
	}

	pesquisaInicial() {
		this.paginaInicial = 1;
		this.filterAreaConhecimento();
		this.obterErroCampoObrigatorio.emit(false);
	}

	atualizarValorArea() {
		this.obterArea.emit(this.areaConhecimentoSelecionada);
	}

	atualizaAreaObrigatoria() {
		if (this.obrigatoriedade) {
			this.obterAreaObrigatoria.emit(this.conhecimentoObrigatorio);
		}
	}
}
