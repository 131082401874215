import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MensagemAlerta } from '@app/core/models/MensagemAlerta';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class FerramentasApiService {
	constructor(private http: HttpClient) {}

	cadastrarMensagemAlerta(mensagem: MensagemAlerta): Observable<any> {
		const url = environment.MENSAGEM_ALERTA;
		return this.http.post(url, mensagem);
	}

	excluirMensagemAlerta(): Observable<any> {
		const url = environment.MENSAGEM_ALERTA;
		return this.http.delete(url);
	}
}
