import { BotaoAcaoProfissiograficoComponent } from '@app/shared/botao-acao-profissiografico/botao-acao-profissiografico.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { DetalharAtividadesCargoComponent } from './detalhar-atividades-cargo/detalhar-atividades-cargo.component';
import { DetalharQuestionarioComponent } from './detalhar-questionario.component';
import { DetalharQuestionarioProfissiograficoComponent } from './detalhar-questionario-profissiografico/detalhar-questionario-profissiografico.component';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { GraficoEscalaComponent } from '@app/shared/grafico-escala/grafico-escala.component';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
	declarations: [DetalharQuestionarioComponent, DetalharQuestionarioProfissiograficoComponent, DetalharAtividadesCargoComponent],
	imports: [
		CommonModule,
		TabViewModule,
		MessagesModule,
		BotaoAcaoProfissiograficoComponent,
		GraficoEscalaComponent,
		SharedModule,
		CoreModule,
		DsGovDirectivesModule
	]
})
export class DetalharQuestionarioProfissiograficoModule {}
