/**
 * Experiência profissional
 */
export class Experiencia {
	id?: number;
	idOportunidade?: number;
	nomeExperiencia?: string;
	obrigatorio?: boolean;
	qtdMinimaMesesExperiencia?: number;
	servicoPublico?: boolean;
}
