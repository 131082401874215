/**
 * Anexo
 */
export class Anexo {
	idAnexo?: number;
	descricaoAnexo?: string;
	arquivo?: Blob;
	tipoAnexo?: any;
	ativo?: boolean;
}
