import * as moment from 'moment';

import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { PesquisarQuestionarioProfissiograficoService } from '../services/pesquisar-questionario-profissiografico.service';
import { UsuarioService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-filtro-pesquisar-questionario',
	templateUrl: './filtro-pesquisar-questionario.component.html'
})
export class FiltroQuestionarioProfissiograficoComponent implements OnInit {
	form!: FormGroup;
	submetido = false;
	orgaoUsuario$?: Observable<any>;
	periodo: Date[] = [];
	codigoOrgao : number | undefined = undefined;
	perfisEnum = PerfisEnum;
	placeholderCargo = 'Selecione um órgão para habilitar o campo';
	somenteGestorPessoas = false;
	perfisUsuario?: string[];

	constructor(
		protected pesquisarService: PesquisarQuestionarioProfissiograficoService,
		private fb: FormBuilder,
		private usuarioService: UsuarioService,
		private route: ActivatedRoute,
		private oportunidadesService: OportunidadesApiService
	) {}

	ngOnInit(): void {
		this.somenteGestorPessoas = false;
		this.verificarSeGestorPessoas();
		this.carregarCombos();
		this.criarFormulario();
		this.preencherDatas();
		this.limparDadosSeUrlAnteriorNaoForDetalhar();
		this.obterNomeOrgaoUsuario();
		this.desabilitarCampos();
		if (this.somenteGestorPessoas) {
			this.placeholderCargo = 'Selecione um concurso para habilitar o campo.';
		} else {
			this.placeholderCargo = 'Selecione um órgão para habilitar o campo.';
		}
	}

	pesquisar() {
		this.submetido = true;
		if (this.form.valid) {
			this.preparaFiltro();
			this.pesquisarService.pesquisar();
			this.submetido = false;
		} else {
			this.form.markAllAsTouched();
		}
	}

	private limparDadosSeUrlAnteriorNaoForDetalhar(): void {
		this.route.queryParams.subscribe(params => {
			if (!(params['preservarParametros'] === 'true')) {
				console.log('O parâmetro preservarParametros não está presente ou é false');
				this.limpar();
			}
		});
	}

	limpar() {
		this.submetido = false;
		this.form.reset();
		this.desabilitarCampos();
		this.form.get('idConcurso')?.enable();
		this.pesquisarService.limpar();
		this.obterNomeOrgaoUsuario();
	}

	reiniciarPaginacao() {
		this.pesquisarService.filtro.page = 1;
	}

	selecionarConcurso(evento: any) {
		const idConcurso = evento.value;
		this.pesquisarService.filtro.idConcurso = idConcurso;

		if (!this.somenteGestorPessoas) {
			this.form.get('codigoOrgao')?.reset();
			if (idConcurso != null) {
				this.form.get('codigoOrgao')?.enable();
				this.pesquisarService.listarOrgaosConcurso(idConcurso);
			} else {
				this.pesquisarService.orgaos$ = undefined;
				this.form.get('codigoOrgao')?.disable();
			}
		} else if (idConcurso != null) {
				this.form.get('codigoCargo')?.enable();
				this.pesquisarService.listarCargos(idConcurso, this.pesquisarService.filtro.codigoOrgao!);
		} 
		if (!idConcurso || !this.somenteGestorPessoas) {
			this.pesquisarService.cargos$ = undefined;
			this.form.get('codigoCargo')?.disable();
		}
		this.form.get('codigoCargo')?.reset();
		this.reiniciarPaginacao();
	}

	selecionarOrgao(codigoOrgao: any) {
		this.form.get('codigoCargo')?.reset();
		if (codigoOrgao != null && this.form.get('idConcurso')!.value) {
			this.pesquisarService.listarCargos(this.form.get('idConcurso')!.value, codigoOrgao);
			this.form.get('codigoCargo')?.enable();
		} else {
			this.pesquisarService.cargos$ = undefined;
			this.form.get('codigoCargo')?.disable();
		}
		this.reiniciarPaginacao();
	}

	private preencherDatas() {
		if (this.pesquisarService.filtro.dataInicioLiberacao && this.pesquisarService.filtro.dataFimLiberacao) {
			this.periodo = [
				this.prepararDataForm(this.pesquisarService.filtro.dataInicioLiberacao),
				this.prepararDataForm(this.pesquisarService.filtro.dataFimLiberacao)
			];
			this.form.get('periodo')?.setValue(this.periodo);
		}
	}

	private criarFormulario() {
		this.form = this.fb.group({
			idConcurso: [this.pesquisarService.filtro.idConcurso, Validators.required],
			codigoOrgao: [this.pesquisarService.filtro.codigoOrgao],
			codigoCargo: [this.pesquisarService.filtro.codigoCargo],
			situacao: [this.pesquisarService.filtro.situacao],
			periodo: [this.periodo, [this.validarPeriodo]]
		});
	}

	private validarPeriodo(control: AbstractControl): ValidationErrors | null {
		if (control.value) {
			const dataInicio = control.value[0];
			const dataFim = control.value[1];
			if (dataInicio && !dataFim) {
				return { dataFinalDePeriodoFaltando: true };
			}
		} 	 	
		return null;
	}

	private desabilitarCampos() {
		this.form.get('codigoOrgao')?.disable();
		this.form.get('codigoCargo')?.disable();
	}

	private carregarCombos() {
		if (!this.pesquisarService.concursos$) {
			this.pesquisarService.listarConcursos();
		}
		if (!this.pesquisarService.situacoes$) {
			this.pesquisarService.listarSituacoes();
		}
		this.pesquisarService.situacoes$?.subscribe(
			situacoes => {
				if (situacoes) {
					if (!this.somenteGestorPessoas) {
						const found = situacoes.find((element) => element.codigo === 'todos');
						if (!found)  {
							situacoes.unshift({codigo: 'todos', descricao: 'Todos'});
						}
					} else {
						const index = situacoes.findIndex(element => element.codigo === "RAS");
						if (index !== -1) {
							const item = situacoes.splice(index, 1);
						}
					}
				}
		});
	}

	private preparaFiltro() {
		this.pesquisarService.filtro.idConcurso = this.obterValor('idConcurso');
		this.pesquisarService.filtro.codigoOrgao = this.obterValor('codigoOrgao');
		if (!this.pesquisarService.filtro.codigoOrgao && this.somenteGestorPessoas)
			this.pesquisarService.filtro.codigoOrgao = this.codigoOrgao;
		this.pesquisarService.filtro.codigoCargo = this.obterValor('codigoCargo');
		this.pesquisarService.filtro.situacao = this.obterValor('situacao');
		if (this.obterValor('periodo') != null) {
			this.pesquisarService.filtro.dataInicioLiberacao = this.prepararDataFiltro(this.obterValor('periodo')[0]);
			this.pesquisarService.filtro.dataFimLiberacao = this.prepararDataFiltro(this.obterValor('periodo')[1]);
		}
	}

	private obterValor(campo: string): any {
		const valor = this.form.get(campo)?.value;
		if (campo == 'situacao' && valor == 'todos')
			return null;
		return valor;
	}

	private prepararDataFiltro(data: Date): string | undefined {
		return data != null ? moment(data).format('DD/MM/YYYY') : undefined;
	}

	private prepararDataForm(data?: string): Date {
		return moment(data, 'DD/MM/YYYY').toDate();
	}

	private obterNomeOrgaoUsuario() {
		if (this.somenteGestorPessoas) {
			this.codigoOrgao = this.usuarioService.getUsuario().codigoOrgaoSiape;
			this.orgaoUsuario$ = this.oportunidadesService.consultarOrgao(this.codigoOrgao!.toString());
			this.form.get('codigoOrgao')?.setValue(this.codigoOrgao);
			this.pesquisarService.filtro.codigoOrgao = this.codigoOrgao;
			this.selecionarOrgao(this.codigoOrgao);
		} else if (this.pesquisarService.filtro.codigoOrgao) {
			this.codigoOrgao = 0;
			this.form.controls['codigoOrgao'].setValue(this.pesquisarService.filtro.codigoOrgao);
			this.selecionarOrgao(this.pesquisarService.filtro.codigoOrgao);
		}
	}

	private verificarSeGestorPessoas() {
		this.perfisUsuario = this.usuarioService.getUsuario().perfis;
		if (this.perfisUsuario?.includes(PerfisEnum.GESTOR_PESSOAS) && !this.perfisUsuario?.includes(PerfisEnum.GESTOR_PRODUTO) && !this.perfisUsuario?.includes(PerfisEnum.PESQUISADOR)) {
			     this.somenteGestorPessoas = true;
		}
	}	
}
