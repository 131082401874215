<div class="mt-3">
	<p-messages [(value)]="mensagens" [enableService]="false" [closable]="false"></p-messages>
</div>

<p-toast position="bottom-right"></p-toast>

<div *ngIf="candidatos && operacao === 'listar'">
	<div class="br-table medium mt-4" *ngIf="usuarioMesmoOrgao; else semPermissao">
		<div class="table-header">
			<div class="top-bar row">
				<div class="col-sm-6">
					<div class="table-title" data-cy="titulo-tabela">Candidatos inscritos: {{ candidatos.length }}</div>
				</div>
				<div class="col-sm-6 text-right">
					<p-button
						styleClass="circle"
						icon="far fa-file-excel"
						title="Exportar tabela"
						alt="Exportar tabela"
						(click)="exportarTabela()"
						data-cy="botao-exportar-tabela"
					></p-button>
					<div
						[ngClass]="{'d-inline': true, 'cursor-not-allowed' : this.candidatosSelecionados.length < 1, 'cursor-pointer': this.candidatosSelecionados.length > 1}"
					>
						<p-button
							styleClass="circle"
							icon="fas fa-envelope"
							title="Enviar notificação para candidatos"
							alt="Enviar notificação para candidatos"
							(click)="enviarNotificacaoMultiplosCandidatos()"
							data-cy="botao-notificacao-multiplos-candidatos"
							[ngClass]="{'cursor-no-events' : this.candidatosSelecionados.length < 1}"
							[disabled]="this.candidatosSelecionados.length < 1"
						></p-button>
						<p-button
							styleClass="circle"
							[ngClass]="{'cursor-no-events' : this.candidatosSelecionados.length < 1}"
							icon="fas fa-file-download"
							title="Download de currículos"
							alt="Download de currículos"
							(click)="baixarMultiplosCurriculos()"
							data-cy="botao-download-curriculos"
							[disabled]="this.candidatosSelecionados.length < 1"
						></p-button>
						<p-button
							styleClass="circle"
							[ngClass]="{'cursor-no-events' : this.candidatosSelecionados.length < 1}"
							icon="fas fa-file-archive"
							title="Download de anexos"
							alt="Download de anexos"
							(click)="baixarMultiplosAnexos()"
							data-cy="botao-download-multiplos-anexos"
							[disabled]="this.candidatosSelecionados.length < 1"
						></p-button>
					</div>
				</div>
			</div>
		</div>
		<p-table
			[value]="candidatos"
			dataKey="id"
			sortField="data_inscricao"
			[(selection)]="candidatosSelecionados"
			dataKey="id"
			sortMode="multiple"
			[rowHover]="true"
			[responsive]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			[rowsPerPageOptions]="[10,20,50,100]"
			data-selection="data-selection"
			data-cy="lista-candidatos-oportunidade"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="checkbox" class="checkbox">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th id="candidato" [pSortableColumn]="'nomeCandidato'">
						Candidato <p-sortIcon [field]="'nomeCandidato'"></p-sortIcon>
					</th>
					<th id="favorito" [pSortableColumn]="'favorito'" class="text-center">
						Favorito <p-sortIcon [field]="'favorito'"></p-sortIcon>
					</th>
					<th id="data_inscricao" [pSortableColumn]="'dataHoraInscricao'" class="text-center">
						Inscrito em <p-sortIcon [field]="'dataHoraInscricao'"></p-sortIcon>
					</th>
					<th id="curriculo" class="text-center">Currículo</th>
					<th
						id="marcado"
						[pSortableColumn]="'marcado'"
						class="text-center"
						title="Marcar se o currículo do candidato já foi analisado"
					>
						Analisado <p-sortIcon [field]="'marcado'"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-candidato let-rowIndex="rowIndex">
				<tr>
					<td class="checkbox">
						<p-tableCheckbox [value]="candidato" [index]="rowIndex"></p-tableCheckbox>
					</td>
					<td headers="candidato" class="text-capitalize">
						<span class="p-column-title mb-2">Candidato</span>
						<span (click)="detalharCandidato(candidato)" class="text-blue-warm-vivid-70 cursor-pointer"
							>{{candidato.nomeCandidato?.toLowerCase()}}</span
						>
					</td>
					<td headers="favorito" class="text-center">
						<span class="p-column-title mb-2">Favorito</span>
						<div
							[ngClass]="{'cursor-not-allowed' : editalComSituacaoFinalistica, 'cursor-pointer': !editalComSituacaoFinalistica}"
						>
							<div
								class="br-tag icon small tag-table"
								[ngClass]="{'bg-gray-20': !candidato.favorito, 'bg-gold-vivid-20': candidato.favorito, 'cursor-no-events': editalComSituacaoFinalistica}"
								(click)="marcarDesmarcarFavorito(candidato.id)"
								title="Marcar como favorito"
							>
								<i class="fas fa-star text-down-01"></i>
							</div>
						</div>
					</td>
					<td headers="data_inscricao" class="text-center">
						<span class="p-column-title mb-2">Data de inscrição</span>
						<div *ngIf="candidato.dataHoraInscricao">{{candidato.dataHoraInscricao.split(" ")[0] }}</div>
						<div class="text-down-01 text-gray-50" *ngIf="candidato.dataHoraInscricao">
							{{candidato.dataHoraInscricao.split(" ")[1] }}
						</div>
						<div *ngIf="!candidato.dataHoraInscricao">-</div>
					</td>
					<td headers="curriculo" class="text-center">
						<span class="p-column-title mb-2">Currículo</span>
						<p-button
							styleClass="circle btn-curriculo"
							icon="fas fa-file-download"
							title="Download do currículo"
							alt="Download do currículo"
							(click)="baixarCurriculo(candidato.id)"
							data-cy="download-curriculo-candidato-oportunidade"
							[disabled]="editalArquivado"
						></p-button>
					</td>
					<td headers="marcado" class="text-center">
						<span class="p-column-title mb-2">Analisado</span>
						<div
							[ngClass]="{'cursor-not-allowed' : editalComSituacaoFinalistica, 'cursor-pointer': !editalComSituacaoFinalistica}"
						>
							<div
								class="br-tag icon small tag-table"
								[ngClass]="{'bg-gray-20': !candidato.marcado, 'bg-green-vivid-30': candidato.marcado, 'cursor-no-events' : editalComSituacaoFinalistica}"
								(click)="marcarDesmarcarAnalisado(candidato.id)"
								title="Marcar currículo como analisado"
							>
								<i class="fas fa-check text-down-01"></i>
							</div>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8" data-cy="nenhum-candidato-oportunidade">Nenhum candidato inscrito até o momento.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<sgp-detalhar-candidato
	[idCandidato]="candidatoDetalhado.id!"
	[numeroEdital]="numeroEdital"
	[nomeOrgao]="nomeOrgao"
	[nomeOportunidade]="nomeOportunidade"
	[idOportunidade]="idOportunidade"
	[editalComSituacaoFinalistica]="editalComSituacaoFinalistica"
	(voltar)="exibirLista()"
	*ngIf="candidatoDetalhado && operacao === 'detalhar'"
></sgp-detalhar-candidato>

<ng-template #semPermissao>
	<div class="br-message info mt-4" role="alert">
		<div class="icon"><i class="fas fa-info-circle fa-lg" aria-hidden="true"></i></div>
		<div class="content">
			<span class="message-title">Sentimos muito.</span
			><span class="message-body">
				Mas você não possui permissão para ver os candidatos à esta oportunidade por ela pertencer outro órgão.</span
			>
		</div>
	</div>
</ng-template>

<sgp-enviar-notificacao
	[candidatos]="candidatosSelecionados"
	[numeroEdital]="numeroEdital"
	[nomeOrgao]="nomeOrgao"
	[nomeOportunidade]="nomeOportunidade"
	[exibirModal]="exibirModalNotificacao"
	(eventShowModal)="fecharModalNotificacao($event)"
	(mensagemFeedback)="exibirMensagemSucessoNotificacao($event)"
	*ngIf="exibirModalNotificacao"
></sgp-enviar-notificacao>
