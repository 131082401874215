<h3 data-cy="titulo-pagina">Tabela de tipos de documentos</h3>

<p-messages [closable]="false"></p-messages>

<div class="text-right">
	<button id="btnCadastrar" data-cy="botao-cadastrar" class="primary" (click)="prepararCadastrar()" type="submit" pButton>
		Cadastrar tipo de documento
	</button>
</div>

<sgp-pesquisar-tabela-administrativa
	labelNome="Tipo de documento"
	[habilitarFiltroCadastradoUsuario]="habilitarFiltroCadastradoUsuario"
	[pesquisaAoCarregar]="pesquisarAoCarregar"
	[limparFormulario]="limparFormularioPesquisa"
	(filtrosPesquisa)="executarPesquisar($event)"
	(executarLimpar)="limparFormulario()"
></sgp-pesquisar-tabela-administrativa>

<div id="resultado" *ngIf="resultado && totalResultado! > 0">
	<div class="br-table small">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">
					<span *ngIf="totalResultado === 1">Foi encontrado 1 resultado</span>
					<span *ngIf="totalResultado! > 1">Foram encontrados {{ totalResultado }} resultados</span>
				</div>
			</div>
		</div>
		<p-table
			[value]="resultado"
			[lazy]="true"
			(onPage)="paginar($event)"
			(onLazyLoad)="getItensPorPagina($event)"
			[loading]="loadingResultado"
			[first]="primeiroItemResultado"
			[totalRecords]="totalResultado!"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			[rowsPerPageOptions]="[10,20,50,100]"
			sortField="nome"
			data-cy="resultado-pesquisa"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-{{cabecalho}}" *ngFor="let cabecalho of colunas">{{ cabecalho.header }}</th>
					<th id="cabecalho-acoes" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-columns="colunas" let-rowData>
				<tr>
					<td *ngFor="let dados of colunas">
						<span class="p-column-title text-weight-semi-bold pr-1">{{ dados.header }}: </span> {{rowData[dados.field]}}
					</td>
					<td headers="cabecalho-acoes">
						<button
							pButton
							class="br-button circle"
							title="Alterar"
							(click)="prepararAlterar(rowData)"
							data-cy="botao-alterar-etapa-etapa.id"
							icon="fas fa-edit"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir"
							(click)="excluirItem(rowData)"
							data-cy="botao-excluir-etapa"
							icon="fas fa-trash-alt"
						></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<sgp-nenhum-resultado [exibir]="totalResultado === 0"></sgp-nenhum-resultado>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>

<p-dialog
	id="modalManterTabela"
	[header]="tituloModalManterTabela"
	[(visible)]="exibirModalManterTabela"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-50vw"
	[breakpoints]="{'960px': '50vw', '640px': '100vw'}"
	[contentStyle]="{minHeight: '150px', overflowX: 'hidden', marginBottom: '0px'}"
	[baseZIndex]="10000"
	[resizable]="false"
	data-cy="modalManterTabela"
	*ngIf="exibirModalManterTabela"
>
	<sgp-manter-tabela-administrativa
		[item]="itemTabela"
		tabela="tipo de documento"
		[exibirCadastradoPeloUsuario]="habilitarFiltroCadastradoUsuario"
		(itemSalvo)="salvarItem($event)"
	></sgp-manter-tabela-administrativa>
</p-dialog>
