import { BrowserModule, Title } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AlterarLaudoModule } from './alterarLaudo/alterar-laudo.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { CadastrarEditalModule } from '@cadastrarEdital/cadastrar-edital.module.';
import { CestaCurriculoModule } from './cestaCurriculo/cestaCurriculo.module';
import { DashboardModule } from '@dashboard/dashboard.module';
import { DashboardProfissiograficoModule } from './dashboard-profissiografico/dashboard-profissiografico.module';
import { DatePipe } from '@angular/common';
import { DetalharLaudoModule } from './detalharLaudo/detalhar-laudo.module';
import { DetalharQuestionarioProfissiograficoModule } from './detalhar-questionario-profissiografico/detalhar-questionario-profissiografico.module';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { FerramentasModule } from './ferramentas/ferramentas.module';
import { GestaoDadosModule } from './gestaoDados/gestaoDados.module';
import { HttpErrorInterceptor } from '@core/interceptors/httperror.interceptor';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PesquisarCurriculoModule } from '@pesquisarCurriculo/pesquisarCurriculo.module';
import { PesquisarEditalModule } from '@pesquisarEdital/pesquisar-edital.module';
import { PesquisarLaudoModule } from './pesquisarLaudo/pesquisar-laudo.module';
import { PesquisarQuestionarioProfissiograficoModule } from './pesquisarQuestionarioProfissiografico/pesquisar-questionario-profissiografico.module';
import { SharedModule } from './shared/shared.module';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TabelasAdministrativasModule } from '@tabelasAdministrativas/tabelasAdministrativas.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VisualizarEditalModule } from './visualizarEdital/visualizarEdital.module';
import { environment } from '@environments/environment';

// Suporte para uso de idiomas (Útil para calendário e outros componentes)
export function httpTranslateLoader(http: HttpClient): any {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent, PagenotfoundComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG),
		AuthModule,
		SigepeMainLayoutModule.forRoot(environment),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient]
			}
		}),
		SharedModule,
		CadastrarEditalModule,
		PesquisarCurriculoModule,
		PesquisarEditalModule,
		TabelasAdministrativasModule,
		VisualizarEditalModule,
		DashboardModule,
		DashboardProfissiograficoModule,
		HttpClientModule,
		FerramentasModule,
		CestaCurriculoModule,
		GestaoDadosModule,
		PesquisarLaudoModule,
		PesquisarQuestionarioProfissiograficoModule,
		DetalharLaudoModule,
		AlterarLaudoModule,
		DetalharQuestionarioProfissiograficoModule,
		DsGovDirectivesModule
	],
	providers: [
		Title,
		DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: environment.PROVIDER_AUTH, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}
		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 		showDialog: false
		// 	})
		// },
		// {
		// 	provide: Sentry.TraceService,
		// 	deps: [Router]
		// },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: () => () => {},
		// 	deps: [Sentry.TraceService],
		// 	multi: true
		// }
	],
	bootstrap: [AppComponent],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
