<h3 data-cy="titulo-pagina">Tabela de tipos de movimentação</h3>

<p-messages [closable]="false"></p-messages>

<div class="text-right">
	<button id="btnCadastrar" data-cy="botao-cadastrar" class="primary" (click)="prepararCadastrar()" type="submit" pButton>
		Cadastrar tipo de movimentação
	</button>
</div>

<sgp-pesquisar-tabela-administrativa
	labelNome="Tipo de documento"
	[habilitarFiltroCadastradoUsuario]="habilitarFiltroCadastradoUsuario"
	[pesquisaAoCarregar]="pesquisarAoCarregar"
	[limparFormulario]="limparFormularioPesquisa"
	(filtrosPesquisa)="executarPesquisar($event)"
	(executarLimpar)="limparFormulario()"
></sgp-pesquisar-tabela-administrativa>

<div id="resultado" *ngIf="resultado && totalResultado! > 0">
	<div class="br-table small">
		<div class="table-header">
			<div class="top-bar">
				<div class="table-title" data-cy="titulo-tabela">
					<span *ngIf="totalResultado === 1">Foi encontrado 1 resultado</span>
					<span *ngIf="totalResultado! > 1">Foram encontrados {{ totalResultado }} resultados</span>
				</div>
			</div>
		</div>
		<p-table
			[value]="resultado"
			[lazy]="true"
			(onPage)="paginar($event)"
			(onLazyLoad)="getItensPorPagina($event)"
			[loading]="loadingResultado"
			[first]="primeiroItemResultado"
			[totalRecords]="totalResultado!"
			dataKey="id"
			[rowHover]="true"
			[responsive]="true"
			responsiveLayout="stack"
			[autoLayout]="true"
			[paginator]="true"
			[rows]="10"
			[rowsPerPageOptions]="[10,20,50,100]"
			sortField="nome"
			data-cy="resultado-pesquisa"
		>
			<ng-template pTemplate="header">
				<tr>
					<th id="cabecalho-sigla" style="width: 10%">Sigla</th>
					<th id="cabecalho-nome">Tipo de movimentação</th>
					<th id="cabecalho-orgaos">Órgãos vinculados</th>
					<th id="cabecalho-ativo" style="width: 10%">Ativo</th>
					<th id="cabecalho-acoes" style="width: 10%">Ações</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item>
				<tr>
					<td headers="cabecalho-sigla">{{ item.codigo }}</td>
					<td headers="cabecalho-nome">{{ item.descricao }}</td>
					<td headers="cabecalho-orgaos">
						<ul>
							<li *ngFor="let orgao of item.orgaos">{{ orgao.nome }}</li>
						</ul>
					</td>
					<td headers="cabecalho-ativo">{{ item.ativo }}</td>
					<td headers="cabecalho-acoes">
						<button
							pButton
							class="br-button circle"
							title="Alterar"
							(click)="prepararAlterar(item)"
							data-cy="botao-alterar-etapa-etapa.id"
							icon="fas fa-edit"
						></button>
						<button
							pButton
							class="br-button circle"
							title="Excluir"
							(click)="excluirItem(item)"
							data-cy="botao-excluir-etapa"
							icon="fas fa-trash-alt"
						></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<sgp-nenhum-resultado [exibir]="totalResultado === 0"></sgp-nenhum-resultado>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>

<!-- Dialog manter tabela tipo movimentacao -->
<p-dialog
	id="modalManterTabela"
	[header]="tituloModal"
	[(visible)]="exibirModalManterTabela"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-50vw"
	[breakpoints]="{'960px': '50vw', '640px': '100vw'}"
	[contentStyle]="{minHeight: '150px', overflowX: 'hidden', marginBottom: '0px'}"
	[baseZIndex]="10000"
	[resizable]="false"
	data-cy="modalManterTabela"
	(onHide)="fecharModal()"
	*ngIf="exibirModalManterTabela"
>
	<p-messages [(value)]="mensagemErro" [enableService]="false" [closable]="false"></p-messages>

	<div class="pr-2 pl-2">
		<div class="row mt-2">
			<div class="col-sm-12 br-input" [ngClass]="{'danger': erroCodigo}">
				<label for="siglaCampo">Sigla:</label>
				<div style="width: 7em">
					<p-inputMask
						#elSiglaMovimentacao
						id="siglaCampo"
						name="siglaCampo"
						type="text"
						data-cy="campo-sigla"
						[(ngModel)]="tipoMovimentacao.codigo"
						mask="aaa"
						styleClass="text-uppercase"
						slotChar=" "
						[autoClear]="false"
						[disabled]="this.operacao === 'alterar'"
					></p-inputMask>
				</div>
				<sgp-message-feedback [show]="erroCodigo" [mensagem]="mensagemErroCodigo" data-cy="erro-nome-sigla"></sgp-message-feedback>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-sm-12 br-input" [ngClass]="{'danger': erroDescricao}">
				<label for="nomeCampo">Nome:</label>
				<input
					#elNomeMovimentacao
					id="nomeCampo"
					name="nomeCampo"
					pInputText
					type="text"
					data-cy="campo-nome"
					[(ngModel)]="tipoMovimentacao.descricao"
					maxlength="50"
					minlength="3"
				/>
				<sgp-message-feedback
					[show]="erroDescricao"
					[mensagem]="mensagemErroDescricao"
					data-cy="erro-nome-campo"
				></sgp-message-feedback>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-sm-12 br-checkbox d-inline">
				<input id="manter-ativo" name="manter-ativo" [(ngModel)]="tipoMovimentacao.ativo" type="checkbox" aria-label="Está ativo" />
				<label for="manter-ativo">Está ativo</label>
			</div>
		</div>

		<fieldset class="mt-4">
			<legend>Órgãos vinculados (opcional)</legend>
			<p>
				Caso o tipo de movimentação esteja vinculado a algum órgão, esta somente será exibida para os órgãos selecionados. Senão,
				será exibido para todos os órgãos
			</p>

			<div class="row mt-2">
				<div class="col-sm-8">
					<sgp-busca-orgao
						id="idOrgaos"
						inputId="idOrgaos"
						label="Órgão"
						[exibirLabel]="false"
						name="selectOrgao"
						[ngModel]="orgaoSelecionado"
						ngDefaultControl
						(ngModelUpdated)="atualizarOrgao($event)"
						data-cy="campo-orgao"
					></sgp-busca-orgao>
				</div>
				<div class="col-sm-4">
					<button id="btnVincularOrgao" data-cy="botao-vincular-orgao" (click)="vincularOrgao()" icon="fa fa-plus" pButton>
						Vincular
					</button>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-sm-12">
					<div class="br-list" role="list">
						<div class="br-item" role="listitem" *ngFor="let orgao of orgaosCadastro">
							<div class="row align-items-center">
								<div class="col">
									<i
										class="fas fa-times close-button"
										(click)="desvincularOrgao(orgao)"
										alt="Desvincular órgão"
										title="Desvincular órgão"
									></i>
									{{ orgao.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</fieldset>

		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<button class="secondary mt-3 mt-sm-0 mr-sm-2" (click)="fecharModal()" type="button" pButton data-cy="botao-cancelar-etapa">
					Cancelar
				</button>
				<button id="btnSalvar" data-cy="botao-salvar" class="primary" (click)="salvar()" type="submit" pButton>
					Salvar tipo de movimentação
				</button>
			</div>
		</div>
	</div>
</p-dialog>
