<p-dialog
	id="modalEnviarNotificacao"
	data-cy="modal-enviar-notificacao"
	header="Enviar notificação para candidatos"
	[(visible)]="exibirModal"
	(onHide)="esconderModal()"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-70vw"
>
	<div class="container-fluid">
		<p-messages [(value)]="mensagem" [enableService]="false" [closable]="false"></p-messages>

		<div class="text-center" *ngIf="enviando; else exibirForm">
			<div class="loading medium"></div>
			<div class="mt-2">Enviando notificação</div>
		</div>
		<ng-template #exibirForm>
			<form [formGroup]="form">
				<div class="row mt-2">
					<div class="col-sm-12">
						<div class="br-input">
							<label for="destinatarios">Para:</label>
							<input
								pInputText
								type="text"
								id="destinatarios"
								formControlName="destinatarios"
								data-cy="campo-destinatarios"
								[attr.disabled]="true"
								class="text-capitalize"
							/>
						</div>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-sm-12">
						<div class="br-input" [ngClass]="{ 'danger': erroAssunto }">
							<label for="assunto">Assunto:</label>
							<input id="assunto" formControlName="assunto" type="text" maxlength="80" pInputText required />
							<sgp-message-feedback [mensagem]="mensagemErroAssunto" [show]="erroAssunto"></sgp-message-feedback>
						</div>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-sm-12">
						<div [ngClass]="{ 'br-textarea': true, 'danger': erroMensagem}" [style]="{'z-index': '1', position: 'relative' }">
							<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>
							<p-editor formControlName="mensagem" [style]="{'height':'200px'}" data-cy="campo-texto-retificacao" required>
								<ng-template pTemplate="header">
									<span class="ql-formats">
										<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
										<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
										<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
										<button
											type="button"
											class="ql-list"
											value="ordered"
											aria-label="Lista numerada"
											title="Lista numerada"
										></button>
										<button
											type="button"
											class="ql-list"
											value="bullet"
											aria-label="Lista com itens"
											title="Lista com itens"
										></button>
										<span class="ql-format-separator"></span>
										<button
											type="button"
											class="ql-clean"
											aria-label="Limpar formatação"
											title="Limpar formatação"
										></button>
									</span>
								</ng-template>
							</p-editor>
							<sgp-message-feedback mensagem="Campo obrigatório" [show]="erroMensagem"></sgp-message-feedback>
						</div>
					</div>
				</div>
			</form>
		</ng-template>
	</div>
	<ng-template pTemplate="footer">
		<p-button label="Cancelar" (click)="esconderModal()" styleClass="br-button secondary mr-2" *ngIf="!enviando"></p-button>
		<p-button label="Enviar" (click)="prepararNotificacao()" styleClass="br-button primary" *ngIf="!enviando"></p-button>
		<p-button label="Aguarde..." styleClass="br-button primary" [disabled]="true" *ngIf="enviando"></p-button>
	</ng-template>
</p-dialog>
