import { Component, Input } from '@angular/core';

import { Edital } from '@core/models/edital';

@Component({
	selector: 'sgp-visualizar-dados-basicos',
	templateUrl: './visualizar-dados-basicos.component.html'
})
export class VisualizarDadosBasicosComponent {
	@Input() edital!: Edital;
}
