import { HttpClient, HttpParams } from '@angular/common/http';

import { FiltroTabelasAdministrativas } from '../models/filtro';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoMovimentacao } from '@app/core/models/tipo-movimentacao';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class TipoMovimentacaoApiService {
	constructor(private http: HttpClient) {}

	URL_TIPO_MOVIMENTACAO = environment.HOST_OPORTUNIDADE + '/' + 'tipos-movimentacao';

	obterTipoMovimentacoes(tipoMovimentacao: FiltroTabelasAdministrativas, pagina: number, tamanho: number): Observable<any[]> {
		let params = new HttpParams();

		if (tipoMovimentacao.nome) {
			params = params.set('nome', tipoMovimentacao.nome);
		}

		if (tipoMovimentacao.ativo) {
			params = params.set('ativo', 1);
		}

		if (pagina) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}

		return this.http.get<any[]>(this.URL_TIPO_MOVIMENTACAO + '/listar', { params: params });
	}

	obterTipoMovimentacao(sigla: string): Observable<TipoMovimentacao> {
		const url = this.URL_TIPO_MOVIMENTACAO + '/' + sigla;
		return this.http.get<TipoMovimentacao>(url);
	}

	incluirTipoMovimentacao(tipoMovimentacao: TipoMovimentacao) {
		return this.http.post(this.URL_TIPO_MOVIMENTACAO, tipoMovimentacao);
	}

	alterarTipoMovimentacao(sigla: string, tipoMovimentacao: TipoMovimentacao) {
		const url = this.URL_TIPO_MOVIMENTACAO + '/' + sigla;
		return this.http.put(url, tipoMovimentacao);
	}

	excluirTipoMovimentacao(sigla: string) {
		const url = this.URL_TIPO_MOVIMENTACAO + '/' + sigla;
		return this.http.delete(url);
	}
}
