import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Anexo } from '@core/models/anexo';
import { CadastrarEditalApiService } from '../services/cadastrar-edital-api.service';
import { Combo } from '@core/models/combo';
import { HttpHeaders } from '@angular/common/http';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';
import { ValidateHelper } from '@core/helpers/validate.helper';

@Component({
	selector: 'sgp-cadastrar-anexos',
	templateUrl: './cadastrar-anexos.component.html'
})
export class CadastrarAnexosComponent implements OnInit, OnDestroy {
	@Input() idEdital!: number;
	@Input() anexo!: Anexo;
	@Input() operacao!: string;
	@Input() anexosCadastradas!: Anexo[];
	@Output() obterOperacao: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild('tipoAnexo') tipoAnexo!: ElementRef;

	labelBotaoSalvar = '';
	msgErro: Message[] = [];

	tipoAnexoSelecionado: any;
	tipoAnexos!: Combo[];
	arquivo?: File;
	tiposArquivos = 'application/pdf';
	tamanhoMaximoArquivo = 5000000;

	erroTipoAnexo = false;
	erroDescricao = false;
	erroArquivo = false;

	subscriptions: Subscription[] = [];

	constructor(private cadastrarEditalApi: CadastrarEditalApiService, private validate: ValidateHelper, private ui: UiService) {}

	ngOnInit(): void {
		this.anexo = new Anexo();
		this.getLabelBotao();
		this.getTiposAnexos();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	onFileSelected(event: any) {
		if (event.files[0].size > this.tamanhoMaximoArquivo || event.files[0].size == 0 || event.files[0].type != this.tiposArquivos) {
			this.arquivo = undefined;
		} else {
			this.arquivo = event.files[0];
		}
	}

	/**
	 * Valida a anexo e retorna o valor para o listar anexos
	 */
	salvarAnexo() {
		if (this.eValido() && this.arquivo) {
			const formData = new FormData();
			formData.append('descricaoAnexo', this.anexo.descricaoAnexo!);
			formData.append('tipoAnexo', this.anexo.tipoAnexo.codigo);
			formData.append('arquivo', this.arquivo, this.arquivo.name);

			const headers = new HttpHeaders();
			headers.append('mimeType', 'multipart/form-data');
			headers.append('contentType', 'false');

			const opcoes = { headers: headers };

			this.subscriptions.push(
				this.cadastrarEditalApi.incluirAnexo(this.idEdital, formData, opcoes).subscribe(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					res => {
						this.msgErro = this.ui.criarListaMensagem('success', '', 'Anexo incluído com sucesso.');
						this.operacao = 'listar';
						this.obterOperacao.emit(this.operacao);
					},
					err => {
						this.msgErro = this.ui.criarListaMensagem('error', '', err.error.message);
					}
				)
			);
		}
	}

	/**
	 * Cancela a operação, voltando para o listar anexos
	 */
	cancelar() {
		this.operacao = 'listar';
		this.obterOperacao.emit(this.operacao);
	}

	/**
	 * Retorna o label do botão salvar, dependendo da operação
	 */
	private getLabelBotao() {
		if (this.operacao === 'cadastrar') {
			this.labelBotaoSalvar = 'Adicionar anexo';
		} else if (this.operacao === 'alterar') {
			this.labelBotaoSalvar = 'Alterar anexo';
		}
	}

	/**
	 * Carrega o combo de tipos de anexo
	 */
	private getTiposAnexos(): void {
		this.tipoAnexos = [];
		this.subscriptions.push(
			this.cadastrarEditalApi.tipoAnexo().subscribe(res => {
				res?.forEach(tipoEdital => {
					this.tipoAnexos.push({ descricao: tipoEdital.descricao, codigo: tipoEdital.codigo });
				});
			})
		);
	}

	/**
	 * Validação dos campos do anexo
	 * @param anexo anexo validado
	 * @returns true | false
	 */
	private eValido(): boolean {
		if (this.validarPreenchimentoCampos()) {
			return false;
		}
		return true;
	}

	private validarPreenchimentoCampos(): boolean {
		this.erroTipoAnexo = this.validate.isEmBranco(this.anexo.tipoAnexo);
		this.erroDescricao = this.validate.isEmBranco(this.anexo.descricaoAnexo);
		this.erroArquivo = this.validate.isEmBranco(this.arquivo);

		return this.erroTipoAnexo || this.erroDescricao || this.erroArquivo;
	}
}
