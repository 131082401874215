<div class="mt-5">
	<div class="float-left">
		<p-skeleton width="50rem" height="2rem" styleClass="mb-3"></p-skeleton>
		<p-skeleton width="15rem" height="1rem" styleClass="mb-4"></p-skeleton>
	</div>
	<div class="float-right">
		<p-skeleton width="10rem" height="2rem" styleClass="mb-4"></p-skeleton>
	</div>
	<div style="clear: both"></div>
</div>

<p-skeleton width="100%" height="4.1rem" styleClass="mb-5"></p-skeleton>

<div>
	<div class="float-left">
		<p-skeleton width="10rem" height="2rem" styleClass="mb-1"></p-skeleton>
		<p-skeleton width="24rem" height="15rem"></p-skeleton>
	</div>
	<div class="float-right">
		<p-skeleton width="15rem" height="2rem" styleClass="mb-1"></p-skeleton>
		<p-skeleton width="24rem" height="20rem"></p-skeleton>
	</div>
	<div style="clear: both"></div>
</div>
