import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { DashboardProfissiograficoService } from './../services/dashboard-profissiografico.service';
import { Observable } from 'rxjs';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { PerfisEnum } from '@app/core/enums/perfis.enum';
import { UsuarioService } from '@sigepe/sigepe-template';

@Component({
	selector: 'sgp-dasboard-profissiografico',
	templateUrl: './dashboard-profissiografico.component.html'
})

//Observação: Dar uma olhada no dashboard.component.ts como exemplo, principalmente na troca de perfil dos usuários (ngDoCheck e ngOnInit)
export class DashboardProfissiograficoComponent implements OnInit, AfterViewInit /* DoCheck */ {
	paginaSituacaoLiberacaoQuestionario = 1;
	nomeUsuario?: string;
	eAutorizador = false;
	dadosUsuario?: any;
	perfilAnterior: string[] = [];
	perfilAtual: string[] = [];
	orgaoUsuario$?: Observable<any>;

	constructor(
		protected dashboardService: DashboardProfissiograficoService,
		private usuarioTemplate: UsuarioService,
		private oportunidadesService: OportunidadesApiService,
		private changes: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		if (this.dashboardService.perfilAnterior != this.perfilAtual) {
			this.dashboardService.renderizarDados = false;
			this.dashboardService.perfilAnterior = this.perfilAtual;
			this.changes.detectChanges();
		}
	}

	navegerSituacaoLiberacaoQuestionario(event: number) {
		this.paginaSituacaoLiberacaoQuestionario = event;
		this.dashboardService.getSituacaoLiberacaoQuestionario(this.paginaSituacaoLiberacaoQuestionario);
	}

	ngOnInit() {
		this.dadosUsuario = this.usuarioTemplate.getUsuario();
		this.perfilAtual = this.dadosUsuario.perfis;
		this.nomeUsuario = this.usuarioTemplate.getToken().name.split(' ')[0];
		this.orgaoUsuario$ = this.oportunidadesService.consultarOrgao(this.dadosUsuario.codigoOrgaoSiape.toString());
	}

	get perfisEnum(): typeof PerfisEnum {
		return PerfisEnum;
	}
}
