export class ParametrosMatch {
	areasAtuacao: AreaAtuacaoMatch[] = [];
	areasConhecimento: AreaConhecimentoMatch[] = [];
	capacitacoes: CapacitacaoMatch[] = [];
	certificacoes: CertificacaoMatch[] = [];
	competencias: CompetenciaMatch[] = [];
	experiencias: ExperienciaMatch[] = [];
	formacoes: FormacaoMatch[] = [];
	idiomas: IdiomaMatch[] = [];
	vinculos: VinculoMatch[] = [];
	areasInteresse: AreaInteresseMatch[] = [];
	selos: SeloMatch[] = [];
	orgao?: OrgaoMatch;
	nomeUorg?: string;
	nomeMunicipio?: string;
	ufOrgao?: string;
	cargo?: string;
	nome?: string;
	codigoOrgao?: number;
}
export class SeloMatch {
	nomeSelo!: string;
	nomeInstituicao?: string;

	constructor(nomeSelo: string, nomeInstituicao?: string) {
		this.nomeSelo = nomeSelo;
		this.nomeInstituicao = nomeInstituicao;
	}
}
export class OrgaoMatch {
	codigoOrgao!: number;
	nome?: string;

	constructor(idOrgao: number, nome?: string) {
		this.codigoOrgao = idOrgao;
		this.nome = nome;
	}
}

export class AreaAtuacaoMatch {
	idAreaAtuacao!: number;
	nome?: string;

	constructor(idAreaAtuacao: number, nome?: string) {
		this.idAreaAtuacao = idAreaAtuacao;
		this.nome = nome;
	}
}

export class AreaInteresseMatch {
	idAreaInteresse!: number;
	nome?: string;

	constructor(idAreaInteresse: number, nome?: string) {
		this.idAreaInteresse = idAreaInteresse;
		this.nome = nome;
	}
}

export class AreaConhecimentoMatch {
	idAreaConhecimento!: number;
	nome?: string;

	constructor(idAreaConhecimento: number, nome?: string) {
		this.idAreaConhecimento = idAreaConhecimento;
		this.nome = nome;
	}
}

export class CapacitacaoMatch {
	nomeCapacitacao!: string;

	constructor(nomeCapacitacao: string) {
		this.nomeCapacitacao = nomeCapacitacao;
	}
}

export class CertificacaoMatch {
	nomeCertificacao!: string;

	constructor(nomeCertificacao: string) {
		this.nomeCertificacao = nomeCertificacao;
	}
}

export class CompetenciaMatch {
	idCompetencia!: number;
	nome?: string;

	constructor(idCompetencia: number, nome?: string) {
		this.idCompetencia = idCompetencia;
		this.nome = nome;
	}
}

export class ExperienciaMatch {
	nomeExperiencia!: string;

	constructor(nomeExperiencia: string) {
		this.nomeExperiencia = nomeExperiencia;
	}
}

export class FormacaoMatch {
	idNivelCurso!: number;
	nomeNivelCurso?: string;
	nomeFormacao?: string;

	constructor(idNivelCurso: number, nomeFormacao?: string, nomeNivelCurso?: string) {
		this.idNivelCurso = idNivelCurso;
		this.nomeFormacao = nomeFormacao;
		this.nomeNivelCurso = nomeNivelCurso;
	}
}

export class IdiomaMatch {
	idIdioma!: number;
	nome?: string;

	constructor(idIdioma: number, nome?: string) {
		this.idIdioma = idIdioma;
		this.nome = nome;
	}
}

export class VinculoMatch {
	id!: number;
	nome?: string;

	constructor(id: number, nome?: string) {
		this.id = id;
		this.nome = nome;
	}
}
