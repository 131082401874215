<p-dialog
	[header]="titulo"
	[(visible)]="showModal"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="modal-70vw"
	(onHide)="esconderModal()"
>
	<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>

	<div
		class="mb-5 br-input"
		*ngIf="tipoAcao | podeSerAcao: acoesEnum.OPO_SOLICITAR_PUBLICACAO_EDITAL:acoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO"
	>
		<label for="unidades-autorizadoras" class="d-block">Unidade autorizadora:</label>
		<p-dropdown
			id="unidades-autorizadoras"
			name="selectSituacaoEdital"
			[style]="{width:'400px', 'z-index': '999999'}"
			[autoWidth]="false"
			[options]="listaUnidadesAutorizadoras"
			[(ngModel)]="unidadeSelecionada"
			optionLabel="descricao"
			placeholder="Selecione"
			[showClear]="true"
			[disabled]="desabilitar"
			data-cy="unidades-autorizadoras"
		></p-dropdown>
		<div style="padding-top: 40px">
			<sgp-message-feedback [show]="erroUnidadeAutorizadora" mensagem="Campo obrigatório"></sgp-message-feedback>
		</div>
	</div>

	<!-- Texto retificação -->
	<div
		class="br-textarea"
		[style]="{'z-index': '1', position: 'relative' }"
		*ngIf="tipoAcao | podeSerAcao: acoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO"
	>
		<label>Texto da retificação (Este será o texto exibido no edital de retificação):</label>
		<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>
		<p-editor
			[(ngModel)]="textoRetificacao"
			[style]="{'height':'200px'}"
			data-cy="campo-texto-retificacao"
			[disabled]="desabilitar"
			[readonly]="desabilitar"
		>
			<ng-template pTemplate="header">
				<span class="ql-formats">
					<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
					<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
					<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
					<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
					<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
					<span class="ql-format-separator"></span>
					<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
				</span>
			</ng-template>
		</p-editor>
		<sgp-message-feedback [show]="erroTextoRetificacao" mensagem="Campo obrigatório"></sgp-message-feedback>
	</div>

	<!-- Texto resultado -->
	<div
		class="br-textarea"
		[style]="{'z-index': '1', position: 'relative' }"
		*ngIf="tipoAcao | podeSerAcao: acoesEnum.OPO_PUBLICAR_RESULTADO"
	>
		<label>Texto da publicação do resultado (Este será o texto exibido no edital de encerramento):</label>
		<sgp-dica-preenchimento-editor></sgp-dica-preenchimento-editor>
		<p-editor
			[(ngModel)]="textoResultado"
			[style]="{'height':'200px'}"
			data-cy="campo-texto-resultado"
			[disabled]="desabilitar"
			[readonly]="desabilitar"
		>
			<ng-template pTemplate="header">
				<span class="ql-formats">
					<button type="button" class="ql-bold" aria-label="Negrito" title="Negrito"></button>
					<button type="button" class="ql-italic" aria-label="Itálico" title="Itálico"></button>
					<button type="button" class="ql-underline" aria-label="Sublinhado" title="Sublinhado"></button>
					<button type="button" class="ql-list" value="ordered" aria-label="Lista numerada" title="Lista numerada"></button>
					<button type="button" class="ql-list" value="bullet" aria-label="Lista com itens" title="Lista com itens"></button>
					<span class="ql-format-separator"></span>
					<button type="button" class="ql-clean" aria-label="Limpar formatação" title="Limpar formatação"></button>
				</span>
			</ng-template>
		</p-editor>
		<sgp-message-feedback [show]="erroTextoPublicacaoResultado" mensagem="Campo obrigatório"></sgp-message-feedback>
	</div>

	<!-- Justificativa -->
	<div
		class="br-textarea"
		*ngIf="tipoAcao |
		podeSerAcao: acoesEnum.OPO_REJEITAR_PUBLICACAO_EDITAL:acoesEnum.OPO_REJEITAR_PUBLICACAO_RETIFICACAO: acoesEnum.OPO_SUSPENDER_EDITAL: acoesEnum.OPO_CANCELAR_EDITAL"
	>
		<label>Justificativa:</label>
		<textarea
			id="justificativa"
			[(ngModel)]="textoJustificativa"
			rows="4"
			cols="60"
			data-cy="campo-justificativa"
			[disabled]="desabilitar"
			maxlength="500"
		></textarea>
		<sgp-message-feedback [show]="erroJustificativa" mensagem="Campo obrigatório"></sgp-message-feedback>
	</div>

	<ng-template pTemplate="footer">
		<p-button
			[label]="labelBotaoPreview"
			(click)="gerarPdfRetificacao()"
			styleClass="br-button secondary"
			[disabled]="desabilitarBotaoPreview"
			*ngIf="tipoAcao === acoesEnum.OPO_SOLICITAR_PUBLICACAO_RETIFICACAO && !finalizado"
		></p-button>
		<p-button
			label="Cancelar"
			(click)="esconderModal()"
			styleClass="br-button secondary"
			[disabled]="desabilitar"
			*ngIf="!finalizado"
		></p-button>
		<p-button label="Fechar" (click)="esconderModal()" styleClass="br-button primary" *ngIf="finalizado"></p-button>
		<p-button
			[label]="labelBotaoAcao"
			(click)="executarAcao()"
			styleClass="br-button primary"
			[disabled]="desabilitar"
			*ngIf="!finalizado"
		></p-button>
	</ng-template>
</p-dialog>
