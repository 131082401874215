// Ambiente de produção
import { AuthGuard, AuthInterceptor, RoleGuard, TransactionGuard } from '@sigepe/ngx-sigepe-seguranca';

import { ConfigEnvironment } from './environment.base';

const HOST_OPORTUNIDADES = 'https://prod-sigepe-api.estaleiro.serpro.gov.br/oportunidades';
const HOST_TALENTOS = 'https://prod-sigepe-api.estaleiro.serpro.gov.br/banco-talentos/servidor/api';
const SECURITY_HOSTS = [
	'https://prod-sigepe-api.estaleiro.serpro.gov.br'
];

//Autoriza
const HOST_LOGIN_UNICO = 'https://sso.acesso.gov.br';
const HOST_JSKS = 'http://localhost:8080';
const HOST_API_SIGEPE = 'https://prod-sigepe-api.estaleiro.serpro.gov.br/seguranca';
const CLIENT_ID = 'sigepe.gov.br';
const COOKIE_DOMAIN = '.sigepe.gov.br';

const config = new ConfigEnvironment(
	HOST_OPORTUNIDADES,
	HOST_TALENTOS,
	SECURITY_HOSTS,
	HOST_LOGIN_UNICO,
	HOST_JSKS,
	HOST_API_SIGEPE,
	CLIENT_ID,
	COOKIE_DOMAIN
);

export const environment = {
	PROVIDER_AUTH: AuthInterceptor,
	GUARDS: [AuthGuard, RoleGuard],
	TRANSACTION: [AuthGuard, TransactionGuard],
	GUARDS_AND_TRANSACTION: [AuthGuard, RoleGuard, TransactionGuard],
	production: true,
	footerUrl: 'https://prod-sigepe-api.estaleiro.serpro.gov.br/template/rodapeSigepe/text',
	menuUrl: 'https://prod-sigepe-api.estaleiro.serpro.gov.br/menu-sigepe',
	mensageriaUrl: 'https://ca-mensageiria.sigepe.gov.br/sigepe-ca-mensageiria-web/private/leitor.jsf',
	manualUrl: 'https://bgp.sigepe.gov.br/sigepe-bgp-web-intranet/pages/manual/consultar.jsf',
	sistemaOrigem: 'OPORTUNIDADES',
	menuAddFromRouter: true,
	menuIgnoreRouterGuard: false,
	analyticsSettings: {
		trackingCode: 'G-Z26HVCJR0C'
	},
	...config.getEnvironment()
};
