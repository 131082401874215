import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Curriculo } from '@core/models/curriculo';
import { PesquisarCurriculoApiService } from '@app/pesquisarCurriculo/services/pesquisar-curriculo-api.service';
import { Subscription } from 'rxjs';
import { TalentosApiService } from '@app/core/services/talentos-api.service';
import moment from 'moment';

@Component({
	selector: 'sgp-exibir-curriculo',
	templateUrl: './exibir-curriculo.component.html'
})
export class ExibirCurriculoComponent implements OnInit, OnDestroy {
	LABEL_GERANDO_PDF = 'Aguarde o download do PDF...';
	LABEL_DOWNLOAD_CURRICULO = 'Download do currículo completo';

	@Input() cpf!: string;
	@Input() nome!: string;
	@Input() exibir!: boolean;
	@Output() atualizarExibir: EventEmitter<boolean> = new EventEmitter<boolean>();

	curriculo?: Curriculo;
	curriculoCarregado = false;
	labelBotaoDownload = this.LABEL_DOWNLOAD_CURRICULO;
	gerandoPDF = false;

	subscriptions: Subscription[] = [];

	constructor(
		private talentosService: TalentosApiService,
		private pesquisarCurriculoApi: PesquisarCurriculoApiService,
		private cdRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.consultarCurriculo();
	}

	ngOnDestroy(): void {
		console.log('ngOnDestroy');
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	converterParaData(data: any) {
		return moment(data).format('DD/MM/YYYY');
	}

	async gerarPdfCurriculo(cpf: string, nome: string) {
		this.labelBotaoDownload = this.LABEL_GERANDO_PDF;
		this.gerandoPDF = true;
		if (cpf) {
			this.subscriptions.push(
				this.pesquisarCurriculoApi.gerarPdfCurriculoService(cpf).subscribe(
					res => {
						const newBlob = new Blob([res], { type: 'application/pdf' });
						const nav = window.navigator as any;
						if (nav && nav.msSaveOrOpenBlob) {
							nav.msSaveOrOpenBlob(newBlob, 'curriculo_' + nome + '.pdf');
							return;
						}
						const data = window.URL.createObjectURL(newBlob);
						const link = document.createElement('a');
						link.href = data;
						link.download = 'curriculo_' + nome + '.pdf';
						link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
						setTimeout(function () {
							window.URL.revokeObjectURL(data);
							link.remove();
						}, 100);
						this.labelBotaoDownload = this.LABEL_DOWNLOAD_CURRICULO;
						this.gerandoPDF = false;
					},
					err => {
						return err.error.message;
					}
				)
			);
		}
	}

	fecharModalCurriculo() {
		this.exibir = false;
		console.log('fechar modal curriculo');
		this.atualizarExibir.emit(false);
	}

	private consultarCurriculo() {
		if (this.cpf) {
			this.subscriptions.push(
				this.talentosService.curriculoService(this.cpf).subscribe(
					res => {
						this.curriculo = res;
						this.curriculoCarregado = true;
						this.cdRef.detectChanges();
					},
					err => {
						// eslint-disable-next-line security-node/detect-crlf
						console.log('erro ao carregar curriculo', err);
					}
				)
			);

			// this.talentosService.curriculoService(this.cpf).pipe(
			// 	tap(() => {
			// 		console.log('curriculo carregado');
			// 		this.curriculoCarregado = true;
			// 	}),
			// 	map(curriculo => {
			// 		this.curriculo = curriculo;
			// 		console.log('curriculo', this.curriculo);
			// 	})
			// );
			// this.talentosService.curriculoService(this.cpf).subscribe(res => {
			// 	this.curriculo = res;
			// 	this.curriculoCarregado = true;
			// 	console.log('curriculoCarregado', this.curriculoCarregado);
			// });
		}
	}
}
