import { publishLast, refCount } from 'rxjs/operators';

import { Combo } from '@app/core/models/combo';
import { Edital } from '@app/core/models/edital';
import { Etapa } from '@app/core/models/etapa';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CadastrarEditalApiService {
	private cacheTipoAnexo$?: Observable<Array<Combo>>;

	constructor(private http: HttpClient) {}

	incluirEdital(edital: Edital): Observable<any> {
		const url = environment.EDITAL;
		return this.http.post(url, edital);
	}

	alterarEdital(edital: Edital): Observable<any> {
		const url = environment.EDITAL + '/' + edital.id.toString();
		return this.http.put(url, edital);
	}

	alterarEditalDadosBasicos(edital: Edital): Observable<any> {
		const url = environment.EDITAL + '/' + edital.id.toString() + '/dados-basicos';
		return this.http.put(url, edital);
	}

	consultarEdital(id: number): Observable<any> {
		const url = environment.EDITAL + '/' + id.toString();
		return this.http.get(url);
	}

	incluirAnexo(idEdital: number, params: FormData, opcoes: any): Observable<any> {
		const url = environment.ANEXOS + '/editais/' + idEdital.toString();
		return this.http.post(url, params, opcoes);
	}

	tipoAnexo() {
		if (!this.cacheTipoAnexo$) {
			this.cacheTipoAnexo$ = this.tipoAnexoService().pipe(publishLast(), refCount());
		}
		return this.cacheTipoAnexo$;
	}

	private tipoAnexoService(): Observable<Combo[]> {
		const url = environment.ANEXOS + '/tipo-anexo';
		return this.http.get<Combo[]>(url);
	}

	excluirAnexo(idAnexo: number): Observable<any> {
		const url = environment.ANEXOS + '/' + idAnexo;
		return this.http.delete(url);
	}

	downloadAnexo(idAnexo: number): Observable<any> {
		const url = environment.ANEXOS + '/' + idAnexo.toString();
		return this.http.get(url, {
			responseType: 'arraybuffer'
		});
	}

	incluirEtapa(idEdital: number, etapa: Etapa): Observable<any> {
		const url = environment.EDITAL + '/' + idEdital.toString() + '/etapas';
		return this.http.post(url, etapa);
	}

	detalharEtapa(idEtapa: number): Observable<any> {
		const url = environment.ETAPAS + '/' + idEtapa.toString();
		return this.http.get(url);
	}

	alterarEtapa(idEtapa: number, etapa: Etapa): Observable<any> {
		const url = environment.ETAPAS + '/' + idEtapa.toString();
		return this.http.put(url, etapa);
	}

	excluirEtapa(idEtapa: number): Observable<any> {
		const url = environment.ETAPAS + '/' + idEtapa.toString();
		return this.http.delete(url);
	}
}
