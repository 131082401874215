import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sgp-modal-adicionar-filtro',
	templateUrl: './modal-adicionar-filtro.component.html'
})
export class ModalAdicionarFiltroComponent {
	@Input() exibirModal = false;
	@Input() nomeRequisito!: string;
	@Output() exibirModalAtualizado: EventEmitter<boolean> = new EventEmitter<boolean>();

	fecharModal() {
		this.exibirModal = false;
		this.exibirModalAtualizado.emit(this.exibirModal);
	}
}
