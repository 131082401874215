import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametrosPesquisaEdital } from '@app/core/models/parametros-pesquisa-edital';
import { RetornoEditais } from '@app/core/models/retorno-editais';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PesquisarEditalApiService {
	constructor(private http: HttpClient) {}

	pesquisarEdital(parametros: ParametrosPesquisaEdital): Observable<RetornoEditais> {
		const url = environment.EDITAL;

		let params = new HttpParams();

		if (parametros.tamanho) {
			params = params.set('size', parametros.tamanho);
		}
		if (parametros.pagina) {
			params = params.set('page', parametros.pagina);
		}
		if (parametros.textoPesquisa) {
			params = params.set('texto', parametros.textoPesquisa);
		}
		if (parametros.codigoSituacao) {
			params = params.set('situacao', parametros.codigoSituacao);
		}
		if (parametros.codigoOrgao) {
			params = params.set('orgao', parametros.codigoOrgao);
		}
		if (parametros.dataInicial) {
			params = params.set('data_inicial', parametros.dataInicial);
		}
		if (parametros.dataFinal) {
			params = params.set('data_final', parametros.dataFinal);
		}
		if (parametros.localidade) {
			params = params.set('localidade', parametros.localidade);
		}

		return this.http.get<RetornoEditais>(url, { params: params });
	}
}
