import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Candidato } from '@app/core/models/candidato';
import { Message } from 'primeng/api';
import { NotificacaoCandidato } from '../models/notificacao-candidato';
import { Subscription } from 'rxjs';
import { UiService } from '@app/core/services/ui.service';
import { VisualizarEditalApiService } from './../services/visualizar-edital-api.service';

@Component({
	selector: 'sgp-enviar-notificacao',
	templateUrl: './enviar-notificacao.component.html'
})
export class EnviarNotificacaoComponent implements OnInit, OnChanges, OnDestroy {
	@Input() candidatos!: Candidato[];
	@Input() exibirModal!: boolean;
	@Input() numeroEdital?: string;
	@Input() nomeOrgao?: string;
	@Input() nomeOportunidade?: string;
	@Output() eventShowModal = new EventEmitter<boolean>();
	@Output() mensagemFeedback = new EventEmitter<Message[]>();

	mensagem: Message[] = [];
	destinatarios?: string;
	assunto?: string;
	textoAssunto?: string;

	enviando = false;
	larguraModal = '75vw';

	erroAssunto = false;
	mensagemErroAssunto = 'Campo obrigatório';
	erroMensagem = false;
	subscriptions: Subscription[] = [];

	form!: FormGroup;

	constructor(private fb: FormBuilder, private ui: UiService, private visualizarEditalApi: VisualizarEditalApiService) {
		this.criarFormulario();
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	ngOnInit() {
		this.atualizarListaDestinatarios();
	}

	ngOnChanges() {
		this.atualizarListaDestinatarios();
	}

	esconderModal() {
		this.form.reset();
		this.eventShowModal.emit(false);
	}

	prepararNotificacao() {
		if (this.form.valid) {
			this.erroAssunto = false;
			this.erroMensagem = false;
			const notificacao = this.prepararDadosParaNotificacao();
			this.notificarCandidatos(notificacao);
		} else {
			this.exibirMensagensValidacao();
		}
	}

	private prepararDadosParaNotificacao(): NotificacaoCandidato {
		const listaIdCandidatos: number[] = [];
		this.candidatos.forEach(candidato => {
			listaIdCandidatos.push(candidato.id!);
		});
		return new NotificacaoCandidato(this.form.controls['assunto'].value, this.form.controls['mensagem'].value, listaIdCandidatos);
	}

	private notificarCandidatos(notificacao: NotificacaoCandidato) {
		this.enviando = true;
		this.larguraModal = '35vw';
		this.subscriptions.push(
			this.visualizarEditalApi.notificarCandidatos(notificacao).subscribe(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				res => {
					this.enviando = false;
					this.larguraModal = '75vw';
					this.mensagemFeedback.emit(this.ui.criarListaMensagem('success', '', 'Notificação realizada com sucesso'));
					this.esconderModal();
				},
				err => {
					this.mensagem = this.ui.criarListaMensagem('error', '', err.error.message);
				}
			)
		);
	}

	private criarFormulario() {
		this.form = this.fb.group({
			destinatarios: [''],
			assunto: ['', [Validators.required, Validators.minLength(3)]],
			mensagem: ['', [Validators.required]]
		});
	}

	private atualizarListaDestinatarios() {
		let destinatarios = '';
		for (const candidato of this.candidatos) {
			destinatarios += candidato.nomeCandidato?.toLocaleLowerCase() + '; ';
		}
		destinatarios = destinatarios.slice(0, -2);
		this.form.get('destinatarios')?.setValue(destinatarios);
		this.textoAssunto = 'Edital ' + this.numeroEdital + ' - ' + this.nomeOrgao + ' - ' + this.nomeOportunidade;
		this.form.get('assunto')?.setValue(this.textoAssunto.substring(0, 79));
	}

	private exibirMensagensValidacao() {
		this.erroAssunto = this.form.controls['assunto'].invalid;

		if (this.form.controls['assunto'].invalid) {
			if (this.form.controls['assunto'].errors?.required) {
				this.mensagemErroAssunto = 'Campo obrigatório';
			} else if (this.form.controls['assunto'].errors?.minlength) {
				this.mensagemErroAssunto = 'Mínimo de 3 caracteres';
			}
		}

		this.erroMensagem = this.form.controls['mensagem'].invalid;
	}
}
