import { Component } from '@angular/core';
import { DetalharLaudoService } from '../services/detalhar-laudo.service';

@Component({
	selector: 'sgp-detalhar-perfil-candidato',
	templateUrl: './detalhar-perfil-candidato.component.html',
	styles: [
		`
			.br-item:hover {
				background-color: var(--gray-4);
			}
		`
	]
})
export class DetalharPerfilCandidatoComponent {
	constructor(protected detalharLaudoService: DetalharLaudoService) {}
}
