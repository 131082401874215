export class RetornoComboTags {
	tags?: NomeTag[];
	constructor(t?: NomeTag[]) {
		this.tags = t;
	}
}

export class NomeTag {
	nomeTag?: string;
	constructor(nome?: string) {
		this.nomeTag = nome;
	}
}
