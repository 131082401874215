<div class="my-2">
	<p-button
		label="Veja aqui a quantidade de currículos compatíveis"
		icon="fas fa-chart-line"
		styleClass="br-button primary small mr-2 mb-2"
		(click)="exibirModalEstatistica()"
		data-cy="curriculos-compativeis"
		[disabled]="!possuiRequisito"
		title="Adicione requisitos para ver a quantidade de currículos compatíveis"
	></p-button>
</div>

<p-dialog
	id="modalEstatisticas"
	header="Estatística de currículos compatíveis"
	[(visible)]="exibirModal"
	[modal]="true"
	[draggable]="false"
	[closeOnEscape]="true"
	[closable]="true"
	styleClass="modal-60vw"
	[contentStyle]="{minHeight: '300px', overflowX: 'hidden'}"
	[baseZIndex]="10000"
	[resizable]="false"
	data-cy="modal-requisito"
>
	<p>
		Esta é uma estimativa de quantos currículos cadastrados no Banco de Talentos contém cada requisito selecionado, apenas para fins
		estatísticos.
	</p>

	<div *ngIf="listaMatchOportunidades.length > 0; else loading">
		<div class="header">
			<div class="text-weight-semi-bold px-3 py-2"><i class="fas fa-chart-line"></i> Estatística</div>
			<span class="br-divider"></span>
		</div>
		<div class="br-list" role="list" *ngFor="let match of listaMatchOportunidades; let last = last" data-cy="lista-match-card">
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col">
						<span [ngClass]="{'text-weight-semi-bold': last}">{{ match.requisito }} - {{ match.descricao }}</span>
					</div>
					<div class="col-auto">
						<span class="text-base" [ngClass]="{'text-weight-semi-bold': last}">{{ match.quantidade }}</span>
					</div>
				</div>
			</div>
			<span class="br-divider" *ngIf="!last"></span>
		</div>
	</div>
	<ng-template #loading>
		<div class="br-list" role="list">
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col">
						<p-skeleton width="12rem" height="1.5rem"></p-skeleton>
					</div>
					<div class="col-auto"></div>
				</div>
			</div>
			<span class="br-divider"></span>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col">
						<p-skeleton width="12rem" height="1.5rem"></p-skeleton>
					</div>
					<div class="col-auto">
						<p-skeleton width="4rem" height="1.5rem" borderRadius="4px"></p-skeleton>
					</div>
				</div>
			</div>
			<span class="br-divider"></span>
			<div class="align-items-center br-item" role="listitem">
				<div class="row align-items-center">
					<div class="col">
						<p-skeleton width="12rem" height="1.5rem"></p-skeleton>
					</div>
					<div class="col-auto">
						<p-skeleton width="4rem" height="1.5rem" borderRadius="4px"></p-skeleton>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="footer">
		<p-button (click)="exibirModal = false" label="Fechar" styleClass="br-button primary" data-cy="botao-fechar-estatistica"></p-button>
	</ng-template>
</p-dialog>
