import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { BrowserTracing } from '@sentry/tracing';
import { enableProdMode } from '@angular/core';
import { environment } from '@environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Sentry.init({
// 	dsn: 'https://530eab429cd844ef889cdb3bab998005@sentry.estaleiro.serpro.gov.br/1076',
// 	environment: 'production',
// 	integrations: [
// 		new BrowserTracing({
// 			tracePropagationTargets: [
// 				'https://oportunidades.sigepe.gov.br/',
// 				'https://prod-sigepe-api.estaleiro.serpro.gov.br/oportunidades',
// 				'https://prod-sigepe-api.estaleiro.serpro.gov.br/banco-talentos/servidor/api'
// 			],
// 			routingInstrumentation: Sentry.routingInstrumentation
// 		}),
// 		new Sentry.Replay()
// 	],
// 	// This sets the sample rate to be 10%. You may want this to be 100% while
// 	// in development and sample at a lower rate in production
// 	replaysSessionSampleRate: 0.1,
// 	// If the entire session is not sampled, use the below sample rate to sample
// 	// sessions when an error occurs.
// 	replaysOnErrorSampleRate: 0.1,
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 0.2
// });

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
