import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { QuestionarioCargo } from '@app/core/models/perfil-profissiografico/questionario-cargo';

@Component({
	selector: 'sgp-detalhar-questionario-profissiografico',
	templateUrl: './detalhar-questionario-profissiografico.component.html'
})
export class DetalharQuestionarioProfissiograficoComponent {
	@Input() questionarioCargo?: Observable<QuestionarioCargo>;
	constructor() {}
}
