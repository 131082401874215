import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
	name: 'sgpMascaraCpf',
	standalone: true
})
export class MascaraCpfPipe implements PipeTransform {
	transform(value: string | undefined | null): string {
		if (value === undefined || value === null) {
			return '-';
		} else if (value.length < 11) {
			value = onzeDigitos(value);
		}
		return value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9) + '-' + value.substring(9, 11);
	}
}
function onzeDigitos(value: string): string {
	let zeros = '';
	for (let i = 0; i < 11 - value.length; i++) {
		zeros += '0';
	}
	return zeros + value;
}
