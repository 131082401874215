import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { CommonModule } from '@angular/common';

@Component({
	selector: 'sgp-grafico-escala',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './grafico-escala.component.html',
	styles: [
		`
			.container {
				display: flex;
			}
			.barraValorPercentual {
				height: 10px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				float: left;
			}
			.barraValorResidual {
				height: 10px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				float: right;
			}
		`
	]
})
export class GraficoEscalaComponent implements AfterViewInit {
	@Input() min!: number;
	@Input() max!: number;
	@Input() valor!: number;
	@ViewChild('barraValorPercentual') barraValorPercentual!: ElementRef<HTMLDivElement>;
	@ViewChild('barraValorResidual') barraValorResidual!: ElementRef<HTMLDivElement>;

	valorPercentual?: number;
	valorResidual?: number;

	ngAfterViewInit() {
		this.valorPercentual = this.calcularPercentual();
		this.valorResidual = 100 - this.valorPercentual;
		this.barraValorPercentual.nativeElement.style.width = `${this.valorPercentual}%`;
		this.barraValorResidual.nativeElement.style.width = `${this.valorResidual}%`;
		if (this.valorPercentual === 100) {
			this.barraValorPercentual.nativeElement.style.borderTopRightRadius = '5px';
			this.barraValorPercentual.nativeElement.style.borderBottomRightRadius = '5px';
		}
		if (this.valorPercentual === 0) {
			this.barraValorResidual.nativeElement.style.borderTopLeftRadius = '5px';
			this.barraValorResidual.nativeElement.style.borderBottomLeftRadius = '5px';
		}
	}

	calcularPercentual(): number {
		return (this.valor / this.max) * 100;
	}
}
