<!-- <pre>
	{{ detalharLaudoService.detalharRespostaCandidato$ | async | json }}
</pre> -->

<p-messages [enableService]="true" [closable]="false" styleClass="mt-4"></p-messages>

<div class="br-card mt-4" *ngIf="(detalharLaudoService.detalharRespostaCandidato$ | async) as resposta">
	<div class="card-header row">
		<div class="col-md-12">
			<span class="text-up-02">Perfil profissiográfico do candidato </span>
		</div>
	</div>
	<div class="card-content">
		<div class="row">
			<div class="col">
				<div class="br-list mt-5" role="list" *ngFor="let grupoPerguntas of resposta.grupoPerguntas">
					<div class="title text-base text-semi-bold px-3 py-2">
						<div class="row">
							<div class="col-md-6">{{grupoPerguntas.nomeGrupo}}</div>
							<div class="col-md-6 text-right">
								{{grupoPerguntas.nomeEscalaInicial}} ({{ grupoPerguntas.numeroEscalaInicial}}) - {{
								grupoPerguntas.nomeEscalaFinal}} ({{ grupoPerguntas.numeroEscalaFinal}})
							</div>
						</div>
					</div>
					<span class="br-divider"></span>
					<div class="br-item" role="listitem" *ngFor="let pergunta of grupoPerguntas.respostas">
						<div class="row">
							<div class="col-10 pt-2">{{ pergunta.textoPergunta }}</div>
							<div class="col-2">
								<sgp-grafico-escala
									[min]="grupoPerguntas.numeroEscalaInicial!"
									[max]="grupoPerguntas.numeroEscalaFinal!"
									[valor]="pergunta.valorResposta!"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
