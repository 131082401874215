<!-- <pre>{{ detalharLaudoService.detalharCargo$ | async | json }}</pre> -->
<div class="br-card mt-4" *ngIf="(detalharLaudoService.detalharCargo$ | async) as cargo">
	<div class="card-header row">
		<div class="col-md-12">
			<span class="text-up-02">Detalhar cargo</span>
		</div>
		<!-- <div class="col-md-2 text-right">
			<sgp-botao-acao-profissiografico [listaAcoes]="cargo.acoesPossiveis!" [idCargo]="cargo.idCargo" />
		</div> -->
	</div>
	<div class="card-content">
		<div class="row">
			<div class="col-md-3 mt-2 text-semi-bold">Cargo:</div>
			<div class="col-md-9 mt-2">{{ cargo.nomeCargo }}</div>
		</div>
		<div class="row">
			<div class="col-md-3 mt-2 text-semi-bold">Órgão:</div>
			<div class="col-md-9 mt-2">{{ cargo.nomeOrgao }}</div>
		</div>
		<div class="row">
			<div class="col-md-3 mt-2 text-semi-bold">Concurso:</div>
			<div class="col-md-9 mt-2">{{ cargo.nomeConcurso }}</div>
		</div>
		<div class="row">
			<div class="col-md-3 mt-2 text-semi-bold">Período de liberação do questionário:</div>
			<div *ngIf="existeDataLiberacao(cargo.dataInicioLiberacao); else semData" class="col-md-9 mt-2">
				{{ cargo.dataInicioLiberacao }} a {{ cargo.dataFimLiberacao }}
			</div>
			<ng-template #semData>
				<div class="col-md-9 mt-2">--</div>
			</ng-template>
		</div>
		<div class="row">
			<div class="col-md-3 mt-2 text-semi-bold">Situação:</div>
			<div class="col-md-9 mt-2">{{ cargo.situacao }}</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 mt-2 mb-2 text-left">
			<button
				id="btnDetalharQuestionario"
				data-cy="botao-detalhar-questionario"
				class="primary mt-3 mt-sm-0 ml-sm-3"
				(click)="detalharQuestionario(cargo.idCargo!)"
				type="submit"
				pButton
			>
				Detalhar Questionário
			</button>
		</div>
	</div>
</div>
