import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem, Message, MessageService, PrimeNGConfig } from 'primeng/api';

import { DatePipe } from '@angular/common';
import { Edital } from '@core/models/edital';
import { OportunidadesApiService } from '@app/core/services/oportunidades-api.service';
import { Paginator } from 'primeng/paginator';
import { ParametrosPesquisaEdital } from '@core/models/parametros-pesquisa-edital';
import { PesquisarEditalApiService } from './../services/pesquisar-edital-api.service';
import { RetornoEditais } from '@core/models/retorno-editais';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@core/services/ui.service';
import { UsuariosService } from '@core/services/usuarios.service';

@Component({
	templateUrl: './pesquisarEdital.component.html',
	styleUrls: ['./pesquisarEdital.component.scss'],
	providers: [MessageService]
})
export class PesquisarEditalComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() parametrosDaPesquisa = new ParametrosPesquisaEdital();
	@ViewChild('paginacao', { static: true }) paginacao!: Paginator;
	@ViewChild('elTextoPesquisa') elTextoPesquisa!: ElementRef;
	mensagem?: Message;

	nomeFuncionalidade = 'Pesquisar edital';
	situacoesEdital!: any[];
	loading = false;

	textoPesquisa!: string;
	selectSituacaoEdital?: any;
	selectOrgao?: any;
	orgaoUsuario?: any;
	dtPeriodoPublicacao?: Date[];
	localidade?: any;
	labelPesquisaAvancada = 'Exibir pesquisa avançada';

	exibirFiltro = false;
	activeItem!: MenuItem;

	retorno?: RetornoEditais;
	firstPageEditais = 1;
	sizePageEditais = 5;
	subscriptions: Subscription[] = [];
	pesquisaPeloBotao = false;

	constructor(
		private messageService: MessageService,
		private cdRef: ChangeDetectorRef,
		private oportunidadesService: OportunidadesApiService,
		private pesquisarEditalApi: PesquisarEditalApiService,
		private datepipe: DatePipe,
		private config: PrimeNGConfig,
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private ui: UiService,
		private usuariosService: UsuariosService
	) {}

	ngAfterViewInit(): void {
		this.elTextoPesquisa.nativeElement.focus();

		if (this.mensagem !== undefined) {
			this.messageService.add(this.mensagem);
		}

		this.obterOrgaoUsuario();
		this.cdRef.detectChanges();
	}

	private obterOrgaoUsuario() {
		const dadosUsuario = this.usuariosService.getDadosUsuario();
		this.subscriptions.push(
			this.oportunidadesService.consultarOrgao(dadosUsuario.codigoOrgaoSiape.toString()).subscribe(
				orgao => {
					if (orgao) {
						this.selectOrgao = { name: orgao.sigla + ' - ' + orgao.nome, code: orgao.codigoSiape };
						this.orgaoUsuario = this.selectOrgao;
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	ngOnInit() {
		this.getSituacoesEdital();
		this.setCalendarioParaPortugues();
		this.obterParametrosDaUrl();
		if (this.parametrosDaPesquisa.pagina) {
			this.pesquisar();
		}
	}

	private obterParametrosDaUrl() {
		this.route.queryParamMap.subscribe(params => {
			if (params.get('textoPesquisa')) {
				this.parametrosDaPesquisa.textoPesquisa = params.get('textoPesquisa')!;
				this.textoPesquisa = params.get('textoPesquisa')!;
			}
			if (params.get('codigoSituacao')) {
				this.parametrosDaPesquisa.codigoSituacao = params.get('codigoSituacao')!;
			}
			if (params.get('codigoOrgao')) {
				this.parametrosDaPesquisa.codigoOrgao = params.get('codigoOrgao')!;
			}
			if (params.get('dataInicial')) {
				this.parametrosDaPesquisa.dataInicial = params.get('dataInicial')!;
			}
			if (params.get('dataFinal')) {
				this.parametrosDaPesquisa.dataFinal = params.get('dataFinal')!;
			}
			if (params.get('localidade')) {
				this.parametrosDaPesquisa.localidade = params.get('localidade')!;
			}
			if (params.get('pagina')) {
				this.parametrosDaPesquisa.pagina = +params.get('pagina')!;
				this.firstPageEditais = (this.parametrosDaPesquisa.pagina - 1) * this.sizePageEditais;
			}
			if (params.get('tamanho')) {
				this.parametrosDaPesquisa.tamanho = +params.get('tamanho')!;
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	/**
	 * Alterna exibição ou não do filtro de pesquisa avançado, mudando o label do botão
	 */
	changeExibirFiltro() {
		if (this.exibirFiltro) {
			this.exibirFiltro = false;
			this.labelPesquisaAvancada = 'Exibir pesquisa avançada';
		} else {
			this.exibirFiltro = true;
			this.labelPesquisaAvancada = 'Esconder pesquisa avançada';
		}
	}

	/**
	 * Para pegar o valor atualizado do componente de órgão
	 * @param event orgao selecionado
	 */
	atualizarOrgao(event: any) {
		this.selectOrgao = event;
	}

	/**
	 * Para pegar o valor atualizado do componente de localidade
	 * @param event localidade selecionada
	 */
	atualizarLocalidade(event: any) {
		this.localidade = event;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	atualizarEdital(event: Edital) {
		console.log('TODO: descobrir uma forma de atualizar o edital');
	}

	/**
	 * Evento de paginacao de editais
	 * @param event
	 */
	paginarEditais(event: any) {
		this.firstPageEditais = event.first;
		this.parametrosDaPesquisa.pagina = Math.trunc(this.firstPageEditais / this.sizePageEditais) + 1;
		this.pesquisar();
	}

	/**
	 * Pesquisa feita pelo botão pesquisar
	 */
	pesquisaInicial() {
		this.firstPageEditais = 1;
		this.montarParametrosPesquisa();
		this.pesquisar();
	}

	pesquisar() {
		this.loading = true;
		this.retorno = undefined;

		this.subscriptions.push(
			this.pesquisarEditalApi.pesquisarEdital(this.parametrosDaPesquisa).subscribe(data => {
				this.retorno = data;
				this.loading = false;
				this.router.navigate(['pesquisarEdital'], { queryParams: this.parametrosDaPesquisa });
			})
		);
	}

	limpar() {
		this.retorno = undefined;
		this.textoPesquisa = '';
		this.elTextoPesquisa.nativeElement.focus();
		this.selectSituacaoEdital = undefined;
		this.dtPeriodoPublicacao = undefined;
		this.localidade = null;
		this.selectOrgao = this.orgaoUsuario;
		this.cdRef.detectChanges();
	}

	/**
	 * Captura a mensagem passada pelo componente de botão de ação após a realização de uma ação
	 * @param event mensagem passada
	 */
	atualizarMensagem(event: Message) {
		this.mensagem = event;
		this.messageService.add(this.mensagem);
		this.cdRef.detectChanges();
		this.pesquisaInicial();
	}

	verificarDuasDatasPreenchidas() {
		if (this.dtPeriodoPublicacao?.length === 2 && this.dtPeriodoPublicacao[1] == null) {
			this.dtPeriodoPublicacao = undefined;
		}
	}

	/**
	 * Configura o componentes de calendário para português
	 */
	private setCalendarioParaPortugues() {
		this.translateService.setDefaultLang('pt');
		this.translateService.use('pt');
		this.subscriptions.push(this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res)));
	}

	private getSituacoesEdital() {
		this.situacoesEdital = [];
		this.subscriptions.push(
			this.oportunidadesService.situacaoEdital().subscribe(
				dados => {
					if (dados !== null) {
						dados.forEach(dado => {
							this.situacoesEdital.push({ name: dado.descricao, code: dado.codigo });
							this.situacoesEdital.sort((a, b) => a.name.localeCompare(b.name));
						});
					}
				},
				err => {
					this.messageService.add(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	private montarParametrosPesquisa() {
		this.parametrosDaPesquisa.textoPesquisa = this.textoPesquisa;
		this.parametrosDaPesquisa.codigoSituacao = this.selectSituacaoEdital ? this.selectSituacaoEdital.code : undefined;
		this.parametrosDaPesquisa.codigoOrgao = this.selectOrgao ? this.selectOrgao.code : undefined;
		this.parametrosDaPesquisa.dataInicial = this.dtPeriodoPublicacao
			? this.datepipe.transform(this.dtPeriodoPublicacao[0], 'dd/MM/yyyy')!
			: undefined;
		this.parametrosDaPesquisa.dataFinal = this.dtPeriodoPublicacao
			? this.datepipe.transform(this.dtPeriodoPublicacao[1], 'dd/MM/yyyy')!
			: undefined;
		this.parametrosDaPesquisa.localidade = this.localidade ? this.localidade.code : undefined;
		this.parametrosDaPesquisa.pagina = Math.trunc(this.firstPageEditais / this.sizePageEditais) + 1;
		this.parametrosDaPesquisa.tamanho = this.sizePageEditais;
	}
}
