import { Component } from '@angular/core';
import { DetalharLaudoService } from '../services/detalhar-laudo.service';

@Component({
	selector: 'sgp-detalhar-laudo-candidato',
	templateUrl: './detalhar-laudo-candidato.component.html'
})
export class DetalharLaudoCandidatoComponent {
	constructor(protected detalharLaudoService: DetalharLaudoService) {}
}
