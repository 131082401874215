<div class="row mb-3">
	<div class="col-sm-12 col-md-7 pt-1">
		<div data-cy="titulo-pagina" class="text-up-04 text-capitalize">Olá, {{ nomeUsuario?.toLowerCase() }}</div>
		<div data-cy="titulo-pagina" class="text-up-01 text-capitalize text-gray-40">{{ nomeOrgao?.toLowerCase() }}</div>
	</div>
	<div class="col-sm-12 col-md-5 text-right mt-4">
		<a
			[routerLink]="['/cadastrarEdital']"
			routerLinkActive="router-link-active"
			class="br-button primary mt-3 mt-sm-0 ml-sm-3"
			data-cy="botao-cadastrar-edital"
			*possuiPerfil="perfisEnum.GESTOR"
		>
			Cadastrar edital
		</a>
	</div>
</div>

<div id="mini-cards" class="row">
	<div class="col-sm-12 col-md-6">
		<div class="row">
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_escuro"
					icone="fa-file"
					[quantidade]="quantitativos.quantidadeEditaisPublicados || '0'"
					descricao="Editais vigentes"
					[ajuda]="true"
					textoAjuda="Editais vigentes são todos aqueles que já foram publicados e não foram cancelados, encerrados ou suspensos."
				></sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_claro"
					icone="fa-archive"
					[quantidade]="quantitativos.quantidadeUorgs || '0'"
					descricao="Uorgs com editais publicados"
					[ajuda]="true"
					textoAjuda="Total de unidades do órgão/entidade que já publicaram edital."
				></sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_escuro"
					icone="fa-briefcase"
					[quantidade]="quantitativos.quantidadeOportunidadesPublicadas || '0'"
					descricao="Oportunidades publicadas"
					[ajuda]="true"
					textoAjuda="Todas oportunidades dos editais vigentes."
				></sgp-mini-card>
			</div>
			<div class="col-sm-12 col-md-6">
				<sgp-mini-card
					cor="azul_claro"
					icone="fa-user"
					[quantidade]="quantitativos.quantidadeCandidatosInscritos || '0'"
					descricao="Candidatos inscritos"
					[ajuda]="true"
					textoAjuda="Candidatos inscritos em todos os editais publicados"
				></sgp-mini-card>
			</div>
		</div>
	</div>
	<div id="grafico_editais_por_situacao" class="col-sm-12 col-md-6" *ngIf="editaisPorSituacao">
		<sgp-card-grafico-doughnut
			titulo="Editais por situação"
			tipoGrafico="doughnut"
			[dados]="editaisPorSituacao"
			[height]="250"
		></sgp-card-grafico-doughnut>
	</div>
</div>

<div id="cards" class="row">
	<div id="lista_editais recentes" class="col-sm-12 col-md-12" *ngIf="!eAutorizador">
		<div class="br-card" *possuiPerfil="[perfisEnum.GESTOR, perfisEnum.GESTOR_REFORMA]">
			<p-tabView>
				<p-tabPanel header="Editais em andamento">
					<div class="card-content pb-5">
						<sgp-card-editais
							titulo="Últimos editais alterados"
							[editais]="editaisEmAndamento"
							[loading]="tabelaEditaisEmAndamento.loading"
							[colunas]="tabelaEditaisEmAndamento.colunas"
							[totalResultado]="tabelaEditaisEmAndamento.totalResultado"
							[primeiroItemResultado]="tabelaEditaisEmAndamento.primeiroItem"
							(paginarEdital)="paginarEditaisEmAndamento($event)"
							(itensPorPagina)="obterItensEditaisEmAndamento($event)"
						></sgp-card-editais>
					</div>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>

	<div id="lista_editais_pendentes_e_recentes" class="col-sm-12 col-md-12" *ngIf="eAutorizador">
		<div class="br-card" *possuiPerfil="[perfisEnum.AUTORIZADOR, perfisEnum.AUTORIZADOR_REFORMA]">
			<div class="card-content pb-4">
				<div class="br-tab">
					<nav class="tab-nav">
						<ul>
							<li class="tab-item" [ngClass]="{'is-active': tabSelecionada === 1}">
								<button type="button" data-panel="panel-1" (click)="trocarTab(1)">
									<span class="name">Editais com autorização pendente</span>
								</button>
							</li>
							<li class="tab-item" [ngClass]="{'is-active': tabSelecionada === 2}">
								<button type="button" data-panel="panel-2" (click)="trocarTab(2)">
									<span class="name">Editais em andamento</span>
								</button>
							</li>
						</ul>
					</nav>
					<div class="tab-content">
						<div class="tab-panel mt-4" id="panel-1" *ngIf="tabSelecionada === 1">
							<sgp-card-editais
								titulo="Editais com autorização pendente"
								[editais]="editaisComPendencias"
								[loading]="tabelaEditaisComPendencias.loading"
								[colunas]="tabelaEditaisComPendencias.colunas"
								[totalResultado]="tabelaEditaisComPendencias.totalResultado"
								[primeiroItemResultado]="tabelaEditaisComPendencias.primeiroItem"
								[pendentes]="true"
								(paginarEdital)="paginarEditaisPendencias($event)"
								(itensPorPagina)="obterItensEditaisPendencias($event)"
							></sgp-card-editais>
						</div>
						<div class="tab-panel mt-4" id="panel-2" *ngIf="tabSelecionada === 2">
							<sgp-card-editais
								titulo="Últimos editais alterados"
								[editais]="editaisEmAndamento"
								[loading]="tabelaEditaisEmAndamento.loading"
								[colunas]="tabelaEditaisEmAndamento.colunas"
								[totalResultado]="tabelaEditaisEmAndamento.totalResultado"
								[primeiroItemResultado]="tabelaEditaisEmAndamento.primeiroItem"
								(paginarEdital)="paginarEditaisEmAndamento($event)"
								(itensPorPagina)="obterItensEditaisEmAndamento($event)"
							></sgp-card-editais>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="grafico_evolucao_mensal_editais" class="col-sm-12 col-md-6">
		<sgp-card-grafico-line
			titulo="Editais publicados nos últimos 12 meses"
			[dados]="evolucaoMensalEditaisPublicados"
			[exibirLegenda]="false"
			[height]="250"
			*ngIf="evolucaoMensalEditaisPublicados"
		>
		</sgp-card-grafico-line>
	</div>

	<div id="grafico_evolucao_mensal_candidatos" class="col-sm-12 col-md-6">
		<sgp-card-grafico-line
			titulo="Candidatos inscritos nos últimos 12 meses"
			[dados]="evolucaoMensalCandidatosInscritos"
			[exibirLegenda]="false"
			[height]="250"
			*ngIf="evolucaoMensalCandidatosInscritos"
		>
		</sgp-card-grafico-line>
	</div>

	<div id="grafico_oportunidades_por_movimentacao" class="col-sm-12 col-md-6" *ngIf="oportunidadesPorMovimentacao">
		<sgp-card-grafico-doughnut
			titulo="Oportunidades por tipo de movimentação"
			tipoGrafico="doughnut"
			[dados]="oportunidadesPorMovimentacao"
			[height]="268"
		></sgp-card-grafico-doughnut>
	</div>

	<div id="grafico_uorgs_mais_editais" class="col-sm-12 col-md-6">
		<sgp-card-grafico-bar-horizontal
			titulo="Editais publicados por UORG"
			[dados]="uorgsComMaisEditaisPublicados"
			[pagina]="paginaUorgsComMaisEditaisPublicados"
			(anterior)="navegarUorgsComMaisEditaisPublicados($event)"
			(proximo)="navegarUorgsComMaisEditaisPublicados($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_uorgs_mais_oportunidades" class="col-sm-12 col-md-6">
		<sgp-card-grafico-bar-horizontal
			titulo="Oportunidades publicadas por UORG"
			[dados]="uorgsComMaisOportunidadesPublicadas"
			[pagina]="paginaUorgsComMaisOportunidadesPublicadas"
			(anterior)="navegarUorgsComMaisOportunidadesPublicadas($event)"
			(proximo)="navegarUorgsComMaisOportunidadesPublicadas($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>

	<div id="grafico_uorgs_mais_candidatos" class="col-sm-12 col-md-6">
		<sgp-card-grafico-bar-horizontal
			titulo="Candidados inscritos por UORG"
			[dados]="uorgsComMaisCandidatosInscritos"
			[pagina]="paginaUorgsComMaisCandidatosInscritos"
			(anterior)="navegarUorgsComMaisCandidatosInscritos($event)"
			(proximo)="navegarUorgsComMaisCandidatosInscritos($event)"
		></sgp-card-grafico-bar-horizontal>
	</div>
</div>
